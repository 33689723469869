import React, { useContext } from 'react';
import { compose, withProps } from 'recompose';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps';
import { Box } from '@material-ui/core';
import { DestinationContext } from '../../Providers/Destination';

const MapDestination = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_API_GOOGLE_MAP}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `87vh` }} />,
    center: { lat: -18.8791902, lng: 47.5079055 },
    streetViewControl: false,
    fullscreenControl: false,
    zoomControl: true,
    disableDefaultUI: true,
  }),
  withScriptjs,
  withGoogleMap,
)((props: any) => {
  const { setIdDestination } = useContext(DestinationContext);
  const {
    setLatitudeDestination,
    setLongitudeDestination,
    latitude,
    longitude,
  } = useContext(DestinationContext);

  const onMapClick = (
    e: google.maps.MouseEvent | google.maps.IconMouseEvent,
  ) => {
    setIdDestination('');
    setLatitudeDestination(+e.latLng.lat());
    setLongitudeDestination(+e.latLng.lng());
  };

  const onDragEnd = (e: google.maps.MouseEvent) => {
    setIdDestination('');
    setLatitudeDestination(+e.latLng.lat());
    setLongitudeDestination(+e.latLng.lng());
  };

  const defaultMapOptions = {
    fullscreenControl: false,
    streetViewControl: false,
    // mapTypeControl: false,
    // zoomControl: false,
  };

  return (
    <Box position="relative" height="100%">
      <GoogleMap
        defaultZoom={14}
        defaultCenter={{
          lat: latitude ? latitude : -18.8791902,
          lng: longitude ? longitude : 47.5079055,
        }}
        center={{
          lat: latitude ? latitude : -18.8791902,
          lng: longitude ? longitude : 47.5079055,
        }}
        defaultOptions={defaultMapOptions}
        onClick={onMapClick}>
        {latitude != undefined && longitude != undefined && (
          <Marker
            title={'Maintenir puis déplacer!'}
            position={{ lat: latitude, lng: longitude }}
            draggable={true}
            onDragEnd={(e) => {
              onDragEnd(e);
            }}
          />
        )}
      </GoogleMap>
    </Box>
  );
});

export default MapDestination;

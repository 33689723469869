import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function File(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 19.129 21.861">
      <path
        d="M20.477,5.855a19.361,19.361,0,0,0-1.861-2.129,19.363,19.363,0,0,0-2.129-1.861,3.405,3.405,0,0,0-1.941-.9H3.957A1.71,1.71,0,0,0,2.249,2.672V21.117a1.71,1.71,0,0,0,1.708,1.708H19.67a1.71,1.71,0,0,0,1.708-1.708V7.8a3.4,3.4,0,0,0-.9-1.941ZM17.65,4.692A18.635,18.635,0,0,1,19.2,6.429H15.913V3.143A18.615,18.615,0,0,1,17.65,4.692Zm2.362,16.425a.346.346,0,0,1-.342.342H3.957a.346.346,0,0,1-.342-.342V2.672a.346.346,0,0,1,.342-.342H14.546V7.112a.683.683,0,0,0,.683.683h4.782ZM16.6,18.726H7.032a.683.683,0,1,1,0-1.366H16.6a.683.683,0,1,1,0,1.366Zm0-2.733H7.032a.683.683,0,1,1,0-1.366H16.6a.683.683,0,0,1,0,1.366Zm0-2.733H7.032a.683.683,0,1,1,0-1.366H16.6a.683.683,0,0,1,0,1.366Z"
        transform="translate(-2.249 -0.964)"
      />
    </SvgIcon>
  );
}

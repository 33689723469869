import gql from 'graphql-tag';

export const DO_PAIEMENT = gql`
  mutation PAIEMENT(
    $delivery: String
    $shortdelivery: String
    $order: String
    $booking: String
    $requestBooking: String
    $requestBookingFlight: String
    $typeOrder: String!
    $paymentMethod: String!
    $reference: String!
    $datePayment: Date
    $amountPayment: Float
  ) {
    createPayment(
      delivery: $delivery
      shortdelivery: $shortdelivery
      order: $order
      booking: $booking
      requestBooking: $requestBooking
      requestBookingFlight: $requestBookingFlight
      typeOrder: $typeOrder
      paymentMethod: $paymentMethod
      reference: $reference
      datePayment: $datePayment
      amountPayment: $amountPayment
    ) {
      message
      payment {
        id
        delivery {
          id
          manifest
          manifestItem
          status
        }
        paymentMethod
        reference
        amount
        amontSolde
        datePayment
      }
    }
  }
`;

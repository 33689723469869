import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function AddressBook(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 17.797 21.356">
      <path
        d="M17.517,0H3.28V1.78H17.517ZM3.28,21.356H17.517v-1.78H3.28Zm14.237-17.8H3.28A1.785,1.785,0,0,0,1.5,5.339V16.017A1.785,1.785,0,0,0,3.28,17.8H17.517a1.785,1.785,0,0,0,1.78-1.78V5.339A1.785,1.785,0,0,0,17.517,3.559ZM10.4,6.006a2,2,0,1,1-2,2A2.005,2.005,0,0,1,10.4,6.006Zm4.449,9.121h-8.9V13.793c0-1.486,2.963-2.225,4.449-2.225s4.449.739,4.449,2.225Z"
        transform="translate(-1.5)"
      />
    </SvgIcon>
  );
}

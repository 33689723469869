import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  positionButton: {
    position: 'absolute',
    right: '2rem',
    bottom: '2rem',
    zIndex: 10,
    color: '#6B6B6B',
    backgroundColor: theme.palette.background.paper,
    '& svg': {
      marginRight: theme.spacing(2),
    },
  },
  list: {
    position: 'absolute',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    zIndex: 10,
    '& *': {
      fontSize: '0.875rem',
    },
  },
  root: {
    width: '100%',
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
}));

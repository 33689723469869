import gql from 'graphql-tag';

export const DO_INIT_PAYMENT = gql`
  mutation INIT_PAYMENT(
    $adresseip: String!
    $idpanier: String!
    $montant: Float!
    $nom: String!
    $email: String!
    $reference: String!
  ) {
    initPayment(
      adresseip: $adresseip
      idpanier: $idpanier
      montant: $montant
      nom: $nom
      email: $email
      reference: $reference
    ) {
      urlVanillaPay
      urlMobileMoney
    }
  }
`;

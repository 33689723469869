import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { DesktopWindows } from '@material-ui/icons';
import moment from 'moment';
import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  DO_CREATE_DELIVERY,
  DO_SHORT_DELIVERY,
} from '../../Graphql/Delivery/mutation';
import {
  CREATE_DELIVERY,
  CREATE_DELIVERYVariables,
} from '../../Graphql/Delivery/types/CREATE_DELIVERY';
import {
  SHORT_DELIVERY,
  SHORT_DELIVERYVariables,
} from '../../Graphql/Delivery/types/SHORT_DELIVERY';
import { DO_CREATE_NOTIFICATION_ITERM_ADMIN } from '../../Graphql/Notification/mutation';
import {
  CREATE_NOTIFICATION_ITERM_ADMIN,
  CREATE_NOTIFICATION_ITERM_ADMINVariables,
} from '../../Graphql/Notification/types/CREATE_NOTIFICATION_ITERM_ADMIN';
import { DO_CREATE_ORDER } from '../../Graphql/Order/mutation';
import {
  CREATE_ORDER,
  CREATE_ORDERVariables,
} from '../../Graphql/Order/types/CREATE_ORDER';
import { DO_PAIEMENT } from '../../Graphql/Paiement/mutation';
import {
  PAIEMENT,
  PAIEMENTVariables,
} from '../../Graphql/Paiement/types/PAIEMENT';
import SnackVariableInterface from '../../Interfaces/SnackVariableInterface';
import { DeliveryInfoContext } from '../../Providers/DeliveryInfo';
import { DestinataireSortedByDistanceContext } from '../../Providers/DestinataireSortedByDistance';
import { DestinationContext } from '../../Providers/Destination';
import { DestinationsContext } from '../../Providers/Destinations';
import { ExpediteurContext } from '../../Providers/Expediteur';
import { IndexDeliveryContext } from '../../Providers/IndexDelivery';
import { PayementVanillaContext } from '../../Providers/PayementVanilla';
import { UserContext } from '../../Providers/User';
import { displaySnackBar } from '../../Utils/snackBarUtils';

export const useCreateDelivery = () => {
  const client = useApolloClient();

  const { usersSortedByDistance } = useContext(
    DestinataireSortedByDistanceContext,
  );

  const { idMe } = useContext(UserContext);
  const history = useHistory();

  const { dateDelivery, selectedIdButtonPaiement } = useContext(
    DeliveryInfoContext,
  );

  const { clearExpediteur } = useContext(ExpediteurContext);
  const { clearDestinations } = useContext(DestinationsContext);
  const { clearDeliveryInfo } = useContext(DeliveryInfoContext);
  const { clearDestination } = useContext(DestinationContext);

  const { setIndexDelivery } = useContext(IndexDeliveryContext);
  const [idDeliveryLocal, setIdDeliveryLocal] = useState('');
  const [idOrder, setIdOrder] = useState<string>('');

  const {
    setMontant,
    montant: montantVanillaPay,
    reference: referenceVanillaPay,
    setIdPanier: setIdPanierVanilla,
    setReference: setReferenceVanilla,
  } = useContext(PayementVanillaContext);

  const calculDistanceTotal = () => {
    let somme = 0;
    for (const item of usersSortedByDistance) {
      somme += item.distanceParcouru;
    }
    return somme;
  };

  const [
    doCreateNotificationAdmin,
    { loading: loadingCreateNotification },
  ] = useMutation<
    CREATE_NOTIFICATION_ITERM_ADMIN,
    CREATE_NOTIFICATION_ITERM_ADMINVariables
  >(DO_CREATE_NOTIFICATION_ITERM_ADMIN, {
    onError: (err) => {
      if (err.networkError?.message === 'Failed to fetch') {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Problème de connexion...',
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      }
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: err.message.split(':')[1],
        isOpen: true,
      };
      return displaySnackBar(client, snackBarData);
    },
  });

  const [createOrder, { loading: loadingCreateOrder }] = useMutation<
    CREATE_ORDER,
    CREATE_ORDERVariables
  >(DO_CREATE_ORDER, {
    onError: (err) => {
      if (err.networkError?.message === 'Failed to fetch') {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Problème de connexion...',
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      }
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: err.message.split(':')[1],
        isOpen: true,
      };
      return displaySnackBar(client, snackBarData);
    },
    onCompleted: (data) => {
      if (data && data.createOrder && data.createOrder.id) {
        setIdOrder(data.createOrder.id);
      }
    },
  });

  const [doCreateDelivery, { loading: loadingCreateDelivery }] = useMutation<
    CREATE_DELIVERY,
    CREATE_DELIVERYVariables
  >(DO_CREATE_DELIVERY, {
    onCompleted: async (data) => {
      setIndexDelivery(0);
      setIdDeliveryLocal(
        data && data.createDelivery.delivery && data.createDelivery.delivery.id,
      );
      setReferenceVanilla(
        data &&
          data.createDelivery.delivery &&
          'LI' + data.createDelivery.delivery.reference,
      );
      setIdPanierVanilla(
        data &&
          data.createDelivery.delivery &&
          'LI' + data.createDelivery.delivery.reference,
      );
      createOrder({ variables: { typeOrder: 'DELIVERY' } });
      for (let i = 1; i < usersSortedByDistance.length; i++) {
        await doCreateShortDelivery({
          variables: {
            delivery:
              data &&
              data.createDelivery.delivery &&
              data.createDelivery.delivery.id,
            distance: usersSortedByDistance[i].distanceParcouru,
            estimation: usersSortedByDistance[i].dureeApproximatif || '',
            pickup: usersSortedByDistance[i - 1].id,
            dropoff: usersSortedByDistance[i].idPickOrDrop,
          },
        });
      }

      if (selectedIdButtonPaiement === '1') {
        history.push('/delivery');
        clearDestination();
        clearDestinations();
        clearDeliveryInfo();
        clearExpediteur();
      } else {
        setMontant(montantVanillaPay);
        history.push('/paiement');
        clearExpediteur();
        clearDestination();
        clearDestinations();
        clearDeliveryInfo();
      }
    },
    onError: (err) => {
      if (err.networkError?.message === 'Failed to fetch') {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Problème de connexion...',
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      }
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: err.message.split(':')[1],
        isOpen: true,
      };
      return displaySnackBar(client, snackBarData);
    },
  });
  const [
    doCreateShortDelivery,
    { loading: loadingShortDelivery },
  ] = useMutation<SHORT_DELIVERY, SHORT_DELIVERYVariables>(DO_SHORT_DELIVERY, {
    onCompleted: (data) => {
      doPaiment({
        variables: {
          typeOrder: 'DELIVERY',
          order: idOrder,
          delivery: idDeliveryLocal,
          reference: referenceVanillaPay,
          shortdelivery: data.createShortDelivery.shortdelivery.id,
          paymentMethod:
            selectedIdButtonPaiement === '1'
              ? 'PAIEMENT_AT_DELIVERY'
              : 'PAIEMENT_MOBILE_MONEY',
          datePayment: moment(dateDelivery).utc().unix(),
        },
      });
    },
    onError: (err) => {
      if (err.networkError?.message === 'Failed to fetch') {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Problème de connexion...',
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      }
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: err.message.split(':')[1],
        isOpen: true,
      };
      return displaySnackBar(client, snackBarData);
    },
  });
  const [doPaiment, { loading: loadingPaiement }] = useMutation<
    PAIEMENT,
    PAIEMENTVariables
  >(DO_PAIEMENT, {
    onCompleted: (data) => {},
    onError: (err) => {
      if (err.networkError?.message === 'Failed to fetch') {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Problème de connexion...',
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      }
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: err.message.split(':')[1],
        isOpen: true,
      };
      return displaySnackBar(client, snackBarData);
    },
  });

  return {
    doCreateDelivery,
    loadingCreateDelivery,
    loadingPaiement,
    loadingShortDelivery,
    calculDistanceTotal,
  };
};

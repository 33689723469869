import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { Box } from '@material-ui/core';
import moment from 'moment';
import React, { FC, Fragment, useContext, useState } from 'react';
import Backdrop from '../../../Components/Backdrop';
import CardHistory from '../../../Components/CardHistory/CardHistory';
import { classnames } from '../../../Components/MapComponent/helper';
import { DO_GET_BILL_HOTEL_USER } from '../../../Graphql/Bill/query';
import {
  GET_BILL_HOTEL_USER,
  GET_BILL_HOTEL_USERVariables,
} from '../../../Graphql/Bill/types/GET_BILL_HOTEL_USER';
import filehistory from '../../../Images/filehistory.svg';
import SnackVariableInterface from '../../../Interfaces/SnackVariableInterface';
import { UserContext } from '../../../Providers/User';
import { displaySnackBar } from '../../../Utils/snackBarUtils';
import useStyles from './style';
interface Hotel {
  value?: string | null;
  setValue?: (params: any) => void;
  index?: number;
  setIndex: (index: any) => void;
  showDetails?: () => void;
}

const HistoriqueHotel: FC<Hotel> = ({ setValue, setIndex, showDetails }) => {
  const classes = useStyles();
  const { customerId } = useContext(UserContext);
  const client = useApolloClient();
  const [active, setActive] = useState(0);

  const { data, loading } = useQuery<
    GET_BILL_HOTEL_USER,
    GET_BILL_HOTEL_USERVariables
  >(DO_GET_BILL_HOTEL_USER, {
    variables: { customer_id: customerId },
    skip: customerId ? false : true,
    fetchPolicy: 'cache-and-network',
    onError: (err) => {
      if (err.networkError?.message === 'Failed to fetch') {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Problème de connexion...',
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      }
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: err.message.split(':')[1],
        isOpen: true,
      };
      return displaySnackBar(client, snackBarData);
    },
    onCompleted: () => {
      setValue && setValue(data?.billsHotelUser[0]);
    },
  });
  const handleClick = (index: any | null) => {
    if (index) {
      setValue && setValue(data?.billsHotelUser[index - 1]);
    }
  };

  const Listehistoriques = data?.billsHotelUser.map((item, index) => {
    let numero = index + 1;
    return (
      <Box
        key={`${index}`}
        className={classnames(
          classes.demandeContainer,
          active === index ? classes.clicked : '',
        )}
        onClick={() => {
          handleClick(numero);
          setActive(index);
        }}>
        <Backdrop open={loading} />
        <img src={filehistory}></img>
        <CardHistory
          titre={item.reference}
          date={moment(item.datePaymentBill).format('DD-MM-YYYY')}
          price={+item.amountTotal}
        />
      </Box>
    );
  });
  return (
    <ul>
      <Backdrop open={loading} />
      {Listehistoriques}
    </ul>
  );
  //   <Fragment>

  //   <Box>
  //   <Typography className={classes.scndContentDate}>
  //     Le:
  //     {facture === undefined
  //       ? '--/--/----'
  //       : facture && facture[index - 1].date}
  //   </Typography>
  //   <Box className={classes.alignementScndContent1}>
  //     <Typography variant="h6">Nombre de Livraisons</Typography>
  //     <Typography variant="h6">03</Typography>
  //   </Box>
  //   <Box className={classes.contentDistance}>
  //     <Box className={classes.alignementScndContent2}>
  //       <Box className={classes.alignementScndContent}>
  //         <Typography variant="h6">Distance</Typography>
  //         <Typography variant="h6">xx Km</Typography>
  //       </Box>
  //       <Box className={classes.alignementScndContent}>
  //         <Typography variant="h6">Frais d&apos;approche</Typography>
  //         <Typography variant="h6">xx Ar</Typography>
  //       </Box>
  //     </Box>
  //   </Box>
  //   <Box className={classes.alignementScndContent}>
  //     <Typography variant="h6">Total</Typography>
  //     <Typography variant="h5">
  //       {facture === undefined
  //         ? 'XX XXX'
  //         : facture && facture[index - 1].price}{' '}
  //       Ar
  //     </Typography>
  //   </Box>
  // </Box>
  // <Box className={classes.alignContentLast}>
  //   <Typography variant="h6">N° de Facture :F344048920</Typography>
  //   {/* <Typography variant="h6">Le : 02/09/2020</Typography> */}

  //   <Button
  //     variant="contained"
  //     color="secondary"
  //     className={classes.button}
  //     startIcon={<GetAppIcon />}>
  //     Télecharger
  //   </Button>
  // </Box>;
  // <Fragment/>
};
export default HistoriqueHotel;

import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function Car(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 22.5 15.75">
      <path
        d="M19.125,6.75h-.562L14.738,1.969a2.251,2.251,0,0,0-1.757-.844H5.461A2.25,2.25,0,0,0,3.372,2.539L1.688,6.829A2.245,2.245,0,0,0,0,9v3.938a.562.562,0,0,0,.563.563H2.25A3.375,3.375,0,0,0,9,13.5h4.5a3.375,3.375,0,0,0,6.75,0h1.688a.562.562,0,0,0,.563-.562V10.125A3.375,3.375,0,0,0,19.125,6.75Zm-13.5,8.438A1.688,1.688,0,1,1,7.313,13.5,1.689,1.689,0,0,1,5.625,15.188ZM8.156,6.75H4.111l1.35-3.375h2.7Zm1.688,0V3.375h3.137l2.7,3.375Zm7.031,8.438A1.688,1.688,0,1,1,18.563,13.5,1.689,1.689,0,0,1,16.875,15.188Z"
        transform="translate(0 -1.125)"
      />
    </SvgIcon>
  );
}

import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function DeliveryLater(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 19.857 19.857">
      <g
        id="Groupe_862"
        data-name="Groupe 862"
        transform="translate(-985.098 -253.361)">
        <g
          id="Groupe_862-2"
          data-name="Groupe 862"
          transform="translate(991.022 258.024)">
          <path
            id="Tracé_2076"
            data-name="Tracé 2076"
            d="M1079.921,328.142h-.575v-.605h8v.606h-.572c-.065.4-.108.77-.191,1.135a4.384,4.384,0,0,1-1.412,2.287,8.851,8.851,0,0,0-.639.627.855.855,0,0,0,.006,1.232c.271.294.567.565.856.842a4.431,4.431,0,0,1,1.337,2.98c.005.069.011.137.018.228h.6v.6h-8.008v-.589h.577c.063-.393.1-.766.186-1.13a4.372,4.372,0,0,1,1.4-2.292c.22-.2.44-.405.64-.626a.859.859,0,0,0,0-1.249c-.265-.288-.556-.554-.84-.825a4.441,4.441,0,0,1-1.35-3c0-.055-.01-.109-.017-.164A.322.322,0,0,0,1079.921,328.142Zm.6.018c.1.452.183.879.287,1.3a.237.237,0,0,0,.184.122q2.355.01,4.71,0a.219.219,0,0,0,.173-.106c.1-.43.193-.864.292-1.319Zm2.516,4.7a1.883,1.883,0,0,1-.686,1.262c-.334.331-.681.652-.985,1.009a6.554,6.554,0,0,0-.514.812.513.513,0,0,0,.089.013c.67-.266,1.339-.532,2.007-.8a.162.162,0,0,0,.086-.117C1083.042,334.309,1083.041,333.584,1083.041,332.859Zm.615.006c0,.713,0,1.426.006,2.139a.225.225,0,0,0,.124.157c.491.206.986.4,1.48.6l.511.2.052-.052a6.505,6.505,0,0,0-.514-.8c-.311-.361-.666-.684-1-1.023A1.838,1.838,0,0,1,1083.656,332.865Z"
            transform="translate(-1079.343 -327.536)"
          />
        </g>
        <g
          id="Groupe_863"
          data-name="Groupe 863"
          transform="translate(985.098 253.361)">
          <path
            id="Tracé_2077"
            data-name="Tracé 2077"
            d="M995.026,273.218a9.928,9.928,0,1,1,9.928-9.928A9.94,9.94,0,0,1,995.026,273.218Zm0-18.788a8.86,8.86,0,1,0,8.86,8.86A8.87,8.87,0,0,0,995.026,254.43Z"
            transform="translate(-985.098 -253.361)"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export default function FlyFirstOne(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 38.175 55.175">
      <g
        id="Icon_metro-airplane"
        data-name="Icon metro-airplane"
        transform="translate(1.522 1.173)">
        <path
          id="Icon_metro-airplane-2"
          data-name="Icon metro-airplane"
          d="M11.441,24.1l6.334-6.334-15.2-11.4L7.006,1.928l19,7.6,5.984-5.984c1.725-1.725,4.134-2.138,5.353-.918s.806,3.629-.919,5.353l-5.983,5.983,7.6,19.007-4.435,4.435L22.21,22.2l-6.335,6.335v8.869H11.44L9.223,30.753,2.571,28.536V24.1H11.44Z"
          transform="translate(-2.571 -1.928)"
          fill="none"
          strokeWidth="2"
        />
      </g>
    </SvgIcon>
  );
}

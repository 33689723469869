import React, { useContext, useState } from 'react';
import { Box, Drawer, Button, Typography } from '@material-ui/core';
import Widget from '../../Components/Widget';
import layoutStyles from '../MakeDelivery/style';
import styles from './style';
import CustomButton from '../../Components/CustomButton';
import Watch from '@material-ui/icons/WatchLaterOutlined';
import Car from '../../Components/Icons/Car';
import classnames from 'classnames';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import ArrowRight from '@material-ui/icons/ArrowRight';
import DeliveryDetails from './DeliveryDetails';
import {
  MY_DELIVERY_WAITING,
  MY_DELIVERY_WAITINGVariables,
  MY_DELIVERY_WAITING_myDeliveriesWaiting,
} from '../../Graphql/Delivery/types/MY_DELIVERY_WAITING';
import {
  DO_MY_DELIVERY_ON_THE_WAY,
  DO_MY_DELIVERY_WAITING,
} from '../../Graphql/Delivery/query';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { UserContext } from '../../Providers/User';
import SnackVariableInterface from '../../Interfaces/SnackVariableInterface';
import { displaySnackBar } from '../../Utils/snackBarUtils';
import {
  MY_DELIVERY_ON_THE_WAY,
  MY_DELIVERY_ON_THE_WAYVariables,
  MY_DELIVERY_ON_THE_WAY_myDeliveriesOnTheWay,
} from '../../Graphql/Delivery/types/MY_DELIVERY_ON_THE_WAY';
import moment from 'moment';
import Backdrop from '../../Components/Backdrop';
import MapDeliveryDetail from './MapDeliveryDetail';
import { PointContext } from '../../Providers/Points';

enum DeliveryState {
  PROGRESS = 'progress',
  WAITING = 'waiting',
}

const Delivery = () => {
  const client = useApolloClient();
  const { idMe } = useContext(UserContext);
  const { addPoints, clearPoints } = useContext(PointContext);

  const { data: dataWaiting, loading: loadingWaiting } = useQuery<
    MY_DELIVERY_WAITING,
    MY_DELIVERY_WAITINGVariables
  >(DO_MY_DELIVERY_WAITING, {
    variables: { id: idMe },
    skip: idMe ? false : true,
    onError: (err) => {
      if (err.networkError?.message === 'Failed to fetch') {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Problème de connexion...',
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      }
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: err.message.split(':')[1],
        isOpen: true,
      };
      return displaySnackBar(client, snackBarData);
    },
    fetchPolicy: 'network-only',
  });

  const { data: dataOnTheWay, loading: loadingOnTheWay } = useQuery<
    MY_DELIVERY_ON_THE_WAY,
    MY_DELIVERY_ON_THE_WAYVariables
  >(DO_MY_DELIVERY_ON_THE_WAY, {
    variables: { id: idMe },
    skip: idMe ? false : true,
    onError: (err) => {
      if (err.networkError?.message === 'Failed to fetch') {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Problème de connexion...',
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      }
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: err.message.split(':')[1],
        isOpen: true,
      };
      return displaySnackBar(client, snackBarData);
    },
    fetchPolicy: 'network-only',
  });

  const drawerRef = React.useRef(null);

  // Gestion Drawer
  const [positionButton, setPositionButton] = React.useState(0);
  let position: any = 0;

  const [open, setOpen] = React.useState<boolean>(false);
  const handleOpenDrawer = () => {
    if (open) {
      setOpen(false);
    } else {
      const snackBarData: SnackVariableInterface = {
        type: 'INFO',
        message: 'Veuillez sélectionner une livraison.',
        isOpen: true,
      };
      displaySnackBar(client, snackBarData);
    }
  };

  React.useEffect(() => {
    position = drawerRef.current
      ? window.getComputedStyle(drawerRef.current as any).width
      : 0;
    setPositionButton(position);
  }, [open]);

  const classesLayout = layoutStyles();
  const classes = styles({ positionButton, open });

  const [deliveryWaiting, setDeliveryWaiting] = useState<
    MY_DELIVERY_WAITING_myDeliveriesWaiting | undefined
  >(undefined);
  const [deliveryOnWay, setDeliveryOnWay] = useState<
    MY_DELIVERY_ON_THE_WAY_myDeliveriesOnTheWay | undefined
  >(undefined);

  const [delivery, setDelivery] = React.useState<DeliveryState>(
    DeliveryState.PROGRESS,
  );

  const handleViewDelivery = async (e: React.MouseEvent) => {
    setDelivery(e.currentTarget.id as DeliveryState);
    setOpen(false);
  };

  const selectDelivery = (id: string) => {
    clearPoints();
    const dataDeliveryOnTheWay = dataOnTheWay?.myDeliveriesOnTheWay.find(
      (item) => item.id === id,
    );

    const dataDEliveryWaiting = dataWaiting?.myDeliveriesWaiting.find(
      (item) => item.id === id,
    );

    if (dataDeliveryOnTheWay !== undefined) {
      setDeliveryOnWay(dataDeliveryOnTheWay);

      const lat = JSON.parse(dataDeliveryOnTheWay.pickup.location)
        .coordinates[1];
      const lng = JSON.parse(dataDeliveryOnTheWay.pickup.location)
        .coordinates[0];
      let points = [{ latitude: lat, longitude: lng }];

      dataDeliveryOnTheWay.shortDelivery.map((item) => {
        let lat = JSON.parse(item.dropoff.location).coordinates[1];
        let lng = JSON.parse(item.dropoff.location).coordinates[0];
        points.push({ latitude: +lat, longitude: +lng });
      });
      addPoints(points);
    }

    if (dataDEliveryWaiting) {
      setDeliveryWaiting(dataDEliveryWaiting);

      const lat = JSON.parse(dataDEliveryWaiting.pickup.location)
        .coordinates[1];
      const lng = JSON.parse(dataDEliveryWaiting.pickup.location)
        .coordinates[0];
      let points = [{ latitude: lat, longitude: lng }];

      dataDEliveryWaiting.shortDelivery.map((item) => {
        let lat = JSON.parse(item.dropoff.location).coordinates[1];
        let lng = JSON.parse(item.dropoff.location).coordinates[0];
        points.push({ latitude: +lat, longitude: +lng });
      });
      addPoints(points);
    }

    setOpen(!open);
  };

  return (
    <Box className={classnames(classesLayout.delivery, classes.reference)}>
      <Backdrop open={loadingOnTheWay} />
      <Backdrop open={loadingWaiting} />
      <Box className={classesLayout.controls}>
        <Widget title="Mes livraisons" disableWidgetMenu elevation={2}>
          <Box className={classes.flex}>
            <CustomButton
              state={delivery}
              id={DeliveryState.PROGRESS}
              startIcon={<Car fontSize="small" />}
              onClick={handleViewDelivery}>
              En route
            </CustomButton>
            <CustomButton
              state={delivery}
              id={DeliveryState.WAITING}
              startIcon={<Watch fontSize="small" />}
              onClick={handleViewDelivery}>
              En attente
            </CustomButton>
          </Box>

          {delivery === 'progress' ? (
            dataOnTheWay && dataOnTheWay.myDeliveriesOnTheWay.length !== 0 ? (
              dataOnTheWay.myDeliveriesOnTheWay &&
              dataOnTheWay.myDeliveriesOnTheWay.map((delivery, index) => (
                <Box
                  className={classes.resume}
                  key={delivery.reference}
                  onClick={() => selectDelivery(delivery.id)}>
                  <Typography variant="h3" gutterBottom>
                    Référence &nbsp;:&nbsp; {delivery.reference}
                  </Typography>
                  <Typography variant="subtitle1">
                    Date prevue :{' '}
                    {moment(delivery.deliveryDate).format('DD MMM YYYY HH:mm')}
                  </Typography>
                </Box>
              ))
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flex="1">
                <Typography variant="h3" gutterBottom>
                  Aucune livraison en route.
                </Typography>
              </Box>
            )
          ) : null}
          {delivery === 'waiting' ? (
            dataWaiting && dataWaiting.myDeliveriesWaiting.length !== 0 ? (
              dataWaiting.myDeliveriesWaiting &&
              dataWaiting.myDeliveriesWaiting.map((delivery, index) => (
                <Box
                  className={classes.resume}
                  key={delivery.reference}
                  onClick={() => selectDelivery(delivery.id)}>
                  <Typography variant="h3" gutterBottom>
                    Référence&nbsp;:&nbsp; {delivery.reference}
                  </Typography>
                  <Typography variant="subtitle1">
                    Date prevue :{' '}
                    {moment(delivery.deliveryDate).format('DD MMM YYYY HH:mm')}
                  </Typography>
                </Box>
              ))
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flex="1">
                <Typography variant="h3" gutterBottom>
                  Aucun livraison en route.
                </Typography>
              </Box>
            )
          ) : null}
        </Widget>
        <Box>
          <Drawer
            open={open}
            PaperProps={{ elevation: 2, ref: drawerRef }}
            anchor="left"
            variant="persistent"
            classes={{ paper: classes.drawer }}
            transitionDuration={{ enter: 0 }}>
            {delivery === 'progress' && deliveryOnWay !== undefined && (
              <DeliveryDetails selected={deliveryOnWay} />
            )}
            {delivery === 'waiting' && deliveryWaiting !== undefined && (
              <DeliveryDetails selected={deliveryWaiting} />
            )}
          </Drawer>
          <Button className={classes.toggleBtn} onClick={handleOpenDrawer}>
            {open ? (
              <ArrowLeft fontSize="small" />
            ) : (
              <ArrowRight fontSize="small" />
            )}
          </Button>
        </Box>
      </Box>
      <Box className={classesLayout.map}>
        <MapDeliveryDetail />
      </Box>
    </Box>
  );
};

export default Delivery;

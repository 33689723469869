import gql from 'graphql-tag';

export const DO_ADDRESS_BOOK_USER = gql`
  query ADDRESS_BOOK_USER($customer_id: String!) {
    addressBooksUser(customer_id: $customer_id) {
      id
      name
      phone
      address
    }
  }
`;

import React, { FC, useContext } from 'react';
import { Button, Box, Typography as TypographyBase } from '@material-ui/core';
// import {
//   withStyles,
//   Badge as BadgeBase,
//   Typography as TypographyBase,
//   Button as ButtonBase,
// } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import DescriptionIcon from '@material-ui/icons/Description';
import {
  NotificationsNone as NotificationsIcon,
  ThumbUp as ThumbUpIcon,
  ShoppingCart as ShoppingCartIcon,
  LocalOffer as TicketIcon,
  BusinessCenter as DeliveredIcon,
  SmsFailed as FeedbackIcon,
  DiscFull as DiscIcon,
  Email as MessageIcon,
  Report as ReportIcon,
  Error as DefenceIcon,
  AccountBox as CustomerIcon,
  Done as ShippedIcon,
  Publish as UploadIcon,
} from '@material-ui/icons';
import classnames from 'classnames';
// styles
import useStyles from './style';
import { useTheme, makeStyles } from '@material-ui/core/styles';

// components
// import { Typography } from '../../../../../../Components/Wrappers/Wrappers';

const typesIcons: any = {
  'e-commerce': <ShoppingCartIcon />,
  down: <GetAppIcon />,
  pdf: <DescriptionIcon />,
  xls: <DescriptionIcon />,
  docx: <DescriptionIcon />,
  png: <DescriptionIcon />,
  txt: <DescriptionIcon />,
};
interface ReservationCertificateProps {
  variant: string;
  fileName: string;
  url: string;
  type: string;
  typeDown: string;
  color: string;
  client: any;
  onClick?: void;
}
const ReservationCertificate: FC<ReservationCertificateProps> = ({
  type,
  fileName,
  url,
  typeDown,
  variant,
  color,
  client,
  onClick,
}) => {
  var classes = useStyles();

  const icon = getIconByType(
    type !== 'txt' && type !== 'pdf' && type !== 'xls' && type !== 'docs'
      ? 'txt'
      : type,
  );
  const iconDown = getIconByType(typeDown);

  const iconWithStyles = React.cloneElement(icon, {
    classes: {
      root:
        type === 'pdf'
          ? classes.notificationIconPdf
          : type === 'xls' || type === 'xlsx'
          ? classes.notificationIconXls
          : type === 'docx' || type === 'doc'
          ? classes.notificationIconWord
          : classes.notificationIconTxt,
    },
    style: {
      color: variant !== 'contained' && color != '',
    },
  });
  const iconWithStylesDown = React.cloneElement(iconDown, {
    classes: {
      root: classes.downIconTxt,
    },
    style: {
      color: variant !== 'contained' && color != '',
    },
  });

  return (
    <Box
      className={classnames(classes.notificationContainer, '', {
        [classes.notificationContained]: variant === 'contained',
        [classes.notificationContainedShadowless]: '',
      })}
      style={{
        backgroundColor: color,
      }}>
      <Box className={classnames(classes.notificationIconContainer, {})}>
        {iconWithStyles}
      </Box>
      <Box className={classes.messageContainer}>
        <Typography
          className={classnames({
            [classes.containedTypography]: variant === 'contained',
          })}
          variant={variant}
          size={variant !== 'contained' && !variant && 'md'}>
          {fileName ? fileName : ''}
        </Typography>
      </Box>
      <Box
        onClick={() => window.open(url ? url : '')}
        className={classnames(classes.notificationIconContainer, {})}>
        {iconWithStylesDown}
      </Box>
    </Box>
  );
};
export default ReservationCertificate;
// ####################################################################
const getIconByType = (type = 'offer'): any => {
  return typesIcons[type];
};
const getColor = (color: any, theme: any, brigtness = 'main') => {
  if (color && theme.palette[color] && theme.palette[color][brigtness]) {
    return theme.palette[color][brigtness];
  }
};

const getFontWeight = (style: any) => {
  switch (style) {
    case 'light':
      return 300;
    case 'medium':
      return 500;
    case 'bold':
      return 600;
    default:
      return 400;
  }
};

const getFontSize = (size: any, variant = '', theme: any) => {
  var multiplier;

  switch (size) {
    case 'sm':
      multiplier = 0.8;
      break;
    case 'md':
      multiplier = 1.5;
      break;
    case 'xl':
      multiplier = 2;
      break;
    case 'xxl':
      multiplier = 3;
      break;
    default:
      multiplier = 1;
      break;
  }

  var defaultSize =
    variant && theme.typography[variant]
      ? theme.typography[variant].fontSize
      : theme.typography.fontSize + 'px';

  return `calc(${defaultSize} * ${multiplier})`;
};

const Typography = ({
  children,
  weight,
  size,
  colorBrightness,
  color,
  ...props
}: any) => {
  var theme = useTheme();

  return (
    <TypographyBase
      style={{
        color: getColor(color, theme, colorBrightness),
        fontWeight: getFontWeight(weight),
        fontSize: getFontSize(size, props.variant, theme),
      }}
      {...props}>
      {children}
    </TypographyBase>
  );
};

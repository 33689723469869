import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PrivateRoute from '../../Components/PrivateRoute';
import Accueil from '../../Container/Accueil';
import InitPassword from '../../Container/InitPassword';
import Login from '../../Container/Login';
import ConfirmeInitPassword from '../../Container/ConfirmeInitPassword';
import PageNotFound from '../../Container/PageNotFound';
import ChoiceAccountToCreate from '../../Container/ChoiceAccountToCreate';
import ParticularAccount from '../../Container/ParticularAccount';
import ProfessionnalAccount from '../../Container/ProfessionnalAccount';
import LoginWithFacebook from '../../Container/LoginWithFacebook';
import MakeDelivery from '../../Container/MakeDelivery';
import Delivery from '../../Container/Delivery';
import BookingHotel from '../../Container/BookingHotel';
import ReservationHotel from '../../Container/ReservationHotel';
import MakeReservation from '../../Container/MakeReservation';
import ReservationRequest from './../../Container/ReservationRequest/index';
import Reservation from './../../Container/Reservation/index';
// import BookingResultSearch from './../../Container/BookingHotel/BookingSearchResult';
import ReservationRecap from './../../Container/ReservationRecap/';
import ReservationVol from '../../Container/ReservationVol/';
import VolEnAttente from './../../Container/ReservationVol/VolEnAttente/';
import VolRecap from '../../Container/ReservationVol/VolEnRecap';
import Wrapper from '../Wrapper';
import Historique from '../../Container/Historique';
import CarnetAdresse from '../../Container/CarnetAdresse';
import MonCompte from '../../Container/MonCompte';
import VanillaPay from '../VanillaPay';
import RatingNote from '../RatingNote';
import Slide from '../Slide';
import ConfirmAccount from '../../Container/ConfirmAccount';
import Terms from '../../Container/Terms';
import { isAuthenticated } from '../../Services/LocalStorage';
const Routes = () => {
  const WrapperAccueil = Wrapper(Accueil);
  const WrapperMakeDelivery = Wrapper(MakeDelivery);
  const WrappertBookingHotel = Wrapper(BookingHotel);
  const WrapperMakeReservation = Wrapper(MakeReservation);
  const WrapperReservationHotel = Wrapper(ReservationHotel);
  const WrapperReservationRequest = Wrapper(ReservationRequest);
  const WrapperReservation = Wrapper(Reservation);
  const WrapperHistorique = Wrapper(Historique);
  const WrapperCarnetAdresse = Wrapper(CarnetAdresse);
  const WrapperMonCompte = Wrapper(MonCompte);
  const WrapperVanillaPay = Wrapper(VanillaPay);
  const WrapperRatingNote = Wrapper(RatingNote);
  const WrapperSlide = Wrapper(Slide);
  // const WrapperBookingResultSearch = Wrapper(BookingResultSearch);
  const WrapperReservationVol = Wrapper(ReservationVol);
  const WrapperReservationRecap = Wrapper(ReservationRecap);
  const WrapperVolEnAttente = Wrapper(VolEnAttente);
  const WrapperVolRecap = Wrapper(VolRecap);
  const WrapperDelivery = Wrapper(Delivery);

  return (
    <Switch>
      <PrivateRoute
        path={['/accueil']}
        component={WrapperAccueil}
        exact={true}
      />
      <PrivateRoute
        path="/accueil/effectuer-livraison"
        component={WrapperMakeDelivery}
        exact={true}
      />
      <PrivateRoute
        path="/accueil/Booking-Hotel"
        component={WrappertBookingHotel}
        exact={true}
      />
      <PrivateRoute
        path="/accueil/Faire-une-Reservation/:idHotel/:idRoom"
        component={WrapperMakeReservation}
        exact={true}
      />
      <PrivateRoute
        path="/accueil/ReservationHotel/:idHotel"
        component={WrapperReservationHotel}
        exact={true}
      />
      <PrivateRoute
        path="/accueil/Demande-Reservation/:idHotel"
        component={WrapperReservationRequest}
        exact={true}
      />
      <PrivateRoute
        path="/accueil/Reservation-hotel/"
        component={WrapperReservation}
        exact={true}
      />
      <PrivateRoute
        path="/carnet-adresse"
        component={WrapperCarnetAdresse}
        exact={true}
      />
      <PrivateRoute
        path="/historiques"
        component={WrapperHistorique}
        exact={true}
      />
      <PrivateRoute
        path="/mon-compte"
        component={WrapperMonCompte}
        exact={true}
      />
      <PrivateRoute
        path="/accueil/Reservation-Vol/"
        component={WrapperReservationVol}
        exact={true}
      />
      <PrivateRoute
        path="/accueil/Recapitulation-reservation/"
        component={WrapperReservationRecap}
        exact={true}
      />
      <PrivateRoute
        path="/accueil/Reservation-Vol/Vol-En-Attente"
        component={WrapperVolEnAttente}
        exact={true}
      />
      <PrivateRoute
        path="/accueil/Reservation-Vol/Recapitulation-Vol"
        component={WrapperVolRecap}
        exact={true}
      />
      <PrivateRoute path="/delivery" component={WrapperDelivery} exact={true} />
      <PrivateRoute
        path="/deliveryInfo"
        component={WrapperAccueil}
        exact={true}
      />
      <PrivateRoute
        path="/deliveryRecap"
        component={WrapperAccueil}
        exact={true}
      />
      <PrivateRoute
        path="/paiement"
        component={WrapperVanillaPay}
        exact={true}
      />
      <PrivateRoute path="/Slide" component={WrapperSlide} exact={true} />

      <Route path="/pagenotfound" component={PageNotFound} />
      <Route path="/terms" component={Terms} />

      {isAuthenticated() && <Redirect to="/accueil" />}

      <Route path="/login" component={Login} />
      <Route path="/initpassword" component={InitPassword} />
      <Route
        path="/reset/:token"
        component={ConfirmeInitPassword}
        exact={true}
      />
      <Route path="/confirm/:token" component={ConfirmAccount} exact={true} />
      <Route path="/choiceaccount" component={ChoiceAccountToCreate} />
      <Route path="/particularaccount" component={ParticularAccount} />
      <Route path="/professionnalaccount" component={ProfessionnalAccount} />
      <Route path="/" component={LoginWithFacebook} />
    </Switch>
  );
};

export default Routes;

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  demandeContainer: {
    padding: '20px 0 0px 0',
    borderBottom: '1px solid #C7D3E6',
    alignItems: 'flex-Start',
    display: 'flex',
    '& img': {
      marginRight: 20,
    },
  },
  clicked: {
    backgroundColor: '#ECF1F9',
  },
  containerFirst: {
    marginTop: '60px',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 10,
    borderBottom: '1px solid #6B6B6B',
  },
  titleDocument: {
    display: 'flex',
    justifyContent: 'space-between',
    '& :first-child': {
      textTransform: 'uppercase',
      fontWeight: 400,
    },
    '&>*': {
      fontSize: 16,
    },
  },
  contentDoc: {
    borderColor: '#4267B2',
    // display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    paddingBottom: '10px',
  },
});
export default useStyles;

import { Box, Typography, TextField, Button } from '@material-ui/core';
import { RouteComponentProps, useHistory, useParams } from 'react-router-dom';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useApolloClient, useQuery, useMutation } from '@apollo/react-hooks';
import useStyles from './style';
import DateAndTime from './../../Components/DateAndTime/DateAndTime';
import moment, { now } from 'moment';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import { UserContext } from '../../Providers/User';
import {
  CREATE_REQUEST_BOOKING,
  CREATE_REQUEST_BOOKINGVariables,
} from '../../Graphql/Hotel/types/CREATE_REQUEST_BOOKING';
import { DO_CREATE_REQUEST_BOOKING } from '../../Graphql/Hotel/mutation';
import { displaySnackBar } from '../../Utils/snackBarUtils';
import SnackVariableInterface from '../../Interfaces/SnackVariableInterface';
import {
  CREATE_NOTIFICATION_ITERM_ADMIN,
  CREATE_NOTIFICATION_ITERM_ADMINVariables,
} from '../../Graphql/Notification/types/CREATE_NOTIFICATION_ITERM_ADMIN';
import { DO_CREATE_NOTIFICATION_ITERM_ADMIN } from '../../Graphql/Notification/mutation';
import {
  GET_HOTEL,
  GET_HOTELVariables,
} from '../../Graphql/Hotel/types/GET_HOTEL';
import { DO_GET_HOTEL } from '../../Graphql/Hotel/query';
import UploadFiles from '../../Components/UploadFiles';
import { DO_CREATE_DOCUMENT_USER } from '../../Graphql/UploadFile/mutation';
import {
  CREATE_DOCUMENT_USER,
  CREATE_DOCUMENT_USERVariables,
} from '../../Graphql/UploadFile/types/CREATE_DOCUMENT_USER';
import { useUploadFile } from '../../Utils/uploadFIle';
import Backdrop from '../../Components/Backdrop';
import { HotelFilterContext } from '../../Providers/HotelFilter';

const ReservationRequest: FC<RouteComponentProps> = ({ history, location }) => {
  const classes = useStyles();
  history = useHistory();
  const client = useApolloClient();

  const { customerId, idMe } = useContext(UserContext);
  const { idHotel }: { idHotel: string } = useParams();
  const { dateDepartContext, dateArriveContext } = useContext(
    HotelFilterContext,
  );
  console.log(moment(dateArriveContext).format(' DDDD DD MMMM YYYY'));
  const [dateDepart, setDepartDate] = useState<Date | null>(dateDepartContext);
  const [dateArrive, setArriveDate] = useState<Date | null>(dateArriveContext);
  console.log(moment(dateDepartContext).format(' DDDD DD MMMM YYYY'));
  const [helper, setHelper] = useState<string>('Veuiller choisir une date');
  const [adultNumber, setAdulteNumber] = useState(0);
  const [enfantNumber, setEnfantNumber] = useState(0);
  const [chambreNumber, setChambreNumber] = useState(0);
  const [commentaire, setCommentaire] = useState<string>('');

  const { uploadFile, getFiles, loading: loadingFile } = useUploadFile();
  const [files, setFiles] = useState<File[] | null>(null);

  const { data: dataHotel, loading: loadingHotel } = useQuery<
    GET_HOTEL,
    GET_HOTELVariables
  >(DO_GET_HOTEL, {
    onError: (err) => {
      if (err.networkError?.message === 'Failed to fetch') {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Problème de connexion...',
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      }
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: err.message.split(':')[1],
        isOpen: true,
      };
      return displaySnackBar(client, snackBarData);
    },
    variables: { id: idHotel },
  });

  useEffect(() => {
    if (
      customerId === null ||
      customerId === undefined ||
      idMe === null ||
      idMe === undefined
    ) {
      history.push('/accueil/Booking-Hotel');
    }
  }, [customerId, idMe]);

  const goToReservation = () => {
    history.push('/accueil/Reservation-hotel');
  };

  const [doCreateDocument] = useMutation<
    CREATE_DOCUMENT_USER,
    CREATE_DOCUMENT_USERVariables
  >(DO_CREATE_DOCUMENT_USER, {
    onError: (err) => {
      if (err.networkError?.message === 'Failed to fetch') {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Problème de connexion...',
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      }
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: err.message.split(':')[1],
        isOpen: true,
      };
      return displaySnackBar(client, snackBarData);
    },
    onCompleted: async (data) => {
      await uploadFile(files);
      setFiles([]);
    },
  });

  const [doCreateRequestBooking, { loading }] = useMutation<
    CREATE_REQUEST_BOOKING,
    CREATE_REQUEST_BOOKINGVariables
  >(DO_CREATE_REQUEST_BOOKING, {
    onError: (err) => {
      if (err.networkError?.message === 'Failed to fetch') {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Problème de connexion...',
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      }
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: err.message.split(':')[1],
        isOpen: true,
      };
      return displaySnackBar(client, snackBarData);
    },
    onCompleted: async (data) => {
      const filesUpload = await getFiles(files);
      await doCreateDocument({
        variables: {
          userId: idMe,
          requestBookingId: data.createRequestBooking.requestBooking.id,
          files: filesUpload,
        },
      });
      if (data.createRequestBooking) {
        const snackBarData: SnackVariableInterface = {
          type: 'SUCCESS',
          message: 'Votre demande a été émise avec succès',
          isOpen: true,
        };
        goToReservation();
        return displaySnackBar(client, snackBarData);
      }
    },
  });

  const incrementAdult = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    event.preventDefault();
    setAdulteNumber((prevValue) => {
      return prevValue + 1;
    });
  };

  const decrementAdult = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    event.preventDefault();
    setAdulteNumber((prevValue) => {
      return prevValue - 1;
    });
  };

  const incrementEnfant = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    event.preventDefault();
    setEnfantNumber((prevValueEnfant) => {
      return prevValueEnfant + 1;
    });
  };

  const decrementEnfant = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    event.preventDefault();
    setEnfantNumber((prevValueEnfant) => {
      return prevValueEnfant - 1;
    });
  };
  const incrementChambre = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    event.preventDefault();
    setChambreNumber((prevValueChambre) => {
      return prevValueChambre + 1;
    });
  };
  const decrementChambre = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    event.preventDefault();
    setChambreNumber((preValueChambre) => {
      return preValueChambre - 1;
    });
  };

  const handleSubmit = () => {
    if (!commentaire) {
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message:
          'Il est mieux de renseigner les informations suggérées dans la section commentaires',
        isOpen: true,
      };
      displaySnackBar(client, snackBarData);
    } else if (dataHotel && dataHotel.hotel) {
      doCreateRequestBooking({
        variables: {
          customerId,
          hotelId: dataHotel?.hotel.id,
          comment: commentaire,
          dateFirst: moment(dateDepart).unix(),
          dateLast: moment(dateArrive).unix(),
          dateRequestBooking: moment(now()).unix(),
          manifest: {
            numberAdult: adultNumber,
            numberChildren: enfantNumber,
            numberRoom: chambreNumber,
          },
        },
      });
    }
  };

  const onChangeFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filesConcat = Array.from(event.target.files || []);
    if (files === null) {
      setFiles(filesConcat);
    } else {
      setFiles((prevState) => {
        if (prevState !== null) {
          return [...prevState, ...filesConcat];
        }
        return [];
      });
    }
  };

  const onDeleteFile = (name: string) => {
    const filesFilter = files?.filter((file) => file.name !== name) || [];
    setFiles(filesFilter);
  };

  if (loadingHotel) {
    return null;
  }

  return (
    <Box className={classes.allContent}>
      <Backdrop open={loadingFile} />
      <Box className={classes.contentDemandeReservation}>
        <Typography variant="h5">Demande de réservation</Typography>
        <Typography variant="caption">
          À quelle période souhaitez-vous séjourner dans l&apos;établissement
        </Typography>
        <Typography variant="h6">{dataHotel?.hotel.name}</Typography>
        <Typography variant="caption">{dataHotel?.hotel.city}</Typography>
      </Box>
      <Box className={classes.contentDatePicker}>
        <Box className={classes.contentOption}>
          <DateAndTime
            titre="Date d'arrivée"
            setValue={setArriveDate}
            setHelper={setHelper}
            helper={
              dateArrive === null && dateDepart !== null
                ? "Veuiller ajouter une date d'arrivée"
                : dateArriveContext
                ? ''
                : helper
            }
            error={false}
            valueDate={dateArrive}
            dateDepart={dateDepart}
            setDateDepart={setDepartDate}
            type={'Hotel'}
          />
          <Typography>Options</Typography>
          <Box className={classes.contentInput}>
            <Box className={classes.contentInputAlign}>
              <label className={classes.colorLabel}>Adulte(s)</label>
              <Box className={classes.contentIncrement}>
                <IconButton
                  color="primary"
                  disabled={adultNumber === 0}
                  onClick={decrementAdult}
                  href="#">
                  <RemoveIcon />
                </IconButton>
                <span>{adultNumber}</span>
                <IconButton color="primary" onClick={incrementAdult} href="#">
                  <AddIcon />
                </IconButton>
              </Box>
            </Box>
            <Box className={classes.contentInputAlign}>
              <label className={classes.colorLabel}>Enfant(s)</label>
              <Box className={classes.contentIncrement}>
                <IconButton
                  color="primary"
                  disabled={enfantNumber === 0}
                  onClick={decrementEnfant}
                  href="#">
                  <RemoveIcon />
                </IconButton>
                <span>{enfantNumber}</span>
                <IconButton color="primary" onClick={incrementEnfant} href="#">
                  <AddIcon />
                </IconButton>
              </Box>
            </Box>
            <Box className={classes.contentInputAlign}>
              <label className={classes.colorLabel}>Chambre(s)</label>
              <Box className={classes.contentIncrement}>
                <IconButton
                  color="primary"
                  disabled={chambreNumber === 0}
                  // classes={{ root }}
                  onClick={decrementChambre}
                  href="#">
                  <RemoveIcon />
                </IconButton>
                <span>{chambreNumber}</span>
                <IconButton color="primary" onClick={incrementChambre} href="#">
                  <AddIcon />
                </IconButton>
              </Box>
            </Box>
            <UploadFiles
              files={files}
              onChangeFiles={onChangeFiles}
              onClickFile={onDeleteFile}
            />
          </Box>
        </Box>
        <Box className={classes.scndContent}>
          <DateAndTime
            titre="Date de départ"
            setValue={setDepartDate}
            //onChange={applyFilter}
            error={
              dateArrive && dateDepart && dateArrive > dateDepart
                ? true
                : dateArrive !== null && dateDepart === null
                ? true
                : false
            }
            helper={
              dateArrive && dateDepart && dateArrive > dateDepart
                ? 'Date de départ inferieur à date Arrivé'
                : dateArrive !== null && dateDepart === null
                ? 'Veuiller choisir une date de départ'
                : ''
            }
            valueDate={dateDepart}
            setArriveDate={setArriveDate}
            arriveDate={dateArrive}
            type={'Hotel'}
          />
          <TextField
            id="outlined-textarea"
            className={classes.comment}
            placeholder="Pour accélerer le traitement de votre demande, merci de mentionner votre numéro de téléphone et votre e-mail."
            multiline
            onChange={(e) => setCommentaire(e.target.value)}
            variant="filled"
          />
          <Box className={classes.ButtonReservationContent}>
            <Button
              variant="contained"
              color="primary"
              className={classes.firstButton}
              onClick={() => history.goBack()}>
              Retour
            </Button>
            <Button
              variant="contained"
              color="primary"
              // onClick={() => history.push('/accueil/Reservation-hotel')}>
              onClick={handleSubmit}>
              OK
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default ReservationRequest;

import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function BoxIcon(props: SvgIconProps) {
  return (
    <>
      <SvgIcon {...props} viewBox="0 0 32.229 32.238">
        <g id="Groupe_2" data-name="Groupe 2" transform="translate(0)">
          <g id="Groupe_639" data-name="Groupe 639">
            <g id="box" transform="translate(0)">
              <path
                id="Tracé_645"
                data-name="Tracé 645"
                d="M87.161,157.347l10.631,3.524,10.631-3.524-10.631-3.524Z"
                transform="translate(-81.678 -144.138)"
              />
              <path
                id="Tracé_646"
                data-name="Tracé 646"
                d="M290.682,227.725,278.9,231.632l2.887,4.585,11.786-3.907Z"
                transform="translate(-261.34 -213.387)"
              />
              <path
                id="Tracé_647"
                data-name="Tracé 647"
                d="M66.232,333.54l-2.574,4.088-9.17-3.04v6.3l11.744,3.893Z"
                transform="translate(-51.062 -312.539)"
              />
              <path
                id="Tracé_648"
                data-name="Tracé 648"
                d="M273.574,337.628,271,333.54v11.237l11.744-3.893v-6.3Z"
                transform="translate(-253.941 -312.539)"
              />
              <path
                id="Tracé_649"
                data-name="Tracé 649"
                d="M11.861,236.217l2.887-4.585L2.962,227.725.075,232.31Z"
                transform="translate(-0.075 -213.387)"
              />
              <path
                id="Tracé_650"
                data-name="Tracé 650"
                d="M241,0h1.889V6.307H241Z"
                transform="translate(-225.83)"
              />
              <path
                id="Tracé_651"
                data-name="Tracé 651"
                d="M0,0H1.889V6.307H0Z"
                transform="matrix(0.866, -0.5, 0.5, 0.866, 6.286, 2.886)"
              />
              <path
                id="Tracé_652"
                data-name="Tracé 652"
                d="M0,0H6.307V1.889H0Z"
                transform="matrix(0.5, -0.866, 0.866, 0.5, 21.152, 7.405)"
              />
            </g>
          </g>
        </g>
      </SvgIcon>
    </>
  );
}

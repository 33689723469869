import React from 'react';
import { Box, Fab } from '@material-ui/core';
import classnames from 'classnames';
import styles from './style';

interface IOptions {
  type: 'periode' | 'vehicule';
}

const periode = ['Jour', 'Semain', 'Mois'];
const vehicule = [
  'Véhicule disponible',
  'Véhicule non disponible',
  'Véhicule on box',
];

export default function Options({ type }: IOptions) {
  const classes = styles();
  const [active, setActive] = React.useState<string>('0');
  const handleClick = (e: React.MouseEvent) => {
    setActive(e.currentTarget.id);
  };

  return (
    <Box
      className={classnames(
        classes.buttonGroup,
        type === 'periode' ? classes.periode : classes.vehicule,
      )}>
      {type === 'periode'
        ? periode.map((val, id) => {
            return (
              <Fab
                key={`${id}`}
                variant="extended"
                size="small"
                id={String(id)}
                onClick={handleClick}
                color={active === String(id) ? 'primary' : 'default'}>
                {val}
              </Fab>
            );
          })
        : vehicule.map((val, id) => {
            return (
              <Fab
                key={`${id}`}
                variant="extended"
                size="small"
                id={String(id)}
                onClick={handleClick}
                color={active === String(id) ? 'primary' : 'default'}>
                {val}
              </Fab>
            );
          })}
    </Box>
  );
}

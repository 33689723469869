import React, { useState, FC, useContext, useEffect } from 'react';
import {
  Box,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  Typography,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import styles from './style';
import classnames from 'classnames';
import {
  DestinataireSortedByDistanceContext,
  User,
} from '../../../Providers/DestinataireSortedByDistance';
import { useApolloClient, useQuery } from '@apollo/react-hooks';

import SnackVariableInterface from '../../../Interfaces/SnackVariableInterface';

import { TransportContext } from '../../../Providers/Transport';
import moment from 'moment';
import { DO_PROMOS } from '../../../Graphql/Delivery/query';
import { PROMOS, PROMOS_promos } from '../../../Graphql/Delivery/types/PROMOS';
import { displaySnackBar } from '../../../Utils/snackBarUtils';
import { DeliveryInfoContext } from '../../../Providers/DeliveryInfo';
import { numberArroud, separatorOfMillier } from '../../../Utils/toolsMontant';
import theme from '../../../theme';
import { PayementVanillaContext } from '../../../Providers/PayementVanilla';
import { ExpediteurContext } from '../../../Providers/Expediteur';

const DeliveryRecap: FC = () => {
  var classes = styles();

  const { usersSortedByDistance } = useContext(
    DestinataireSortedByDistanceContext,
  );

  const client = useApolloClient();

  const { coastBydistance, forfaitExpress, forfaitProgram, id } = useContext(
    TransportContext,
  );

  const { address } = useContext(ExpediteurContext);

  const { setMontant } = useContext(PayementVanillaContext);

  const [usersSorted, setUsersSorted] = useState<User[]>();

  useEffect(() => {
    setUsersSorted(
      usersSortedByDistance.filter((user) => user.distanceParcouru !== 0),
    );
  }, []);

  const {
    dateDelivery,
    dateType,
    setSelectedIdButtonPaiement,
    selectedIdButtonPaiement,
  } = useContext(DeliveryInfoContext);

  const [promo, setPromo] = useState<PROMOS_promos | null>(null);

  const { data: dataPromos } = useQuery<PROMOS>(DO_PROMOS, {
    onError: (err) => {
      if (err.networkError?.message === 'Failed to fetch') {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Problème de connexion...',
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      }
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: err.message.split(':')[1],
        isOpen: true,
      };
      return displaySnackBar(client, snackBarData);
    },
    onCompleted: (data) => {
      if (data && data.promos) {
        let valuePromo = 0;
        const promosFilter = data.promos.filter(
          (promo) => promo.princingId === id,
        );
        for (const promofilter of promosFilter) {
          if (
            moment(dateDelivery, 'YYYY-MM-DD').toDate() >=
              moment(promofilter.dateFirst as Date, 'YYYY-MM-DD').toDate() &&
            moment(dateDelivery, 'YYYY-MM-DD').toDate() <=
              moment(promofilter.dateLast as Date, 'YYYY-MM-DD').toDate() &&
            promofilter.status === 'activer'
          ) {
            setPromo(promofilter);
            valuePromo = promofilter.value;
            break;
          }
        }
      }
    },
    fetchPolicy: 'network-only',
  });

  const priceWithoutReduction = (
    coastByDistanceParams: number,
    distance: number,
  ) => {
    return numberArroud(coastByDistanceParams * distance);
  };

  const priceWithPromotion = () => {
    if (dataPromos && dataPromos.promos) {
      let valuePromo = 0;
      const promosFilter = dataPromos.promos.filter(
        (promo) => promo.princingId === id,
      );
      for (const promo of promosFilter) {
        if (
          moment(dateDelivery, 'YYYY-MM-DD').toDate() >=
            moment(promo.dateFirst as Date, 'YYYY-MM-DD').toDate() &&
          moment(dateDelivery, 'YYYY-MM-DD').toDate() <=
            moment(promo.dateLast as Date, 'YYYY-MM-DD').toDate() &&
          promo.status === 'activer'
        ) {
          valuePromo = promo.value;
          break;
        }
      }
      return valuePromo;
    }
  };

  const totalMontant = () => {
    let totalPrice = 0;
    if (usersSorted) {
      for (const item of usersSorted) {
        totalPrice +=
          priceWithoutReduction(coastBydistance, item.distanceParcouru) || 0;
      }
    }

    if (dateType === 'now') {
      const totalReduction =
        (totalPrice + forfaitExpress) * ((priceWithPromotion() || 0) / 100);
      setMontant(totalPrice + forfaitExpress - numberArroud(totalReduction));
      return totalPrice + forfaitExpress - numberArroud(totalReduction);
    } else {
      const reduction = (forfaitExpress * forfaitProgram) / 100;
      const total = numberArroud(totalPrice + (forfaitExpress - reduction));
      const totalReduction = total * ((priceWithPromotion() || 0) / 100);
      setMontant(total - numberArroud(totalReduction));
      return total - numberArroud(totalReduction);
    }
  };

  return (
    <Box>
      <Box className={classes.recap}>
        <Typography
          variant="h2"
          color="textSecondary"
          className={classes.borderBottom}>
          Récapitulatif
        </Typography>

        <Box className={classnames(classes.flex, classes.borderBottom)}>
          <Typography variant="subtitle1" color="textSecondary">
            Nombre de livraison
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {usersSorted && usersSorted.length.toString().padStart(2, '0')}
          </Typography>
        </Box>
        <Box>
          <Box className={classes.flex}>
            <Typography variant="h3" gutterBottom>
              Point de départ
            </Typography>
          </Box>
          <Box className={classnames(classes.flex, classes.borderBottom)}>
            <Typography variant="subtitle1" color="textSecondary">
              {address}
            </Typography>
          </Box>
        </Box>
        {usersSorted?.map((item, index) => (
          <Box key={`${index}`}>
            <Box className={classes.flex}>
              <Typography variant="h3" gutterBottom>
                Destination
              </Typography>
              <Typography variant="h3" gutterBottom>
                Frais de livraison
              </Typography>
            </Box>

            <Box className={classnames(classes.flex, classes.borderBottom)}>
              <Typography variant="subtitle1" color="textSecondary">
                {item.address}
              </Typography>
              <Box>
                <Typography variant="subtitle1" color="textSecondary">
                  {separatorOfMillier(
                    Number(
                      priceWithoutReduction(
                        coastBydistance,
                        item.distanceParcouru,
                      ),
                    ),
                  )}{' '}
                  Ar
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  {item.distanceParcouru} km
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  {item.dureeApproximatif}
                </Typography>
              </Box>
            </Box>
          </Box>
        ))}

        <Box
          className={classes.flex}
          style={{ display: 'flex', flexDirection: 'column' }}>
          {promo && (
            <>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  padding: theme.spacing(0),
                }}>
                <Typography variant="h3" gutterBottom>
                  Nom de promotion
                </Typography>
                <Typography variant="h3" gutterBottom>
                  {promo.name}
                </Typography>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  padding: theme.spacing(0),
                }}>
                <Typography variant="h3" gutterBottom>
                  Début de promotion
                </Typography>
                <Typography variant="h3" gutterBottom>
                  {moment(promo.dateFirst).format('DD-MM-YYYY')}
                </Typography>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  padding: theme.spacing(0),
                }}>
                <Typography variant="h3" gutterBottom>
                  Fin de promotion
                </Typography>
                <Typography variant="h3" gutterBottom>
                  {moment(promo.dateLast).format('DD-MM-YYYY')}
                </Typography>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  padding: theme.spacing(0),
                }}>
                <Typography variant="h3" gutterBottom>
                  Remise
                </Typography>
                <Typography variant="h3" gutterBottom>
                  -{promo.value.toFixed(2)}%
                </Typography>
              </Box>
            </>
          )}
          {dateType === 'now' && (
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                padding: theme.spacing(0),
              }}>
              <Typography variant="h3" gutterBottom>
                Frais d&apos;approche
              </Typography>
              <Typography variant="h3" gutterBottom>
                {separatorOfMillier(Number(forfaitExpress))} Ar
              </Typography>
            </Box>
          )}

          {dateType == 'later' && (
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                padding: theme.spacing(0),
              }}>
              <Typography variant="h3" gutterBottom>
                Frais d&apos;approche
              </Typography>
              <Typography variant="h3" gutterBottom>
                {separatorOfMillier(
                  numberArroud(
                    forfaitExpress - (forfaitExpress * forfaitProgram) / 100,
                  ),
                )}
                Ar
              </Typography>
            </Box>
          )}

          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}>
            <Typography variant="h3" gutterBottom>
              Total
            </Typography>
            <Typography variant="h3" gutterBottom>
              {separatorOfMillier(Number(totalMontant()))} Ar
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box>
        <Typography variant="h3" color="textSecondary">
          Mode de paiement
        </Typography>

        <FormControl component="fieldset" fullWidth>
          <RadioGroup
            defaultValue="1"
            aria-label="gender"
            name="customized-radios"
            onChange={(e) => setSelectedIdButtonPaiement(e.target.value)}>
            <Box className={classes.radioContainer}>
              <FormControlLabel
                value="1"
                control={<Radio color="primary" />}
                label="Paiement à la livraison"
              />
            </Box>
            <Box className={classes.radioContainer}>
              <FormControlLabel
                value="2"
                control={<Radio color="primary" />}
                label="Paiement mobile money"
              />
            </Box>
          </RadioGroup>
        </FormControl>
      </Box>
    </Box>
  );
};

export default withRouter(DeliveryRecap);

import React, { FC } from 'react';
import MomentUtils from '@date-io/moment';
import useStyles from './style';
import Grid from '@material-ui/core/Grid';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { Box, TextField, Typography } from '@material-ui/core';
const TextFieldComponent = (props: any) => {
  return <TextField {...props} variant={'standard'} disabled={true} />;
};
interface dateTimer {
  titre: string;
  value?: string;
  setValue?: (date: any) => void;
  onChange?: any;
  helper?: string;
  error?: boolean;
  valueDate?: any;
  dateDepart?: any;
  setDateDepart?: any;
  arriveDate?: any;
  setArriveDate?: any;
  type?: any;
  setHelper?: any;
}

const DateAndTime: FC<dateTimer> = ({
  titre,
  value,
  setValue,
  onChange,
  helper,
  error,
  valueDate,
  dateDepart,
  setDateDepart,
  arriveDate,
  setArriveDate,
  type,
  setHelper,
}) => {
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = React.useState();

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
    if (type === 'Hotel') {
      setHelper && setHelper('');
      if (dateDepart && dateDepart < date) {
        setDateDepart && setDateDepart(date);
      }
      if (arriveDate && arriveDate > date) {
        setArriveDate(date);
      }
    }
    if (arriveDate && arriveDate > date) {
      setArriveDate(date);
    }
    if (setValue) {
      setValue(date);
    }
  };

  return (
    <Box className={classes.allContentDateTime}>
      <Typography className={classes.titreDateTime}>{titre}</Typography>
      <Box>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Grid container justify="space-around">
            {type === 'Hotel' ? (
              <KeyboardDatePicker
                disableToolbar
                disablePast={true}
                inputVariant="filled"
                variant="inline"
                format="DD MMM YYYY"
                margin="normal"
                id="date-picker-inline"
                value={valueDate}
                helperText={helper}
                error={error}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                TextFieldComponent={TextFieldComponent}
              />
            ) : (
              <KeyboardDatePicker
                disableToolbar
                disablePast={true}
                inputVariant="outlined"
                variant="inline"
                format="DD MMM YYYY"
                margin="normal"
                id="date-picker-inline"
                value={selectedDate}
                onChange={handleDateChange as any}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            )}
          </Grid>
        </MuiPickersUtilsProvider>
      </Box>
    </Box>
  );
};
export default DateAndTime;

import gql from 'graphql-tag';

export const DO_NEW_NOTIFICATION = gql`
  subscription NEW_NOTIFICATION($user_id: String) {
    newNotification(user_id: $user_id) {
      publishedPost {
        id
        status
        createdAt
        shortDelivery {
          dropoff {
            id
            address
            name
            phone
          }
          id
          status
          reference
          delivery {
            id
            status
            reference
            courier {
              id
            }
            dropoff {
              name
              address
              phone
            }
          }
        }
        requestBooking {
          id
          reference
          hotel {
            id
            name
          }
        }
        requestBookingFlight {
          id
          reference
          status
        }
        notification {
          id
          status
        }
      }
    }
  }
`;

export const DO_NEW_NUMBER_NOTIFICATION = gql`
  subscription NEW_NUMBER_NOTIFICATION($user_id: String) {
    newNumberNotification(user_id: $user_id) {
      notificationInterm {
        userId
      }
      nbrNotifitication
    }
  }
`;

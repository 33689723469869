import { useLoadScript } from '@react-google-maps/api';
import React, { useEffect, useState } from 'react';
import { createContext, FC } from 'react';

interface Ilocation {
  lat: number;
  lng: number;
}

interface MapInputProps {
  isLoaded: boolean;
  loadError: Error | undefined;
  loadScript: () => void;
  setCurrentPan: (lng: number, lat: number) => void;
  currentPan: Ilocation;
  zoom: number;
}

export const InitMapContext = createContext<MapInputProps>({
  isLoaded: false,
  loadError: undefined,
  loadScript: () => {},
  setCurrentPan: () => {},
  currentPan: {
    lat: -18.8791902,
    lng: 47.5079055,
  },
  zoom: 8,
});

export const LoadScriptProvider: FC = ({ children }) => {
  const libraries = ['places'];
  // const [loadError, setLoadError] = useState<Error | undefined>();
  const [zoom, setZoom] = useState<number>(8);
  const [currentPan, setCurrentPan] = useState({
    lat: -18.8791902,
    lng: 47.5079055,
  });

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_API_GOOGLE_MAP|| "",
    libraries: ["places"],
  });

  if (loadError) {
    console.log('loadError => ', loadError);
  }

  return (
    <InitMapContext.Provider
      value={{
        isLoaded,
        loadError,
        loadScript: () => {},
        currentPan,
        setCurrentPan: (lng: number, lat: number) => {
          setCurrentPan({
            lng: lng,
            lat: lat,
          });
          setZoom(14);
        },
        zoom,
      }}>
      {children}
    </InitMapContext.Provider>
  );
};

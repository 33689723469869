import gql from 'graphql-tag';

export const DO_GET_RESERVATION_CERIFICATE = gql`
  query GET_RESERVATION_CERIFICATE($customer_id: String!) {
    getReservationCertificate(userId: $customer_id) {
      id
      fileName
      pathUrl
      type
      url
    }
  }
`;

import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function Home(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 27.031 25.53">
      <path
        id="home"
        d="M85.166,96.307,73.646,84.793l-.772-.772a.939.939,0,0,0-1.324,0L59.257,96.307a1.905,1.905,0,0,0-.561,1.372,1.927,1.927,0,0,0,1.935,1.887H61.9v9.711H82.525V99.566h1.294a1.908,1.908,0,0,0,1.348-3.259ZM73.881,107.13H70.542v-6.082h3.339Zm6.5-9.711v9.711H75.789v-6.8A1.192,1.192,0,0,0,74.6,99.14h-4.77a1.192,1.192,0,0,0-1.193,1.193v6.8H64.045V97.42H61.183L72.214,86.4l.689.689L83.243,97.42Z"
        transform="translate(-58.696 -83.748)"
      />
    </SvgIcon>
  );
}

import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { useContext } from 'react';
import {
  DO_CREATE_PICK_UP,
  DO_CREATE_ADRESS_BOOK,
} from '../../Graphql/Delivery/mutation';
import {
  CREATE_ADRESS_BOOK,
  CREATE_ADRESS_BOOKVariables,
} from '../../Graphql/Delivery/types/CREATE_ADRESS_BOOK';
import {
  CREATE_PICK_UP,
  CREATE_PICK_UPVariables,
} from '../../Graphql/Delivery/types/CREATE_PICK_UP';
import SnackVariableInterface from '../../Interfaces/SnackVariableInterface';
import { ExpediteurContext } from '../../Providers/Expediteur';
import { ExpediteurLocationContext } from '../../Providers/ExpediteurLocation';
import { IndexDeliveryContext } from '../../Providers/IndexDelivery';
import { displaySnackBar } from '../../Utils/snackBarUtils';

export const useComponentMutationsExpediteur = () => {
  const {
    setAdresseBookIdExpediteur,
    setIdExpediteur,
    name,
    phone,
    address,
    clearExpediteur,
  } = useContext(ExpediteurContext);

  const { setIndexDelivery, indexDelivery } = useContext(IndexDeliveryContext);
  const { latitude, longitude } = useContext(ExpediteurLocationContext);
  const client = useApolloClient();

  const [doCreatePickUp, { loading: loadingPickup }] = useMutation<
    CREATE_PICK_UP,
    CREATE_PICK_UPVariables
  >(DO_CREATE_PICK_UP, {
    onError: (err) => {
      if (err.networkError?.message === 'Failed to fetch') {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Problème de connexion...',
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      }
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: err.message.split(':')[1],
        isOpen: true,
      };
      return displaySnackBar(client, snackBarData);
    },
    onCompleted: (data) => {
      setIdExpediteur(data.createPickup.id);
      setIndexDelivery(indexDelivery + 1);
    },
  });

  const [
    doCreateAdresseBook,
    { loading: loadingCreateAdresseBook },
  ] = useMutation<CREATE_ADRESS_BOOK, CREATE_ADRESS_BOOKVariables>(
    DO_CREATE_ADRESS_BOOK,
    {
      onError: (err) => {
        if (err.networkError?.message === 'Failed to fetch') {
          const snackBarData: SnackVariableInterface = {
            type: 'ERROR',
            message: 'Problème de connexion...',
            isOpen: true,
          };
          return displaySnackBar(client, snackBarData);
        }
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: err.message.split(':')[1],
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      },
      onCompleted: (data) => {
        setAdresseBookIdExpediteur(data.createAddressBook.id);
        doCreatePickUp({
          variables: {
            name: name,
            phone: phone,
            address: address,
            latitude: latitude === 0 ? '' : '' + latitude,
            longitude: longitude === 0 ? '' : '' + longitude,
            addressBookId: data.createAddressBook.id,
          },
        });
      },
    },
  );

  return {
    doCreateAdresseBook,
    loadingPickup,
    loadingCreateAdresseBook,
  };
};

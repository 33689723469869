import gql from 'graphql-tag';

export const DO_CREATE_REQUEST_BOOKING_VOL = gql`
  mutation CREATE_REQUEST_BOOKING_VOL(
    $customerId: String!
    $typeBooking: String!
    $dateRequestBooking: Date
    $dateFirst: Date
    $dateLast: Date
    $cityDeparture: String!
    $cityArrival: String!
    $numberofPassenger: Int!
    $manifest: JSON!
    $comment: String
    $typeFlight: String!
  ) {
    createRequestBookingFlight(
      customerId: $customerId
      typeBooking: $typeBooking
      dateRequestBooking: $dateRequestBooking
      dateFirst: $dateFirst
      dateLast: $dateLast
      cityDeparture: $cityDeparture
      cityArrival: $cityArrival
      numberofPassenger: $numberofPassenger
      manifest: $manifest
      comment: $comment
      typeFlight: $typeFlight
    ) {
      message
      requestBookingFlight {
        id
      }
    }
  }
`;

export const DO_REJECTING_REQUEST_BOOKING_FLIGHT = gql`
  mutation REJECTING_REQUEST_BOOKING_FLIGHT($requestBookingFlight_id: String!) {
    rejectingRequestBookingFlight(
      requestBookingFlight_id: $requestBookingFlight_id
    ) {
      message
    }
  }
`;

export const DO_UPDATE_REQUEST_BOOKING_VOL = gql`
  mutation UPDATE_REQUEST_BOOKING_VOL(
    $requestId: String!
    $customerId: String!
    $typeBooking: String
    $typeFlight: String
    $dateRequestBooking: Date
    $dateFirst: Date
    $dateLast: Date
    $cityDeparture: String
    $cityArrival: String
    $numberofPassenger: Int
    $manifest: JSON
    $comment: String
    $withCertificat: Boolean
  ) {
    updateRequestBookingFlight(
      requestId: $requestId
      customerId: $customerId
      typeBooking: $typeBooking
      dateRequestBooking: $dateRequestBooking
      dateFirst: $dateFirst
      dateLast: $dateLast
      cityDeparture: $cityDeparture
      cityArrival: $cityArrival
      numberofPassenger: $numberofPassenger
      manifest: $manifest
      comment: $comment
      typeFlight: $typeFlight
      withCertificat: $withCertificat
    ) {
      message
      requestBookingFlight {
        id
        withCertificat
      }
    }
  }
`;

import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Divider,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import Star from '@material-ui/icons/Star';
import Close from '@material-ui/icons/Close';
import Happy from '@material-ui/icons/SentimentSatisfiedAlt';
import styles from './style';
import TextInput from '../../../Components/TextInput';
import { MY_DELIVERY_ON_THE_WAY_myDeliveriesOnTheWay } from '../../../Graphql/Delivery/types/MY_DELIVERY_ON_THE_WAY';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { AMOUNT_TOTAL_PAYMENT } from '../../../Graphql/Paiement/types/AMOUNT_TOTAL_PAYMENT';
import { DO_AMOUNT_TOTAL_PAYMENT } from '../../../Graphql/Paiement/query';
import moment, { unix } from 'moment';
import SnackVariableInterface from '../../../Interfaces/SnackVariableInterface';
import { displaySnackBar } from '../../../Utils/snackBarUtils';
import { SelectAllRounded } from '@material-ui/icons';
import { CourierContext } from '../../../Providers/courier';

interface Delivery {
  selected: MY_DELIVERY_ON_THE_WAY_myDeliveriesOnTheWay;
}

interface ManifestItem {
  name: string;
  poids: number;
  taille: string;
}
interface Manifest {
  reference: string;
  description: string;
}

const numberWithSpaces = (x: number) => {
  return x
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

const nl2br = (str: string | null): string | null => {
  return str ? str.replace(new RegExp('\n', 'g'), '<br/>') : str;
};

const DeliveryDetails = ({ selected }: Delivery) => {
  const classes = styles();
  const { setCourier } = useContext(CourierContext);
  const client = useApolloClient();
  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = useState<number>(0);
  const handleShowDialog = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleRating = (e: React.ChangeEvent<{}>, value: number | null) => {
    setValue(value ? value : 0);
  };

  useEffect(() => {
    setCourier(selected.courier);
  }, []);

  const { data } = useQuery<AMOUNT_TOTAL_PAYMENT>(DO_AMOUNT_TOTAL_PAYMENT, {
    variables: {
      delivery: selected.id,
      datePayment: moment(selected.deliveryDate).utc().format(),
    },
    onError: (err) => {
      if (err.networkError?.message === 'Failed to fetch') {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Problème de connexion...',
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      }
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: err.message.split(':')[1],
        isOpen: true,
      };
      return displaySnackBar(client, snackBarData);
    },
    fetchPolicy: 'network-only',
  });

  return (
    <Box className={classes.resume}>
      <Typography variant="h3" color="primary">
        Informations sur le colis
      </Typography>

      <Box className={classes.description}>
        <Typography variant="h3" gutterBottom>
          Point de départ
        </Typography>
        <Box className={classes.info}>
          <Typography variant="subtitle1">{selected.pickup.name}</Typography>
          <Typography>{selected.pickup.phone}</Typography>
          <Typography>{selected.pickup.address}</Typography>
        </Box>
      </Box>

      {selected.manifest && JSON.parse(selected.manifest).description && (
        <Box className={classes.description}>
          <Typography variant="h3" gutterBottom>
            Description
          </Typography>
          <Box>
            <Typography>
              <div
                dangerouslySetInnerHTML={
                  {
                    __html: nl2br(
                      JSON.parse(selected.manifest).description || '',
                    ),
                  } as any
                }
              />
            </Typography>
          </Box>
        </Box>
      )}

      <Box className={classes.description}>
        <Typography variant="h3" gutterBottom>
          Destination
        </Typography>
        {selected.shortDelivery.map((shortItem, index) => (
          <Box key={`${index}`} className={classes.info}>
            <Typography variant="subtitle1">
              {shortItem.dropoff.name}
            </Typography>
            <Typography>{shortItem.dropoff.phone}</Typography>
            <Typography>{shortItem.dropoff.address}</Typography>
          </Box>
        ))}
      </Box>

      <Divider
        variant="fullWidth"
        style={{ marginTop: '1rem', marginBottom: '1rem' }}
      />

      {selected.manifestItem && (
        <Box>
          <Typography>
            Taille : {JSON.parse(selected.manifestItem).taille}
          </Typography>
          <Typography>
            Poids : {JSON.parse(selected.manifestItem).poids} kg
          </Typography>
          {selected.courier.name && (
            <Typography>Livreur : {selected.courier.name}</Typography>
          )}
          <Typography>Transport : {selected.vehiculeType.name}</Typography>
          <Typography>
            Immatriculation : {selected.vehicule.immatriculation}
          </Typography>
          {data?.amountTotalpayments && (
            <Typography>
              Montant total :{' '}
              {data ? numberWithSpaces(data.amountTotalpayments) : 0} Ar
            </Typography>
          )}
        </Box>
      )}

      {/* <Box display="flex" mt={2}>
        <Button variant="outlined">Voir la facture</Button>
        <Button variant="contained" color="primary" onClick={handleShowDialog}>
          Noter
        </Button>
      </Box> */}
    </Box>
  );
};

export default DeliveryDetails;

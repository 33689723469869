import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { config } from 'dotenv';
import { ApolloProvider } from '@apollo/react-hooks';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import './index.css';
import GlobalSnackBar from './Components/GlobalSnackBar';
import App from './Container/App';
import createClient from './apolloClient';
import theme from './theme';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from './Providers';
import { initializeFirebase } from './Utils/firebase';
config();
const token = localStorage.getItem('token');
initializeFirebase();
const client = createClient(token ? token : '');

ReactDOM.render(
  <ApolloProvider client={client}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalSnackBar />
      <BrowserRouter>
        <Provider>
          <App />
        </Provider>
      </BrowserRouter>
    </ThemeProvider>
  </ApolloProvider>,
  document.getElementById('root'),
);

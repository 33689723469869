import React, { useState } from 'react';
import { createContext, FC } from 'react';

interface ExpediteurInputProps {
  id: string;
  name: string;
  phone: string;
  address: string;
  addressBookId: string;
  setIdExpediteur: (expediteur: string) => void;
  setNameExpediteur: (expediteur: string) => void;
  setPhonexpediteur: (expediteur: string) => void;
  setAdressExpediteur: (expediteur: string) => void;
  setAdresseBookIdExpediteur: (expediteur: string) => void;
  clearExpediteur: () => void;
}

export const ExpediteurContext = createContext<ExpediteurInputProps>({
  id: '',
  name: '',
  address: '',
  phone: '',
  addressBookId: '',
  setIdExpediteur: (expediteur: string) => {},
  setNameExpediteur: (expediteur: string) => {},
  setPhonexpediteur: (expediteur: string) => {},
  setAdressExpediteur: (expediteur: string) => {},
  setAdresseBookIdExpediteur: (expediteur: string) => {},
  clearExpediteur: () => {},
});

export const ExpediteurProvider: FC = ({ children }) => {
  const [id, setIdExpediteur_] = useState('');
  const [name, setNameExpediteur_] = useState('');
  const [address, setAdressExpediteur_] = useState('');
  const [phone, setPhonexpediteur_] = useState('');
  const [adresseBookId, setAdresseBookIdExpediteur_] = useState('');

  return (
    <ExpediteurContext.Provider
      value={{
        id: id,
        name: name,
        address: address,
        phone: phone,
        addressBookId: adresseBookId,
        clearExpediteur: () => {
          setIdExpediteur_('');
          setNameExpediteur_('');
          setAdressExpediteur_('');
          setPhonexpediteur_('');
          setAdresseBookIdExpediteur_('');
        },
        setIdExpediteur: (value) => {
          setIdExpediteur_(value);
        },
        setNameExpediteur: (value) => {
          setNameExpediteur_(value);
        },
        setAdressExpediteur: (value) => {
          setAdressExpediteur_(value);
        },
        setPhonexpediteur: (value) => {
          setPhonexpediteur_(value);
        },
        setAdresseBookIdExpediteur: (value) => {
          setAdresseBookIdExpediteur_(value);
        },
      }}>
      {children}
    </ExpediteurContext.Provider>
  );
};

import React, { useContext } from 'react';
import { compose, withProps } from 'recompose';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps';
import { Box } from '@material-ui/core';
import { ExpediteurLocationContext } from '../../Providers/ExpediteurLocation';
import { ExpediteurContext } from '../../Providers/Expediteur';
import Locate from '../MapComponent/Locate';

const LocationProps = () => {
  const {
    setLatitudeExpediteurLocation,
    latitude,
    longitude,
    setLongitudeExpediteurLocation,
  } = useContext(ExpediteurLocationContext);
  const { setIdExpediteur } = useContext(ExpediteurContext);

  const onMapClick = (
    e: google.maps.MouseEvent | google.maps.IconMouseEvent,
  ) => {
    setIdExpediteur('');
    setLatitudeExpediteurLocation(+e.latLng.lat());
    setLongitudeExpediteurLocation(+e.latLng.lng());
  };

  const onDragEnd = (e: google.maps.MouseEvent) => {
    setIdExpediteur('');
    setLatitudeExpediteurLocation(+e.latLng.lat());
    setLongitudeExpediteurLocation(+e.latLng.lng());
  };

  const defaultMapOptions = {
    fullscreenControl: false,
    streetViewControl: false,
    //mapTypeControl: false,
    // zoomControl: false,
  };

  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  const success = (pos: any) => {
    var crd = pos.coords;
    setIdExpediteur('');
    setLatitudeExpediteurLocation(crd.latitude);
    setLongitudeExpediteurLocation(crd.longitude);
  };

  const error = (err: any) => {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  };

  const onClickPosition = () => {
    navigator.geolocation.getCurrentPosition(success, error, options);
  };

  return (
    <Box position="relative" height="100%">
      {/* <Locate onClick={onClickPosition} /> */}
      <GoogleMap
        defaultZoom={16}
        defaultCenter={{
          lat: latitude ? latitude : -18.8791902,
          lng: longitude ? longitude : 47.5079055,
        }}
        center={{
          lat: latitude ? latitude : -18.8791902,
          lng: longitude ? longitude : 47.5079055,
        }}
        defaultOptions={defaultMapOptions}
        onClick={onMapClick}>
        {latitude != undefined && longitude != undefined && (
          <Marker
            title={'Maintenir puis déplacer!'}
            position={{ lat: latitude, lng: longitude }}
            draggable={true}
            onDragEnd={(e) => {
              onDragEnd(e);
            }}
          />
        )}
      </GoogleMap>
    </Box>
  );
};

const MapExpediteur = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_API_GOOGLE_MAP}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `87vh` }} />,
    center: { lat: -18.8791902, lng: 47.5079055 },
    zoomControl: true,
    disableDefaultUI: true,
  }),
  withScriptjs,
  withGoogleMap,
)(LocationProps);

export default MapExpediteur;

import React, { useContext } from 'react';

import { Backdrop, Box, Button } from '@material-ui/core';
import DestinationInfo from '../DestinationInfo';
import { CSSProperties } from '@material-ui/styles';
import DestinationInput from './DestinationInput';
import { DestinationContext } from '../../../Providers/Destination';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import {
  DO_CREATE_ADRESS_BOOK,
  DO_CREATE_DROP_OFF,
} from '../../../Graphql/Delivery/mutation';
import {
  CREATE_ADRESS_BOOK,
  CREATE_ADRESS_BOOKVariables,
} from '../../../Graphql/Delivery/types/CREATE_ADRESS_BOOK';
import SnackVariableInterface from '../../../Interfaces/SnackVariableInterface';
import { displaySnackBar } from '../../../Utils/snackBarUtils';
import {
  CREATE_DROP_OFF,
  CREATE_DROP_OFFVariables,
} from '../../../Graphql/Delivery/types/CREATE_DROP_OFF';
import { UserContext } from '../../../Providers/User';
import { DestinationsContext } from '../../../Providers/Destinations';

const Destination = () => {
  const styles: CSSProperties = {
    margin: 'auto',
    color: '#4267B2',
    backgroundColor: '#ECF1F9',
  };

  const {
    id: idDest,
    addressBookId,
    name: nameDest,
    address: addressDest,
    phone: phoneDest,
    latitude: latitudeDest,
    longitude: longitudeDest,
    clearDestination,
    setAdresseBookIdDestination,
  } = useContext(DestinationContext);

  const { addDestination } = useContext(DestinationsContext);
  const { customerId } = useContext(UserContext);

  const client = useApolloClient();

  const [
    doCreateAdresseBook,
    { loading: loadingCreateAdresseBook },
  ] = useMutation<CREATE_ADRESS_BOOK, CREATE_ADRESS_BOOKVariables>(
    DO_CREATE_ADRESS_BOOK,
    {
      onError: (err) => {
        if (err.networkError?.message === 'Failed to fetch') {
          const snackBarData: SnackVariableInterface = {
            type: 'ERROR',
            message: 'Problème de connexion...',
            isOpen: true,
          };
          return displaySnackBar(client, snackBarData);
        }
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: err.message.split(':')[1],
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      },
      onCompleted: (data) => {
        doCreateDropOff({
          variables: {
            name: nameDest,
            address: addressDest,
            phone: phoneDest,
            latitude: latitudeDest === 0 ? '' : '' + latitudeDest,
            longitude: longitudeDest === 0 ? '' : '' + longitudeDest,
            addressBookId:
              data && data.createAddressBook && data.createAddressBook.id,
          },
        });
        setAdresseBookIdDestination(
          data && data.createAddressBook && data.createAddressBook.id,
        );
      },
    },
  );

  const [doCreateDropOff, { loading }] = useMutation<
    CREATE_DROP_OFF,
    CREATE_DROP_OFFVariables
  >(DO_CREATE_DROP_OFF, {
    onError: (err) => {
      if (err.networkError?.message === 'Failed to fetch') {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Problème de connexion...',
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      }
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: err.message.split(':')[1],
        isOpen: true,
      };
      return displaySnackBar(client, snackBarData);
    },
    onCompleted: (data) => {
      addDestination({
        id: data.createDropoff.id,
        name: nameDest,
        address: addressDest,
        phone: phoneDest,
        latitude: String(latitudeDest),
        longitude: String(longitudeDest),
        addressBookId: addressBookId,
      });
      clearDestination();
    },
  });

  const onClickAjouter = () => {
    doCreateAdresseBook({
      variables: {
        name: nameDest,
        phone: phoneDest,
        address: addressDest,
        latitude: latitudeDest === 0 ? '' : '' + latitudeDest,
        longitude: longitudeDest === 0 ? '' : '' + longitudeDest,
        customer: customerId,
      },
    });
  };

  return (
    <Box pr={1} pb={1}>
      <Backdrop open={loading} />
      <Backdrop open={loadingCreateAdresseBook} />
      <DestinationInput title="Choisir une destination" />
      <Button variant="contained" style={styles} onClick={onClickAjouter}>
        Ajouter
      </Button>
      <DestinationInfo />
    </Box>
  );
};

export default Destination;

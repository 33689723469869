import gql from 'graphql-tag';

export const DO_FORGOT_PASSWORD = gql`
  mutation FORGOT_PASSWORD($email: String!) {
    forgotPassword(email: $email) {
      action
      success
      message
    }
  }
`;

export const DO_CONFIRME_FORGOT_PASSWORD = gql`
  mutation CONFIRME_FORGOT_PASSWORD($token: String!, $newPassword: String!) {
    confirmForgotPassword(token: $token, newPassword: $newPassword) {
      accessToken
    }
  }
`;

export const DO_SIGNUP_WITH_FACEBOOK = gql`
  mutation SIGNUP_WITH_FACEBOOK($idToken: String!) {
    signupWithFacebook(idToken: $idToken) {
      accessToken
      user {
        id
        email
        phone
        name
        firstName
        password
        courierId
        customerId
      }
    }
  }
`;

export const DO_CREATE_FCM_TOKEN = gql`
  mutation CREATE_FCM_TOKEN($user: String!, $token: String!) {
    createFCMToken(user: $user, token: $token) {
      message
      fCMToken {
        id
        token
        userId
      }
    }
  }
`;

export const DO_CONFIRM_ACCOUNT = gql`
  mutation CONFIRM_ACCOUNT($token: String!) {
    confirmCompte(token: $token) {
      accessToken
      user {
        id
        email
        phone
        name
        firstName
        password
        courierId
        customerId
      }
    }
  }
`;

import React, { useContext, useEffect, useMemo, useState } from 'react';
import { compose, withProps } from 'recompose';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  DirectionsRenderer,
  Marker,
} from 'react-google-maps';
import { Box } from '@material-ui/core';
import { PointContext } from '../../../Providers/Points';
import { useQuery, useSubscription } from '@apollo/react-hooks';
import {
  DO_LOCATION_COURIER,
  DO_USER_BY_COURIER,
} from '../../../Graphql/Livreur/query';
import { DO_NEW_POSITION_COURIER_SUBSCRIPTION } from '../../../Graphql/Livreur/subscription';
import {
  LOCATION_COURIER,
  LOCATION_COURIERVariables,
} from '../../../Graphql/Livreur/types/LOCATION_COURIER';
import {
  NEW_POSITION_COURIER_SUBSCRIPTION,
  NEW_POSITION_COURIER_SUBSCRIPTIONVariables,
} from '../../../Graphql/Livreur/types/NEW_POSITION_COURIER_SUBSCRIPTION';
import {
  USER_BY_COURIER,
  USER_BY_COURIERVariables,
} from '../../../Graphql/Livreur/types/USER_BY_COURIER';
import { CourierContext } from '../../../Providers/courier';

const MapDeliveryDetail = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_API_GOOGLE_MAP}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `87vh` }} />,
    center: { lat: -18.8791902, lng: 47.5079055 },
    streetViewControl: false,
    fullscreenControl: false,
    zoomControl: true,
    disableDefaultUI: true,
  }),
  withScriptjs,
  withGoogleMap,
)(() => {
  const [courierIdUser, setcourierIdUser] = useState('');
  const [directions, setDirections] = useState<google.maps.DirectionsResult>();

  const { courier } = useContext(CourierContext);
  const { points } = useContext(PointContext);

  const directionsService = useMemo(
    () => new google.maps.DirectionsService(),
    [],
  );

  const { data: dataCourierId } = useQuery<
    LOCATION_COURIER,
    LOCATION_COURIERVariables
  >(DO_LOCATION_COURIER, {
    variables: { id: courier?.id || '' },
    fetchPolicy: 'cache-and-network',
  });

  useQuery<USER_BY_COURIER, USER_BY_COURIERVariables>(DO_USER_BY_COURIER, {
    variables: {
      id: courier?.id || '',
    },
    onCompleted: (data) => {
      if (data && data.userByCourier && data.userByCourier) {
        setcourierIdUser(data.userByCourier.id);
      }
    },
  });

  useSubscription<
    NEW_POSITION_COURIER_SUBSCRIPTION,
    NEW_POSITION_COURIER_SUBSCRIPTIONVariables
  >(DO_NEW_POSITION_COURIER_SUBSCRIPTION, {
    variables: {
      courier_id: courierIdUser,
    },
    onSubscriptionData: ({ client, subscriptionData }) => {
      if (subscriptionData.data?.newPositionCourierSubscription?.location) {
        client.writeQuery<LOCATION_COURIER, LOCATION_COURIERVariables>({
          query: DO_LOCATION_COURIER,
          variables: {
            id: courier?.id || '',
          },
          data: {
            locationCourier: {
              courierPosition:
                subscriptionData.data.newPositionCourierSubscription,
              __typename: 'CourierPayloadObjectPosition',
            },
          },
        });
      }
    },
  });

  useEffect(() => {
    if (points.length !== 0) {
      const origin = {
        lat:
          +points[0].latitude === undefined ? -18.8791902 : +points[0].latitude,
        lng:
          +points[0].longitude === undefined
            ? 47.5079055
            : +points[0].longitude,
      };

      const destination = {
        lat:
          +points[points.length - 1].latitude === undefined
            ? -18.8791902
            : +points[points.length - 1].latitude,
        lng:
          +points[points.length - 1].longitude === undefined
            ? 47.5079055
            : +points[points.length - 1].longitude,
      };

      const wayPoints = () => {
        let locations: google.maps.DirectionsWaypoint[] = [];
        for (const location of points) {
          locations.push({
            location: new google.maps.LatLng(
              +location.latitude,
              +location.longitude,
            ),
          });
        }
        return locations;
      };

      directionsService.route(
        {
          origin: origin,
          destination: destination,
          travelMode: google.maps.TravelMode.DRIVING,
          waypoints: wayPoints(),
          optimizeWaypoints: false,
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            setDirections(result);
          }
        },
      );
    }
  }, [directionsService, points]);

  const defaultMapOptions = {
    fullscreenControl: false,
    streetViewControl: false,
  };

  return (
    <Box position="relative" height="100%">
      <GoogleMap
        defaultZoom={18}
        defaultCenter={{ lat: -18.8791902, lng: 47.5079055 }}
        defaultOptions={defaultMapOptions}>
        {directions?.routes && <DirectionsRenderer directions={directions} />}

        <Marker
          position={{
            lat:
              dataCourierId?.locationCourier?.courierPosition.location &&
              JSON.parse(
                dataCourierId?.locationCourier?.courierPosition.location,
              ).coordinates[1],
            lng:
              dataCourierId?.locationCourier?.courierPosition.location &&
              JSON.parse(
                dataCourierId?.locationCourier?.courierPosition.location,
              ).coordinates[0],
          }}
          animation={google.maps.Animation.DROP}></Marker>
      </GoogleMap>
    </Box>
  );
});

export default MapDeliveryDetail;

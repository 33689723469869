import React, { useState } from 'react';
import { createContext, FC } from 'react';
import { MY_DELIVERY_ON_THE_WAY_myDeliveriesOnTheWay_courier } from '../Graphql/Delivery/types/MY_DELIVERY_ON_THE_WAY';

interface CourierInfoProps {
  courier: MY_DELIVERY_ON_THE_WAY_myDeliveriesOnTheWay_courier | undefined;
  setCourier: (
    courier: MY_DELIVERY_ON_THE_WAY_myDeliveriesOnTheWay_courier | undefined,
  ) => void;
  clearCourier: () => void;
}

export const CourierContext = createContext<CourierInfoProps>({
  courier: undefined,
  setCourier: () => {},
  clearCourier: () => {},
});

export const CourierProvider: FC = ({ children }) => {
  const [courier, setCourier] = useState<
    MY_DELIVERY_ON_THE_WAY_myDeliveriesOnTheWay_courier | undefined
  >();
  return (
    <CourierContext.Provider
      value={{
        courier,
        setCourier,
        clearCourier: () => {
          setCourier(undefined);
        },
      }}>
      {children}
    </CourierContext.Provider>
  );
};

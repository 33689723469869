import React, { createRef, useContext, useState } from 'react';
import {
  AppBar as AppBarTop,
  Toolbar,
  Typography,
  IconButton,
  Box,
  Badge,
  Menu,
  MenuItem,
  ListItemIcon,
  ClickAwayListener,
  Button,
  Paper,
  Popper,
} from '@material-ui/core';
import Dropdown from '@material-ui/icons/KeyboardArrowDown';
import Notifications from '@material-ui/icons/NotificationsNone';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import ArrowRight from '@material-ui/icons/ArrowRight';
import Exit from '@material-ui/icons/ExitToApp';
import styles from './style';
import Mobix from '../../Icons/Mobix';
import { UserContext } from '../../../Providers/User';
import { useTheme } from '../../Wrapper/Wrapper';
import {
  useApolloClient,
  useMutation,
  useQuery,
  useSubscription,
} from '@apollo/react-hooks';
import {
  DO_GET_NUMBER_NOTIFICATION,
  DO_NOTIFICATION_CUSTOMER,
} from '../../../Graphql/Notification/query';
import {
  DO_NEW_NUMBER_NOTIFICATION,
  DO_NEW_NOTIFICATION,
} from '../../../Graphql/Notification/subscription';
import {
  GET_NUMBER_NOTIFICATION,
  GET_NUMBER_NOTIFICATIONVariables,
} from '../../../Graphql/Notification/types/GET_NUMBER_NOTIFICATION';
import {
  NEW_NOTIFICATION,
  NEW_NOTIFICATIONVariables,
} from '../../../Graphql/Notification/types/NEW_NOTIFICATION';
import {
  NEW_NUMBER_NOTIFICATION,
  NEW_NUMBER_NOTIFICATIONVariables,
} from '../../../Graphql/Notification/types/NEW_NUMBER_NOTIFICATION';
import {
  NOTIFICATION_CUSTOMER,
  NOTIFICATION_CUSTOMERVariables,
} from '../../../Graphql/Notification/types/NOTIFICATION_CUSTOMER';
import SnackVariableInterface from '../../../Interfaces/SnackVariableInterface';
import { displaySnackBar } from '../../../Utils/snackBarUtils';
import { DO_UPDATE_NOTIFICATION } from '../../../Graphql/Notification/mutation';
import {
  UPDATE_NOTIFICATION,
  UPDATE_NOTIFICATIONVariables,
} from '../../../Graphql/Notification/types/UPDATE_NOTIFICATION';
import { useHistory } from 'react-router-dom';
import RatingNote from '../../RatingNote';

const AppBar = (props: { open: boolean }) => {
  const { open } = props;
  const classes = styles();
  const menuRef = createRef<HTMLButtonElement>();
  const [anchorElt, setAnchorElt] = React.useState<HTMLElement | null>(null);
  const [menu, setMenu] = useState<boolean>(false);

  const { cleanUser, name, firstName, idMe } = useContext(UserContext);
  const client = useApolloClient();

  const toggle = useTheme();
  const handleClick = () => {
    toggle.toggle();
  };

  const handleMenuClick = () => {
    setMenu(!menu);
    setAnchorElt(menuRef.current);
  };

  const logout = () => {
    localStorage.clear();
    cleanUser();
    window.location.reload();
  };

  const [numberNotification, setNumberNotification] = useState<number>(0);
  const history = useHistory();

  const [showRating, setshowRating] = useState(false);
  const [deliveryId, setDeliveryId] = useState('');
  const [courrierId, setCourrierId] = useState('');

  useQuery<GET_NUMBER_NOTIFICATION, GET_NUMBER_NOTIFICATIONVariables>(
    DO_GET_NUMBER_NOTIFICATION,
    {
      variables: { idUser: idMe },
      skip: idMe ? false : true,
      onCompleted: (data) => {
        if (data && data.num_notification) {
          setNumberNotification(data.num_notification);
        }
      },

      onError: (err) => {
        if (err.networkError?.message === 'Failed to fetch') {
          const snackBarData: SnackVariableInterface = {
            type: 'ERROR',
            message: 'Problème de connexion...',
            isOpen: true,
          };
          return displaySnackBar(client, snackBarData);
        }
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: err.message.split(':')[1],
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  useSubscription<NEW_NUMBER_NOTIFICATION, NEW_NUMBER_NOTIFICATIONVariables>(
    DO_NEW_NUMBER_NOTIFICATION,
    {
      variables: { user_id: idMe },
      skip: idMe ? false : true,
      onSubscriptionData: ({ client, subscriptionData }) => {
        if (
          subscriptionData &&
          subscriptionData.data &&
          subscriptionData.data.newNumberNotification
        ) {
          setNumberNotification(
            subscriptionData.data.newNumberNotification.nbrNotifitication,
          );
        }
      },
    },
  );

  const { data: dataNotif, loading: loadingNotif } = useQuery<
    NOTIFICATION_CUSTOMER,
    NOTIFICATION_CUSTOMERVariables
  >(DO_NOTIFICATION_CUSTOMER, {
    skip: idMe ? false : true,
    variables: { idUser: idMe },
    fetchPolicy: 'cache-and-network',
  });

  useSubscription<NEW_NOTIFICATION, NEW_NOTIFICATIONVariables>(
    DO_NEW_NOTIFICATION,
    {
      variables: { user_id: idMe },
      onSubscriptionData: ({ client, subscriptionData }) => {
        if (
          subscriptionData &&
          subscriptionData.data &&
          subscriptionData.data.newNotification
        ) {
          const dataPreview = client.cache.readQuery<
            NOTIFICATION_CUSTOMER,
            NOTIFICATION_CUSTOMERVariables
          >({ query: DO_NOTIFICATION_CUSTOMER, variables: { idUser: idMe } });

          client.writeQuery<
            NOTIFICATION_CUSTOMER,
            NOTIFICATION_CUSTOMERVariables
          >({
            query: DO_NOTIFICATION_CUSTOMER,
            variables: { idUser: idMe },
            data: {
              notificationsCustomer: dataPreview
                ? [
                    ...[subscriptionData.data.newNotification.publishedPost],
                    ...dataPreview.notificationsCustomer,
                  ]
                : [],
            },
          });
        }
      },
    },
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClickNotification = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const openNotification = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const [doUpdateNotification, { loading }] = useMutation<
    UPDATE_NOTIFICATION,
    UPDATE_NOTIFICATIONVariables
  >(DO_UPDATE_NOTIFICATION, {
    onCompleted: (data) => {},
    onError: (err) => {
      if (err.networkError?.message === 'Failed to fetch') {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Problème de connexion...',
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      }
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: err.message.split(':')[1],
        isOpen: true,
      };
      return displaySnackBar(client, snackBarData);
    },
  });

  const onPressCard = (
    idNotification: string,
    status: string,
    hotelReference: string,
    volReference: string,
  ) => {
    doUpdateNotification({
      variables: { id: idNotification, status: 'vue' },
    }).then((result) => {
      if (status === 'livrée') {
        setDeliveryId(
          result.data?.updateNotification.notificationInterm.shortDelivery
            ?.delivery.id || '',
        );
        setCourrierId(
          result.data?.updateNotification.notificationInterm.shortDelivery
            ?.delivery.courier?.id || '',
        );
        setshowRating(false);
        setshowRating(true);
      }
      if (hotelReference.length !== 0) {
        history.push('/accueil/Recapitulation-reservation/');
      }
      if (volReference.length !== 0) {
        history.push('/accueil/Reservation-Vol/Recapitulation-Vol');
      }
    });
  };

  return (
    <AppBarTop position="absolute" color="transparent" elevation={0}>
      {showRating && (
        <RatingNote courrierId={courrierId} deliveryId={deliveryId} />
      )}
      <Toolbar className={classes.toolbar}>
        <Button
          variant="contained"
          className={classes.toggleBtn}
          onClick={handleClick}>
          {open ? <ArrowLeft /> : <ArrowRight />}
        </Button>
        {!open && <Mobix htmlColor="#4267B2" className={classes.logoAppBar} />}
        <Box className={classes.toolbarActions}>
          <Box className={classes.notification}>
            <IconButton
              aria-label="show 3 new notifications"
              size="medium"
              onClick={(e) => handleClickNotification(e)}>
              <Badge badgeContent={numberNotification} color="error">
                <Notifications className={classes.NotifModif} />
              </Badge>
            </IconButton>
          </Box>

          <Box className={classes.userInfo}>
            <Typography>Bonjour</Typography>
            {firstName ? (
              <Typography className={classes.userName}>{firstName}</Typography>
            ) : name ? (
              <Typography className={classes.userName}>{name}</Typography>
            ) : (
              <></>
            )}
          </Box>
          <Box>
            <IconButton
              className={classes.toolbarBtn}
              size="small"
              ref={menuRef}
              onClick={handleMenuClick}>
              <Dropdown />
            </IconButton>
            <Popper id={id} open={openNotification}>
              <Paper className={classes.paper}>
                {dataNotif &&
                  dataNotif.notificationsCustomer &&
                  dataNotif.notificationsCustomer &&
                  dataNotif.notificationsCustomer.length !== 0 &&
                  dataNotif.notificationsCustomer.map((item, index) => (
                    <Button
                      key={index}
                      onClick={() =>
                        onPressCard(
                          item.notification.id,
                          item.status,
                          (item &&
                            item.requestBooking &&
                            item.requestBooking.hotel &&
                            item.requestBooking.hotel.name) ||
                            '',
                          (item &&
                            item.requestBookingFlight &&
                            item.requestBookingFlight.reference) ||
                            '',
                        )
                      }
                      style={{
                        backgroundColor:
                          item.notification.status === 'vue'
                            ? undefined
                            : '#ecf1f9',
                      }}>
                      {item.shortDelivery?.delivery.reference && (
                        <Typography key={`${index}`} className={classes.Notif}>
                          Votre colis de Réf :{' '}
                          {item.shortDelivery?.delivery.reference || ''} (
                          N°colis : {item.shortDelivery?.reference || ''},
                          Destinataire :{' '}
                          {item.shortDelivery?.dropoff?.name || ''} ) est &nbsp;
                          {item.status.trim() === 'en cours de traitement'
                            ? "en cours d'enlèvement."
                            : ''}
                          {item.status.trim() === 'en route'
                            ? 'en cours de livraison.'
                            : ''}
                          {item.status.trim() === 'livrée' ? 'livré.' : ''}
                          {item.status.trim() === 'en retard'
                            ? 'en retard.'
                            : ''}
                          {item.status.trim() === 'en problème'
                            ? 'en pause'
                            : ''}
                        </Typography>
                      )}
                      {item &&
                        item.requestBooking &&
                        item.requestBooking.hotel &&
                        item.requestBooking.hotel.name &&
                        item.requestBooking.hotel.name.length !== 0 && (
                          <Typography
                            key={`${index}`}
                            className={classes.Notif}>
                            Votre réservation dans &quot;
                            {item.requestBooking?.hotel.name}&quot; Réf{' '}
                            {item.requestBooking?.reference} est traitée
                          </Typography>
                        )}
                      {item &&
                        item.requestBookingFlight &&
                        item.requestBookingFlight.reference &&
                        item.requestBookingFlight.reference.length !== 0 && (
                          <Typography
                            key={`${index}`}
                            className={classes.Notif}>
                            {item.status === 'CERTIFICAT_PAY'
                              ? `Votre demande d'attestation de réservation de vol Réf ${item.requestBookingFlight?.reference} est traitée`
                              : `Votre réservation de vol Réf ${
                                  item.requestBookingFlight?.reference
                                } ${
                                  item.requestBookingFlight.status ===
                                  'PROCESSING'
                                    ? 'est traitée'
                                    : 'est rejetée'
                                }`}
                          </Typography>
                        )}
                    </Button>
                  ))}
                {dataNotif &&
                  dataNotif.notificationsCustomer &&
                  dataNotif.notificationsCustomer.length === 0 && (
                    <Typography className={classes.Notif}>
                      Aucune notification
                    </Typography>
                  )}
              </Paper>
            </Popper>
            <Menu anchorEl={anchorElt} keepMounted={false} open={menu}>
              <ClickAwayListener onClickAway={handleMenuClick}>
                <MenuItem onClick={logout}>
                  <ListItemIcon>
                    <Exit fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="inherit">Déconnexion</Typography>
                </MenuItem>
              </ClickAwayListener>
            </Menu>
          </Box>
        </Box>
      </Toolbar>
    </AppBarTop>
  );
};

export default AppBar;

import React, { FC, useContext } from 'react';
import { Box, TextField, Typography } from '@material-ui/core';
import TextInput from '../../../../Components/TextInput';
import styles from '../style';
import { useQuery } from '@apollo/react-hooks';
import { DO_ADDRESS_BOOKS_DROP_OFF } from '../../../../Graphql/Delivery/query';
import { Autocomplete } from '@material-ui/lab';
import {
  ADDRESS_BOOKS_DROP_OFF,
  ADDRESS_BOOKS_DROP_OFF_addressBookUserDropoff,
} from '../../../../Graphql/Delivery/types/ADDRESS_BOOKS_DROP_OFF';
import { UserContext } from '../../../../Providers/User';
import SearchInMap from '../../../../Components/MapComponent/SearchInMap';
import { DestinationContext } from '../../../../Providers/Destination';
import {
  DestinationsContext,
  DestinationsInterface,
} from '../../../../Providers/Destinations';

interface IDestinationInput {
  title: string;
}

const DestinationInput: FC<IDestinationInput> = ({ title }) => {
  const classes = styles();
  const {
    setIdDestination,
    setNameDestination,
    setPhoneDestination,
    setAdresseBookIdDestination,
    setAdressDestination,
    setLongitudeDestination,
    setLatitudeDestination,
    name,
    phone,
    address,
    clearDestination,
  } = useContext(DestinationContext);

  const { addDestination } = useContext(DestinationsContext);

  const { customerId } = useContext(UserContext);

  const { data, refetch } = useQuery<ADDRESS_BOOKS_DROP_OFF>(
    DO_ADDRESS_BOOKS_DROP_OFF,
    {
      skip: customerId ? false : true,
      variables: { id: customerId },
    },
  );

  const onChangeDestination = (
    value: string | ADDRESS_BOOKS_DROP_OFF_addressBookUserDropoff | null,
  ) => {
    if (value === null) {
      clearDestination();
    } else {
      const { dropoff, id, name, address, phone } = value as any;
      const lng = Number(JSON.parse(dropoff.location).coordinates[0]);
      const lat = Number(JSON.parse(dropoff.location).coordinates[1]);
      if (dropoff.id) {
        setIdDestination(dropoff.id);
      }
      setAdresseBookIdDestination(id || '');
      setNameDestination(name || '');
      setAdressDestination(address || '');
      setPhoneDestination(phone || '');
      setLatitudeDestination(lat);
      setLongitudeDestination(lng);

      const dest = {
        id: dropoff.id,
        name: name,
        phone: phone,
        address: address,
        latitude: '' + lat,
        longitude: '' + lng,
        addressBookId: id,
      } as DestinationsInterface;
      addDestination(dest);
    }
  };

  const onChangeTextInput = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    clearDestination();
    setNameDestination(e.target.value);
  };

  const defaultProps = {
    options:
      (data && data.addressBookUserDropoff && data.addressBookUserDropoff) ||
      [],
    getOptionLabel: (option: ADDRESS_BOOKS_DROP_OFF_addressBookUserDropoff) =>
      option.name,
  };
  return (
    <Box position="relative" mb={1}>
      <Typography variant="h2">{title}</Typography>
      <Box className={classes.flex}>
        <Autocomplete
          id="name"
          freeSolo
          {...defaultProps}
          onChange={(event, value) => {
            onChangeDestination(value);
          }}
          onFocus={() => {
            refetch();
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Prénom et nom"
              margin="normal"
              style={{ fontSize: 12 }}
              //value={name}
              onChange={(e) => {
                onChangeTextInput(e);
              }}
            />
          )}
        />
        <TextInput
          type="phone"
          variant="filled"
          name="Téléphone"
          placeholder="Téléphone"
          value={phone}
          onChange={(e) => setPhoneDestination(e.target.value)}
        />
      </Box>
      <SearchInMap />
      <Box>
        <TextInput
          type="text"
          variant="filled"
          name="Adresse"
          placeholder="Adresse ou point de repère"
          value={address}
          onChange={(e) => setAdressDestination(e.target.value)}
          fullWidth
        />
      </Box>
    </Box>
  );
};

export default DestinationInput;

import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(1.5),
    '& Button': {
      backgroundColor: '#F75A5F',
      width:30,
      heigth:30,
      transition: 'background ease 0.2s',
      '&:hover': {
        backgroundColor: '#DB0000',
      },
    },
    '& svg': {
      color: theme.palette.background.paper,
    },
  },
  bordered: {
    marginRight: theme.spacing(2),
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    border: `1px solid #A7A7A7`,
    width:'100%',
    borderRadius: 5,
    '& p': {
      fontSize: '.875rem',
    },
    '& p:first-of-type': {
      fontWeight: 700,
    },
  },
}));

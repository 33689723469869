import React, { FC, useState, useContext, useEffect } from 'react';
import { useApolloClient, useQuery, useLazyQuery } from '@apollo/react-hooks';
import moment, { now } from 'moment';
import Box from '@material-ui/core/Box';
import useStyles from './style';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import img from '../../Images/lovaHotel.png';
import img1 from '../../Images/ikopaHotel.png';
import madaHotel from '../../Images/madaHotel.png';
import CardHotel from './../../Components/CardHotel/CardHotel';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MenuIcon from '@material-ui/icons/Menu';
import DateAndTime from '../../Components/DateAndTime';
import { DO_FILTER_HOTELS, DO_SEARCH_HOTEL } from '../../Graphql/Hotel/query';
import {
  SEARCH_HOTEL,
  SEARCH_HOTELVariables,
} from '../../Graphql/Hotel/types/SEARCH_HOTEL';
import { DO_GET_HOTELS } from '../../Graphql/Hotel/query';
import { GET_HOTELS } from '../../Graphql/Hotel/types/GET_HOTELS';
import SnackVariableInterface from '../../Interfaces/SnackVariableInterface';
import { displaySnackBar } from '../../Utils/snackBarUtils';
import {
  FILTER_HOTELS,
  FILTER_HOTELSVariables,
} from '../../Graphql/Hotel/types/FILTER_HOTELS';

import { HotelContext } from '../../Providers/Hotel';
import { HotelFilterContext } from '../../Providers/HotelFilter';
import {
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
} from '@material-ui/core';
import { Animated } from 'react-animated-css';

const BookingHotel: FC<RouteComponentProps> = () => {
  const client = useApolloClient();

  const history = useHistory();
  const { setHotel } = useContext(HotelContext);
  const {
    hotelFilter,
    setHotelFilter,
    dateDepartContext,
    dateArriveContext,
    numberStars,

    setMotCle,
    setDateDepartContext,
    setDateArriveContext,
    setNumberStars,
    motCle,
  } = useContext(HotelFilterContext);

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [fieldSearch, setFieldSearch] = useState<any>(motCle);
  const [filterWithDate, setFilterWithDate] = useState<boolean>(false);
  const [dateDepart, setDepartDate] = useState<Date | null>(dateDepartContext);
  const [dateArrive, setArriveDate] = useState<Date | null>(dateArriveContext);
  const [show, setShow] = useState<true | false>(false);
  const [asc, setAsc] = useState<boolean>(true);
  const [helper, setHelper] = useState<string>('Veuiller choisir une date');
  const [etoile, setEtoile] = React.useState(numberStars);
  const [ascFilter, setAscFilter] = useState<boolean | undefined>();
  const [descFilter, setDescFilter] = useState<boolean | undefined>();

  const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
    },
  })((props: MenuProps) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));
  useEffect(() => {
    console.log(hotelFilter);
  }, []);

  const { data: dataHotel, loading: loadingHotel } = useQuery<GET_HOTELS>(
    DO_GET_HOTELS,
    {
      onError: (err) => {
        if (err.networkError?.message === 'Failed to fetch') {
          const snackBarData: SnackVariableInterface = {
            type: 'ERROR',
            message: 'Problème de connexion...',
            isOpen: true,
          };
          return displaySnackBar(client, snackBarData);
        }
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: err.message.split(':')[1],
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      },
    },
  );

  const { data: doGetFilter, loading: loadingFilter } = useQuery<
    FILTER_HOTELS,
    FILTER_HOTELSVariables
  >(DO_FILTER_HOTELS, {
    onError: (err) => {
      if (err.networkError?.message === 'Failed to fetch') {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Problème de connexion...',
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      }
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: err.message.split(':')[1],
        isOpen: true,
      };
      return displaySnackBar(client, snackBarData);
    },
    variables: {
      numberStar: etoile,
      dateArrival: moment(dateDepart).unix(),
      dateDeparture: moment(dateArrive).unix(),
      searchName: fieldSearch,
      asc: ascFilter,
      desc: descFilter,
    },
    onCompleted: (data) => {
      if (data && data.filterHotels) {
        setHotelFilter(data.filterHotels);
        setDateArriveContext(dateArrive);
        setDateDepartContext(dateDepart);
        setMotCle(fieldSearch);
        setNumberStars(etoile);
        // console.log(data.filterHotels);
      }
    },
    fetchPolicy: 'network-only',
  });
  useEffect(() => {
    console.log(show);
  }, [show]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const gotoReservation = (id: string) => {
    const hotel =
      dataHotel && dataHotel.hotels.find((hotel) => hotel.id === id);
    setHotel(hotel);
    if (hotel) {
      history.push(`/accueil/ReservationHotel/${hotel.id}`);
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
  };
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setEtoile(Number(event.target.value));
  };

  return (
    <Box className={classes.bookingContaitner}>
      <Box className={classes.inputcontent}>
        <Box
          component="form"
          className={classes.root}
          onSubmit={(e) => handleSubmit(e)}>
          <InputBase
            className={
              fieldSearch === '' && helper === ''
                ? classes.inputOnShake
                : classes.inputNormal
            }
            placeholder="Rechercher ( ville ou hôtel )"
            onChange={(e) => setFieldSearch(e.target.value)}
            error={true}
            color={'primary'}
            value={fieldSearch}
          />
        </Box>

        <Box>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}>
            <MenuIcon />
          </Button>

          <Menu
            className={classes.menuBooking}
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}>
            <FormControl className={classes.listEtoiles}>
              <InputLabel id="demo-simple-select-label">Catégories</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={etoile}
                onChange={handleChange}>
                <MenuItem value={0}>Tous</MenuItem>
                <MenuItem value={1}>1 etoile</MenuItem>
                <MenuItem value={2}>2 étoiles</MenuItem>
                <MenuItem value={3}>3 étoiles</MenuItem>
                <MenuItem value={4}>4 étoiles</MenuItem>
                <MenuItem value={5}>5 étoiles</MenuItem>
              </Select>
            </FormControl>
            <MenuItem>
              <DateAndTime
                titre="Date d'arrivée"
                setValue={setArriveDate}
                value={moment(dateArrive).format(' DDDD DD MMM YYYY')}
                //onChange={applyFilter}
                helper={helper}
                setHelper={setHelper}
                valueDate={dateArrive}
                dateDepart={dateDepart}
                setDateDepart={setDepartDate}
                type={'Hotel'}
              />
            </MenuItem>
            <MenuItem>
              <DateAndTime
                titre="Date de départ"
                setValue={setDepartDate}
                value={moment(dateDepart).format(' DDDD DD MMM YYYY')}
                //   onChange={applyFilter}
                error={
                  dateArrive && dateDepart && dateArrive > dateDepart
                    ? true
                    : false
                }
                helper={
                  dateArrive && dateDepart && dateArrive > dateDepart
                    ? 'Date de départ inferieur à date Arrivé'
                    : ''
                }
                valueDate={dateDepart}
                setArriveDate={setArriveDate}
                arriveDate={dateArrive}
                type={'Hotel'}
              />
            </MenuItem>
          </Menu>
        </Box>
      </Box>
      {fieldSearch === '' && helper === '' ? (
        <Typography className={classes.typo}>
          {'Veuiller entrer un lieu ou un hôtel'}
        </Typography>
      ) : null}
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignSelf: 'center',
          justifyContent: 'center',
        }}>
        <DateAndTime
          titre="Date d'arrivée"
          setValue={setArriveDate}
          //onChange={applyFilter}
          setHelper={setHelper}
          helper={
            dateArrive === null && dateDepart !== null
              ? "Veuiller ajouter une date d'arrivée"
              : helper
          }
          error={
            dateArrive === null && dateDepart !== null
              ? true
              : fieldSearch === ''
              ? false
              : helper === ''
              ? false
              : true
          }
          valueDate={dateArrive}
          dateDepart={dateDepart}
          setDateDepart={setDepartDate}
          type={'Hotel'}
        />
        <DateAndTime
          titre="Date de départ"
          setValue={setDepartDate}
          //onChange={applyFilter}
          error={
            dateArrive && dateDepart && dateArrive > dateDepart
              ? true
              : dateArrive !== null && dateDepart === null
              ? true
              : false
          }
          helper={
            dateArrive && dateDepart && dateArrive > dateDepart
              ? 'Date de départ inferieur à date Arrivé'
              : dateArrive !== null && dateDepart === null
              ? 'Veuiller choisir une date de départ'
              : ''
          }
          valueDate={dateDepart}
          setArriveDate={setArriveDate}
          arriveDate={dateArrive}
          type={'Hotel'}
        />

        {/* <Button style={{marginTop:10}}>Appliquer</Button> */}
      </Box>
      <>
        {dateArrive === null ||
        dateDepart === null ||
        fieldSearch === '' ? null : (
          <Box className={classes.boutonContainer}>
            <Button
              variant="contained"
              className={ascFilter ? classes.firstBouton : ''}
              onClick={() => {
                setAscFilter(undefined);
                setDescFilter(true);
              }}>
              Le plus Bas
            </Button>
            <Button
              variant="contained"
              className={descFilter ? classes.firstBouton : ''}
              onClick={() => {
                setDescFilter(undefined);
                setAscFilter(true);
              }}>
              Le plus cher
            </Button>
          </Box>
        )}
        {dateArrive === null ||
        dateDepart === null ||
        fieldSearch === '' ? null : loadingFilter ? (
          <CircularProgress style={{ alignSelf: 'center' }}></CircularProgress>
        ) : dateArrive === null ||
          dateDepart === null ||
          fieldSearch === '' ? null : (
          <Box className={classes.contentOffre}>
            {hotelFilter.length > 0 ? (
              hotelFilter.map((hotel) => (
                <Paper
                  className={classes.positionnementBouton}
                  key={hotel.id}
                  style={{ marginRight: '5px', marginLeft: '5px' }}>
                  <CardHotel
                    titre={hotel.name}
                    AdresseHotel={hotel.city}
                    href="href"
                    img={
                      hotel.pictureHotels[0] &&
                      hotel.pictureHotels[0].urlPicture
                        ? hotel.pictureHotels[0].urlPicture
                        : ''
                    }
                    price={hotel.rateDay}
                    starHotel={hotel.numberStar}
                    promo={
                      hotel.promotionHotels[0] &&
                      hotel.promotionHotels[0].ratePercentage
                        ? `-${hotel.promotionHotels[0].ratePercentage}`
                        : null
                    }
                    promoBegin={
                      hotel.promotionHotels[0] &&
                      hotel.promotionHotels[0].dateFirst
                        ? `-${hotel.promotionHotels[0].dateFirst}`
                        : null
                    }
                    promoEnd={
                      hotel.promotionHotels[0] &&
                      hotel.promotionHotels[0].dateLast
                        ? `-${hotel.promotionHotels[0].dateLast}`
                        : null
                    }
                  />
                  <Button
                    onClick={() => gotoReservation(hotel.id)}
                    variant="contained"
                    color="primary"
                    className={classes.bookingButton}>
                    Voir l&apos;offre
                  </Button>
                </Paper>
              ))
            ) : (etoile === 0 &&
                fieldSearch === '' &&
                !filterWithDate &&
                dataHotel &&
                dataHotel?.hotels.length > 0) ||
              loadingFilter ||
              loadingHotel ? (
              dataHotel?.hotels.map((hotel) => (
                <Paper
                  className={classes.positionnementBouton}
                  key={hotel.id}
                  style={{ marginRight: '5px', marginLeft: '5px' }}>
                  <CardHotel
                    titre={hotel.name}
                    AdresseHotel={hotel.city}
                    href="href"
                    img={
                      hotel.pictureHotels[0] &&
                      hotel.pictureHotels[0].urlPicture
                        ? hotel.pictureHotels[0].urlPicture
                        : ''
                    }
                    price={hotel.rateDay}
                    starHotel={hotel.numberStar}
                    promo={
                      hotel.promotionHotels[0] &&
                      hotel.promotionHotels[0].ratePercentage
                        ? `-${hotel.promotionHotels[0].ratePercentage}`
                        : null
                    }
                  />
                  <Button
                    onClick={() => gotoReservation(hotel.id)}
                    variant="contained"
                    color="primary"
                    className={classes.bookingButton}>
                    Voir l&apos;offre
                  </Button>
                </Paper>
              ))
            ) : (
              <Typography variant="h1" component="h2">
                ---
              </Typography>
            )}
          </Box>
        )}
      </>
    </Box>
  );
};
export default BookingHotel;

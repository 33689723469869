import React from 'react';
import { Button, ButtonProps } from '@material-ui/core';

export default function CustomButton(props: ButtonProps & { state: any }) {
  return (
    <Button
      {...props}
      variant={props.state === props.id ? 'contained' : 'outlined'}
      color={props.state === props.id ? 'secondary' : 'default'}
      id={props.id}>
      {props.children}
    </Button>
  );
}

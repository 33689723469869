import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { Box, Typography } from '@material-ui/core';
import moment from 'moment';
import React, { FC, useContext, useState } from 'react';
import Backdrop from '../../../Components/Backdrop';
import CardHistory from '../../../Components/CardHistory/CardHistory';
import { classnames } from '../../../Components/MapComponent/helper';
import { DO_GET_BILL_DELIVERY_USER } from '../../../Graphql/Bill/query';
import {
  GET_BILL_DELIVERY_USER,
  GET_BILL_DELIVERY_USERVariables,
} from '../../../Graphql/Bill/types/GET_BILL_DELIVERY_USER';
import filehistory from '../../../Images/filehistory.svg';
import SnackVariableInterface from '../../../Interfaces/SnackVariableInterface';
import { UserContext } from '../../../Providers/User';
import { displaySnackBar } from '../../../Utils/snackBarUtils';
import useStyles from './style';
interface livraison {
  value?: string | null;
  setValue?: (params: any) => void;
  index?: number;
  setIndex: (index: any) => void;
  showDetails?: () => void;
}
const HistoriqueLivraison: FC<livraison> = ({
  setValue,
  setIndex,
  showDetails,
}) => {
  const classes = useStyles();
  const { customerId } = useContext(UserContext);
  const client = useApolloClient();
  const [active, setActive] = useState(0);

  const { data, loading } = useQuery<
    GET_BILL_DELIVERY_USER,
    GET_BILL_DELIVERY_USERVariables
  >(DO_GET_BILL_DELIVERY_USER, {
    variables: { customer_id: customerId },
    skip: customerId ? false : true,
    fetchPolicy: 'cache-and-network',
    onError: (err) => {
      if (err.networkError?.message === 'Failed to fetch') {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Problème de connexion...',
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      }
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: err.message.split(':')[1],
        isOpen: true,
      };
      return displaySnackBar(client, snackBarData);
    },
    onCompleted: () => {
      setValue && setValue(data?.billsDeliveryUser[0]);
    },
  });

  const handleClick = (index: any | null) => {
    if (index) {
      setValue && setValue(data?.billsDeliveryUser[index - 1]);
    }
  };
  const Listehistoriques =
    data &&
    data.billsDeliveryUser.map((item, index) => {
      let numero = index + 1;
      return (
        <Box
          key={`${index}`}
          className={classnames(
            classes.demandeContainer,
            active === index ? classes.clicked : '',
          )}
          onClick={() => {
            handleClick(numero);
            setActive(index);
          }}>
          <img src={filehistory}></img>
          <Backdrop open={loading} />
          <CardHistory
            titre={item.reference}
            date={moment(item.datePaymentBill).format('DD-MM-YYYY')}
            price={+item.amountTotal}
          />
        </Box>
      );
    });
  return (
    <>
      <Backdrop open={loading} />
      <ul>{Listehistoriques}</ul>
      {/* <Box>
        <Box className={classes.alignementScndConten}>
          <Typography variant="h6">Référence &nbsp;:&nbsp; </Typography>
          <Typography variant="h6">FTKFDF9</Typography>
        </Box>
        {/* <Typography className={classes.scndContentDate}>
          Le &nbsp;:&nbsp; &nbsp;
          {facture === undefined
            ? '--/--/----'
            : facture && facture[index - 1].date}
        </Typography> */}
      {/* <Typography> Le : 02/02/2020</Typography>
        <Box>
          <Box
            className={classes.alignementScndContent1}
            style={{ border: 'none', fontWeight: 900 }}>
            <Typography variant="h5" style={{ fontWeight: 900 }}>
              {' '}
              Destination
            </Typography>
            <Typography variant="h5" style={{ fontWeight: 900 }}>
              Frais de Livraisons
            </Typography>
          </Box>
          <Box className={classes.alignementScndContent1}>
            <Typography variant="h6"> Dest2</Typography>
            <Typography variant="h6">4 300</Typography>
          </Box>{' '}
          <Box className={classes.alignementScndContent1}>
            <Typography variant="h6"> Ambohimanarina</Typography>
            <Typography variant="h6"> --</Typography>
          </Box>
        </Box>
        <Box>
          <Box
            className={classes.alignementScndContent1}
            style={{ border: 'none', fontWeight: 900 }}>
            <Typography variant="h5" style={{ fontWeight: 900 }}>
              {' '}
              Destination
            </Typography>
            <Typography variant="h5" style={{ fontWeight: 900 }}>
              Frais de Livraisons
            </Typography>
          </Box>
          <Box className={classes.alignementScndContent1}>
            <Typography variant="h6">Dest1</Typography>
            <Typography variant="h6">3 600</Typography>
          </Box>{' '}
          <Box className={classes.alignementScndContent1}>
            <Typography variant="h6"> Ambohitrarahaba</Typography>
            <Typography variant="h6"> --</Typography>
          </Box>
        </Box>

        <Box className={classes.contentDistance}>
          <Box className={classes.alignementScndContent2}>
            <Box className={classes.alignementScndContent}>
              <Typography variant="h6" style={{ fontWeight: 900 }}>
                Frais d&apos;approche
              </Typography>
              <Typography variant="h6" style={{ fontWeight: 900 }}>
                xx Ar
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className={classes.alignementScndContent}>
          <Typography variant="h6" style={{ fontWeight: 900 }}>
            Total
          </Typography>
          <Typography variant="h6" style={{ fontWeight: 900 }}> */}
      {/* {facture === undefined
              ? 'XX XXX'
              : facture && facture[index - 1].price}{' '}
            Ar */}
      {/* </Typography>
        </Box>
      </Box> */}
    </>
  );
};
export default HistoriqueLivraison;

import React, { FC, useState } from 'react';
import { Typography, Box, Button } from '@material-ui/core';
import useStyles from './style';
import logo from '../../Images/logo.svg';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import TextInput from '../../Components/TextInput';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import { DO_FORGOT_PASSWORD } from '../../Graphql/User/mutation';
import SnackVariableInterface from '../../Interfaces/SnackVariableInterface';
import { displaySnackBar } from '../../Utils/snackBarUtils';
import { Link } from 'react-router-dom';

const InitPassword: FC<RouteComponentProps> = ({ history }) => {
  const classes = useStyles();
  const client = useApolloClient();
  const [email, setEmail] = useState<string>('');

  const [DO_FORGOT] = useMutation(DO_FORGOT_PASSWORD, {
    onCompleted: (data) => {
      const snackBarData: SnackVariableInterface = {
        type: 'INFO',
        message: 'Veuillez ouvrir votre boite e-mail.',
        isOpen: true,
      };
      displaySnackBar(client, snackBarData);
    },
    onError: ({ message }) => {
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: message.split(':')[1],
        isOpen: true,
      };
      displaySnackBar(client, snackBarData);
    },
  });
  const onClickCreerUnCompte = () => {
    history.push('/choiceaccount');
  };

  const onChangeEmail = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { value } = event.target;
    setEmail(value);
  };

  const onClickRéinitialiser = () => {
    if (email.trim().length === 0) {
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: 'Le champ email est vide.',
        isOpen: true,
      };
      displaySnackBar(client, snackBarData);
    } else {
      DO_FORGOT({ variables: { email } });
    }
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.left}>
        <Box className={classes.logo}>
          <img src={logo} alt="logo" />
        </Box>

        <Box className={classes.login}>
          <Typography variant="h1">Réinitialiser votre mot de passe</Typography>

          <TextInput
            variant="outlined"
            type="email"
            name="email"
            onChange={onChangeEmail}
            placeholder="Veuillez saisir votre adresse e-mail"
            fullWidth
          />

          <Button
            variant="contained"
            color="primary"
            onClick={onClickRéinitialiser}>
            Réinitialiser
          </Button>

          <Typography variant="subtitle2">
            Vous n&apos;avez pas de compte ?
          </Typography>

          <Button
            variant="outlined"
            color="primary"
            onClick={onClickCreerUnCompte}>
            Créer un compte
          </Button>
        </Box>
        <Box className={classes.terms}>
          <Typography>
            En vous connectant sur notre plateforme, <br />
            vous êtes d&apos;accord avec&nbsp;
            <Link style={{ color: 'inherit' }} to="/terms">
              les termes et les conditions
            </Link>
          </Typography>
        </Box>
      </Box>
      <Box className={classes.bg}>
        {/* <img src={GroupeDeMasque} alt="Mobix" /> */}
      </Box>
    </Box>
  );
};

export default withRouter(InitPassword);

import React, { useContext } from 'react';
import styles from './style';
import { Box, Typography, IconButton } from '@material-ui/core';
import Delete from '@material-ui/icons/DeleteForeverOutlined';
import { DestinationsContext } from '../../../Providers/Destinations';

const DestinationInfo = () => {
  const classes = styles();

  const { destinations, removeDestination } = useContext(DestinationsContext);
  const removeDestinationFromProvider = (id: string) => {
    removeDestination(id);
  };

  return (
    <Box>
      {destinations &&
        destinations.map((destination, index) => (
          <Box className={classes.container} key={index}>
            <Box className={classes.bordered}>
              <Typography>{destination.name}</Typography>
              <Typography>{destination.phone}</Typography>
              <Typography>{destination.address}</Typography>
            </Box>

            <IconButton
              size="small"
              onClick={() => removeDestinationFromProvider(destination.id)}>
              <Delete />
            </IconButton>
          </Box>
        ))}
    </Box>
  );
};

export default DestinationInfo;

import React, { FC, useContext, useState } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import useStyles from './style';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import BoxIcon from '../../Components/Icons/BoxIcon';
import Travel from '../../Components/Icons/Travel/Travel';
import Hotel from '../../Components/Icons/Hotel/Hotel';
import Commande from '../../Components/Icons/Commande/Commande';
import Tower from '../../Components/Icons/Tower/Tower';
import FlyFirstOne from '../../Components/Icons/FlyFirstOne/FlyFirstOne';
import FlyFirst from '../../Components/Icons/FlyFirst/FlyFirst';
import Time from '../../Components/Icons/Time/Time';
import Truck from '../../Components/Icons/Truck/Truck';
import Widget from '../../Components/Widget';
import { useGetNumberDelivery } from './numberDelivery';
import { useGetNumberRequest } from './numberRequest';
import { useQuery } from '@apollo/react-hooks';
import {
  NUM_REQUEST_BOOKING_FLIGHTS,
  NUM_REQUEST_BOOKING_FLIGHTSVariables,
} from '../../Graphql/Vol/types/NUM_REQUEST_BOOKING_FLIGHTS';
import { DO_NUM_REQUEST_BOOKING_FLIGHTS } from '../../Graphql/Vol/query';
import { UserContext } from '../../Providers/User';

const Accueil: FC<RouteComponentProps> = ({ history, location }) => {
  const classes = useStyles();
  const { idMe } = useContext(UserContext);
  const [nombreVol, setNombreVol] = useState<number>(0);

  const { numberOnWay, numberWaiting } = useGetNumberDelivery();
  const {
    numberRequestWaiting,
    numberRequestProcessed,
  } = useGetNumberRequest();
  const { data: volCount, loading } = useQuery<
    NUM_REQUEST_BOOKING_FLIGHTS,
    NUM_REQUEST_BOOKING_FLIGHTSVariables
  >(DO_NUM_REQUEST_BOOKING_FLIGHTS, {
    variables: { user_id: idMe },
    fetchPolicy: 'cache-and-network',
    onCompleted: (volCount) => {
      setNombreVol(
        volCount === undefined
          ? 0
          : volCount.num_RequestBookingFlights < 9
          ? 0 + volCount.num_RequestBookingFlights
          : volCount.num_RequestBookingFlights,
      );
    },
  });

  return (
    <Box className={classes.containerIndex}>
      <Widget
        title="Que souhaitez-vous faire ?"
        elevation={2}
        className={classes.paper}
        disableWidgetMenu>
        <Button
          onClick={() => history.push('/accueil/effectuer-livraison')}
          variant="contained"
          color="secondary"
          startIcon={<BoxIcon />}>
          Effectuer un transport
        </Button>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<Travel />}
          onClick={() => history.push('/accueil/Reservation-Vol/')}>
          Effectuer un vol
        </Button>
        <Button
          onClick={() => history.push('/accueil/Booking-Hotel')}
          variant="contained"
          color="secondary"
          startIcon={<Hotel />}>
          Réserver un hôtel
        </Button>
        <Button variant="contained" color="secondary" startIcon={<Commande />}>
          Faire une commande
        </Button>
      </Widget>
      <Widget
        title="Mes livraisons ?"
        elevation={2}
        className={classes.paper}
        disableWidgetMenu>
        <Button
          variant="contained"
          startIcon={<Truck />}
          size="large"
          onClick={() => {
            history.push('/delivery');
          }}>
          <Box>
            <span className={classes.count}>
              {numberOnWay.toString().padStart(2, '0')}
            </span>
            <span>En route</span>
          </Box>
        </Button>
        <Button
          variant="contained"
          startIcon={<Time />}
          size="large"
          onClick={() => {
            history.push('/delivery');
          }}>
          <Box>
            <span className={classes.count}>
              {numberWaiting.toString().padStart(2, '0')}
            </span>
            <span>En attente</span>
          </Box>
        </Button>
      </Widget>
      <Widget
        title="Mes réservations"
        className={classes.paper}
        elevation={2}
        disableWidgetMenu>
        <Box>
          <Typography color="textSecondary" variant="body2" gutterBottom>
            Réservations de vol
          </Typography>
          {/* <Button
            variant="contained"
            startIcon={<FlyFirst htmlColor="#4267B2" />}
            size="large"
            fullWidth>
            <Box>
              <span className={classes.count}>01</span>
              <span>Vol international</span>
            </Box>
          </Button> */}
          <Button
            variant="contained"
            startIcon={<FlyFirstOne stroke="#4267B2" />}
            size="large"
            onClick={() =>
              history.push('/accueil/Reservation-Vol/Vol-En-Attente/')
            }
            fullWidth>
            <Box
              onClick={() =>
                history.push('/accueil/Reservation-Vol/Vol-En-Attente/')
              }>
              <span className={classes.count}>
                {nombreVol < 9 ? '0' + nombreVol : nombreVol}
              </span>
              <span>Vol</span>
            </Box>
          </Button>
        </Box>
        <Box mt={2}>
          <Typography color="textSecondary" variant="body2" gutterBottom>
            Réservation d&apos;Hôtel
          </Typography>
          <Button
            variant="contained"
            startIcon={<Tower />}
            size="large"
            style={{
              backgroundColor: '#58C6DE',
            }}
            fullWidth
            onClick={() => history.push('/accueil/Reservation-hotel/')}>
            <Box>
              <span className={classes.count}>
                {(numberRequestWaiting + numberRequestProcessed)
                  .toString()
                  .padStart(2, '0')}
              </span>
              <span>Réservation</span>
            </Box>
          </Button>
        </Box>
      </Widget>
    </Box>
  );
};

export default withRouter(Accueil);

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  containerIndex: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    height: '100%',
    alignContent: 'flex-start',
    justifyContent: 'flex-start',
    '& button': {
      marginBottom: theme.spacing(2),
      fontWeight: 'normal',
      '& svg': {
        marginRight: theme.spacing(1),
        fontSize: '1.5rem !important',
      },
      '&>span': {
        display: 'flex',
        justifyContent: 'flex-start',
      },
    },
    '@media(max-width:940px)': {
      flexDirection: 'row',
      overflowY: 'auto',
    },
  },
  paper: {
    maxWidth: 400,
    minWidth: 380,
    margin: theme.spacing(0, 2, 4),
    height: 'auto !important',
    borderRadius: 10,
    '&>div': {
      padding: `${theme.spacing(2.5, 3, 2)} !important`,
    },
    '& .MuiButton-sizeLarge': {
      height: 80,
      padding: theme.spacing(1),
      borderRadius: 10,
      backgroundColor: '#C7D3E6',
      '& svg': {
        fontSize: '3rem !important',
      },
      '& div': {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: theme.spacing(2),
        textAlign: 'left',
      },
    },
    '@media(max-width:525px)': {
      width: 'calc(100% - 0.5rem)',
      minWidth: 'initial',
      maxWidth: 'initial',
      marginLeft: 0,
      marginRight: 0,
    },
  },
  count: {
    marginBottom: theme.spacing(1),
    fontSize: '1.875rem',
    fontWeight: 700,
    lineHeight: 0.75,
  },
}));

export default useStyles;

import React, { FC } from 'react';
import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import MenuItem from '../MenuItem';
import Home from '../../Icons/Home';
import Bill from '../../Icons/Bill';
import Delivery from '../../Icons/DeliveryIcon';
import AddressBook from '../../Icons/AddressBook';
import Booking from '../../Icons/Booking';
import Account from '../../Icons/Account';
import styles from './style';
import Mobix from '../../Icons/Mobix/Mobix';
import File from '../../Icons/File';
import classnames from 'classnames';
import Hotel from '../../Icons/Hotel/Hotel';
import Travel from './../../Icons/Travel/Travel';

const Menu: FC<RouteComponentProps & { open: boolean }> = (props) => {
  const { history, open } = props;
  const path: string = history.location.pathname;
  const classes = styles();
  const [openj, setOpenj] = React.useState(false);
  const handleClick = () => {
    setOpenj(!openj);
  };

  const [openHot, setOpenHot] = React.useState(false);
  const handleClickHotel = () => {
    setOpenHot(!openHot);
  };

  const [openVol, setOpenVol] = React.useState(false);
  const handleClickVol = () => {
    setOpenVol(!openVol);
  };
  return (
    <Box
      className={classnames(
        classes.menuContainer,
        open ? null : classes.collapsed,
      )}>
      {open && <Mobix className={classes.logo} htmlColor="#4267B2" />}
      <Box className={classes.listMenu}>
        <MenuItem
          icon={<Home />}
          title="Accueil"
          active={path.includes('accueil') || /\/accueil/.test(path)}
          to="/accueil"
        />
        <MenuItem
          icon={<Bill stroke="#4267B2" />}
          title="Historiques"
          active={path.includes('historiques')}
          to="/historiques"
        />
        <ListItem button onClick={handleClick} className={classes.menuAccueil}>
          <MenuItem
            icon={<Delivery stroke="#4267B2" />}
            title="Mes livraisons"
            active={path === '/delivery'}
            to="/delivery"
          />
          {/* <MenuItem
            title="Mes livraisons"
            active={path === '/delivery'}
            to="/delivery"
          /> */}
        </ListItem>
        {/* <Collapse in={openj} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem className={classes.listContent}>
              <MenuItem
                title="Effectuer une livraisons"
                active={path === '/accueil/effectuer-livraison'}
                to="/accueil/effectuer-livraison"
              />
            </ListItem>
          </List>
          <List component="div" disablePadding>
            <ListItem className={classes.listContent}>
              <MenuItem
                title="Mes livraisons"
                active={path === '/delivery'}
                to="/delivery"
              />
            </ListItem>
          </List>
        </Collapse> */}
        <MenuItem
          icon={<AddressBook />}
          title="Carnet d'adresses"
          active={path === '/carnet-adresse'}
          to="/carnet-adresse"
        />
        {/*
        <ListItem
          button
          onClick={handleClickHotel}
          className={classes.menuAccueil}>
          <MenuItem
            icon={<Hotel />}
            title="Hôtel"
            active={path === '/'}
            to="#"
          />
        </ListItem>
        */}

        <Collapse in={openHot} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem className={classes.listContent}>
              <MenuItem
                title="Réserver un Hôtel"
                active={path === '/accueil/Booking-Hotel'}
                to="/accueil/Booking-Hotel"
              />
            </ListItem>
            <ListItem className={classes.listContent}>
              <MenuItem
                // icon={<Hotel />}
                title="Mes réservations"
                active={path === '/accueil/Reservation-hotel/'}
                to="/accueil/Reservation-hotel/"
              />
            </ListItem>
          </List>
        </Collapse>

        <ListItem
          button
          onClick={handleClickVol}
          className={classes.menuAccueil}>
          <MenuItem
            icon={<Travel />}
            title="Vol"
            active={path === '/reservation'}
            to="#"
          />
        </ListItem>
        {open ? (
          <Collapse in={openVol} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem className={classes.listContent}>
                <MenuItem
                  title="Réserver un Vol"
                  active={path === '/accueil/Reservation-Vol'}
                  to="/accueil/Reservation-Vol"
                />
                <MenuItem
                  title="Mes réservations"
                  active={path === '/accueil/Reservation-Vol/Vol-En-Attente'}
                  to="/accueil/Reservation-Vol/Vol-En-Attente"
                />
              </ListItem>
            </List>
          </Collapse>
        ) : (
          <List component="div" disablePadding>
            <ListItem className={classes.listContent}>
              <MenuItem
                title="Réserver un Vol"
                active={path === '/accueil/Reservation-Vol'}
                to="/accueil/Reservation-Vol"
              />
              <MenuItem
                title="Mes réservations"
                active={path === '/accueil/Reservation-Vol/Vol-En-Attente'}
                to="/accueil/Reservation-Vol/Vol-En-Attente"
              />
            </ListItem>
          </List>
        )}

        <MenuItem
          icon={<Account stroke="#4267B2" />}
          title="Mon compte"
          active={path === '/mon-compte'}
          to="/mon-compte"
        />
        <div>
          <Typography variant="body2">
            Besoin d&apos;aide ? <br /> Contactez-nous au 034 21 182 82
          </Typography>
          <MenuItem
            icon={<File />}
            title="Mentions légales"
            active={path === '/terms'}
            to="/terms"
          />
        </div>
      </Box>
    </Box>
  );
};

export default withRouter(Menu);

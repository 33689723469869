import gql from 'graphql-tag';

export const DO_LOGIN = gql`
  mutation LOGIN($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      accessToken
      user {
        id
        customerId
      }
    }
  }
`;

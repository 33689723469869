import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function DeliveryNow(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24.504 20.158">
      <g
        id="Groupe_859"
        data-name="Groupe 859"
        transform="translate(-120.174 -137.457)">
        <g
          id="Groupe_859-2"
          data-name="Groupe 859"
          transform="translate(125.295 137.457)">
          <path
            id="Tracé_2074"
            data-name="Tracé 2074"
            d="M196.388,157.615a10.1,10.1,0,0,1-9.249-6.067.652.652,0,0,1,.378-.878h0a.648.648,0,0,1,.811.349,8.78,8.78,0,1,0,1.277-9.055.653.653,0,0,1-.883.121h0a.651.651,0,0,1-.125-.944,10.079,10.079,0,1,1,7.791,16.473Z"
            transform="translate(-187.084 -137.457)"
          />
        </g>
        <rect
          id="Rectangle_68"
          data-name="Rectangle 68"
          width="9.463"
          height="1.318"
          rx="0.659"
          transform="translate(120.174 142.859)"
        />
        <rect
          id="Rectangle_69"
          data-name="Rectangle 69"
          width="8.465"
          height="1.318"
          rx="0.659"
          transform="translate(121.172 145.774)"
        />
        <rect
          id="Rectangle_70"
          data-name="Rectangle 70"
          width="6.908"
          height="1.318"
          rx="0.659"
          transform="translate(122.729 148.689)"
        />
        <g
          id="Groupe_860"
          data-name="Groupe 860"
          transform="translate(132.139 144.248)">
          <path
            id="Tracé_2075"
            data-name="Tracé 2075"
            d="M278.5,231.754l-1.8-1.8a.65.65,0,0,1,0-.92h0a.65.65,0,0,1,.92,0l.913.912a.65.65,0,0,0,.9.016l3.876-3.6a.65.65,0,0,1,.919.034h0a.65.65,0,0,1-.034.919l-4.794,4.449A.65.65,0,0,1,278.5,231.754Z"
            transform="translate(-276.515 -226.194)"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

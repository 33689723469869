import { makeStyles } from '@material-ui/core/styles';
import { Rowing } from '@material-ui/icons'

export default makeStyles((theme) =>({
  resume: {
    height: '100%',
    maxHeight: '100%',
    overflowY: 'auto',
    color: theme.palette.text.secondary,
    '& p': {
      fontSize: '0.875rem',
    },
  },
  rating: {
    padding: theme.spacing(2),
    fontSize: '3rem',
    '& label': {
      marginLeft: theme.spacing(0.75),
      marginRight: theme.spacing(0.75),
    },
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: theme.spacing(0.5),
    color: theme.palette.grey[500],
  },
}));


import gql from 'graphql-tag';

export const VOL_INFO_FRAGEMENT = gql`
  fragment VolInfo on RequestBookingFlight {
    id
    reference
    customerId
    typeBooking
    cityDeparture
    cityArrival
    comment
    createdAt
    updatedAt
    dateFirst
    dateLast
    dateRequestBooking
    manifest
    numberofPassenger
    status
    withCertificat
    typeFlight
    responseBookingFlights {
      id
      nameOfCompagny
      dateFirstOneWay
      dateLastOneWay
      dateFirstReturnWay
      dateLastReturnWay
      comment
      rateBookingFlight
    }
  }
`;

import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function Truck(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 55.563 38.521">
      <g
        id="Groupe_877"
        data-name="Groupe 877"
        transform="translate(34.961 24.513)">
        <g id="Groupe_876" data-name="Groupe 876">
          <path
            id="Tracé_2087"
            data-name="Tracé 2087"
            d="M329.155,304.4a7,7,0,1,0,7,7A7.012,7.012,0,0,0,329.155,304.4Zm0,10.506a3.5,3.5,0,1,1,3.5-3.5A3.505,3.505,0,0,1,329.155,314.909Z"
            transform="translate(-322.151 -304.403)"
          />
        </g>
      </g>
      <g
        id="Groupe_879"
        data-name="Groupe 879"
        transform="translate(11.031 24.513)">
        <g id="Groupe_878" data-name="Groupe 878">
          <path
            id="Tracé_2088"
            data-name="Tracé 2088"
            d="M108.651,304.4a7,7,0,1,0,7,7A7.012,7.012,0,0,0,108.651,304.4Zm0,10.506a3.5,3.5,0,1,1,3.5-3.5A3.505,3.505,0,0,1,108.651,314.909Z"
            transform="translate(-101.647 -304.403)"
          />
        </g>
      </g>
      <g
        id="Groupe_881"
        data-name="Groupe 881"
        transform="translate(35.895 3.502)">
        <g id="Groupe_880" data-name="Groupe 880">
          <path
            id="Tracé_2089"
            data-name="Tracé 2089"
            d="M341.542,111.754a1.752,1.752,0,0,0-1.565-.964h-9.222v3.5H338.9l4.768,9.483,3.13-1.574Z"
            transform="translate(-330.756 -110.79)"
          />
        </g>
      </g>
      <g
        id="Groupe_883"
        data-name="Groupe 883"
        transform="translate(23.463 29.824)">
        <g id="Groupe_882" data-name="Groupe 882">
          <rect
            id="Rectangle_92"
            data-name="Rectangle 92"
            width="13.249"
            height="3.502"
          />
        </g>
      </g>
      <g
        id="Groupe_885"
        data-name="Groupe 885"
        transform="translate(4.961 29.824)">
        <g id="Groupe_884" data-name="Groupe 884">
          <path
            id="Tracé_2090"
            data-name="Tracé 2090"
            d="M53.536,353.345h-6.07a1.751,1.751,0,0,0,0,3.5h6.07a1.751,1.751,0,1,0,0-3.5Z"
            transform="translate(-45.715 -353.345)"
          />
        </g>
      </g>
      <g id="Groupe_887" data-name="Groupe 887" transform="translate(4.961)">
        <g id="Groupe_886" data-name="Groupe 886">
          <path
            id="Tracé_2091"
            data-name="Tracé 2091"
            d="M95.95,97.641l-3.444-4.436a1.749,1.749,0,0,0-1.383-.677H78.4V80.272a1.751,1.751,0,0,0-1.751-1.751H47.466a1.751,1.751,0,1,0,0,3.5H74.9V94.279a1.751,1.751,0,0,0,1.751,1.751H90.265l2.55,3.285v9.03H87.971a1.751,1.751,0,0,0,0,3.5h6.6a1.751,1.751,0,0,0,1.751-1.751V98.715A1.753,1.753,0,0,0,95.95,97.641Z"
            transform="translate(-45.715 -78.521)"
          />
        </g>
      </g>
      <g
        id="Groupe_889"
        data-name="Groupe 889"
        transform="translate(2.86 20.953)">
        <g id="Groupe_888" data-name="Groupe 888">
          <path
            id="Tracé_2092"
            data-name="Tracé 2092"
            d="M36.158,271.6H28.1a1.751,1.751,0,1,0,0,3.5h8.054a1.751,1.751,0,0,0,0-3.5Z"
            transform="translate(-26.353 -271.597)"
          />
        </g>
      </g>
      <g id="Groupe_891" data-name="Groupe 891" transform="translate(0 14.066)">
        <g id="Groupe_890" data-name="Groupe 890">
          <path
            id="Tracé_2093"
            data-name="Tracé 2093"
            d="M16.692,208.134H1.751a1.751,1.751,0,0,0,0,3.5H16.692a1.751,1.751,0,0,0,0-3.5Z"
            transform="translate(0 -208.134)"
          />
        </g>
      </g>
      <g
        id="Groupe_893"
        data-name="Groupe 893"
        transform="translate(2.86 7.179)">
        <g id="Groupe_892" data-name="Groupe 892">
          <path
            id="Tracé_2094"
            data-name="Tracé 2094"
            d="M43.045,144.672H28.1a1.751,1.751,0,0,0,0,3.5H43.045a1.751,1.751,0,0,0,0-3.5Z"
            transform="translate(-26.353 -144.672)"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

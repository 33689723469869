import { makeStyles } from '@material-ui/core/styles';
import 'react-image-gallery/styles/css/image-gallery.css';
const useStyles = makeStyles({
  allContent: {
    width: '80%',
    margin: 'auto',
    display: 'flex',
    height: '100%',
    overflow: 'auto',
    backgroundColor: '#fff',
    padding: '0px 0px 0 0px',
    webkitBoxShadow: '0px 3px 6px 0px rgba(0,0,0,0.27)',
    mozBoxShadow: '0px 3px 6px 0px rgba(0,0,0,0.27)',
    boxShadow: '0px 3px 6px 0px rgba(0,0,0,0.27)',
    '@media (max-width : 1365px )': {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      overflow: 'auto',
      minHeight: '820px',
    },
  },
  firstContentReservation: {
    width: '60%',
    // height:'100%',
    padding: 27,
    minHeight: '726px',
    webkitBoxShadow: '0px 3px 6px 0px rgba(0,0,0,0.27)',
    mozBoxShadow: '0px 3px 6px 0px rgba(0,0,0,0.27)',
    boxShadow: '0px 3px 6px 0px rgba(0,0,0,0.27)',
    '@media (max-width : 1365px )': {
      width: '100%',
    },
  },

  secondContentReservation: {
    display: 'flex',
    flexDirection: 'column',
    color: 'unset',
    padding: '20px',
    // marginLeft: '50px',
    '@media (max-width: 1365px)': {
      alignItems: 'center',
    },
    '@media (min-width: 1366px)': {
      width: '40%',
    },
    '& h6': {
      width: '62%',
      '@media (max-width: 1365px)': {
        width: '100%',
        textAlign: 'center',
      },
    },
    '&  img': {
      display: 'block',
    },
    '& .MuiTypography-colorTextSecondary': {
      display: 'flex',
      fontSize: 14,
      paddingTop: '5px',
      color: '#6B6B6B',
    },
  },
  textBarrerRsrv: {
    textDecoration: 'unset',
    fontSize: '12px',
    fontWeight: 'normal',
    paddingRight: '20px',
    color: '#6B6B6B',
  },
  promoContnt: {
    width: '96%',
    height: '380px',
    paddingLeft: '2%',
    overflow: 'auto',
    padding: '2%',
  },
  positionnementBouton: {
    position: 'relative',
    marginBottom: '10px',
    // webkitBoxShadow: '0px 3px 6px 2px rgba(0,0,0,0.26)',
    // mozBoxShadow: '0px 3px 6px 2px rgba(0,0,0,0.26)',
    // borderBottom:'1px solid #E6E6E6',
    borderRadius: 'unset',
    borderTop: '1px solid #E6E6E6',
    boxShadow: 'none',
    '@media (min-width : 1270px) and (max-width : 1365px)': {
      width: '60%',
      margin: 'auto',
      marginBottom: '20px',
    },
    '@media (max-width : 1270px)': {
      width: 500,
      marginBottom: '20px',
      margin: 'auto',
    },
  },
  bookingButton: {
    color: '#fff',
    right: '5px',
    width: '27%',
    bottom: '5px',
    padding: '0 !important',
    height: '25px',
    fontSize: '12px',
    borderRadius: 2,
    fontWeight: 500,
    display: 'flex',
    position: 'absolute',
    backgroundColor: '#E9685E',
  },
  contentChambrePromo: {
    display: 'flex',
    justifyContent: 'center',
    paddingRight: 10,
    borderTop: '1px solid #E6E6E6',
    boxShadow: 'unset',
    borderBottom: '1px solid #E6E6E6',
    '& div:nth-of-type(n+1)': {
      display: 'flex',
      paddingLeft: '0px',
      width: '100%',
    },
    '& Button': {
      backgroundColor: '#E9685E',
      color: '#fff',
      borderRadius: '5px',
      alignSelf: 'flex-end',
      fontWeight: 'normal',
      marginBottom: '10px',
    },
  },
  titreOffres: {
    display: 'none !important',
    textDecoration: 'none',
    fontSize: 16,
  },
  bookingFirstContent: {
    display: 'flex',
    width: '60%',
    padding: 20,
  },
  titre: {
    display: 'none',
  },
  AdresseHotel: {},
  textBarrer: {
    display: 'none',
    textDecoration: 'none',
  },
  price: {},
  frstScdBtnReservation: {
    backgroundColor: '#4267B2',
    borderRadius: '5px',
    color: '#fff',
    textTransform: 'inherit',
    margin: '20px 0 20px 0',
    width: '62%',
    fontSize: 14,
    fontWeight: 'normal',
    padding: '0 !important',
    '@media (max-width: 1365px)': {
      width: '30%',
    },
    '@media (max-width: 1200px)': {
      width: '40%',
    },
    '& Button': {
      transform: 'none',
      BackgroundColor: 'none',
      '& :hover': { display: 'none' },
    },
    '& span': {
      lineHeight: '40px',
      transition: 'none',
      borderRadius: '5px',
    },
    '& .imageBooking': {
      display: 'none',
    },
    '& :focus': {
      backgroundColor: 'none',
    },
    '& :hover': {
      backgroundColor: '#538CFF',
    },
  },
  titleRoom: {
    width: '100% !important',
    fontSize: 16,
    fontWeight: 'bold',
    color: '#4267B2',
    paddingBottom: '20px',
  },
  contentTitleHotel: {
    borderBottom: '1px solid #E6E6E6',
    paddingBottom: '20px',
    '& h5': {
      fontSize: '16px',
      fontWeight: 'bold',
      color: '#6B6B6B',
    },
  },
  descriptionHotel: {
    paddingTop: '20px',
    paddingBottom: '20px',
    fontSize: '12px',
    color: '#6B6B6B',
    height: '250px',
    overflow: 'auto',
    whiteSpace: 'break-spaces',
  },
  ImageGalleryRightNav: {
    display: 'none',
  },
});
export default useStyles;

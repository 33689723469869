import { makeStyles } from '@material-ui/core/styles';
import { Rowing } from '@material-ui/icons';

const useStyles = makeStyles({
  contentCard: {
 width:'35%',
  minHeight:'720px',
  },
  allContent: {
    fontSize:12,
    width:'100%',
    display:'flex',
    flexDirection:'column',
  },
  contentInformation: {
    width:'100%',
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'space-between',
  },
  contentReference: {
    display:'flex',
    flexDirection:'column',
    padding:10,    
    marginBottom:'30px',
    justifyContent:'space-between',
    '&:nth-child(1) ':{
    },
    '& div':{
      display:'flex',
      padding:'20px 0',
      flexDirection:'row',
      borderBottom:'1px solid #C7D3E6',
      justifyContent:'space-between',
      '& :nth-child(2)':{
        fontWeight:900,
      },
    },
  },
  contentTitle: {
    display:'flex',
    justifyContent:'center',
    fontSize:15,
    fontWeight:'bolder',
    paddingBottom:20,
    borderBottom:'1px solid gray',
  },
  AllcontentButton:{
    display:'flex',
    flexDirection:'column',
    width:'100%',
    justifyContent:'center',
    alignItems:'center',
    backgroundColor:'#F5F5F5',
    paddingTop:20,
    paddingBottom:'20px',
    '& p:first-child':{
      marginBottom:20,
    },
  },
  contentButton: {
    display:'flex',
    flexDirection:'column',
    width:'100%',
    justifyContent:'center',
    alignItems:'center',
    '& Button:first-child': {
      backgroundColor:'#337ab7',
      marginBottom:'20px',
      width:'80%',
      margin:'auto',
      borderRadius:'2px',
      color:'#fff'
    },
    '& Button:nth-child(2)': {
      width:'80%',
      margin:'auto',
      marginBottom:'20px',
      borderRadius:'2px',
      color:'#fff',
      backgroundColor:'#50a050',
    },
   
  },
  textPayement:{
    width:'80%',
    margin:'auto',
    textAlign:'center',
  },
});

export default useStyles;

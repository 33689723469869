const ACCESS_TOKEN_KEY = 'token';

export const setAccessToken = (token: string) =>
  localStorage.setItem(ACCESS_TOKEN_KEY, token);

export const getAccessToken = (): string | null =>
  localStorage.getItem(ACCESS_TOKEN_KEY);

export const isAuthenticated = (): boolean =>
  !!localStorage.getItem(ACCESS_TOKEN_KEY);

export const clearLocalStorage = () => localStorage.clear();

export const separatorOfMillier = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const numberArroud = (value: number) => {
  if (value % 100 === 0) {
    return value;
  } else {
    return Math.ceil((value + 1) / 100) * 100;
  }
};

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import ErrorIcon from '@material-ui/icons/ErrorOutlineRounded';
import InfoIcon from '@material-ui/icons/InfoRounded';
import CloseIcon from '@material-ui/icons/CloseRounded';
import { amber, green, red, blue } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { GET_SNACKBAR_STATE } from '../../Graphql/snackbar/query';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles = makeStyles((theme: Theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: red[600],
  },
  info: {
    backgroundColor: blue[600],
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export interface Props {
  className?: string;
  message?: string;
  onClose?: () => void;
  variant: keyof typeof variantIcon;
}

function MySnackbarContentWrapper(props: Props) {
  const classes = useStyles();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classnames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classnames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={onClose}
          size="small">
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

MySnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

const GlobalSnackBar = () => {
  const client = useApolloClient();

  const closeSnack = () => {
    client.writeData({
      data: {
        snackBar: {
          isOpen: false,
          __typename: 'SnackBar',
        },
      },
    });
  };

  const { data } = useQuery(GET_SNACKBAR_STATE, { fetchPolicy: 'cache-only' });

  const { isOpen, message, type } = 
    data && data.snackBar
      ? data.snackBar
      : { isOpen: false, message: '', type: 'INFO' };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isOpen}
      autoHideDuration={3000}
      onClose={closeSnack}>
      <MySnackbarContentWrapper
        onClose={closeSnack}
        variant={type.toLowerCase()}
        message={message}
      />
    </Snackbar>
  );
};

export default GlobalSnackBar;

import gql from 'graphql-tag';

export const DO_CREATE_ADRESS_BOOK = gql`
  mutation CREATE_ADRESS_BOOK(
    $name: String!
    $address: String!
    $phone: String!
    $customer: String!
    $latitude: String!
    $longitude: String!
  ) {
    createAddressBook(
      name: $name
      address: $address
      phone: $phone
      customer: $customer
      latitude: $latitude
      longitude: $longitude
    ) {
      id
      message
    }
  }
`;

export const DO_CREATE_PICK_UP = gql`
  mutation CREATE_PICK_UP(
    $name: String!
    $phone: String!
    $address: String!
    $addressBookId: String!
    $latitude: String!
    $longitude: String!
  ) {
    createPickup(
      name: $name
      phone: $phone
      address: $address
      addressBookId: $addressBookId
      latitude: $latitude
      longitude: $longitude
    ) {
      id
      message
    }
  }
`;

export const DO_CREATE_DROP_OFF = gql`
  mutation CREATE_DROP_OFF(
    $name: String!
    $address: String!
    $phone: String!
    $addressBookId: String!
    $longitude: String!
    $latitude: String!
  ) {
    createDropoff(
      name: $name
      address: $address
      addressBookId: $addressBookId
      phone: $phone
      longitude: $longitude
      latitude: $latitude
    ) {
      id
      message
    }
  }
`;

export const DO_SHORT_DELIVERY = gql`
  mutation SHORT_DELIVERY(
    $delivery: String!
    $manifest: JSON
    $manifestItem: JSON
    $distance: Float!
    $estimation: String!
    $pickup: String!
    $dropoff: String!
  ) {
    createShortDelivery(
      delivery: $delivery
      manifest: $manifest
      manifestItem: $manifestItem
      distance: $distance
      estimation: $estimation
      pickup: $pickup
      dropoff: $dropoff
    ) {
      message
      shortdelivery {
        id
        status
      }
    }
  }
`;

export const DO_CREATE_DELIVERY = gql`
  mutation CREATE_DELIVERY(
    $courier: String
    $customer: String!
    $distance: Float!
    $pickup: String!
    $dropoff: String!
    $vehiculeType: String!
    $deliveryDate: Date
    $manifest: JSON!
    $manifestItem: JSON!
    $deliveryType: String!
    $pathImageDelivery: String
  ) {
    createDelivery(
      courier: $courier
      customer: $customer
      manifest: $manifest
      manifestItem: $manifestItem
      distance: $distance
      pickup: $pickup
      dropoff: $dropoff
      vehiculeType: $vehiculeType
      deliveryDate: $deliveryDate
      deliveryType: $deliveryType
      pathImageDelivery: $pathImageDelivery
    ) {
      message
      delivery {
        id
        status
        reference
      }
    }
  }
`;

import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  reference: {
    position: 'relative',
  },
  flex: {
    display: 'flex',
    '& button': {
      padding: `${theme.spacing(2)}px !important`,
      fontSize: '0.875rem',
      fontWeight: 400,
      borderRadius: 6,
    },
  },
  resume: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(1),
    backgroundColor: '#ECF1F9',
    borderTop: '1px solid #E6E6E6',
    borderBottom: '1px solid #E6E6E6',
    cursor: 'pointer',
  },
  drawer: ({ open }: any) => ({
    maxWidth: 400,
    position: 'absolute',
    left: open ? '35%' : 0,
    padding: theme.spacing(2),
    zIndex: open ? 2 : -1,
  }),
  toggleBtn: ({ positionButton, open }: any) => ({
    position: 'absolute',
    top: 10,
    left: open ? `calc(${positionButton} + 35%)` : '35%',
    minWidth: 22,
    width: 22,
    height: 50,
    padding: `${theme.spacing(1)}px !important`,
    color: theme.palette.text.secondary,
    boxShadow: '2px 0 5px rgba(0,0,0,.25)',
    background: theme.palette.background.paper,
    borderRadius: 0,
    zIndex: 2,
    '&:hover': {
      background: theme.palette.background.paper,
    },
  }),
}));

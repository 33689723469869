import { makeStyles } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { BorderBottom } from '@material-ui/icons';

export default makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    position: 'fixed',
    top: 77,
    right: 2,
    maxHeight: '700px',
    overflow: 'auto',
    zIndex: 10,
    '& Button': {
      width: '100%',
      backgroundColor: '#e6e6e6',
      borderRadius: 2,
      marginBottom: '10px',
    },
  },
  Notif: {
    // position: 'fixed',
    fontSize: '12px',
    width: '100%',
    paddingTop: '20px',
    zIndex: 20,
    paddingBottom: '20px',
    padding: '3px 10px 10px 10px',
  },
  toolbar: {
    padding: theme.spacing(2, 3),
    position: 'relative',
    minHeight: '100%',
  },
  toggleBtn: {
    position: 'absolute',
    zIndex: 10,
    left: 0,
    top: 0,
    minWidth: 22,
    width: 22,
    height: 40,
    padding: `${theme.spacing(1)}px !important`,
    color: theme.palette.text.secondary,
    transform: 'translateY(50%)',
    background: theme.palette.background.paper,
    boxShadow: '2px 0 6px rgba(0,0,0,.1)',
    borderRadius: 0,
    '&:hover': {
      background: theme.palette.background.paper,
      boxShadow: '2px 0 10px rgba(0,0,0,.1)',
    },
  },
  logoAppBar: {
    width: 125,
    fontSize: '3rem',
    marginLeft: theme.spacing(2),
  },
  toolbarActions: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  notification: {
    marginRight: theme.spacing(5),
    '& svg': {
      fontSize: '2rem',
    },
  },
  userInfo: {},
  userName: {
    color: '#A7A7A7',
    fontSize: '.875rem',
  },
  toolbarBtn: {
    marginLeft: theme.spacing(2),
    color: theme.palette.background.paper,
    backgroundColor: theme.palette.primary.main,
  },
  NotifModif: {
    '& div:first-child': { display: 'none' },
  },
}));

import React, { useState } from 'react';
import { createContext, FC } from 'react';

interface PayementVanillaInputProps {
  adresseip: string;
  idpanier: string;
  montant: number;
  nom: string;
  email: string;
  reference: string;
  setAdresseip: (adresseip: string) => void;
  setIdPanier: (idpanier: string) => void;
  setMontant: (montant: number) => void;
  setNom: (nom: string) => void;
  setEmail: (email: string) => void;
  setReference: (reference: string) => void;
}

export const PayementVanillaContext = createContext<PayementVanillaInputProps>({
  adresseip: '',
  idpanier: '',
  montant: 0,
  nom: '',
  email: '',
  reference: '',
  setAdresseip: (adresseip: string) => {},
  setIdPanier: (idpanier: string) => {},
  setMontant: (montant: number) => {},
  setNom: (nom: string) => {},
  setEmail: (email: string) => {},
  setReference: (reference: string) => {},
});

export const PayementVanillaProvider: FC = ({ children }) => {
  const [adresseip, setAdresseip] = useState('');
  const [idpanier, setIdPanier] = useState('');
  const [montant, setMontant] = useState(0);
  const [nom, setNom] = useState('');
  const [email, setEmail] = useState('');
  const [reference, setReference] = useState('');
  return (
    <PayementVanillaContext.Provider
      value={{
        adresseip,
        idpanier,
        montant,
        nom,
        email,
        reference,
        setAdresseip,
        setIdPanier,
        setMontant,
        setEmail,
        setNom,
        setReference,
      }}>
      {children}
    </PayementVanillaContext.Provider>
  );
};

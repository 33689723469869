import React, { useCallback, useState } from 'react';
import { createContext, FC } from 'react';

interface IPoint {
  latitude: number;
  longitude: number;
}

interface PointProps {
  points: IPoint[];
  addPoints: (points: IPoint[]) => void;
  clearPoints: () => void;
}

export const PointContext = createContext<PointProps>({
  points: [],
  addPoints: () => {},
  clearPoints: () => {},
});

export const PointProvider: FC = ({ children }) => {
  const [points, setPoints] = useState<IPoint[]>([]);

  const addPoints = useCallback((points: IPoint[]) => {
    setPoints(points);
  }, []);

  const clearPoints = useCallback(() => {
    setPoints([]);
  }, []);

  return (
    <PointContext.Provider
      value={{
        points,
        addPoints,
        clearPoints,
      }}>
      {children}
    </PointContext.Provider>
  );
};

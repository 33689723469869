import { createMuiTheme } from '@material-ui/core';

const defaultTheme = createMuiTheme();

const theme = createMuiTheme({
  palette: {
    primary: { main: '#4267B2' },
    secondary: { main: '#FFEC51' },
    text: {
      primary: '#212121',
    },
  },
  typography: {
    fontFamily: 'Poppins',
    fontSize: 16,
    h1: {
      fontSize: '1.5rem',
      fontWeight: 700,
      marginBottom: '1.25rem',
    },
    h2: {
      fontSize: '1.25rem',
      fontWeight: 700,
      marginBottom: '1rem',
    },
    h3: {
      fontSize: '1.125rem',
      fontWeight: 700,
      marginBottom: '1rem',
    },
    subtitle1: {
      fontSize: '.875rem',
    },
    subtitle2: {
      fontSize: '.75rem',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        display: 'flex',
        alignItems: 'center',
        height: 40,
        padding: `${defaultTheme.spacing(1, 5)} !important`,
        textTransform: 'initial',
        fontWeight: 700,
        borderRadius: 20,
      },
      sizeLarge: {
        height: 55,
      },
      contained: {
        boxShadow: 'none',
        backgroundColor: '#CAD1DE',
      },
      outlined: {
        boxShadow: 'none',
      },
      containedPrimary: {
        color: '#000',
        backgroundColor: '#FFEC51',
        '&:hover': {
          color: '#FFF',
          backgroundColor: '#FFA117',
        },
      },
      containedSecondary: {
        color: '#FFF',
        backgroundColor: '#4267B2',
        '&:hover': {
          color: '#FFF',
          backgroundColor: '#4267B2',
        },
      },
      outlinedPrimary: {
        color: '#FFF',
        borderColor: '#FFF',
        backgroundColor: 'transparent',
        '&:hover': {
          color: '#FFEC51',
          borderColor: '#FFEC51',
        },
      },
    },
    MuiButtonGroup: {
      grouped: {
        flexDirection: 'column',
        width: 60,
        height: 60,
        fontSize: '0.75rem',
        '& .MuiButton-label': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        },
        '& .MuiButton-startIcon': {
          margin: '0 0 0.5rem',
        },
      },
      groupedOutlinedHorizontal: {
        border: '1px solid #E6E6E6 !important',
      },
    },
    MuiFab: {
      root: {
        fontSize: '.875rem',
        textTransform: 'capitalize',
        boxShadow: '0 2px 5px rgba(0,0,0,.25)',
      },
      extended: {
        '&$sizeSmall': {
          padding: defaultTheme.spacing(0, 2),
        },
      },
      sizeSmall: {
        padding: defaultTheme.spacing(0, 1.5),
      },
    },
    MuiTextField: {
      root: {
        marginBottom: defaultTheme.spacing(2),
      },
    },
    MuiOutlinedInput: {
      root: {
        height: 45,
        borderRadius: 10,
        backgroundColor: '#FFF',
      },
      notchedOutline: {
        border: 0,
      },
      multiline: {
        height: 'auto !important',
        '& .MuiOutlinedInput-notchedOutline': {
          border: '1px solid #E6E6E6',
        },
      },
    },
    MuiFilledInput: {
      root: {
        borderBottom: '1px solid #E6E6E6',
        backgroundColor: '#FFF',
        '&:hover, &$focused': {
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
        },
      },
      input: {
        padding: defaultTheme.spacing(2.5, 1.5, 1.25),
      },
      underline: {
        '&::before': {
          content: `none`,
        },
      },
    },
    MuiInputBase: {
      root: {
        color: '#6B6B6B',
        fontSize: '.875rem',
      },
    },
    MuiFormControlLabel: {
      label: {
        marginTop: -3,
      },
    },
    MuiRadio: {
      colorPrimary: {
        color: '#FFF',
        '&$checked': {
          color: '#FFF',
        },
      },
    },
    MuiPaper: {
      elevation2: {
        boxShadow: '0 2px 5px rgba(0,0,0,.25)',
      },
    },
  },
});

export default theme;

import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { DO_REJECTING_REQUEST_BOOKING_FLIGHT } from '../../../Graphql/Vol/mutation';
import {
  REJECTING_REQUEST_BOOKING_FLIGHT,
  REJECTING_REQUEST_BOOKING_FLIGHTVariables,
} from '../../../Graphql/Vol/types/REJECTING_REQUEST_BOOKING_FLIGHT';
import SnackVariableInterface from '../../../Interfaces/SnackVariableInterface';
import { displaySnackBar } from '../../../Utils/snackBarUtils';

export const useRejectFlight = () => {
  const client = useApolloClient();
  const history = useHistory();
  const [doRejectFlight, { loading: loadingRejectFlight }] = useMutation<
    REJECTING_REQUEST_BOOKING_FLIGHT,
    REJECTING_REQUEST_BOOKING_FLIGHTVariables
  >(DO_REJECTING_REQUEST_BOOKING_FLIGHT, {
    onError: (err) => {
      if (err.networkError?.message === 'Failed to fetch') {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Problème de connexion...',
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      }
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: err.message.split(':')[1],
        isOpen: true,
      };
      return displaySnackBar(client, snackBarData);
    },
    onCompleted: async () => {
      const snackBarData: SnackVariableInterface = {
        type: 'SUCCESS',
        message: 'Vol rejeté',
        isOpen: true,
      };
      await displaySnackBar(client, snackBarData);
      console.log('snack');
    },
  });

  return { doRejectFlight, loadingRejectFlight };
};

import React, { FC } from 'react';

const Terms: FC = () => {
  return (
    <div
      style={{ padding: 50 }}
      dangerouslySetInnerHTML={{
        __html: `
        <p style="margin-bottom: 0in">
<font size="3" style="font-size: 13pt"><b>ACCEPTATION ET MODIFICATION
DES CGU </b></font>: 
</p>
<p style="margin-bottom: 0in"><br/>

</p>
<p style="margin-bottom: 0in">L'utilisation de l'application est
soumise à l'acceptation des CGU. Au moment de la création de leur
Compte, les utilisateurs doivent cliquer sur « J'accepte les
Conditions Générales d'Utilisation ».</p>
<p style="margin-bottom: 0in">Seule l'acceptation des CGU permet aux
utilisateurs d'accéder aux Services proposés par l'Application et
de créer un Compte. Les CGU doivent être acceptées dans leur
intégralité et sans modification sauf convention écrite contraire
signée par la Société. En cas de manquement par un utilisateur à
l'une quelconque des obligations énoncées aux présentes, la
Société se réserve le droit de suspendre ou de résilier le Compte
des utilisateurs ou l'accès aux Services, sans que le Membre ne
puisse prétendre à aucun remboursement, avoir ou compensation.</p>
<p style="margin-bottom: 0in">Les CGU comportent des clauses
relatives à la Livraison des Biens, qui sont expressément acceptées
par l'Utilisateur par l'émission d'une Demande de Livraison.</p>
<p style="margin-bottom: 0in">Aucune condition particulière ou
autres conditions générales émises par l'Utilisateur et/ou le
Courrier Indépendant ne prévaudra sur les CGU sauf accord écrit
contraire signé par la Société.</p>
<p style="margin-bottom: 0in"><br/>

</p>
<p style="margin-bottom: 0in"><font size="3" style="font-size: 13pt"><b>CRÉATION
D’UN COMPTE :</b></font> 
</p>
<p style="margin-bottom: 0in"><br/>

</p>
<p style="margin-bottom: 0in"><font size="3" style="font-size: 12pt"><b>Informations
générales</b></font> 
</p>
<p style="margin-bottom: 0in"><br/>

</p>
<p style="margin-bottom: 0in">Afin de pouvoir accéder aux Services
de MOBIX, l’utilisateur  doit au préalable disposer d'un appareil
compatible (au minimum avec IOS X, Android X, Windows X ou Mac OS X)
<!-- ca veut dire que les niveaux des appareils doivent être de 10 toujours? -->en
bon état de fonctionnement et doit créer un Compte.</p>
<p style="margin-bottom: 0in">En cas de connexion au moyen de
l'interface Facebook intégrée à l'Application, l'Utilisateur
autorise MOBIX à utiliser les données qu'il renseigne dans son
compte Facebook pour compléter son profil d'Utilisateur, étant
entendu que les données issues de l'Application ne donnent lieu à
toute publication sur le profil Facebook de l'Utilisateur, notamment
en relation avec les Livraisons de Biens.</p>
<p style="margin-bottom: 0in">L’utilisateur doit suivre les
instructions fournies par MOBIX via l'Application pour créer son
Compte et fournir les données d'identification requises (nom,
prénom, adresse postale, adresse e-mail, etc..) de manière
correcte, précise et sincèrement.</p>
<p style="margin-bottom: 0in">L’utilisateur mettra à jour
rapidement ses données d'identification via l'Application en cas de
changement et suivra les procédures prévues par MOBIX. Si les
données fournies s'avèrent inexactes, incomplètes ou erronées,
MOBIX se réserve le droit de suspendre l'accès au Compte et aux
Services jusqu'à ce que le Membre corrige ces erreurs.</p>
<p style="margin-bottom: 0in">L’utilisateur reconnaît et accepte
que les Services ne soient accessibles que via l'Application.</p>
<p style="margin-bottom: 0in"><br/>

</p>
<p style="margin-bottom: 0in"><font size="3" style="font-size: 13pt"><b>Informations
de connexion</b></font> 
</p>
<p style="margin-bottom: 0in">Lors de la création du Compte
permettant l'accès aux Services, MOBIX transmet au utilisateur des
Informations de connexion, à savoir un identifiant et un mot de
passe.</p>
<p style="margin-bottom: 0in">Dans le cas où l'Utilisateur créerait
un Compte depuis l'interface Facebook Connect, ses identifiants
Facebook lui permettront de se connecter automatiquement.</p>
<p style="margin-bottom: 0in">Les utilisateurs  n'utilisent
l'Application qu'avec ses propres Identifiants, et acceptent que tous
les actes corrélatifs sur l'Application entrepris avec lesdits
Identifiants soient automatiquement réputés avoir été accomplis
par l’utilisateur, ou à tout le moins sous son contrôle.</p>
<p style="margin-bottom: 0in">L’utilisateur s'engage à garder
confidentielles ses Informations de Connexion et à ne les
communiquer à aucun tiers.</p>
<p style="margin-bottom: 0in">En cas de perte, de vol ou de tout acte
frauduleux ou oubli des Informations de connexion, le Membre doit en
informer MOBIX dans les meilleurs délais en envoyant un e-mail à
l’adresse e-mail suivante : contact@mobix.mg , pour les coursiers
indépendants et les utilisateurs respectivement, et fournira toute
preuve de son identité qui pourra être requise.</p>
<p style="margin-bottom: 0in">MOBIX examinera la demande dans les
meilleurs délais dès réception et pourra, par mesure de sécurité,
suspendre l'accès aux Services avec les Identifiants de
l’utilisateur. MOBIX traitera la demande de l’utilisateur dans
les meilleurs délais et lui adressera par retour de nouvelles
Identifiants.</p>
<p style="margin-bottom: 0in"><br/>

</p>
<p style="margin-bottom: 0in"><font size="3" style="font-size: 12pt"><b>Utilisation
des données</b></font></p>
<p style="margin-bottom: 0in"><br/>

</p>
<p style="margin-bottom: 0in">Les données récoltées par
l’application (nom, prénom, numéro, adresse) et les pièces
jointes sont utilisées par MOBIX uniquement à des fins
professionnelles. Elles sont récoltées et stockées uniquement pour
une utilisation exclusive dans le cadre de l’utilisation de
l’application.  Aucune information sur l’utilisateur ne sera
divulgué par MOBIX, en dehors du cadre d’utilisation de
l’application. 
</p>
<p style="margin-bottom: 0in"><br/>

</p>
<p style="margin-bottom: 0in"><font size="3" style="font-size: 12pt"><b>PROCESSUS
RELATIFS À L'UTILISATION DES SERVICES MOBIX :</b></font><font size="3" style="font-size: 12pt">
</font>
</p>
<p style="margin-bottom: 0in"><br/>

</p>
<ol>
	<li><p style="margin-bottom: 0in"><font size="3" style="font-size: 13pt"><b>Conditions
	de livraisons : </b></font>
	</p>
</ol>
<p style="margin-bottom: 0in"><br/>

</p>
<p style="margin-bottom: 0in">Pour effectuer des livraisons, les
informations suivantes sont requises par l’application: <br/>
<br/>

</p>
<ul>
	<li><p style="margin-bottom: 0in"><u><b>Informations sur
	l’expéditeur :</b></u> nom ou prénom de l’expéditeur (nom de
	lieu, d’établissement ou de magasin valide également),
	localisation via google maps puis préciser via le pin l’endroit
	exact, numéro de téléphone valide sur le territoire malgache sous
	les opérateurs Telma, Airtel et Orange, point de repère pour le
	lieu d’expédition (lieu précis exemple portail rouge, ou bien
	copier et coller les informations saisies sur la localisation via
	google)</p>
	<li><p style="margin-bottom: 0in"><u><b>Informations sur le
	destinataire:</b></u> nom ou prénom du destinataire (nom de lieu,
	d’établissement ou de magasin valide également), localisation
	via google maps puis préciser via le pin l’endroit exact, numéro
	de téléphone valide sur le territoire malgache sous les opérateurs
	Telma, Airtel et Orange, point de repère pour le lieu de
	destination (lieu précis exemple portail rouge, ou bien copier et
	coller les informations saisies sur la localisation via google)<br/>
Il
	est à noter que l’utilisateur peut saisir jusqu’à cinq
	destinataire au total</p>
	<li><p style="margin-bottom: 0in"><u><b>Informations sur le colis:</b></u>
	Si l’utilisateur souhaite faire transporter un colis, et que les
	informations sur ce colis lui sont connues, alors il sera plus
	facile pour l’application de conseiller l’utilisateur sur le
	mode de transport adéquat (poids de la marchandise, dimensions,
	volume). Si l’utilisateur ne connaît pas ses détails, il peut
	décrire dans la partie description les marchandises à transporter,
	et insérer une photo à partir de la section photo de l’application</p>
	<li><p style="margin-bottom: 0in"><u><b>Informations sur les
	personnes à transporter:</b></u> si l’utilisateur a besoin de se
	déplacer seul, il peut le préciser dans la section nombre de
	personnes à transporter. Sachant qu’une personne à transporter
	peut choisir une moto, une voiture légère ou un utilitaire. Si il
	y a plus d’une personne à transporter, alors, l'utilisateur a le
	choix entre la voiture légère et l’utilitaire.</p>
	<li><p style="margin-bottom: 0in"><u><b>Choix sur le moyen de
	transport :</b></u> l’utilisateur peut choisir entre les moyens de
	transport disponibles sur l’application. Toutefois, si les
	informations sur le colis ou les personnes à transporter sont
	préalablement remplies par l’utilisateur, alors les modes de
	transports disponibles sont pré-séléctionnés par l'application.</p>
	<li><p style="margin-bottom: 0in"><u><b>Livraison express et
	livraison programmée:</b></u> L’utilisateur peut choisir de
	planifier sa course ou de l'exécuter de suite. Les deux modes sont
	disponibles sur l’application.</p>
	<li><p style="margin-bottom: 0in"><u><b>Paiement :</b></u> Avant de
	confirmer la course, une récapitulation de la demande de
	l’utilisateur est affichée à l’écran avec le prix et les
	détails de la course. Pour confirmer le paiement, l'utilisateur
	peut choisir de payer en avance via vanilla pay ou mobile banking
	(telma, airtel et orange) ou bien de payer la course une fois
	consommée. Si l’utilisateur commet un défaut de paiement à la
	livraison, Mobix est en droit de suspendre son compte.</p>
	<li><p style="margin-bottom: 0in"><u><b>Annulation:</b></u> pour
	toute annulation de course, l'utilisateur doit informer MOBIX via le
	mail <a href="mailto:contact@mobix.mg"><font color="#1155cc"><u>contact@mobix.mg</u></font></a>
	ou via un message sur la page de mobix, quinze minutes après
	l’émission de la course, pour qu’aucun coursier soit déjà
	parti, sans quoi, l’utilisateur devra payer le déplacement déjà
	entamé par le livreur.</p>
	<li><p style="margin-bottom: 0in"><u><b>Suivi de la course:</b></u>
	L’utilisateur peut suivre l’avancement de sa course via
	l’application directement, pour voir la position du livreur à
	l’instant T. 
	</p>
	<li><p style="margin-bottom: 0in"><u><b>Facturation:</b></u> Les
	factures de l’application sont disponibles une fois la course
	achevée. L’utilisateur peut donc consulter ultérieurement son
	historique de course. 
	</p>
	<li><p style="margin-bottom: 0in"><u><b>Assurance:</b></u> Chaque
	transport est assuré par notre assureur SANLAM.</p>
</ul>
<p style="margin-bottom: 0in"><br/>

</p>
<ol start="2">
	<li><p style="margin-bottom: 0in"><font size="3" style="font-size: 13pt"><b>Condition
	de réservation de billet d’avion/ achat d’une attestation de
	réservation :</b></font></p>
</ol>
<p style="margin-left: 0.5in; margin-bottom: 0in"><br/>

</p>
<ul>
	<li><p style="margin-bottom: 0in"><u><b>Saisie des aéroports: </b></u>Pour
	faire une recherche sur les aéroports,l’utlisateur peut faire une
	recherche par ville, par pays, ou par code IATA.</p>
	<li><p style="margin-bottom: 0in"><u><b>Vol national : </b></u>permet
	de réserver un vol local, sur le territoire malgache (exemple:
	Antananarivo-Toamasina)</p>
	<li><p style="margin-bottom: 0in"><u><b>Vol International :</b></u>
	permet de réserver un vol à l’international (exemple:
	Paris-Antananarivo ou Londres-Paris)</p>
	<li><p style="margin-bottom: 0in"><u><b>Aller simple: </b></u>permet
	la réservation de vol en aller simple</p>
	<li><p style="margin-bottom: 0in"><u><b>Aller-retour:</b></u> permet
	la réservation de vol en aller-retour</p>
	<li><p style="margin-bottom: 0in"><u><b>Passagers:</b></u> Sont
	considérés comme - bébé les passagers de 1 à 23 mois<br/>
     
	                                                     - enfant les
	passagers de 2 à 12 ans <br/>
                                     
	                     - adulte les passagers supérieurs à 12 ans 
	</p>
	<li><p style="margin-bottom: 0in"><u><b>Pièces jointes:</b></u>
	pour que les réservations soient effectives, l’utilisateur doit
	envoyer sur l’application des informations authentiques, ceci
	aussi dans le but d’éviter tout conflit à l’aéroport</p>
	<li><p style="margin-bottom: 0in"><u><b>Champ à remplir :</b></u>
	L’utilisateur doit y insérer son nom et prénom, son email, son
	numéro de téléphone, ainsi que les spécificités de la classe
	demandée afin que sa demande soit traitée au plus vite</p>
	<li><p style="margin-bottom: 0in"><u><b>Paiement:</b></u> Si le prix
	du billet est validé par l’utilisateur, celui-ci peut confirmer
	sa demande en payant sur mobile money (Telma, Airtel ou Orange), ou
	directement vers son compte Vanilla Pay. Sinon il peut la refuser</p>
	<li><p style="margin-bottom: 0in"><u><b>Demande d’attestation de
	réservation:</b></u> La demande d’attestation de réservation se
	fait uniquement sur les vols internationaux. Les prix sont fixés
	par l’application et une demande de confirmation a lieu avant le
	paiement, ce qui permet à l’utilisateur de vérifier le coût de
	l’attestation. 
	</p>
	<li><p style="margin-bottom: 0in"><u><b>Compagnies en relation avec
	MOBIX: </b></u>Madatours étant une agence de voyage, accrédité
	par l’IATA et l’ATAF, propose des billets de plusieurs
	compagnies aériennes.</p>
</ul>
<p style="margin-left: 0.5in; margin-bottom: 0in"><br/>

</p>
<p style="margin-left: 0.5in; margin-bottom: 0in"><br/>

</p>
<p style="margin-left: 0.5in; margin-bottom: 0in"><br/>

</p>
<p style="margin-left: 0.5in; margin-bottom: 0in"><br/>

</p>
<p style="margin-left: 0.5in; margin-bottom: 0in"><br/>

</p>
<ol start="3">
	<li><p style="margin-bottom: 0in"><font size="3" style="font-size: 13pt"><b>Processus
	de réservation d’hôtel : </b></font>
	</p>
</ol>
<p style="margin-left: 0.5in; margin-bottom: 0in"><br/>

</p>
<p style="margin-left: 0.5in; margin-bottom: 0in">Les hôtels sur
l’application MOBIX sont localisés sur le territoire malgache. 
</p>
<p style="margin-left: 0.5in; margin-bottom: 0in"><br/>

</p>
<ul>
	<li><p style="margin-bottom: 0in"><u><b>Barre de recherche :</b></u>
	permet à l’utilisateur de chercher un hôtel selon son nom ou sa
	localisation. 
	</p>
	<li><p style="margin-bottom: 0in"><u><b>Date d’arrivée :</b></u> 
	correspond à la date à laquelle l’utilisateur pense commencer
	son séjour à l’hôtel</p>
	<li><p style="margin-bottom: 0in"><u><b>Date de départ :</b></u>
	correspond à la date à laquelle l’utilisateur pense partir de
	l'hôtel</p>
	<li><p style="margin-bottom: 0in"><u><b>Filtre :</b></u> -le plus
	bas : classe les hôtels dans l’ordre décroissant selon la
	promotion la plus élevée à la promotion la moins élevée</p>
</ul>
<p style="margin-left: 0.5in; margin-bottom: 0in">           -le plus
haut: classe les hôtels dans l’ordre croissant selon la promotion
la moins élevée à la promotion la plus élevée</p>
<p style="margin-left: 0.5in; margin-bottom: 0in">           -selon
le nombre d’étoile: classe les hôtels dans l’ordre décroissant,
avec en tête de classement celui qui a le plus d’étoile</p>
<p style="margin-left: 0.5in; margin-bottom: 0in"><br/>

</p>
<ul>
	<li><p style="margin-bottom: 0in"><u><b>Liste des chambres :</b></u>
	correspond aux différents types de chambres disponible dans l’hôtel
	(double, simple; familiale…)</p>
	<li><p style="margin-bottom: 0in"><u><b>Réserver :</b></u>  permet
	de réserver le même type de chambre, en plusieurs quantité
	(exemple 3 chambres doubles, ou 2 chambre simple)</p>
	<li><p style="margin-bottom: 0in"><u><b>Demander une réservation:</b></u>
	permet de préciser le besoin de l’utilisateur et de commander
	plusieurs types de chambre à la fois. 
	</p>
</ul>
<p style="margin-left: 0.5in; margin-bottom: 0in">La demande est
d’abord envoyée par l’utilisateur, puis une confirmation avec le
prix correspondant lui sera demandé après traitement de sa requête.</p>
<ul>
	<li><p style="margin-bottom: 0in"><u><b>Pièces jointes:</b></u>
	pour une efficacité du traitement de sa demande, l’utilisateur
	doit envoyer des informations claires et véritables à
	l’application.</p>
	<li><p style="margin-bottom: 0in"><u><b>Tarifs et Promotions :</b></u>
	Les tarifs et les promotions sont affichés en détail dans
	l’application, selon la période choisie par l’utilisateur. En
	effectuant le paiement, l’utilisateur accepte les tarifs proposés
	par MOBIX et ne demandera pas ni se plaindra auprès des
	établissements partenaires de MOBIX.</p>
	<li><p style="margin-bottom: 0in"><u><b>Paiement:</b></u> Si le prix
	de l’Hôtel est validé par l’utilisateur, celui-ci peut
	confirmer sa demande en payant sur mobile money (Telma, Airtel ou
	Orange), ou directement vers son compte Vanilla Pay. Sinon il peut
	la refuser.</p>
	<li><p style="margin-bottom: 0in"><u><b>Factures:</b></u> Les
	factures sur les hôtels sont disponibles dans l’application dans
	la rubrique historique puis la partie Réservation Hôtel.</p>
	<li><p style="margin-bottom: 0in"><u><b>Annulation:</b></u> Les
	annulations doivent être effectuées par mail à l’adresse
	<a href="mailto:contact@mobix.mg"><font color="#1155cc"><u>contact@mobix.mg</u></font></a>
	ou via message sur la page facebook de MOBIX avec mention de la
	référence indiquée sur la facture. Elle doit se faire au
	préalable, au moins 5 jours après envoi du paiement par
	l’utilisateur, sans quoi, elle ne sera pas effective.<br/>
Si
	l’Hôtel demande une caution pendant la réservation, MOBIX sera
	dans l’obligation de retenir cette caution si l’utilisateur
	annule.MOBIX remboursera la différence à l’utilisateur. 
	</p>
</ul>
<p style="margin-bottom: 0in"><br/>

</p>
  `,
      }}
    />
  );
};

export default Terms;

import React, { FC, useContext } from 'react';
import useStyles from './style';
import { Box, Button, Paper, TextField, Typography } from '@material-ui/core';
import VanillaPayimage from '../../Images/logo-vp.png';
import { PayementVanillaContext } from '../../Providers/PayementVanilla';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { DO_INIT_PAYMENT } from '../../Graphql/PaymentVanilla/mutation';
import {
  INIT_PAYMENT,
  INIT_PAYMENTVariables,
} from '../../Graphql/PaymentVanilla/types/INIT_PAYMENT';
import SnackVariableInterface from '../../Interfaces/SnackVariableInterface';
import { displaySnackBar } from '../../Utils/snackBarUtils';
import { useHistory } from 'react-router-dom';
import { separatorOfMillier } from '../../Utils/toolsMontant';
import { UserContext } from '../../Providers/User';
import Backdrop from '../Backdrop';

const VanillaPay: FC = () => {
  const classes = useStyles();
  const client = useApolloClient();

  const { name, email } = useContext(UserContext);
  const { reference, montant, idpanier } = useContext(PayementVanillaContext);

  const [doGetUrlPayement, { loading }] = useMutation<
    INIT_PAYMENT,
    INIT_PAYMENTVariables
  >(DO_INIT_PAYMENT, {
    onError: (err) => {
      if (err.networkError?.message === 'Failed to fetch') {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Problème de connexion...',
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      }
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: err.message.split(':')[1],
        isOpen: true,
      };
      return displaySnackBar(client, snackBarData);
    },
  });

  const onPressVanilla = () => {
    doGetUrlPayement({
      variables: {
        adresseip: '127.0.0.1',
        idpanier,
        reference: String(reference),
        nom: name,
        email,
        montant,
      },
    }).then((result) => {
      window.location.replace(result?.data?.initPayment?.urlVanillaPay ?? '');
    });
  };

  const onPressMobileMoney = () => {
    doGetUrlPayement({
      variables: {
        adresseip: '127.0.0.1',
        idpanier,
        reference: String(reference),
        nom: name,
        email,
        montant,
      },
    }).then((result) => {
      window.location.replace(result.data?.initPayment?.urlMobileMoney || '');
    });
  };

  return (
    <Box className={classes.contentCard}>
      <Backdrop open={loading} />
      <Paper className={classes.allContent}>
        <Box className={classes.contentInformation}>
          <Box>
            <img src={VanillaPayimage}></img>
          </Box>
          <Box className={classes.contentReference}>
            <Typography className={classes.contentTitle}>
              Paiement pour &quot;mobix&quot;
            </Typography>
            <Box>
              <Typography>Référence interne</Typography>
              <Typography>Paiement</Typography>
            </Box>
            <Box>
              <Typography>Montant TTC :</Typography>
              <Typography>{separatorOfMillier(Number(montant))}Ar</Typography>
            </Box>
          </Box>
        </Box>
        <Box className={classes.AllcontentButton}>
          <Typography>Payer avec</Typography>
          <Box className={classes.contentButton}>
            <Button variant="contained" onClick={onPressVanilla}>
              Mon compte Vanilla Pay
            </Button>
            <Button variant="contained" onClick={onPressMobileMoney}>
              Mobile Money
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};
export default VanillaPay;

import React, { FC, useContext, useState } from 'react';
import {
  Typography,
  Box,
  Radio,
  IconButton,
  Button,
  FormControlLabel,
} from '@material-ui/core';
import useStyles from './style';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import TextInput from '../../Components/TextInput';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useApolloClient, useQuery, useMutation } from '@apollo/react-hooks';
import SnackVariableInterface from '../../Interfaces/SnackVariableInterface';
import { displaySnackBar } from '../../Utils/snackBarUtils';
import { TYPE_PARTICULAR } from '../../Graphql/User/types/TYPE_PARTICULAR';
import { DO_TYPE_PARTICULAR } from '../../Graphql/User/query';
import {
  SIGNUP_PARTICULAR,
  SIGNUP_PARTICULARVariables,
} from '../../Graphql/SignupParticular/types/SIGNUP_PARTICULAR';
import { DO_SIGNUP_PARTICULAR } from '../../Graphql/SignupParticular/mutation';
import Backdrop from '../../Components/Backdrop';
import CustomLink from '../../Components/CustomLink';
import GroupeDeMasque from '../../Images/GroupeDeMasque.svg';
import { UserContext } from '../../Providers/User';

interface Inscription {
  name: string;
  firstName: string;
  phone: string;
  email: string;
  password: string;
  confirmePassword: string;
}

const ParticularAccount: FC<RouteComponentProps> = ({ history }) => {
  const classes = useStyles();
  const client = useApolloClient();
  const [idType, setIdType] = useState<string>('');
  const [values, setValues] = useState<Inscription>({
    name: '',
    firstName: '',
    phone: '',
    email: '',
    password: '',
    confirmePassword: '',
  });

  const [checkContrat, setCheckContrat] = useState<boolean>(false);

  useQuery<TYPE_PARTICULAR>(DO_TYPE_PARTICULAR, {
    onCompleted: (data) => {
      setIdType(
        data && data.typeParticular && data.typeParticular.id
          ? data.typeParticular.id
          : '',
      );
    },
  });

  const [signup, { loading: loadingSignup }] = useMutation<
    SIGNUP_PARTICULAR,
    SIGNUP_PARTICULARVariables
  >(DO_SIGNUP_PARTICULAR, {
    onCompleted: (data) => {
      const snackBarData: SnackVariableInterface = {
        type: 'INFO',
        message:
          'Pour valider votre compte, Veuillez ouvrir votre boite e-mail.',
        isOpen: true,
      };
      displaySnackBar(client, snackBarData);
    },
    onError: ({ message }) => {
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: message.split(':')[1],
        isOpen: true,
      };
      displaySnackBar(client, snackBarData);
    },
  });

  const onClickPrevRoute = () => {
    history.push('/choiceaccount');
  };

  const onClickInscrire = () => {
    if (checkContrat === false) {
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: 'Veuillez accepter les termes et les conditions',
        isOpen: true,
      };
      displaySnackBar(client, snackBarData);
    }

    if (values.name.trim().length === 0) {
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: 'Le champ nom est vide.',
        isOpen: true,
      };
      displaySnackBar(client, snackBarData);
    }

    if (values.password !== values.confirmePassword) {
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: 'Les mots de passe ne sont pas conformes',
        isOpen: true,
      };
      displaySnackBar(client, snackBarData);
    }

    if (values.confirmePassword.trim().length === 0) {
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: 'Le champ confirmé le mot de passe est vide.',
        isOpen: true,
      };
      displaySnackBar(client, snackBarData);
    }

    if (
      checkContrat === true &&
      values.password.trim().length !== 0 &&
      values.confirmePassword.trim().length !== 0 &&
      values.password === values.confirmePassword &&
      values.name.trim().length !== 0
    ) {
      signup({
        variables: {
          name: values.name,
          firstName: values.firstName,
          email: values.email,
          password: values.password,
          phone: values.phone,
          userType: idType,
        },
      });
    }
  };

  const onTextChange = (e: any) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  return (
    <Box className={classes.container}>
      <Backdrop open={loadingSignup} />
      <Box className={classes.left}>
        <Box className={classes.btnPrev}>
          <IconButton aria-label="prev" size="small" onClick={onClickPrevRoute}>
            <ChevronLeftIcon style={{ color: 'white' }} fontSize="large" />
          </IconButton>
        </Box>

        <Box>
          <Typography variant="h1">Créer un compte particulier</Typography>

          <TextInput
            variant="outlined"
            required
            name="name"
            value={values.name}
            placeholder="Nom *"
            onChange={onTextChange}
            fullWidth
          />
          <TextInput
            variant="outlined"
            required
            name="firstName"
            value={values.firstName}
            placeholder="Prénom"
            onChange={onTextChange}
            fullWidth
          />
          <TextInput
            variant="outlined"
            required
            name="phone"
            value={values.phone}
            placeholder="Téléphone *"
            onChange={onTextChange}
            fullWidth
          />
          <TextInput
            variant="outlined"
            required
            type="email"
            name="email"
            value={values.email}
            placeholder="Email *"
            onChange={onTextChange}
            fullWidth
          />
          <TextInput
            variant="outlined"
            type="password"
            name="password"
            value={values.password}
            placeholder="Mot de passe *"
            onChange={onTextChange}
            fullWidth
          />
          <TextInput
            variant="outlined"
            type="password"
            name="confirmePassword"
            placeholder="Confirmer le mot de passe *"
            value={values.confirmePassword}
            onChange={onTextChange}
            fullWidth
          />
          <FormControlLabel
            onChange={() => setCheckContrat(true)}
            control={
              <Radio color="primary" size="small" value="1" name="radio" />
            }
            label={
              <>
                <Typography variant="subtitle2" component="span">
                  J&apos;ai lu et j&apos;accepte&nbsp;
                  <CustomLink
                    href="/terms"
                    variant="subtitle2"
                    color="secondary"
                    underline="hover">
                    les termes & conditions et la Politique de confidentialité
                  </CustomLink>
                </Typography>
              </>
            }
            style={{ width: '100%' }}
          />
        </Box>

        <Box textAlign="center">
          <Button variant="contained" color="primary" onClick={onClickInscrire}>
            S&apos;inscrire
          </Button>
          <Typography variant="subtitle2">
            Si vous avez déjà un compte,
          </Typography>
          <CustomLink
            href="/login"
            variant="h1"
            color="inherit"
            underline="hover">
            Identifiez-vous
          </CustomLink>
        </Box>
      </Box>
      <Box className={classes.bg}>
        {/* <img src={GroupeDeMasque} alt="Mobix" /> */}
      </Box>
    </Box>
  );
};

export default withRouter(ParticularAccount);

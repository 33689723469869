import { split } from 'apollo-link';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { API_URL, APP_WS_URL } from './config';

const createClient = (token: string) => {
  const httpLink = createHttpLink({
    uri: API_URL,
  });

  // Create a WebSocket link:
  const wsLink = new WebSocketLink({
    uri: APP_WS_URL || 'ws://localhost:4000/graphql',
    options: {
      reconnect: true,
      connectionParams: {
        authToken: token,
      },
    },
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const link = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    authLink.concat(httpLink),
  );

  return new ApolloClient({
    link,
    cache: new InMemoryCache(),
  });
};

export default createClient;

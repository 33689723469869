import { useApolloClient, useLazyQuery } from '@apollo/react-hooks';
import { useContext } from 'react';
import { DO_GET_DISTANCE } from '../../Graphql/GetDistance/query';
import {
  GET_DISTANCE,
  GET_DISTANCEVariables,
} from '../../Graphql/GetDistance/types/GET_DISTANCE';
import SnackVariableInterface from '../../Interfaces/SnackVariableInterface';
import { DestinataireSortedByDistanceContext } from '../../Providers/DestinataireSortedByDistance';
import { IndexDeliveryContext } from '../../Providers/IndexDelivery';

import { displaySnackBar } from '../../Utils/snackBarUtils';

export const useGetDistanceSortedByUser = () => {
  const client = useApolloClient();

  const { setDestinatairesSortedByDistance } = useContext(
    DestinataireSortedByDistanceContext,
  );

  const { indexDelivery, setIndexDelivery } = useContext(IndexDeliveryContext);

  const [
    doGetDistance,
    { data: dataDistanceSorted, loading: loadingDistance },
  ] = useLazyQuery<GET_DISTANCE, GET_DISTANCEVariables>(DO_GET_DISTANCE, {
    onError: (err) => {
      if (err.networkError?.message === 'Failed to fetch') {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Problème de connexion...',
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      }
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: err.message.split(':')[1],
        isOpen: true,
      };
      return displaySnackBar(client, snackBarData);
    },
    onCompleted: (data) => {
      if (data.getDistance) {
        setDestinatairesSortedByDistance(data.getDistance as any);
        setIndexDelivery(indexDelivery + 1);
      }
    },
    fetchPolicy: 'network-only',
  });

  return {
    doGetDistance,
    dataDistanceSorted,
    loadingDistance,
  };
};

import { makeStyles } from '@material-ui/core';
import { ImportantDevices } from '@material-ui/icons';

export default makeStyles((theme) => ({
  allContent: {
    width: '80%',
    margin: 'auto',
    backgroundColor: '#fff',
    padding: 20,
    webkitBoxShadow: '0px 3px 6px 0px rgba(0,0,0,0.12)',
    mozBoxShadow: '0px 3px 6px 0px rgba(0,0,0,0.12)',
    boxShadow: '0px 3px 6px 0px rgba(0,0,0,0.12)',
  },
  firstContentButton: {
    display: 'flex',
    '& Button': {
      width: '15%',
      borderRadius: 25,
      fontSize: 12,
      fontWeight: 500,
      padding: '0 !important',
      marginLeft: 10,
      marginBottom: '30px',
    },
    '@media (max-width:1023px)': {
      flexDirection: 'column',
      alignItems: 'center',
      '& Button': {
        width: '50%',
      },
    },
    '& Button:first-child': {
      backgroundColor: '#fff',
      webkitBoxShadow: '0px 3px 6px 0px rgba(0,0,0,0.12)',
      mozBoxShadow: '0px 3px 6px 0px rgba(0,0,0,0.12)',
      boxShadow: '0px 3px 6px 0px rgba(0,0,0,0.12)',
      '&:hover': {
        color: '#000',
      },
    },
    '& Button:last-child': {
      backgroundColor: '#55A7EE',
      color: '#fff',
      '&:hover': {
        color: '#000',
      },
    },
  },
  scndContent: {
    '@media (max-width:1365px)': {
      width: '100%',
      marginTop: '50px',
    },
    '& .MuiTextField-root': {
      height: '100%',
    },
    '& .MuiFilledInput-underline:after': {
      display: 'none',
    },
    '& :first-child': {},
  },
  textField: {
    height: '160px',
    width: '68%',
    position: 'relative',
    boxShadow: 'none',
    borderRadius: '5px',
    border: '1px solid #A7A7A7',
    '& :after': { display: 'none' },
    '& :hover': { backgroundColor: 'none' },

    '& .MuiInputBase-root': {
      width: '100%',
      height: '100%',
      '& input': { position: 'absolute', top: 0 },
    },
  },
  ButtonReservationContent: {},
  firstButton: {
    color: '#000',
    backgroundColor: '#CAD1DE',
    marginRight: '15px',
    maxWidth: '109px',
    '&:hover': {
      backgroundColor: '#B4B9C4',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    '& div': {
      display: 'none',
    },
  },

  selectEmpty: {
    marginTop: 0,
    '& input': {
      width: '100% !important',
      paddingTop: '0 !important',
      paddingBottom: '15px !important',
    },
  },

  contentDatePicker: {
    '@media (max-width:1365px)': {
      // display: 'flex',
      // width: '70%',
      // margin: 'auto',
    },
    '@media (max-width:1200px)': {
      // width: '90%',
    },
    '& >*': {
      // width: '45%',
      '& .MuiFormControl-root ': {
        // width: '100%',
        // border: '1px solid #A7A7A7',
        // borderRadius: '5px',
      },
      '& p': {
        margin: 0,
        textTransform: 'inherit',
      },
    },
  },
  contentInput: {
    display: 'flex',
    // justifyContent:'space-evenly',
    width: '100%',
    '@media(max-width:1023px)': {
      flexDirection: 'column-reverse',
      alignItems: 'center',
    },
    '& div': {
      '& .MuiSelect-select.MuiSelect-select': {
        backgroundColor: 'unset',
        paddingLeft: '30px',
        lineHeight: '28px',
      },
    },
    '& .MuiFormLabel-root': {
      fontSize: '16px',
      color: '#55a7ee',
      textTransform: 'uppercase',
      '&:last-child': {
        display: 'flex',
      },
    },
    '& .MuiFormControl-root ': {
      color: '#000',
      margin: 0,
      fontSize: '12px',
      border: '1px solid',
      borderRadius: '5px',
      marginTop: '20px',
      '& :before , :after': {
        display: 'none',
      },
      '& svg': {
        position: 'absolute',
        right: 0,
      },
    },
  },
  contentSelectFirst: {
    width: '70%',
    marginRight: '40px',
  },
  selectEmptyLast: {
    width: 'auto',
    '& input': {
      color: '#000',
      outline: 'none',
      fontSize: '16px',
      marginTop: '20px',
      borderRadius: '5px',
      paddingLeft: '10px',
      lineHeight: '38px',
      border: '1px solid #000 !important',
      marginBottom: '20px',
    },
  },
  contentSelect: {
    display: 'flex',
    width: '100%',
    '& div': {
      width: '94%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  },
  contentOption: {
    display: 'flex',
    width: '100%',
    marginTop: 20,
    '& :first-child': {
      marginRight: '10px',
      width: '99%',
      justifyContent: 'start',
    },
    '& :last-child': {
      width: '99%',
      justifyContent: 'start',
    },
    '& input': {
      width: '100%',
      borderRadius: 5,
      marginRight: '10px',
      justifyContent: 'space-between',
    },
    '@media (max-width:1365px)': {},
  },
  contentInput1: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '12px',
    '& label,input': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  contentPassager: {
    display: 'flex',
    flexDirection: 'column',
    '& .MuiFormControl-root': {
      marginBottom: 20,
    },
    '& input': {
      paddingLeft: '20px',
      height: 28,
    },
  },
  contentInputAlign: {
    height: '32px',
    display: 'flex',
    marginTop: '18px',
    paddingBottom: '15px',
    alignItems: 'center',
    borderBottom: '1px solid #E6E6E6',
    justifyContent: 'space-between',
    '&:first-child': {
      // textTransform: 'uppercase',
    },
  },
  colorLabel: {
    color: '#55A7EE',
    fontWeight: 'bold',
    fontSize: '12px',
  },
  contentIncrement: {
    display: 'flex',
    width: '40%',
    justifyContent: 'space-between;',
    alignItems: 'center',
    alignContent: 'center',
    '& >span': {
      fontSize: 12,
    },
    '& .MuiIconButton-colorPrimary ': {
      color: '#f4f4f5',
      width: '22px',
      height: '22px',
      background: '#55A7EE',
      fontSize: 12,
      borderRadius: '2px',

      '& .MuiIconButton-colorPrimary:hover': {
        backgroundColor: 'gray',
      },
    },
    '& .MuiIconButton-root.Mui-disabled': {
      borderRadius: 2,
      backgroundColor: '#CAD1DE',
    },
  },
  contentButtonAjout: {
    display: 'flex',
    justifyContent: 'start',
    width: '100%',
    padding: '0 !important',
    '& .MuiIconButton-label': {
      height: '27px',
    },
    '& .MuiButtonBase-root': {
      width: '27px !important',
    },
  },
  contentIncrementContent: {
    width: 'auto',
  },
}));

import React, { useState } from 'react';
import { createContext, FC } from 'react';

interface DestinationInterface {
  id: string;
  name: string;
  phone: string;
  address: string;
  addressBookId: string;
  latitude: number;
  longitude: number;
}

interface DestinationInputProps {
  id: string;
  name: string;
  phone: string;
  address: string;
  addressBookId: string;
  latitude: number;
  longitude: number;
  setIdDestination: (destination: string) => void;
  setNameDestination: (destination: string) => void;
  setPhoneDestination: (destination: string) => void;
  setAdressDestination: (destination: string) => void;
  setLatitudeDestination: (destination: number) => void;
  setLongitudeDestination: (destination: number) => void;
  setAdresseBookIdDestination: (destination: string) => void;
  clearDestination: () => void;
}

export const DestinationContext = createContext<DestinationInputProps>({
  id: '',
  name: '',
  address: '',
  phone: '',
  latitude: 0,
  longitude: 0,
  addressBookId: '',
  setIdDestination: (destination: string) => {},
  setNameDestination: (destination: string) => {},
  setPhoneDestination: (destination: string) => {},
  setAdressDestination: (destination: string) => {},
  setLatitudeDestination: (destination: number) => {},
  setLongitudeDestination: (destination: number) => {},
  setAdresseBookIdDestination: (destination: string) => {},
  clearDestination: () => {},
});

export const DestinationProvider: FC = ({ children }) => {
  const [destination, setDestination] = useState<DestinationInterface>({
    id: '',
    name: '',
    address: '',
    phone: '',
    latitude: 0,
    longitude: 0,
    addressBookId: '',
  });

  const [id, setIdDestination] = useState('');
  const [name, setNameDestination] = useState('');
  const [address, setAdressDestination] = useState('');
  const [phone, setPhoneDestination] = useState('');
  const [latitude, setLatitudeDestination] = useState(0);
  const [longitude, setLongitudeDestination] = useState(0);
  const [adresseBookId, setAdresseBookIdDestination] = useState('');

  return (
    <DestinationContext.Provider
      value={{
        id: id,
        latitude: latitude,
        longitude: longitude,
        name: name,
        address: address,
        phone: phone,
        addressBookId: adresseBookId,
        clearDestination: () => {
          setIdDestination('');
          setNameDestination('');
          setAdressDestination('');
          setPhoneDestination('');
          setLatitudeDestination(0);
          setLongitudeDestination(0);
          setAdresseBookIdDestination('');
        },
        setIdDestination,
        setNameDestination,
        setAdressDestination,
        setPhoneDestination,
        setLatitudeDestination,
        setLongitudeDestination,
        setAdresseBookIdDestination,
      }}>
      {children}
    </DestinationContext.Provider>
  );
};

import React, { useState, createContext, FC } from 'react';

interface DeliveryProps {
  indexDelivery: number;
  setIndexDelivery: (index: number) => void;
}

export const IndexDeliveryContext = createContext<DeliveryProps>({
  indexDelivery: 0,
  setIndexDelivery: () => {},
});

export const IndexDeliveryProvider: FC = ({ children }) => {
  const [indexDelivery, setIndexDelivery] = useState<number>(0);

  return (
    <IndexDeliveryContext.Provider
      value={{
        indexDelivery,
        setIndexDelivery,
      }}>
      {children}
    </IndexDeliveryContext.Provider>
  );
};

import { Box, Button, Paper, Typography } from '@material-ui/core';
import React, { FC, Fragment, useContext, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import useStyles from './style';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import imageAvion from '../../../Images/avionBlue.svg';
import EllipseBlue from '../../../Images/EllipseBlue.svg';
import ligneBlue from '../../../Images/LigneBlue.svg';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { DO_MY_REQUEST_BOOKING_WAITING_VOL } from '../../../Graphql/Vol/query';
import { UserContext } from '../../../Providers/User';
import {
  MY_REQUEST_BOOKING_WAITING_VOL,
  MY_REQUEST_BOOKING_WAITING_VOLVariables,
} from '../../../Graphql/Vol/types/MY_REQUEST_BOOKING_WAITING_VOL';
import Backdrop from '../../../Components/Backdrop';
import SnackVariableInterface from '../../../Interfaces/SnackVariableInterface';
import { displaySnackBar } from '../../../Utils/snackBarUtils';
import moment from 'moment';
import { classnames } from '../../../Components/MapComponent/helper';
import Weight from '../../../Components/Icons/Weight';

const VolEnAttente: FC<RouteComponentProps> = () => {
  const history = useHistory();
  const goToRecap = () => {
    history.push('/accueil/Reservation-Vol/Recapitulation-Vol');
  };
  const [buttonSelected, setButtonSelected] = useState('2');
  const client = useApolloClient();
  const classes = useStyles();
  const [active, setActive] = useState(0);
  const { idMe } = useContext(UserContext);
  const [indexDetails, setIndexDetails] = useState<number>(0);
  const { data, loading: loadingVol } = useQuery<
    MY_REQUEST_BOOKING_WAITING_VOL,
    MY_REQUEST_BOOKING_WAITING_VOLVariables
  >(DO_MY_REQUEST_BOOKING_WAITING_VOL, {
    variables: { user_id: idMe },
    skip: idMe ? false : true,
    fetchPolicy: 'cache-and-network',
    onError: (err) => {
      if (err.networkError?.message === 'Failed to fetch') {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Problème de connexion...',
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      }
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: err.message.split(':')[1],
        isOpen: true,
      };
      return displaySnackBar(client, snackBarData);
    },
    onCompleted: () => {
      console.log('idme web->', idMe);
    },
  });

  const handleShowDetails = (index: number) => {
    setIndexDetails(index);
  };

  return (
    <Box className={classes.allContent}>
      <Backdrop open={loadingVol} />
      <Box className={classes.firstContent}>
        <Typography variant="h6">Réservation Vol</Typography>
        <Box className={classes.contentButton}>
          <Button
            variant="contained"
            color="default"
            className={classes.button}
            startIcon={<AccessTimeIcon />}>
            En Attente
          </Button>
          <Button
            variant="outlined"
            color="default"
            onClick={goToRecap}
            className={classes.button}
            startIcon={<CheckCircleIcon />}>
            Traité
          </Button>
        </Box>

        {data?.myRequestBookingFlightWaiting.length === 0 ? (
          <Box style={{ fontWeight: 'bold' }}>Aucun vol en attente.</Box>
        ) : (
          data?.myRequestBookingFlightWaiting &&
          data?.myRequestBookingFlightWaiting.map((dataVol, index) => {
            index++;
            return (
              <Box
                key={`${index}`}
                className={classnames(active == index ? classes.clicked : '')}>
                {' '}
                <Box
                  onClick={() => {
                    handleShowDetails(index - 1);
                    setActive(index);
                  }}>
                  <Box className={classes.demandeContainer}>
                    <Box className={classes.contentDemande}>
                      <Typography variant="subtitle1">
                        Demande de réservation
                      </Typography>
                      <Typography variant="subtitle1">
                        {moment(dataVol.createdAt).format(' DD MMM YYYY')}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        variant="subtitle1"
                        className={classes.demandeAlignement}>
                        Référence :&nbsp;{' '}
                        <Typography variant="subtitle1">
                          {' '}
                          {dataVol?.reference}
                        </Typography>
                      </Typography>
                      <Box className={classes.departure}>
                        <Typography
                          variant="subtitle1"
                          className={classes.demandeAlignement}>
                          <img src={imageAvion}></img> {dataVol.cityDeparture}
                        </Typography>
                        <Typography>
                          <img
                            src={ligneBlue}
                            className={classes.alignementAvions}></img>
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          className={classes.demandeAlignement}>
                          <img src={EllipseBlue}></img> {dataVol.cityArrival}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            );
          })
        )}
      </Box>
      {data?.myRequestBookingFlightWaiting.length === 0 ? (
        <Box style={{ fontWeight: 'bold' }}></Box>
      ) : (
        <Box className={classes.scndContent}>
          <Typography variant="caption">Demande de réservation</Typography>
          <Box>
            <Box>
              <Box className={classes.contentDemandeScnd}>
                <Typography
                  variant="subtitle1"
                  className={classes.demandeAlignement}>
                  Référence :&nbsp;
                  <Typography variant="subtitle1">
                    {
                      data?.myRequestBookingFlightWaiting[indexDetails]
                        .reference
                    }
                  </Typography>
                </Typography>
                <Typography variant="subtitle1">
                  {data?.myRequestBookingFlightWaiting.length === 0
                    ? '-'
                    : moment(
                        data?.myRequestBookingFlightWaiting[indexDetails]
                          .dateRequestBooking,
                      ).format(' DD MMM YYYY')}
                </Typography>
              </Box>

              <Box className={classes.demandeContainer}>
                <Typography variant="h3">Départ : </Typography>

                <Box className={classes.departure}>
                  <Box className={classes.aligncontentDeparture}>
                    <Typography
                      variant="subtitle1"
                      className={classes.demandeAlignement}>
                      <img src={imageAvion}></img>{' '}
                      {data?.myRequestBookingFlightWaiting.length === 0
                        ? '-'
                        : data?.myRequestBookingFlightWaiting[indexDetails]
                            .cityDeparture}
                    </Typography>
                    <Typography variant="subtitle1">
                      {data?.myRequestBookingFlightWaiting.length === 0
                        ? '-'
                        : moment(
                            data?.myRequestBookingFlightWaiting[indexDetails]
                              .dateFirst,
                          ).format(' DD MMM YYYY')}
                    </Typography>
                  </Box>
                  <Typography>
                    <img
                      src={ligneBlue}
                      className={classes.alignementAvions}></img>
                  </Typography>
                  <Box className={classes.aligncontentDeparture}>
                    <Typography
                      variant="subtitle1"
                      className={classes.demandeAlignement}>
                      <img src={EllipseBlue}></img>
                      {data?.myRequestBookingFlightWaiting.length === 0
                        ? '-'
                        : data?.myRequestBookingFlightWaiting[indexDetails]
                            .cityArrival}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              {data?.myRequestBookingFlightWaiting.length === 0 ? (
                '-'
              ) : data?.myRequestBookingFlightWaiting[indexDetails]
                  .typeBooking === 'ONE_WAY' ? null : (
                <Box className={classes.demandeContainer}>
                  <Typography variant="h3">Retour : </Typography>
                  <Box className={classes.departure}>
                    <Box className={classes.aligncontentDeparture}>
                      <Typography
                        variant="subtitle1"
                        className={classes.demandeAlignement}>
                        <img src={imageAvion}></img>{' '}
                        {data?.myRequestBookingFlightWaiting.length === 0
                          ? '-'
                          : data?.myRequestBookingFlightWaiting[indexDetails]
                              .cityArrival}
                      </Typography>
                      <Typography variant="subtitle1">
                        {data?.myRequestBookingFlightWaiting.length === 0
                          ? '-'
                          : moment(
                              data?.myRequestBookingFlightWaiting[indexDetails]
                                .dateLast,
                            ).format(' DD MMM YYYY')}
                      </Typography>
                    </Box>
                    <Typography>
                      <img
                        src={ligneBlue}
                        className={classes.alignementAvions}></img>
                    </Typography>
                    <Box className={classes.aligncontentDeparture}>
                      <Typography
                        variant="subtitle1"
                        className={classes.demandeAlignement}>
                        <img src={EllipseBlue}></img>{' '}
                        {data?.myRequestBookingFlightWaiting.length === 0
                          ? '-'
                          : data?.myRequestBookingFlightWaiting[indexDetails]
                              .cityDeparture}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>

            <Box className={classes.recapDate}>
              <Typography
                variant="subtitle1"
                className={classes.demandeAlignement}>
                <Typography variant="subtitle1" className={classes.colorText}>
                  Compagnie Aérienne :
                </Typography>
                <Typography variant="subtitle1">
                  {data?.myRequestBookingFlightWaiting.length === 0
                    ? '-'
                    : data?.myRequestBookingFlightWaiting[indexDetails]
                        .responseBookingFlights.length === 0
                    ? '-'
                    : data?.myRequestBookingFlightWaiting[indexDetails]
                        .responseBookingFlights[0].nameOfCompagny}
                </Typography>
              </Typography>
            </Box>

            <Box className={classes.contentPassager}>
              <Typography
                variant="subtitle1"
                className={classes.colorTextTransform}>
                Passager(s)
              </Typography>
              <Box className={classes.recapName}>
                <Typography variant="subtitle1">
                  {data?.myRequestBookingFlightWaiting.length === 0
                    ? '-'
                    : data?.myRequestBookingFlightWaiting[indexDetails]
                        ?.manifest &&
                      JSON.parse(
                        data?.myRequestBookingFlightWaiting[indexDetails]
                          ?.manifest,
                      ).numberAdult}{' '}
                  Adulte(s){' '}
                </Typography>
                <Typography variant="subtitle1">
                  {data?.myRequestBookingFlightWaiting.length === 0
                    ? '-'
                    : data?.myRequestBookingFlightWaiting[indexDetails]
                        ?.manifest &&
                      JSON.parse(
                        data?.myRequestBookingFlightWaiting[indexDetails]
                          ?.manifest,
                      ).numberChildren}{' '}
                  Enfant(s) de 2 à 12 ans
                </Typography>
                <Typography variant="subtitle1">
                  {data?.myRequestBookingFlightWaiting.length === 0
                    ? '-'
                    : data?.myRequestBookingFlightWaiting[indexDetails]
                        ?.manifest &&
                      JSON.parse(
                        data?.myRequestBookingFlightWaiting[indexDetails]
                          ?.manifest,
                      ).numberBaby}{' '}
                  Bébé(s)
                </Typography>
              </Box>
              <Typography
                variant="subtitle1"
                className={classes.colorTextTransform}>
                commentaire :
              </Typography>
              <Typography variant="subtitle1" className={classes.comment}>
                {data?.myRequestBookingFlightWaiting.length === 0
                  ? '-'
                  : data?.myRequestBookingFlightWaiting[indexDetails].comment}
              </Typography>
            </Box>
            <Box className={classes.statusReservation}>
              <Typography>Statut</Typography>
              <Typography>En Attente</Typography>
              <Box className={classes.separator}></Box>
              <Typography>
                Nous vous enverrons une notification confirmant votre
                réservation.
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default VolEnAttente;

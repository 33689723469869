import gql from 'graphql-tag';

export const DO_CREATE_BILL = gql`
  mutation CREATE_BILL(
    $orderId: String!
    $customer_id: String!
    $datePaymentBill: Date
  ) {
    createBill(
      orderId: $orderId
      customer_id: $customer_id
      datePaymentBill: $datePaymentBill
    ) {
      id
    }
  }
`;

export const DO_DOWNLOAD_BILL_DELIVERY = gql`
  mutation DOWNLOAD_BILL_DELIVERY($customer_id: String!, $bill_id: String!) {
    downloadBillDelivery(customer_id: $customer_id, bill_id: $bill_id)
  }
`;

export const DO_DOWNLOAD_BILL_Hotel = gql`
  mutation DOWNLOAD_BILL_HOTEL($customer_id: String!, $bill_id: String!) {
    downloadBillHotel(customer_id: $customer_id, bill_id: $bill_id)
  }
`;

export const DO_DOWNLOAD_BILL_VOL = gql`
  mutation DOWNLOAD_BILL_VOL($customer_id: String!, $bill_id: String!) {
    downloadBillFlight(customer_id: $customer_id, bill_id: $bill_id)
  }
  `;

import React, { FC } from 'react';
import useStyles from './style';

import ImageGallery from 'react-image-gallery';
import { Box } from '@material-ui/core';

interface ImageTypes {
  original: string;
  thumbnail: string;
}

const Slide = ({ imagesSource }: { imagesSource: ImageTypes | any }) => {
  const classes = useStyles();
  return (
    <Box className={classes.contentSlide}>
      <ImageGallery
        thumbnailPosition="right"
        autoPlay={false}
        items={imagesSource}
        showNav={false}
        showFullscreenButton={false}
        showPlayButton={false}
      />
    </Box>
  );
};
export default Slide;

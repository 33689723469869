import React from 'react';
import { Link, LinkProps } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

export default function CustomLink(props: LinkProps) {
  const { href } = props;
  let history = useHistory();
  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();

    history.push(href ? href : '/');
  };

  return (
    <Link {...props} onClick={handleClick}>
      {props.children}
    </Link>
  );
}

import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export default function Travel(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24.573 24.573">
      <path
        d="M8.714,17.285,13.1,12.9,2.571,5,5.643,1.928,18.805,7.194,22.949,3.05c1.195-1.195,2.863-1.481,3.708-.636s.558,2.513-.636,3.708l-4.144,4.144,5.266,13.164L24.072,26.5l-7.9-10.531-4.387,4.387V26.5H8.714L7.178,21.892,2.571,20.357V17.285H8.714Z"
        transform="translate(-2.571 -1.928)"
      />
    </SvgIcon>
  );
}

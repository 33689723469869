import React, { FC, useContext, useState } from 'react';
import {
  Typography,
  Box,
  Radio,
  IconButton,
  FormControlLabel,
  Button,
} from '@material-ui/core';
import useStyles from './style';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import TextInput from '../../Components/TextInput';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import SnackVariableInterface from '../../Interfaces/SnackVariableInterface';
import { displaySnackBar } from '../../Utils/snackBarUtils';
import { useApolloClient, useQuery, useMutation } from '@apollo/react-hooks';
import { TYPE_PROFESSIONNAL } from '../../Graphql/User/types/TYPE_PROFESSIONNAL';
import { DO_TYPE_PROFESSIONAL } from '../../Graphql/User/query';
import {
  SIGNUP_PROFESSIONNEL,
  SIGNUP_PROFESSIONNELVariables,
} from '../../Graphql/SignupProfessionnel/types/SIGNUP_PROFESSIONNEL';
import { DO_SIGNUP_PROFESSIONNEL } from '../../Graphql/SignupProfessionnel/mutation';
import Backdrop from '../../Components/Backdrop';
import CustomLink from '../../Components/CustomLink';
import GroupeDeMasque from '../../Images/GroupeDeMasque.svg';

import { UserContext } from '../../Providers/User';

interface Inscription {
  name: string;
  nif: string;
  stat: string;
  phone: string;
  email: string;
  password: string;
  confirmePassword: string;
}

const ProfessionnalAccount: FC<RouteComponentProps> = ({ history }) => {
  const classes = useStyles();
  const [checkContrat, setCheckContrat] = useState<boolean>(false);
  const client = useApolloClient();
  const [idType, setIdType] = useState<string>('');
  const [values, setValues] = useState<Inscription>({
    name: '',
    nif: '',
    stat: '',
    phone: '',
    email: '',
    password: '',
    confirmePassword: '',
  });

  useQuery<TYPE_PROFESSIONNAL>(DO_TYPE_PROFESSIONAL, {
    onCompleted: (data) => {
      setIdType(
        data && data.typeProfessional && data.typeProfessional.id
          ? data.typeProfessional.id
          : '',
      );
    },
  });

  const [signup, { loading: loadingCreateAccount }] = useMutation<
    SIGNUP_PROFESSIONNEL,
    SIGNUP_PROFESSIONNELVariables
  >(DO_SIGNUP_PROFESSIONNEL, {
    onCompleted: (data) => {
      const snackBarData: SnackVariableInterface = {
        type: 'INFO',
        message:
          'Pour valider votre compte, Veuillez ouvrir votre boite e-mail.',
        isOpen: true,
      };
      displaySnackBar(client, snackBarData);
    },
    onError: ({ message }) => {
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: message.split(':')[1],
        isOpen: true,
      };
      displaySnackBar(client, snackBarData);
    },
  });

  const onClickPrevRoute = () => {
    history.push('/choiceaccount');
  };

  const onTextChange = (e: any) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const onClickInscrire = () => {
    if (values.name.trim().length === 0) {
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: 'Le champ nom est vide.',
        isOpen: true,
      };
      displaySnackBar(client, snackBarData);
    }

    if (checkContrat === false) {
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: 'Veuillez accepter les termes et les conditions',
        isOpen: true,
      };
      displaySnackBar(client, snackBarData);
    }

    if (values.password !== values.confirmePassword) {
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: 'Les mots de passe ne sont pas conformes',
        isOpen: true,
      };
      displaySnackBar(client, snackBarData);
    }

    if (values.confirmePassword.trim().length === 0) {
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: 'Le champ confirmé le mot de passe est vide.',
        isOpen: true,
      };
      displaySnackBar(client, snackBarData);
    }
    if (
      checkContrat === true &&
      values.password.trim().length !== 0 &&
      values.confirmePassword.trim().length !== 0 &&
      values.password === values.confirmePassword &&
      values.name.trim().length !== 0
    ) {
      signup({
        variables: {
          name: values.name,
          nif: values.nif,
          stat: values.stat,
          phone: values.phone,
          userType: idType,
          email: values.email,
          password: values.password,
        },
      });
    }
  };

  return (
    <Box className={classes.container}>
      <Backdrop open={loadingCreateAccount} />
      <Box className={classes.left}>
        <Box className={classes.btnPrev}>
          <IconButton aria-label="prev" size="small" onClick={onClickPrevRoute}>
            <ChevronLeftIcon style={{ color: 'white' }} fontSize="large" />
          </IconButton>
        </Box>
        <Typography variant="h1">Créer un compte professionnel</Typography>
        <Box>
          <TextInput
            variant="outlined"
            required
            name="name"
            value={values.name}
            placeholder="Nom ou raison sociale *"
            onChange={onTextChange}
            fullWidth
          />
          <TextInput
            variant="outlined"
            required
            name="nif"
            value={values.nif}
            placeholder="NIF *"
            onChange={onTextChange}
            fullWidth
          />
          <TextInput
            variant="outlined"
            required
            name="stat"
            value={values.stat}
            placeholder="STAT *"
            onChange={onTextChange}
            fullWidth
          />
          <TextInput
            variant="outlined"
            required
            name="phone"
            value={values.phone}
            placeholder="Téléphone *"
            onChange={onTextChange}
            fullWidth
          />
          <TextInput
            variant="outlined"
            type="email"
            required
            name="email"
            value={values.email}
            placeholder="Email *"
            onChange={onTextChange}
            fullWidth
          />
          <TextInput
            variant="outlined"
            type="password"
            name="password"
            value={values.password}
            placeholder="Mot de passe *"
            onChange={onTextChange}
            fullWidth
          />

          <TextInput
            variant="outlined"
            type="password"
            name="confirmePassword"
            value={values.confirmePassword}
            placeholder="Confirmer le mot de passe *"
            onChange={onTextChange}
            fullWidth
          />
        </Box>
        <FormControlLabel
          onChange={() => setCheckContrat(true)}
          control={
            <Radio color="primary" size="small" value="1" name="radio" />
          }
          label={
            <Typography variant="subtitle2" component="span">
              J&apos;ai lu et j&apos;accepte&nbsp;
              <CustomLink
                href="/terms"
                variant="subtitle2"
                color="secondary"
                underline="hover">
                les termes & conditions et la Politique de confidentialité
              </CustomLink>
            </Typography>
          }
        />

        <Box textAlign="center">
          <Button variant="contained" color="primary" onClick={onClickInscrire}>
            S&apos;inscrire
          </Button>
          <Typography variant="subtitle2">
            Si vous avez déjà un compte,
          </Typography>
          <CustomLink
            href="/login"
            variant="h1"
            color="inherit"
            underline="hover">
            Identifiez-vous
          </CustomLink>
        </Box>
      </Box>
      <Box className={classes.bg}>
        {/* <img src={GroupeDeMasque} alt="Mobix" /> */}
      </Box>
    </Box>
  );
};

export default withRouter(ProfessionnalAccount);

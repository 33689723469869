import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function User (props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 15.257 22.36">
      <g
        id="Groupe_743"
        data-name="Groupe 743"
        transform="translate(-207.375 -84.386)">
        <path
          id="Tracé_2036"
          data-name="Tracé 2036"
          d="M264.494,199.7H263.4a.624.624,0,1,0,0,1.247h1.092a.624.624,0,0,0,0-1.247Z"
          transform="translate(-45.805 -95.335)"
        />
        <path
          id="Tracé_2037"
          data-name="Tracé 2037"
          d="M215.534,99.794a2.507,2.507,0,0,1-1.772-.275,2.49,2.49,0,0,0,.085-.312,5.135,5.135,0,0,0,6.283-4.968V93.23a.624.624,0,0,0,1.183-.277V90.471a2.118,2.118,0,0,1,.613-1.5.625.625,0,0,0,.177-.382.651.651,0,0,0-.122-.456,5.161,5.161,0,0,0-2.847-2.189.635.635,0,0,0,.005-.192.621.621,0,0,0-.348-.483c-2.456-1.182-5.934-1.41-8.169.745a6.925,6.925,0,0,0-1.883,5.049v1.887a1.736,1.736,0,0,0,1.151,1.634,5.116,5.116,0,0,0,2.759,4.178,1.26,1.26,0,0,1-1.259,1.192,4.02,4.02,0,0,0-4.016,4.016v2.149a.624.624,0,1,0,1.247,0v-2.149a2.772,2.772,0,0,1,2.768-2.768v0a2.482,2.482,0,0,0,1.68-.644,3.763,3.763,0,0,0,2.729.453.624.624,0,1,0-.266-1.219Zm-4.41-8.534a1.561,1.561,0,0,1,1.56-1.56h4.64a1.561,1.561,0,0,1,1.56,1.56v2.952a3.879,3.879,0,0,1-7.759,0Zm8.942-.789v.187a2.812,2.812,0,0,0-2.742-2.206h-4.64a2.812,2.812,0,0,0-2.672,1.945,5,5,0,0,1,1.957-3.879,5.9,5.9,0,0,1,4.952-.612.627.627,0,0,0,.427,1l.64.068a3.968,3.968,0,0,1,2.72,1.509A3.383,3.383,0,0,0,220.066,90.471Z"
        />
        <path
          id="Tracé_2038"
          data-name="Tracé 2038"
          d="M261.6,169.954a1.253,1.253,0,0,1-1.079-.605.624.624,0,1,0-1.065.649,2.492,2.492,0,0,0,2.144,1.2,2.771,2.771,0,0,1,2.768,2.768v2.149a.624.624,0,1,0,1.247,0V173.97A4.02,4.02,0,0,0,261.6,169.954Z"
          transform="translate(-42.983 -69.996)"
        />
      </g>
    </SvgIcon>
  );
}

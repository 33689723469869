import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
export default makeStyles((theme) => ({
  notificationContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    borderBottom: '1px solid #e6e6e6',
    paddingBottom: 10,
  },
  notificationContained: {
    borderRadius: 45,
    height: 45,
  },
  notificationContainedShadowless: {
    boxShadow: 'none',
  },
  notificationIconPdf: {
    fill: '#FF0000',
  },
  notificationIconWord: {
    fill: '#368BC1',
  },
  notificationIconXls: {
    fill: '#008000',
  },
  notificationIconTxt: {
    fill: '#2554C7',
  },
  downIconTxt: {
    fill: '#000000',
  },
  notificationIconContainer: {
    minWidth: 40,
    height: 40,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 24,
    '&svg': {
      display: 'flex',
    },
  },
  notificationIconContainerContained: {
    fontSize: 18,
    color: '#FFFFFF80',
    // color: '#FFFFFF80',
  },
  notificationIconContainerRounded: {
    marginRight: theme.spacing(2),
  },
  containedTypography: {
    color: 'white',
  },

  messageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexGrow: 1,
    //color: 'white',
    maxWidth: 347,
    overflowX: 'hidden',
    scroll: 'none',
    scrollbarWidth: 'none',
    '& .scrollbar-hidden:-webkit-scrollbar ': {
      display: 'none',
    },
    '&::-webkit-scrollbar': {
      //height: 10,
    },
    '&:hover': {
      overflowX: 'scroll',
    },
  },
  extraButton: {
    color: 'white',
    '&:hover, &:focus': {
      background: 'transparent',
    },
  },

  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

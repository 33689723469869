import React from 'react';
import { compose, withProps } from 'recompose';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  DirectionsRenderer,
} from 'react-google-maps';
import { Box } from '@material-ui/core';
import { useGetWayPoint } from './wayPoints';

const MapDeliveryInfo = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_API_GOOGLE_MAP}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `87vh` }} />,
    center: { lat: -18.8791902, lng: 47.5079055 },
    streetViewControl: false,
    fullscreenControl: false,
    zoomControl: true,
    disableDefaultUI: true,
  }),
  withScriptjs,
  withGoogleMap,
)((props: any) => {
  const { directions } = useGetWayPoint();

  const defaultMapOptions = {
    fullscreenControl: false,
    streetViewControl: false,
  };

  return (
    <Box position="relative" height="100%">
      <GoogleMap
        defaultZoom={18}
        defaultCenter={props.center}
        defaultOptions={defaultMapOptions}>
        <DirectionsRenderer directions={directions} />
      </GoogleMap>
    </Box>
  );
});

export default MapDeliveryInfo;

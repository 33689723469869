import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  demandeContainer: {
    padding: '20px 0 0px 0',
    borderBottom: '1px solid #C7D3E6',
    alignItems: 'flex-Start',
    display: 'flex',
    '& img': {
      marginRight:20,
    },  
  },
  alignementScndConten:{
    display:'flex',
    fontWeight:900,
      },
      scndContentDate: {
        fontSize:12,
        borderBottom:'1px solid gray',
        paddingBottom:10,
        marginBottom:10,
        '& :nth-child(2)div':{
          display:'flex ',
        },
    },
    alignementScndContent: {
      display:'flex',
      padding:'0px 0px 5px 0',
      justifyContent:'space-between',
  
    },
    alignementScndContent1: {
      display:'flex',
      padding:'0px 0px 10px 0',
      marginBottom:'20px',
      justifyContent:'space-between',
      borderBottom:'1px solid #C7D3E6',
    },
    alignementScndContent2: {
      display:'flex',
      flexDirection:'column',
      padding:'0px 0px 10px 0',
      marginBottom:'20px',
      justifyContent:'space-between',
      borderBottom:'1px solid #C7D3E6',
    },
    contentDistance: {
      fontSize:'14px',
    },
    clicked:{
      backgroundColor: '#ECF1F9',
    },
});
export default useStyles;
import React, { FC } from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import StarIcon from '@material-ui/icons/Star';
import useStyles from './style';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import classnames from 'classnames';
import { start } from 'repl';
import moment from 'moment';

const separatorOfMillier = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};
interface propsCardHotel {
  titre: string;
  AdresseHotel: string;
  href: string;
  img?: string;
  price?: any;
  priceBarrer?: any;
  isBarred?: boolean;
  customTextBarrerClass?: any;
  starHotel?: number;
  promo?: any;
  promoBegin?: any;
  promoEnd?: any;
}

const CardHotel: FC<propsCardHotel> = ({
  titre,
  AdresseHotel,
  href,
  img,
  price,
  priceBarrer,
  isBarred,
  customTextBarrerClass,
  starHotel,
  promo,
  promoBegin,
  promoEnd,
}) => {
  const classes = useStyles();
  const star = [];
  // console.log(
  //   moment(promoBegin.substr(1, promoBegin.length))
  //     .format('DD MMMM')
  //     .substr(0, 6),
  // );

  for (let i = 0; i < (starHotel || 0); i++) {
    star.push(i);
  }
  return (
    <Box className={classes.contentCard}>
      <Box className={classes.bookingFirstContent}>
        {img ? (
          <Box className={classes.imageBooking}>
            <CardMedia component="img" image={img} title="proposition" />
          </Box>
        ) : null}

        <Box className={classes.contentDescription}>
          <p className={classes.titreOffres}>{titre}</p>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className={classes.adresseOfre}>
            {AdresseHotel}
          </Typography>
          <Box className={classes.contentStarFirst}>
            {star.map((i, index) => {
              return (
                <Box key={index + ''} className={classes.contentStar}>
                  <StarIcon style={{ color: '#E9685E' }} />
                </Box>
              );
            })}
          </Box>
          {/* <Typography className={classes.contentStar}>{starHotel}</Typography> */}
          <Box className={classes.contentPrice}>
            <Typography
              component="p"
              className={classnames(classes.textBarrer, customTextBarrerClass)}>
              {/* {props.priceBarrer} */}
            </Typography>
            {/* <Typography className={classes.priceDeco}>{separatorOfMillier(price)} Ar</Typography> */}
          </Box>
        </Box>
      </Box>
      {promo && (
        <Box style={{ display: 'flex', flexDirection: 'column' }}>
          {' '}
          <Box className={classes.promoContent}>
            {promo}
            <Typography className={classes.percentPromo}>%</Typography>
          </Box>
          <Box className={classes.datePromo}>
            {moment(promoBegin.substr(1, promoBegin.length))
              .format('DD MMMM')
              .substr(0, 6) +
              ' au ' +
              moment(promoEnd.substr(1, promoEnd.length))
                .format('DD MMMM')
                .substr(0, 6)}
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default CardHotel;

import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export default function Commande(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 26.802 26.802">
      <path
        id="Tracé_2268"
        data-name="Tracé 2268"
        d="M259.926,256A3.931,3.931,0,0,0,256,259.926v.785h7.852v-.785A3.931,3.931,0,0,0,259.926,256Z"
        transform="translate(-242.599 -242.599)"
      />
      <path
        id="Tracé_2269"
        data-name="Tracé 2269"
        d="M151,140.52h18.9V120H151Zm14.919-2.408H154.926v-1.57h10.993Zm-12.563-6.282h1.57v-.785a5.489,5.489,0,0,1,4.711-5.417v-1.65h1.57v1.65a5.489,5.489,0,0,1,4.711,5.417v.785h1.57v1.57H153.356Z"
        transform="translate(-143.096 -113.718)"
      />
      <path
        id="Tracé_2270"
        data-name="Tracé 2270"
        d="M6.334,4.711,15.756,0H0V20.468H6.334Z"
      />
      <path
        id="Tracé_2271"
        data-name="Tracé 2271"
        d="M194.1,33.6l-5.9,2.952h5.9Z"
        transform="translate(-178.348 -31.841)"
      />
    </SvgIcon>
  );
}

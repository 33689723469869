import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  link: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: 45,
    // marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    textDecoration: 'none',
    borderRadius: 20,
    '&>svg': {
      maxWidth: 25,
      marginRight: theme.spacing(1.5),
      color: '#4267B2',
    },
    '&>span': {
      color: '#6B6B6B',
      // fontSize: '.875rem',
      fontSize: '12px',
    },
  },
  active: {
    backgroundColor: '#ECF1F9',
    '&>span': {
      color: '#000',
    },
  },
}));

import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function DeliveryIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 22.14 25.036">
      <g
        id="Icon_feather-box"
        data-name="Icon feather-box"
        transform="translate(1.071 1.036)">
        <path
          id="Tracé_828"
          data-name="Tracé 828"
          d="M24.5,19.042V9.876a2.306,2.306,0,0,0-1.111-1.982L15.611,3.31a2.164,2.164,0,0,0-2.222,0L5.611,7.893A2.306,2.306,0,0,0,4.5,9.876v9.166a2.306,2.306,0,0,0,1.111,1.982l7.778,4.583a2.164,2.164,0,0,0,2.222,0l7.778-4.583A2.306,2.306,0,0,0,24.5,19.042Z"
          transform="translate(-4.5 -3.003)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Tracé_829"
          data-name="Tracé 829"
          d="M4.905,10.44l9.709,5.446,9.709-5.446"
          transform="translate(-4.614 -4.649)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Tracé_830"
          data-name="Tracé 830"
          d="M18,28.871V18"
          transform="translate(-8 -5.871)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </SvgIcon>
  );
}

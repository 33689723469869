import gql from 'graphql-tag';

export const DO_TYPE_PARTICULAR = gql`
  query TYPE_PARTICULAR {
    typeParticular {
      id
      name
    }
  }
`;

export const DO_TYPE_PROFESSIONAL = gql`
  query TYPE_PROFESSIONNAL {
    typeProfessional {
      id
      name
    }
  }
`;

export const ME = gql`
  query Me {
    me {
      id
      email
      phone
      name
      firstName
      customer {
        id
      }
    }
  }
`;

export const DO_USER_BY_CUSTOMER = gql`
  query USER_BY_CUSTOMER($id: String!) {
    userByCustomer(id: $id) {
      id
      email
      name
      phone
      password
    }
  }
`;

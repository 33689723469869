import React, { FC, useContext } from 'react';
import { Typography, Box, Button } from '@material-ui/core';
import useStyles from './style';
import logo from '../../Images/logo.svg';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import GroupeDeMasque from '../../Images/GroupeDeMasque.svg';
import { Link } from 'react-router-dom';
import CustomLink from '../../Components/CustomLink';
import {
  CONFIRM_ACCOUNT,
  CONFIRM_ACCOUNTVariables,
} from '../../Graphql/User/types/CONFIRM_ACCOUNT';
import Backdrop from '../../Components/Backdrop';
import { DO_CONFIRM_ACCOUNT } from '../../Graphql/User/mutation';
import SnackVariableInterface from '../../Interfaces/SnackVariableInterface';
import { displaySnackBar } from '../../Utils/snackBarUtils';
import { UserContext } from '../../Providers/User';

interface ConfirmAccountProps {
  listResult: any;
  match: {
    params: { token: string | undefined };
  };
}

const ConfirmAccount: FC<ConfirmAccountProps & RouteComponentProps> = ({
  match: { params },
}) => {
  const classes = useStyles();
  const history = useHistory();
  const client = useApolloClient();
  const { setToken } = useContext(UserContext);

  const [doConfirm, { loading }] = useMutation<
    CONFIRM_ACCOUNT,
    CONFIRM_ACCOUNTVariables
  >(DO_CONFIRM_ACCOUNT, {
    onCompleted: (data) => {
      if (data && data.confirmCompte) {
        const snackBarData: SnackVariableInterface = {
          type: 'SUCCESS',
          message: 'Votre compte a été créé avec succès',
          isOpen: true,
        };
        displaySnackBar(client, snackBarData);
        setTimeout(() => {
          localStorage.setItem('token', data.confirmCompte.accessToken);
          setToken(data.confirmCompte.accessToken);

          history.replace('/accueil');
          window.location.reload();
        }, 2000);
      } else {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: "Erreur lors de l'authentification",
          isOpen: true,
        };
        displaySnackBar(client, snackBarData);
      }
    },
    onError: ({ message }) => {
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: message.split(':')[1],
        isOpen: true,
      };
      displaySnackBar(client, snackBarData);
    },
  });

  const onClickConfirme = () => {
    console.log('params.token', params.token);
    doConfirm({ variables: { token: params.token || '' } });
  };

  return (
    <Box className={classes.container}>
      <Backdrop open={loading} />
      <Box className={classes.left}>
        <Box className={classes.logo}>
          <img src={logo} alt="logo" />
        </Box>
        <Box className={classes.login}>
          <Typography variant="h1">Confirmation de compte</Typography>

          <Button variant="contained" color="primary" onClick={onClickConfirme}>
            Confirmer
          </Button>

          <Typography variant="subtitle2">
            Si vous avez déjà un compte,
          </Typography>
          <CustomLink
            href="/login"
            variant="h1"
            color="inherit"
            underline="hover">
            Identifiez-vous
          </CustomLink>
        </Box>

        <Box className={classes.terms}>
          <Typography>
            En vous connectant sur notre plateforme, <br />
            vous êtes d&apos;accord avec&nbsp;
            <Link style={{ color: 'inherit' }} to="/terms">
              les termes et les conditions
            </Link>
          </Typography>
        </Box>
      </Box>
      <Box className={classes.bg}>
        <img src={GroupeDeMasque} alt="Mobix" />
      </Box>
    </Box>
  );
};

export default withRouter(ConfirmAccount);

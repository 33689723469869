import gql from 'graphql-tag';
import {
  HOTEL_INFO_FRAGEMENT,
  REQUEST_BOOKING_INFO_FRAGMENT,
} from './fragment';

export const DO_GET_HOTELS = gql`
  query GET_HOTELS {
    hotels {
      ...HotelInfo
    }
  }
  ${HOTEL_INFO_FRAGEMENT}
`;

export const DO_SEARCH_HOTEL = gql`
  query SEARCH_HOTEL($inputName: String) {
    searchHotels(inputName: $inputName) {
      ...HotelInfo
    }
  }
  ${HOTEL_INFO_FRAGEMENT}
`;

export const DO_FILTER_HOTELS = gql`
  query FILTER_HOTELS(
    $desc: Boolean
    $asc: Boolean
    $typeHotel: String
    $numberStar: Int
    $dateArrival: Date
    $dateDeparture: Date
    $searchName:String
  ) {
    filterHotels(
      desc: $desc
      asc: $asc
      typeHotel: $typeHotel
      numberStar: $numberStar
      dateArrival: $dateArrival
      dateDeparture: $dateDeparture
      searchName:$searchName
    ) {
      ...HotelInfo
    }
  }
  ${HOTEL_INFO_FRAGEMENT}
`;

export const DO_MY_REQUEST_BOOKING_WAITING = gql`
  query MY_REQUEST_BOOKING_WAITING($user_id: String!) {
    myRequestBookingWaiting(user_id: $user_id) {
      ...RequestBookingInfo
    }
  }
  ${REQUEST_BOOKING_INFO_FRAGMENT}
`;

export const DO_MY_REQUEST_BOKING_PROCESSING = gql`
  query MY_REQUEST_BOKING_PROCESSING($user_id: String!) {
    myRequestBookingProcessing(user_id: $user_id) {
      ...RequestBookingInfo
    }
  }
  ${REQUEST_BOOKING_INFO_FRAGMENT}
`;

export const DO_GET_NUMBER_ROOM_AVAILABLE = gql`
  query GET_NUMBER_ROOM_AVAILABLE($idRoom: String!) {
    numberRoomAvailable(idRoom: $idRoom)
  }
`;

export const DO_GET_HOTEL = gql`
  query GET_HOTEL($id: String!) {
    hotel(id: $id) {
      id
      name
      city
      pictureHotels {
        urlPicture
        typePicture
      }
      promotionHotels {
        id
        ratePercentage
        dateFirst
        dateLast
      }
      rateDay
      ratePromotion
      numberStar
      description
      rooms {
        id
        typeRoom {
          id
          name
          codeType
          status
          typeBed
          numberPerson
        }
        rateDay
        comment
      }
    }
  }
`;

import { makeStyles } from '@material-ui/core';

export default makeStyles({
  layout: {
    display: 'flex',
    width: '100%',
    height: '100vh',
    maxHeight: '100vh',
    overflow: 'hidden',
    '&>div': {
      height: '100%',
    },
  },
  dashboardContent: {
    position: 'relative',
    flex: 'auto',
    padding: '100px 1rem 1rem',
    backgroundColor: '#F2F3FA',
  },
  content: {
    height: '100%',
    overflow: 'auto',
  },
});

import React, { FC, useState } from 'react';
import { Typography, Box, Button } from '@material-ui/core';
import useStyles from './style';
import logo from '../../Images/logo.svg';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import { DO_CONFIRME_FORGOT_PASSWORD } from '../../Graphql/User/mutation';
import SnackVariableInterface from '../../Interfaces/SnackVariableInterface';
import { displaySnackBar } from '../../Utils/snackBarUtils';
import GroupeDeMasque from '../../Images/GroupeDeMasque.svg';
import TextInput from '../../Components/TextInput';
import CustomLink from '../../Components/CustomLink';
import { Link } from 'react-router-dom';

interface ConfirmeInitPasswordProps {
  listResult: any;
  match: {
    params: { token: string | undefined };
  };
}

const ConfirmeInitPassword: FC<
  ConfirmeInitPasswordProps & RouteComponentProps
> = ({ match: { params } }) => {
  const classes = useStyles();
  const [password, setPassword] = useState<string>('');
  const client = useApolloClient();

  const [DO_CONFIRME] = useMutation(DO_CONFIRME_FORGOT_PASSWORD, {
    onCompleted: (data) => {
      const snackBarData: SnackVariableInterface = {
        type: 'INFO',
        message: 'Mot de passe réinitialisé avec succès',
        isOpen: true,
      };
      displaySnackBar(client, snackBarData);
    },
    onError: ({ message }) => {
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: message.split(':')[1],
        isOpen: true,
      };
      displaySnackBar(client, snackBarData);
    },
  });

  const onChangEmailAdresse = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { value } = event.target;
    setPassword(value);
  };

  const onClickConfirme = () => {
    console.log('params.token', params.token);
    console.log('password', password);
    if (password.trim().length === 0) {
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: 'Le champ mot de passe est vide.',
        isOpen: true,
      };
      displaySnackBar(client, snackBarData);
    } else {
      DO_CONFIRME({
        variables: { token: params.token, newPassword: password },
      });
    }
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.left}>
        <Box className={classes.logo}>
          <img src={logo} alt="logo" />
        </Box>

        <Box className={classes.login}>
          <Typography variant="h1">Réinitialisation de mot de passe</Typography>
          <TextInput
            variant="outlined"
            type="password"
            name="password"
            onChange={onChangEmailAdresse}
            placeholder="Entrer votre nouveau mot de passe"
            fullWidth
          />

          <Button variant="contained" color="primary" onClick={onClickConfirme}>
            Confirmer
          </Button>

          <Typography variant="subtitle2">
            Si vous avez déjà un compte,
          </Typography>
          <CustomLink
            href="/login"
            variant="h1"
            color="inherit"
            underline="hover">
            Identifiez-vous
          </CustomLink>
        </Box>

        <Box className={classes.terms}>
          <Typography>
            En vous connectant sur notre plateforme, <br />
            vous êtes d&apos;accord avec&nbsp;
            <Link style={{ color: 'inherit' }} to="/terms">
              les termes et les conditions
            </Link>
          </Typography>
        </Box>
      </Box>
      <Box className={classes.bg}>
        <img src={GroupeDeMasque} alt="Mobix" />
      </Box>
    </Box>
  );
};

export default withRouter(ConfirmeInitPassword);

import React, { useState } from 'react';
import { createContext, FC } from 'react';

interface ExpediteurLocationInterface {
  latitude: number;
  longitude: number;
}

interface ExpediteurLocationInputProps {
  latitude: number;
  longitude: number;
  setLatitudeExpediteurLocation: (expediteur: number) => void;
  setLongitudeExpediteurLocation: (expediteur: number) => void;
  clearExpediteurLocation: () => void;
}

export const ExpediteurLocationContext = createContext<ExpediteurLocationInputProps>({
  latitude: 0,
  longitude: 0,
  setLatitudeExpediteurLocation: (expediteur: number) => {},
  setLongitudeExpediteurLocation: (expediteur: number) => {},
  clearExpediteurLocation: () => {},
});

export const ExpediteurLocationProvider: FC = ({ children }) => {
  // const [expediteur, setExpediteurLocation] = useState<ExpediteurLocationInterface>({
  //   latitude: 0,
  //   longitude: 0,
  // });

  const [latitude, setLatitudeExpediteurLocation_] = useState(0)
  const [longitude, setLongitudeExpediteurLocation_] = useState(0)

  return (
    <ExpediteurLocationContext.Provider
      value={{
        latitude: latitude,
        longitude: longitude,
        clearExpediteurLocation: () => {
          
        },
        setLatitudeExpediteurLocation: (value) => setLatitudeExpediteurLocation_(value) ,
        setLongitudeExpediteurLocation: (value) => setLongitudeExpediteurLocation_(value) ,
      }}>
      {children}
    </ExpediteurLocationContext.Provider>
  );
};

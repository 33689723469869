import React, { FC, Fragment, useContext, useEffect, useState } from 'react';
import useStyles from './style';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  NativeSelect,
  Typography,
} from '@material-ui/core';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import CardHistory from '../../Components/CardHistory';
import GetAppIcon from '@material-ui/icons/GetApp';
import filehistory from '../../Images/filehistory.svg';
import HistoriqueVol from './HistoriqueVol';
import HistoriqueCommande from './HistoriqueCommande';
import HistoriqueLivraison from './HistoriqueLivraison';
import HistoriqueHotel from './HistoriqueHotel';
import { UserContext } from '../../Providers/User';
import moment from 'moment';
import {
  DOWNLOAD_BILL_DELIVERY,
  DOWNLOAD_BILL_DELIVERYVariables,
} from '../../Graphql/Bill/types/DOWNLOAD_BILL_DELIVERY';
import {
  DO_DOWNLOAD_BILL_DELIVERY,
  DO_DOWNLOAD_BILL_Hotel,
  DO_DOWNLOAD_BILL_VOL,
} from '../../Graphql/Bill/mutation';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import SnackVariableInterface from '../../Interfaces/SnackVariableInterface';
import { displaySnackBar } from '../../Utils/snackBarUtils';
import {
  DOWNLOAD_BILL_HOTEL,
  DOWNLOAD_BILL_HOTELVariables,
} from '../../Graphql/Bill/types/DOWNLOAD_BILL_HOTEL';
import {
  DOWNLOAD_BILL_VOL,
  DOWNLOAD_BILL_VOLVariables,
} from '../../Graphql/Bill/types/DOWNLOAD_BILL_VOL';
interface historiqueInterface {}

const MakeReservation = () => {
  const [loader, setLoader] = useState(true);
  const classes = useStyles();
  const { name, firstName, idMe, customerId } = useContext(UserContext);
  const client = useApolloClient();
  const history = useHistory();
  const [index, setIndex] = useState(0);
  const [facture, setFacture] = useState<any | null>();
  const [billFlightUser, setBillFlightUser] = useState<any | null>();
  const [billDeliveryUser, setBillDeliveryUser] = useState<any | null>();
  const [billHotelUser, setBillHotelUser] = useState<any | null>();
  const [state, setState] = React.useState<{
    selected: string | number;
    name: string;
  }>({
    selected: 'livraison',
    name: 'hai',
  });
  const [doCreatePdfDelivery, { loading: loadingDelivery }] = useMutation<
    DOWNLOAD_BILL_DELIVERY,
    DOWNLOAD_BILL_DELIVERYVariables
  >(DO_DOWNLOAD_BILL_DELIVERY, {
    onError: (err) => {
      if (err.networkError?.message === 'Failed to fetch') {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Problème de connexion...',
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      }
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: err.message.split(':')[1],
        isOpen: true,
      };
      return displaySnackBar(client, snackBarData);
    },
    onCompleted: (data) => {
      if (data.downloadBillDelivery) {
        window.open(data.downloadBillDelivery);
      }
    },
  });

  const [doCreatePdfHotel, { loading: loadingHotel }] = useMutation<
    DOWNLOAD_BILL_HOTEL,
    DOWNLOAD_BILL_HOTELVariables
  >(DO_DOWNLOAD_BILL_Hotel, {
    onError: (err) => {
      if (err.networkError?.message === 'Failed to fetch') {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Problème de connexion...',
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      }
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: err.message.split(':')[1],
        isOpen: true,
      };
      return displaySnackBar(client, snackBarData);
    },
    onCompleted: (data) => {
      if (data.downloadBillHotel) {
        window.open(data.downloadBillHotel);
      }
    },
  });

  const [doCreatePdfvol, { loading: loadingVol }] = useMutation<
    DOWNLOAD_BILL_VOL,
    DOWNLOAD_BILL_VOLVariables
  >(DO_DOWNLOAD_BILL_VOL, {
    onError: (err) => {
      if (err.networkError?.message === 'Failed to fetch') {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Problème de connexion...',
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      }
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: err.message.split(':')[1],
        isOpen: true,
      };
      return displaySnackBar(client, snackBarData);
    },
    onCompleted: (data) => {
      if (data.downloadBillFlight) {
        window.open(data.downloadBillFlight);
      }
    },
  });

  const handleChange = (
    event: React.ChangeEvent<{ name?: string; value: string }>,
  ) => {
    const name = event.target.name as keyof typeof state;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };
  const downloadPdfVol = () => {
    doCreatePdfvol({
      variables: { customer_id: customerId, bill_id: billFlightUser?.id || '' },
    });
  };

  const downloadPdfHotel = () => {
    doCreatePdfHotel({
      variables: { customer_id: customerId, bill_id: billHotelUser?.id || '' },
    });
  };

  const downloadPdfDelivery = () => {
    doCreatePdfDelivery({
      variables: {
        customer_id: customerId,
        bill_id: billDeliveryUser?.id || '',
      },
    });
  };

  return (
    <Box className={classes.allContent}>
      <Box className={classes.firstContent}>
        <Typography variant="h6">Historique de</Typography>
        <Box className={classes.contentButton}>
          <FormControl className={classes.formControl}>
            <NativeSelect
              value={state.selected}
              onChange={handleChange}
              name="selected"
              className={classes.selectEmpty}
              inputProps={{ 'aria-label': 'selected' }}>
              <option value="livraison">Livraisons</option>
              <option value={'hotel'}>Réservation Hôtel</option>
              <option value={'vol'}>Réservation Vol</option>
            </NativeSelect>
          </FormControl>
        </Box>
        <Box>
          {state.selected === 'livraison' ? (
            <HistoriqueLivraison
              setValue={setBillDeliveryUser}
              setIndex={setIndex}
            />
          ) : state.selected === 'vol' ? (
            <Fragment>
              <HistoriqueVol
                setValue={setBillFlightUser}
                setIndex={setIndex}
                setLoader={setLoader}
              />
            </Fragment>
          ) : state.selected === 'hotel' ? (
            <HistoriqueHotel setValue={setBillHotelUser} setIndex={setIndex} />
          ) : (
            <div>Vide</div>
          )}
        </Box>
      </Box>
      <Box className={classes.scndContent}>
        {state.selected === 'livraison' ? (
          <Fragment>
            {billDeliveryUser === undefined ? (
              <Box>Aucun historique disponible</Box>
            ) : (
              <Fragment>
                <Box>
                  <Box className={classes.alignementScndConten}>
                    <Typography variant="h6">
                      Référence &nbsp;:&nbsp;{' '}
                    </Typography>
                    <Typography variant="h6">
                      {billDeliveryUser === undefined
                        ? '-'
                        : billDeliveryUser &&
                          billDeliveryUser.order.payments[0].delivery
                            ?.reference}
                    </Typography>
                  </Box>
                  <Typography className={classes.scndContentDate}>
                    Le &nbsp;:&nbsp; &nbsp;
                    {billDeliveryUser === undefined
                      ? '--/--/----'
                      : billDeliveryUser &&
                        moment(billDeliveryUser.datePayementBill).format(
                          'DD MMM YYYY',
                        )}
                  </Typography>

                  <Box>
                    <Box
                      className={classes.alignementScndContent1}
                      style={{ border: 'none', fontWeight: 900 }}>
                      <Typography variant="h5" style={{ fontWeight: 900 }}>
                        {' '}
                        Destination
                      </Typography>
                      <Typography variant="h5" style={{ fontWeight: 900 }}>
                        Frais de Livraison
                      </Typography>
                    </Box>
                    {''}
                    {''}
                    {billDeliveryUser === undefined ? (
                      <Box className={classes.alignementScndContent1}>
                        <Typography variant="h6">-</Typography>
                        <Typography variant="h6">xxx</Typography>
                      </Box>
                    ) : (
                      billDeliveryUser &&
                      billDeliveryUser.order.payments.map(
                        (itemDelivery: any, index: number) => {
                          return (
                            <Box
                              className={classes.alignementScndContent1}
                              key={index}>
                              <Typography variant="h6">
                                {itemDelivery.shortDelivery?.dropoff?.address}
                              </Typography>
                              <Typography variant="h6">
                                {itemDelivery.amount}
                              </Typography>
                            </Box>
                          );
                        },
                      )
                    )}
                  </Box>
                  {''}
                  {''}
                  {billDeliveryUser === undefined ? (
                    <Box className={classes.contentDistance}>
                      <Box className={classes.alignementScndContent2}>
                        <Box className={classes.alignementScndContent}>
                          <Typography variant="h6" style={{ fontWeight: 900 }}>
                            Frais d&apos;approche
                          </Typography>
                          <Typography variant="h6" style={{ fontWeight: 900 }}>
                            xx Ar
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  ) : billDeliveryUser &&
                    billDeliveryUser.order.payments[0].delivery
                      ?.deliveryType === 'later' &&
                    billDeliveryUser.order.payments[0].delivery.vehiculeType
                      .forfaitProgram !== 0 ? (
                    <Box className={classes.contentDistance}>
                      <Box className={classes.alignementScndContent2}>
                        <Box className={classes.alignementScndContent}>
                          <Typography variant="h6" style={{ fontWeight: 900 }}>
                            Frais d&apos;approche
                          </Typography>
                          <Typography variant="h6" style={{ fontWeight: 900 }}>
                            {Math.round(
                              billDeliveryUser.order.payments[0].delivery
                                .vehiculeType.forfaitExpress -
                                (+billDeliveryUser.order.payments[0].delivery
                                  .vehiculeType.forfaitExpress *
                                  +billDeliveryUser.order.payments[0].delivery
                                    .vehiculeType.forfaitProgram) /
                                  100,
                            )}
                            Ar
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  ) : billDeliveryUser &&
                    billDeliveryUser.order.payments[0].delivery
                      ?.deliveryType === 'now' ? (
                    <Box className={classes.contentDistance}>
                      <Box className={classes.alignementScndContent2}>
                        <Box className={classes.alignementScndContent}>
                          <Typography variant="h6" style={{ fontWeight: 900 }}>
                            Frais d&apos;approche
                          </Typography>
                          <Typography variant="h6" style={{ fontWeight: 900 }}>
                            {
                              billDeliveryUser.order.payments[0].delivery
                                .vehiculeType.forfaitExpress
                            }
                            Ar
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  ) : null}

                  <Box className={classes.alignementScndContent}>
                    <Typography variant="h6" style={{ fontWeight: 900 }}>
                      Total
                    </Typography>
                    <Typography variant="h6" style={{ fontWeight: 900 }}>
                      {billDeliveryUser === undefined
                        ? 'XX XXX'
                        : billDeliveryUser && billDeliveryUser.amountTotal}{' '}
                      Ar
                    </Typography>
                  </Box>
                  <Box className={classes.alignementScndContent}>
                    <Typography variant="h6" style={{ fontWeight: 900 }}>
                      Numéro de facture &nbsp;:
                    </Typography>
                    <Typography variant="h6" style={{ fontWeight: 900 }}>
                      {billDeliveryUser === undefined
                        ? 'XX XXX'
                        : billDeliveryUser && billDeliveryUser.reference}{' '}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <IconButton
                    color="primary"
                    aria-label="add an alarm"
                    className={classes.buttonDownload}
                    disabled={loadingDelivery}
                    onClick={downloadPdfDelivery}>
                    {loadingDelivery ? (
                      <>
                        {' '}
                        <CircularProgress size={20}></CircularProgress>
                        Téléchargement
                      </>
                    ) : (
                      <>
                        {' '}
                        <GetAppIcon /> Télécharger
                      </>
                    )}
                  </IconButton>
                </Box>
              </Fragment>
            )}
          </Fragment>
        ) : state.selected === 'hotel' ? (
          <Fragment>
            {billHotelUser === undefined ? (
              <Fragment>Aucun historique disponible</Fragment>
            ) : (
              <Fragment>
                <Box>
                  <Typography className={classes.scndContentDate}>
                    Le &nbsp;:&nbsp; &nbsp;
                    {billHotelUser === undefined
                      ? '--/--/----'
                      : billHotelUser &&
                        moment(billHotelUser.datePayementBill).format(
                          'DD MMM YYYY',
                        )}
                  </Typography>
                  <Box>
                    <Box
                      className={classes.alignementScndContent1}
                      style={{
                        flexDirection: 'column',
                      }}>
                      <Typography variant="h6" style={{ color: '#6B6B6B' }}>
                        Description
                      </Typography>
                      <Typography
                        variant="h6"
                        style={{
                          color: '#6B6B6B',
                          fontWeight: 900,
                          textTransform: 'uppercase',
                        }}>
                        Réservation hôtel (
                        {billHotelUser === undefined
                          ? 'Hotel name'
                          : billHotelUser.order.payments[0].requestBooking
                          ? billHotelUser.order.payments[0].requestBooking
                              ?.hotel.name
                          : billHotelUser.order.payments[0].booking?.hotel.name}
                        )
                      </Typography>
                      <Typography variant="h6" style={{ color: '#6B6B6B' }}>
                        {billHotelUser === undefined
                          ? 'Hotel name'
                          : billHotelUser.order.payments[0].requestBooking
                          ? billHotelUser.order.payments[0].requestBooking
                              ?.hotel.city
                          : billHotelUser.order.payments[0].booking?.hotel.city}
                      </Typography>
                    </Box>
                    <Box
                      className={classes.alignementScndContent1}
                      style={{
                        padding: 0,
                        flexDirection: 'column',
                      }}>
                      <Typography variant="h6">
                        Du&nbsp;:&nbsp;
                        {billHotelUser === undefined
                          ? '0'
                          : billHotelUser.order.payments[0].requestBooking
                          ? billHotelUser.order &&
                            billHotelUser.order.payments[0].requestBooking
                              ?.dateFirst &&
                            moment(
                              billHotelUser.order.payments[0].requestBooking
                                ?.dateLast,
                            ).format('DD-MM-YYYY')
                          : billHotelUser.order &&
                            billHotelUser.order.payments[0].booking
                              ?.dateFirst &&
                            moment(
                              billHotelUser.order.payments[0].booking?.dateLast,
                            ).format('DD-MM-YYYY')}{' '}
                      </Typography>
                      <Typography variant="h6">
                        Au&nbsp;:&nbsp;
                        {billHotelUser === undefined
                          ? '0'
                          : billHotelUser.order.payments[0].requestBooking
                          ? billHotelUser.order &&
                            billHotelUser.order.payments[0].requestBooking
                              ?.dateFirst &&
                            moment(
                              billHotelUser.order.payments[0].requestBooking
                                ?.dateFirst,
                            ).format('DD-MM-YYYY')
                          : billHotelUser.order &&
                            billHotelUser.order.payments[0].booking
                              ?.dateFirst &&
                            moment(
                              billHotelUser.order.payments[0].booking
                                ?.dateFirst,
                            ).format('DD-MM-YYYY')}{' '}
                      </Typography>
                    </Box>

                    {billHotelUser.order.payments[0].requestBooking
                      ?.manifest ? (
                      <Box
                        className={classes.alignementScndContent1}
                        style={{
                          padding: 0,
                          flexDirection: 'column',
                        }}>
                        <Typography variant="h6">
                          {billHotelUser === undefined
                            ? '0'
                            : billHotelUser.order.payments[0].requestBooking
                                ?.manifest &&
                              JSON.parse(
                                billHotelUser.order.payments[0].requestBooking
                                  ?.manifest,
                              ).numberAdult}{' '}
                          Adulte
                        </Typography>
                        <Typography variant="h6">
                          {billHotelUser === undefined
                            ? '0'
                            : billHotelUser.order.payments[0].requestBooking
                                ?.manifest &&
                              JSON.parse(
                                billHotelUser.order.payments[0].requestBooking
                                  ?.manifest,
                              ).numberChildren}{' '}
                          Enfant de 2 à 12 ans{' '}
                        </Typography>
                        <Typography variant="h6">
                          {billHotelUser === undefined
                            ? '0'
                            : billHotelUser.order.payments[0].requestBooking
                                ?.manifest &&
                              JSON.parse(
                                billHotelUser.order.payments[0].requestBooking
                                  ?.manifest,
                              ).numberRoom}{' '}
                          chambres réservée(s){' '}
                        </Typography>{' '}
                      </Box>
                    ) : (
                      <Box
                        className={classes.alignementScndContent1}
                        style={{
                          padding: 0,
                          flexDirection: 'column',
                        }}>
                        <Typography variant="h6">
                          Nombre de chambre&nbsp;:&nbsp;
                          {billHotelUser === undefined
                            ? '0'
                            : billHotelUser.order.payments[0].booking
                                ?.numberofBooking &&
                              billHotelUser.order.payments[0].booking
                                ?.numberofBooking}{' '}
                        </Typography>
                      </Box>
                    )}
                  </Box>

                  <Box className={classes.contentDistance}>
                    <Box className={classes.alignementScndContent2}>
                      <Box className={classes.alignementScndContent}>
                        <Typography variant="h6" style={{ fontWeight: 900 }}>
                          {billHotelUser === undefined
                            ? 'Type de chambre'
                            : billHotelUser &&
                              billHotelUser.order.payments[0].booking?.room
                                .typeRoom.name}
                        </Typography>
                        <Typography variant="h6" style={{ fontWeight: 900 }}>
                          {billHotelUser === undefined
                            ? 'XX XXXX'
                            : billHotelUser &&
                              billHotelUser.order.payments[0].booking?.room
                                .rateDay
                            ? billHotelUser.order.payments[0].booking?.room
                                .rateDay
                            : billHotelUser.order.payments[0].requestBooking
                                ?.ResponseBooking[0].rateHotel}{' '}
                          Ar
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box className={classes.alignementScndContent}>
                    <Typography variant="h6" style={{ fontWeight: 900 }}>
                      Total
                    </Typography>
                    <Typography variant="h5" style={{ fontWeight: 900 }}>
                      {billHotelUser === undefined
                        ? 'XX XXX'
                        : billHotelUser && billHotelUser.amountTotal}{' '}
                      Ar
                    </Typography>
                  </Box>
                  <Box className={classes.alignementScndContent}>
                    <Typography variant="h6" style={{ fontWeight: 900 }}>
                      Numéro de facture &nbsp;:
                    </Typography>
                    <Typography variant="h6" style={{ fontWeight: 900 }}>
                      {billHotelUser === undefined
                        ? 'XX XXX'
                        : billHotelUser && billHotelUser.reference}{' '}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <IconButton
                    color="primary"
                    aria-label="add an alarm"
                    className={classes.buttonDownload}
                    disabled={loadingHotel}
                    onClick={downloadPdfHotel}>
                    {loadingHotel ? (
                      <>
                        {' '}
                        <CircularProgress size={20}></CircularProgress>
                        Téléchargement
                      </>
                    ) : (
                      <>
                        {' '}
                        <GetAppIcon /> Télécharger
                      </>
                    )}
                  </IconButton>
                </Box>
              </Fragment>
            )}
          </Fragment>
        ) : state.selected === 'vol' ? (
          <Fragment>
            {billFlightUser === undefined ? (
              <Fragment>Aucun historique disponible</Fragment>
            ) : (
              <Fragment>
                <Box>
                  <Typography className={classes.scndContentDate}>
                    Le &nbsp;:&nbsp; &nbsp;
                    {billFlightUser === undefined
                      ? '--/--/----'
                      : billFlightUser &&
                        moment(billFlightUser.datePayementBill).format(
                          'DD MMM YYYY',
                        )}
                  </Typography>
                  <Typography className={classes.scndContentDate}>
                    Référence &nbsp;:&nbsp; &nbsp;
                    {billFlightUser === undefined
                      ? '-'
                      : billFlightUser &&
                        billFlightUser.order.payments[0].requestBookingFlight
                          ?.reference}
                  </Typography>
                  <Box>
                    <Box>
                      <Box
                        className={classes.alignementScndContent1}
                        style={{
                          border: 'none',
                          margin: '10px 0',
                          padding: 0,
                        }}>
                        <Typography
                          variant="h5"
                          style={{ textTransform: 'uppercase' }}>
                          Aller :
                        </Typography>
                      </Box>
                      <Box
                        className={classes.alignementScndContent1}
                        style={{ border: 'none', margin: 0, padding: 0 }}>
                        <Typography variant="h6">
                          {' '}
                          {billFlightUser === undefined
                            ? '-'
                            : billFlightUser.order.payments[0].length === 0
                            ? '--'
                            : billFlightUser.order.payments[0]
                                .requestBookingFlight?.cityDeparture}
                        </Typography>
                        <Typography variant="h6">
                          {billFlightUser === undefined
                            ? '--/--/----'
                            : billFlightUser.order.payments[0].length === 0
                            ? '-/-/----'
                            : moment(
                                billFlightUser.order.payments[0]
                                  .requestBookingFlight
                                  ?.responseBookingFlights[0].dateFirstOneWay,
                              ).format('DD-MM-YYYY à HH:mm')}
                        </Typography>
                      </Box>
                      <Box
                        className={classes.alignementScndContent1}
                        style={{ border: 'none', margin: 0, padding: 0 }}>
                        <Typography variant="h6">
                          {billFlightUser === undefined
                            ? '-'
                            : billFlightUser.order.payments[0].length === 0
                            ? '--'
                            : billFlightUser.order.payments[0]
                                .requestBookingFlight?.cityArrival}
                        </Typography>
                        <Typography variant="h6">
                          {' '}
                          {billFlightUser === undefined
                            ? '--/--/----'
                            : billFlightUser.order.payments[0].length === 0
                            ? '-/-/----'
                            : billFlightUser.order &&
                              moment(
                                billFlightUser.order.payments[0]
                                  .requestBookingFlight
                                  ?.responseBookingFlights[0].dateLastOneWay,
                              ).format('DD-MM-YYYY à HH:mm')}
                        </Typography>
                      </Box>
                    </Box>

                    {billFlightUser === undefined ? (
                      '----'
                    ) : billFlightUser.order.payments[0].requestBookingFlight
                        ?.typeBooking === 'ONE_WAY' ? null : (
                      <Box>
                        <Box
                          className={classes.alignementScndContent1}
                          style={{
                            border: 'none',
                            margin: '10px 0',
                            padding: 0,
                          }}>
                          <Typography
                            variant="h5"
                            style={{ textTransform: 'uppercase' }}>
                            Retour :
                          </Typography>
                        </Box>
                        <Box
                          className={classes.alignementScndContent1}
                          style={{ border: 'none', margin: 0, padding: 0 }}>
                          <Typography variant="h6">
                            {' '}
                            {billFlightUser === undefined
                              ? '-'
                              : billFlightUser.order.payments[0].length === 0
                              ? '--'
                              : billFlightUser.order.payments[0]
                                  .requestBookingFlight?.cityArrival}
                          </Typography>
                          <Typography variant="h6">
                            {billFlightUser === undefined
                              ? '--/--/----'
                              : billFlightUser.order.payments[0].length === 0
                              ? '-/-/----'
                              : moment(
                                  billFlightUser.order.payments[0]
                                    .requestBookingFlight
                                    ?.responseBookingFlights[0]
                                    .dateFirstReturnWay,
                                ).format('DD-MM-YYYY à HH:mm')}
                          </Typography>
                        </Box>
                        <Box
                          className={classes.alignementScndContent1}
                          style={{ border: 'none', margin: 0, padding: 0 }}>
                          <Typography variant="h6">
                            {' '}
                            {billFlightUser === undefined
                              ? '-'
                              : billFlightUser.order.payments[0].length === 0
                              ? '--'
                              : billFlightUser.order.payments[0]
                                  .requestBookingFlight?.cityDeparture}
                          </Typography>
                          <Typography variant="h6">
                            {' '}
                            {billFlightUser === undefined
                              ? '--/--/----'
                              : billFlightUser.order.payments[0].length === 0
                              ? '-/-/----'
                              : moment(
                                  billFlightUser.order.payments[0]
                                    .requestBookingFlight
                                    ?.responseBookingFlights[0]
                                    .dateLastReturnWay,
                                ).format('DD-MM-YYYY à HH:mm')}
                          </Typography>
                        </Box>
                      </Box>
                    )}

                    <Box>
                      <Box
                        className={classes.alignementScndContent1}
                        style={{
                          border: 'none',
                          margin: '10px 0',
                          padding: 0,
                        }}>
                        <Typography variant="h6">
                          Compagnie Aerienne :
                        </Typography>
                        <Typography variant="h6">
                          {billFlightUser === undefined
                            ? '-'
                            : billFlightUser.order.payments[0].length === 0
                            ? '-'
                            : billFlightUser.order.payments[0]
                                .requestBookingFlight?.responseBookingFlights[0]
                                .nameOfCompagny}{' '}
                        </Typography>
                      </Box>
                      <Box
                        className={classes.alignementScndContent1}
                        style={{
                          border: 'none',
                          margin: '10px 0',
                          padding: 0,
                        }}>
                        <Typography
                          variant="h5"
                          style={{ textTransform: 'uppercase' }}>
                          Passager(s) :
                        </Typography>
                      </Box>
                      <Box
                        className={classes.alignementScndContent1}
                        style={{
                          padding: 0,
                          flexDirection: 'column',
                        }}>
                        <Typography variant="h6">
                          {billFlightUser === undefined
                            ? '0'
                            : billFlightUser.order.payments[0]
                                .requestBookingFlight?.manifest &&
                              JSON.parse(
                                billFlightUser.order.payments[0]
                                  .requestBookingFlight?.manifest,
                              ).numberAdult}{' '}
                          Adulte(s)
                        </Typography>
                        <Typography variant="h6">
                          {billFlightUser === undefined
                            ? '0'
                            : billFlightUser.order.payments[0]
                                .requestBookingFlight?.manifest &&
                              JSON.parse(
                                billFlightUser.order.payments[0]
                                  .requestBookingFlight?.manifest,
                              ).numberChildren}{' '}
                          Enfant(s) de 2 à 12 ans{' '}
                        </Typography>
                        <Typography variant="h6">
                          {billFlightUser === undefined
                            ? '0'
                            : billFlightUser.order.payments[0]
                                .requestBookingFlight?.manifest &&
                              JSON.parse(
                                billFlightUser.order.payments[0]
                                  .requestBookingFlight?.manifest,
                              ).numberBaby}{' '}
                          Bébé(s){' '}
                        </Typography>{' '}
                      </Box>
                    </Box>
                  </Box>
                  <Box className={classes.alignementScndContent}>
                    <Typography variant="h6" style={{ fontWeight: 900 }}>
                      Total
                    </Typography>
                    <Typography variant="h6" style={{ fontWeight: 900 }}>
                      {billFlightUser === undefined
                        ? 'XX XXX'
                        : billFlightUser && billFlightUser.amountTotal}{' '}
                      Ar
                    </Typography>
                  </Box>
                  <Box className={classes.alignementScndContent}>
                    <Typography variant="h6" style={{ fontWeight: 900 }}>
                      Numéro de facture&nbsp;:
                    </Typography>
                    <Typography variant="h6" style={{ fontWeight: 900 }}>
                      {billFlightUser === undefined
                        ? 'XX XXX'
                        : billFlightUser && billFlightUser.reference}{' '}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <IconButton
                    color="primary"
                    aria-label="download pdf"
                    className={classes.buttonDownload}
                    disabled={loadingVol}
                    onClick={downloadPdfVol}>
                    {loadingVol ? (
                      <>
                        {' '}
                        <CircularProgress size={20}></CircularProgress>
                        Téléchargement
                      </>
                    ) : (
                      <>
                        {' '}
                        <GetAppIcon /> Télécharger
                      </>
                    )}
                  </IconButton>
                </Box>
              </Fragment>
            )}
          </Fragment>
        ) : null}
      </Box>
    </Box>
  );
};
export default MakeReservation;

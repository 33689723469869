import {
  Box,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from '@material-ui/core';
import moment, { now } from 'moment';
import React, { FC, useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import DateAndTime from '../../Components/DateAndTime';
import useStyles from './style';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import ButtonVol from './ButtonVol';
import { UserContext } from '../../Providers/User';
import { useCreateBookingVol } from './booking';
import Backdrop from '../../Components/Backdrop';
import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/react-hooks';
import SnackVariableInterface from '../../Interfaces/SnackVariableInterface';
import { displaySnackBar } from '../../Utils/snackBarUtils';
import UploadFiles from '../../Components/UploadFiles';
import { useUploadFile } from '../../Utils/uploadFIle';
import { DO_CREATE_DOCUMENT_USER } from '../../Graphql/UploadFile/mutation';
import {
  CREATE_DOCUMENT_USER,
  CREATE_DOCUMENT_USERVariables,
} from '../../Graphql/UploadFile/types/CREATE_DOCUMENT_USER';
import { GET_VOL, GET_VOL_MG } from '../../Graphql/Vol/query';
import {
  VOL_MG,
  VOL_MGVariables,
  VOL_MG_aeroportFilterMg,
} from '../../Graphql/Vol/types/VOL_MG';
import {
  VOL,
  VOLVariables,
  VOL_aeroportFilter,
} from '../../Graphql/Vol/types/VOL';
import { Autocomplete } from '@material-ui/lab';
import _ from 'lodash';
import groupAeroportsByMunicipality from './utils/groupAeroportsByMunicipality';
import AeroportSuggestion from './AeroportSuggestion/AeroportSuggestion';

const INPUT_TIMOUT = 1000;

const ReservationVol: FC = () => {
  const classes = useStyles();
  const { doCreateBooking, loading } = useCreateBookingVol();
  const client = useApolloClient();
  const [buttonSelected, setButtonSelected] = useState('2');
  const [buttonSelectedVolType, setButtonSelectedVolType] = useState('1');
  const [typeFlight, setTypeFlight] = useState('VOL_NATIONAL');
  const [dateDepart, setDepartDate] = useState<Date | null>(new Date());
  const [dateArrive, setArriveDate] = useState<Date | null>(new Date());
  const [comment, setcomment] = useState('');
  const [adultNumber, setAdulteNumber] = useState(0);
  const [enfantNumber, setEnfantNumber] = useState(0);
  const [bebeNumber, setBebeNumber] = useState(0);
  const { customerId, idMe } = useContext(UserContext);
  const dateNow = new Date();
  const [waitEventDepart, setWaitEventDepart] = useState<any>(null);
  const [waitEventArrivee, setWaitEventArrivee] = useState<any>(null);
  dateNow.setDate(dateNow.getDate() - 1);

  const { uploadFile, getFiles, loading: loadingFile } = useUploadFile();
  const [files, setFiles] = useState<File[] | null>(null);

  const [doCreateDocument] = useMutation<
    CREATE_DOCUMENT_USER,
    CREATE_DOCUMENT_USERVariables
  >(DO_CREATE_DOCUMENT_USER, {
    onError: (err) => {
      if (err.networkError?.message === 'Failed to fetch') {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Problème de connexion...',
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      }
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: err.message.split(':')[1],
        isOpen: true,
      };
      return displaySnackBar(client, snackBarData);
    },
    onCompleted: async (data) => {
      await uploadFile(files);
      setFiles([]);
    },
  });

  const gotoRequestVol = () => {
    if (
      volDepartMg?.name.trim().length === undefined &&
      volDepartInternational?.name.trim().length === undefined
    ) {
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: 'Veuillez entrer un point de départ valide...',
        isOpen: true,
      };
      displaySnackBar(client, snackBarData);
    } else if (
      volArriveMg?.name.trim().length === undefined &&
      volArriveInternational?.name.trim().length === undefined
    ) {
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: 'Veuillez entrer un point de destination valide...',
        isOpen: true,
      };
      displaySnackBar(client, snackBarData);
    } else if (adultNumber + enfantNumber + bebeNumber === 0) {
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: 'Veuillez vérifier le nombre de passager...',
        isOpen: true,
      };
      displaySnackBar(client, snackBarData);
    } else if (
      (volDepartMg?.name.trim().length !== undefined &&
        volDepartMg?.name.trim() === volArriveMg?.name.trim()) ||
      (volDepartInternational?.name.trim().length !== undefined &&
        volDepartInternational?.name.trim() ===
          volArriveInternational?.name.trim())
    ) {
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: 'Veuillez vérifier la destination...',
        isOpen: true,
      };
      displaySnackBar(client, snackBarData);
    } else if (dateDepart && dateDepart < dateNow) {
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: 'Veuillez vérifier la date...',
        isOpen: true,
      };
      displaySnackBar(client, snackBarData);
    } else if (!comment) {
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message:
          'Il est mieux de renseigner les informations suggérées dans la section commentaires',
        isOpen: true,
      };
      displaySnackBar(client, snackBarData);
    } else {
      if (buttonSelected === '2') {
        if (files !== null) {
          doCreateBooking({
            variables: {
              customerId,
              typeBooking: 'RETURN_WAY',
              dateRequestBooking: moment(now()).unix(),
              dateFirst: moment(dateDepart).unix(),
              dateLast: moment(dateArrive).unix(),
              comment: comment,
              cityDeparture:
                buttonSelectedVolType === '1'
                  ? volDepartMg?.name ?? ''
                  : volDepartInternational?.name ?? '',
              cityArrival:
                buttonSelectedVolType === '1'
                  ? volArriveMg?.name ?? ''
                  : volArriveInternational?.name ?? '',
              numberofPassenger: adultNumber + enfantNumber + bebeNumber,
              manifest: {
                numberAdult: adultNumber,
                numberChildren: enfantNumber,
                numberBaby: bebeNumber,
              },
              typeFlight: typeFlight,
            },
          }).then(async (result) => {
            setVolDepartMg(null);
            setVolArriveMg(null);
            setVolDepartInternational(null);
            setVolArriveInternational(null);
            const filesUpload = await getFiles(files);
            doCreateDocument({
              variables: {
                userId: idMe,
                requestBookingFlightId:
                  result.data?.createRequestBookingFlight.requestBookingFlight
                    .id,
                files: filesUpload,
              },
            });
          });
        } else {
          const snackBarData: SnackVariableInterface = {
            type: 'ERROR',
            message: 'Veuillez inserer vos documents...',
            isOpen: true,
          };
          displaySnackBar(client, snackBarData);
        }
      } else {
        if (files !== null) {
          doCreateBooking({
            variables: {
              customerId,
              typeBooking: 'ONE_WAY',
              dateRequestBooking: moment(now()).unix(),
              dateFirst: moment(dateDepart).unix(),
              comment: comment,
              cityDeparture:
                buttonSelectedVolType === '1'
                  ? volDepartMg?.name ?? ''
                  : volDepartInternational?.name ?? '',
              cityArrival:
                buttonSelectedVolType === '1'
                  ? volArriveMg?.name ?? ''
                  : volArriveInternational?.name ?? '',
              numberofPassenger: adultNumber + enfantNumber + bebeNumber,
              manifest: {
                numberAdult: adultNumber,
                numberChildren: enfantNumber,
                numberBaby: bebeNumber,
              },
              typeFlight: typeFlight,
            },
          }).then(async (result) => {
            setVolDepartMg(null);
            setVolArriveMg(null);
            setVolDepartInternational(null);
            setVolArriveInternational(null);
            const filesUpload = await getFiles(files);
            doCreateDocument({
              variables: {
                userId: idMe,
                requestBookingFlightId:
                  result.data?.createRequestBookingFlight.requestBookingFlight
                    .id,
                files: filesUpload,
              },
            });
          });
        } else {
          const snackBarData: SnackVariableInterface = {
            type: 'ERROR',
            message: 'Veuillez inserer vos documents...',
            isOpen: true,
          };
          displaySnackBar(client, snackBarData);
        }
      }
    }
  };

  const incrementAdult = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    event.preventDefault();
    setAdulteNumber((prevValue) => {
      return prevValue + 1;
    });
  };

  const [doGetVolMgDepart, { data: dataVolFilterMgDepart }] = useLazyQuery<
    VOL_MG,
    VOL_MGVariables
  >(GET_VOL_MG);

  const [doGetVolMgArrive, { data: dataVolFilterMgArrive }] = useLazyQuery<
    VOL_MG,
    VOL_MGVariables
  >(GET_VOL_MG);
  const formatDataVol = (data: any[] | undefined) => {
    if (data) {
      return data.map((item: any) => ({
        ...item,
        name: `${item.name} - ${item.iata}`,
      }));
    }
    return [];
  };
  const [
    doGetVolDepartInternational,
    { data: dataVolFilterVolDepartInternational },
  ] = useLazyQuery<VOL, VOLVariables>(GET_VOL);

  const [
    doGetVolArriveInternational,
    { data: dataVolFilterVolArriveInternational },
  ] = useLazyQuery<VOL, VOLVariables>(GET_VOL);

  const [
    volDepartMg,
    setVolDepartMg,
  ] = useState<VOL_MG_aeroportFilterMg | null>();

  const [
    volArriveMg,
    setVolArriveMg,
  ] = useState<VOL_MG_aeroportFilterMg | null>();

  const [
    volDepartInternational,
    setVolDepartInternational,
  ] = useState<VOL_aeroportFilter | null>();

  const [
    volArriveInternational,
    setVolArriveInternational,
  ] = useState<VOL_aeroportFilter | null>();

  const decrementAdult = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    event.preventDefault();
    setAdulteNumber((prevValue) => {
      return prevValue - 1;
    });
  };

  const incrementEnfant = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    event.preventDefault();
    setEnfantNumber((prevValueEnfant) => {
      return prevValueEnfant + 1;
    });
  };

  const decrementEnfant = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    event.preventDefault();
    setEnfantNumber((prevValueEnfant) => {
      return prevValueEnfant - 1;
    });
  };

  const incrementChambre = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    event.preventDefault();
    setBebeNumber((prevValueChambre) => {
      return prevValueChambre + 1;
    });
  };

  const decrementChambre = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    event.preventDefault();
    setBebeNumber((preValueChambre) => {
      return preValueChambre - 1;
    });
  };

  const onChangeType = (id: string) => {
    setButtonSelected(id);
  };

  const onChangeTypeVol = (id: string) => {
    setButtonSelectedVolType(id);
    if (id === '1') {
      setTypeFlight('VOL_NATIONAL');
      setVolDepartMg(null);
      setVolArriveMg(null);
    } else {
      setTypeFlight('VOL_INTERNATIONAL');
      setVolDepartInternational(null);
      setVolArriveInternational(null);
    }
  };

  const onChangeFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filesConcat = Array.from(event.target.files || []);
    if (files === null) {
      setFiles(filesConcat);
    } else {
      setFiles((prevState) => {
        if (prevState !== null) {
          return [...prevState, ...filesConcat];
        }
        return [];
      });
    }
  };

  const onDeleteFile = (name: string) => {
    const filesFilter = files?.filter((file) => file.name !== name) || [];
    setFiles(filesFilter);
  };

  const getOptionLabel = (option: VOL_aeroportFilter) => {
    if (option.iata) return `${option.name} - ${option.iata}`;
    return option.name;
  };

  const renderOption = (option: VOL_aeroportFilter) => (
    <AeroportSuggestion aeroport={option} />
  );

  const onChangeDepart = (e: any) =>
    doGetVolDepartInternational({
      variables: { name: e.target.value },
    });

  const onChangeArrive = (e: any) =>
    doGetVolArriveInternational({
      variables: { name: e.target.value },
    });

  useEffect(() => {
    let timer: any = 0;
    if (waitEventDepart) {
      timer = setTimeout(() => onChangeDepart(waitEventDepart), INPUT_TIMOUT);
    }
    return () => clearTimeout(timer);
  }, [waitEventDepart]);

  useEffect(() => {
    let timer: any = 0;
    if (waitEventArrivee) {
      timer = setTimeout(() => onChangeArrive(waitEventArrivee), INPUT_TIMOUT);
    }
    return () => clearTimeout(timer);
  }, [waitEventArrivee]);

  return (
    <Box className={classes.allContent}>
      <Backdrop open={loading || loadingFile} />
      <Box className={classes.firstContentButton}>
        <ButtonVol
          id={'1'}
          title={'Vol national'}
          selectedId={buttonSelectedVolType}
          onClick={() => onChangeTypeVol('1')}
        />
        <ButtonVol
          id={'2'}
          title={'Vol international'}
          selectedId={buttonSelectedVolType}
          onClick={() => onChangeTypeVol('2')}
        />
      </Box>
      <Box className={classes.firstContentButton}>
        <ButtonVol
          id={'1'}
          title={'Aller simple'}
          selectedId={buttonSelected}
          onClick={() => onChangeType('1')}
        />
        <ButtonVol
          id={'2'}
          title={'Aller retour'}
          selectedId={buttonSelected}
          onClick={() => onChangeType('2')}
        />
      </Box>
      {buttonSelectedVolType === '1' ? (
        <Box className={classes.contentDatePicker} key={buttonSelectedVolType}>
          <Box className={classes.contentInput}>
            <Box className={classes.contentSelectFirst}>
              <Box className={classes.contentSelect}>
                <Box>
                  <InputLabel>Aéroport de départ (National)</InputLabel>
                  <Autocomplete
                    className={classes.selectEmpty}
                    id="name1"
                    options={formatDataVol(
                      (dataVolFilterMgDepart &&
                        dataVolFilterMgDepart.aeroportFilterMg) ||
                        [],
                    )}
                    getOptionLabel={(option: VOL_MG_aeroportFilterMg) =>
                      option.name
                    }
                    freeSolo
                    onChange={(event, value: any) => {
                      setVolDepartMg(value);
                    }}
                    value={volDepartMg}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled={false}
                        variant="filled"
                        placeholder="Aéroport de départ"
                        style={{ marginBottom: 0 }}
                        value={volDepartMg?.name}
                        onChange={(e) =>
                          doGetVolMgDepart({
                            variables: { name: e.target.value },
                          })
                        }
                        fullWidth
                      />
                    )}
                  />
                </Box>
                <Box>
                  <InputLabel>Aéroport d&apos;arrivée (National)</InputLabel>

                  <Autocomplete
                    className={classes.selectEmpty}
                    id="name2"
                    options={formatDataVol(
                      (dataVolFilterMgArrive &&
                        dataVolFilterMgArrive.aeroportFilterMg) ||
                        [],
                    )}
                    getOptionLabel={(option: VOL_MG_aeroportFilterMg) =>
                      option.name
                    }
                    freeSolo
                    onChange={(event, value: any) => {
                      setVolArriveMg(value);
                    }}
                    value={volArriveMg}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled={false}
                        variant="filled"
                        placeholder="Aéroport d' arrivée"
                        style={{ marginBottom: 0 }}
                        value={volArriveMg?.name}
                        onChange={(e) =>
                          doGetVolMgArrive({
                            variables: { name: e.target.value },
                          })
                        }
                        fullWidth
                      />
                    )}
                  />
                </Box>
              </Box>
              <Box className={classes.contentOption}>
                <DateAndTime
                  titre="DÉPART"
                  setValue={setDepartDate}
                  value={moment(dateDepart).format(' DDDD DD MMM YYYY')}
                />

                {buttonSelected === '2' && (
                  <DateAndTime
                    titre="RETOUR"
                    setValue={setArriveDate}
                    value={moment(dateArrive).format(' DDDD DD MMM YYYY')}
                  />
                )}
              </Box>
            </Box>

            <Box className={classes.contentPassager}>
              <Box>
                <InputLabel>Passagers</InputLabel>
                <TextField
                  id="standard-read-only-input"
                  label=""
                  multiline
                  rows={8}
                  style={{
                    maxHeight: 43,
                    textAlign: 'center',
                    paddingTop: 8,
                    paddingLeft: 20,
                  }}
                  value={adultNumber + enfantNumber + bebeNumber}
                  defaultValue=""
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Box>
              <Box className={classes.contentInput1}>
                <Box className={classes.contentInputAlign}>
                  <label className={classes.colorLabel}>Adulte(s)</label>
                  <Box className={classes.contentIncrement}>
                    <IconButton
                      color="primary"
                      disabled={adultNumber === 0}
                      onClick={decrementAdult}
                      href="#">
                      <RemoveIcon />
                    </IconButton>
                    <span>{adultNumber}</span>
                    <IconButton
                      color="primary"
                      onClick={incrementAdult}
                      href="#">
                      <AddIcon />
                    </IconButton>
                  </Box>
                </Box>
                <Box className={classes.contentInputAlign}>
                  <label className={classes.colorLabel}>Enfant(s)</label>
                  <Box className={classes.contentIncrement}>
                    <IconButton
                      color="primary"
                      disabled={enfantNumber === 0}
                      onClick={decrementEnfant}
                      href="#">
                      <RemoveIcon />
                    </IconButton>
                    <span>{enfantNumber}</span>
                    <IconButton
                      color="primary"
                      onClick={incrementEnfant}
                      href="#">
                      <AddIcon />
                    </IconButton>
                  </Box>
                </Box>
                <Box className={classes.contentInputAlign}>
                  <label className={classes.colorLabel}>Bébé(s)</label>
                  <Box className={classes.contentIncrement}>
                    <IconButton
                      color="primary"
                      disabled={bebeNumber === 0}
                      // classes={{ root }}
                      onClick={decrementChambre}
                      href="#">
                      <RemoveIcon />
                    </IconButton>
                    <span>{bebeNumber}</span>
                    <IconButton
                      color="primary"
                      onClick={incrementChambre}
                      href="#">
                      <AddIcon />
                    </IconButton>
                  </Box>
                </Box>

                <UploadFiles
                  files={files}
                  onChangeFiles={onChangeFiles}
                  onClickFile={onDeleteFile}
                />
              </Box>
            </Box>
          </Box>
          <Box>
            <TextField
              multiline
              label=""
              id="filled-margin-normal"
              placeholder="Pour accélerer le traitement de votre demande, merci de mentionner votre numéro de téléphone et votre e-mail ainsi que votre classe de résérvation ( économique, business )"
              className={classes.textField}
              margin="normal"
              variant="filled"
              rows={7}
              onChange={(e) => {
                setcomment(e.target.value);
              }}
            />
          </Box>
          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={gotoRequestVol}>
              Confirmer
            </Button>
          </Box>
        </Box>
      ) : (
        <Box className={classes.contentDatePicker} key={buttonSelectedVolType}>
          <Box className={classes.contentInput}>
            <Box className={classes.contentSelectFirst}>
              <Box className={classes.contentSelect}>
                <Box>
                  <InputLabel>Aéroport de départ (International)</InputLabel>
                  <Autocomplete
                    className={classes.selectEmpty}
                    id="name3"
                    options={groupAeroportsByMunicipality(
                      dataVolFilterVolDepartInternational,
                    )}
                    getOptionLabel={getOptionLabel}
                    getOptionDisabled={(option) => !option.id}
                    autoComplete={true}
                    freeSolo
                    onChange={(event, value: any) => {
                      setVolDepartInternational(value);
                    }}
                    value={volDepartInternational}
                    filterOptions={(options) => options}
                    renderOption={renderOption}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled={false}
                        variant="filled"
                        placeholder="Aéroport de départ international"
                        style={{ marginBottom: 0 }}
                        value={volDepartInternational?.name}
                        onChange={(e) => {
                          e.persist();
                          setWaitEventDepart(e);
                        }}
                        fullWidth
                      />
                    )}
                  />
                </Box>
                <Box>
                  <InputLabel>
                    Aéroport d&apos;arrivée (International)
                  </InputLabel>
                  <Autocomplete
                    className={classes.selectEmpty}
                    id="name4"
                    options={groupAeroportsByMunicipality(
                      dataVolFilterVolArriveInternational,
                    )}
                    getOptionLabel={getOptionLabel}
                    getOptionDisabled={(option) => !option.id}
                    filterOptions={(options) => options}
                    renderOption={renderOption}
                    freeSolo
                    onChange={(event, value: any) => {
                      setVolArriveInternational(value);
                    }}
                    value={volArriveInternational}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled={false}
                        variant="filled"
                        placeholder="Aéroport d'arrivée international"
                        style={{ marginBottom: 0 }}
                        value={volArriveInternational?.name}
                        onChange={(e) => {
                          e.persist();
                          setWaitEventArrivee(e);
                        }}
                        fullWidth
                      />
                    )}
                  />
                </Box>
              </Box>
              <Box className={classes.contentOption}>
                <DateAndTime
                  titre="DÉPART"
                  setValue={setDepartDate}
                  value={moment(dateDepart).format(' DDDD DD MMM YYYY')}
                />

                {buttonSelected === '2' && (
                  <DateAndTime
                    titre="RETOUR"
                    setValue={setArriveDate}
                    value={moment(dateArrive).format(' DDDD DD MMM YYYY')}
                  />
                )}
              </Box>
            </Box>

            <Box className={classes.contentPassager}>
              <Box>
                <InputLabel>Passagers</InputLabel>
                <TextField
                  id="standard-read-only-input"
                  label=""
                  multiline
                  rows={8}
                  style={{
                    maxHeight: 43,
                    textAlign: 'center',
                    paddingTop: 8,
                    paddingLeft: 20,
                  }}
                  value={adultNumber + enfantNumber + bebeNumber}
                  defaultValue=""
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Box>
              <Box className={classes.contentInput1}>
                <Box className={classes.contentInputAlign}>
                  <label className={classes.colorLabel}>Adulte(s)</label>
                  <Box className={classes.contentIncrement}>
                    <IconButton
                      color="primary"
                      disabled={adultNumber === 0}
                      onClick={decrementAdult}
                      href="#">
                      <RemoveIcon />
                    </IconButton>
                    <span>{adultNumber}</span>
                    <IconButton
                      color="primary"
                      onClick={incrementAdult}
                      href="#">
                      <AddIcon />
                    </IconButton>
                  </Box>
                </Box>
                <Box className={classes.contentInputAlign}>
                  <label className={classes.colorLabel}>Enfant(s)</label>
                  <Box className={classes.contentIncrement}>
                    <IconButton
                      color="primary"
                      disabled={enfantNumber === 0}
                      onClick={decrementEnfant}
                      href="#">
                      <RemoveIcon />
                    </IconButton>
                    <span>{enfantNumber}</span>
                    <IconButton
                      color="primary"
                      onClick={incrementEnfant}
                      href="#">
                      <AddIcon />
                    </IconButton>
                  </Box>
                </Box>
                <Box className={classes.contentInputAlign}>
                  <label className={classes.colorLabel}>Bébé(s)</label>
                  <Box className={classes.contentIncrement}>
                    <IconButton
                      color="primary"
                      disabled={bebeNumber === 0}
                      // classes={{ root }}
                      onClick={decrementChambre}
                      href="#">
                      <RemoveIcon />
                    </IconButton>
                    <span>{bebeNumber}</span>
                    <IconButton
                      color="primary"
                      onClick={incrementChambre}
                      href="#">
                      <AddIcon />
                    </IconButton>
                  </Box>
                </Box>

                <UploadFiles
                  files={files}
                  onChangeFiles={onChangeFiles}
                  onClickFile={onDeleteFile}
                />
              </Box>
            </Box>
          </Box>
          <Box>
            <TextField
              multiline
              label=""
              id="filled-margin-normal"
              placeholder="Pour accélerer le traitement de votre demande, merci de mentionner votre numéro de téléphone et votre e-mail ainsi que votre classe de résérvation ( économique, business )"
              className={classes.textField}
              margin="normal"
              variant="filled"
              rows={7}
              onChange={(e) => {
                setcomment(e.target.value);
              }}
            />
          </Box>
          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={gotoRequestVol}>
              Confirmer
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default ReservationVol;

import React from 'react';
import DeliveryInput from './DeliveryInput';

const Deliveries = () => {
  return (
    <>
      <DeliveryInput />
    </>
  );
};

export default Deliveries;

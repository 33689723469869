import React, { useState } from 'react';
import { createContext, FC } from 'react';
import { FILTER_HOTELS_filterHotels } from '../Graphql/Hotel/types/FILTER_HOTELS';
import { SEARCH_HOTEL_searchHotels } from '../Graphql/Hotel/types/SEARCH_HOTEL';

interface HotelFilterInfoProps {
  motCle: string;
  dateArriveContext: Date | null;
  dateDepartContext: Date | null;
  numberStars: number;
  indexOfRooms: number;
  hotelFilter: SEARCH_HOTEL_searchHotels[] | FILTER_HOTELS_filterHotels[];
  setHotelFilter: (
    hotel: SEARCH_HOTEL_searchHotels[] | FILTER_HOTELS_filterHotels[],
  ) => void;
  setMotCle: (motCle: string) => void;
  setDateDepartContext: (dateDepart: Date | null) => void;
  setDateArriveContext: (dateArrive: Date | null) => void;
  clearHotelFilter: () => void;
  setNumberStars: (numberStars: number) => void;
  setIndexOfRooms: (indexOfRooms: number) => void;
}

export const HotelFilterContext = createContext<HotelFilterInfoProps>({
  hotelFilter: [],
  motCle: '',
  dateArriveContext: null,
  dateDepartContext: null,
  numberStars: 0,
  indexOfRooms: 0,
  setMotCle: () => {},
  setDateArriveContext: () => {},
  setDateDepartContext: () => {},
  setHotelFilter: () => {},
  clearHotelFilter: () => {},
  setNumberStars: () => {},
  setIndexOfRooms: () => {},
});

export const HotelFilterProvider: FC = ({ children }) => {
  const [hotelFilter, setHotelFilter] = useState<
    SEARCH_HOTEL_searchHotels[] | FILTER_HOTELS_filterHotels[]
  >([]);
  const [motCle, setMotCle] = useState<string>('');
  const [dateDepartContext, setDateDepartContext] = useState<Date | null>(null);
  const [dateArriveContext, setDateArriveContext] = useState<Date | null>(null);
  const [numberStars, setNumberStars] = useState<number>(0);
  const [indexOfRooms, setIndexOfRooms] = useState<number>(0);

  return (
    <HotelFilterContext.Provider
      value={{
        hotelFilter,
        motCle,
        dateArriveContext,
        dateDepartContext,
        numberStars,
        indexOfRooms,
        setMotCle,
        setDateArriveContext,
        setDateDepartContext,
        setHotelFilter,
        setNumberStars,
        setIndexOfRooms,
        clearHotelFilter: () => {
          setHotelFilter([]);
          setMotCle('');
          setDateArriveContext(null);
          setDateDepartContext(null);
          setNumberStars(0);
          setIndexOfRooms(0);
        },
      }}>
      {children}
    </HotelFilterContext.Provider>
  );
};

import React, { useState } from 'react';
import {
  Paper,
  IconButton,
  Menu,
  MenuItem,
  Box,
  PaperProps,
  Typography,
} from '@material-ui/core';
import { Settings as SettingIcon } from '@material-ui/icons';

// styles
import useStyles from './style';
import Options from './Options/Options';

interface IWidget {
  title?: string;
  disableWidgetMenu: boolean;
  children: any;
  optionsDate?: boolean;
  optionsType?: boolean;
}

const Widget = (props: IWidget & PaperProps) => {
  const {
    children,
    title,
    disableWidgetMenu,
    optionsDate,
    optionsType,
  } = props;

  const [height, setHeight] = useState(0);
  React.useEffect(() => {
    window.addEventListener('load', () =>
      setHeight(window ? window.innerHeight : 0),
    );
    window.addEventListener('resize', () =>
      setHeight(window ? window.innerHeight : 0),
    );
  }, [height]);

  const classes = useStyles({ height });
  const [moreButtonRef, setMoreButtonRef] = useState(null);
  const [isMoreMenuOpen, setMoreMenuOpen] = useState(false);

  const OptionsDate = <Options type="periode" />;
  const OptionsType = <Options type="vehicule" />;

  return (
    <Paper
      {...props}
      classes={{ root: classes.paper }}
      elevation={props.elevation ? props.elevation : 0}>
      {title && (
        <Box className={classes.header}>
          <Typography variant="h1">{title}</Typography>
          {optionsDate && OptionsDate}
          {optionsType && OptionsType}
          {!disableWidgetMenu && (
            <>
              <IconButton
                color="primary"
                aria-owns="widget-menu"
                aria-haspopup="true"
                onClick={() => setMoreMenuOpen(true)}
                buttonRef={setMoreButtonRef}
                size="small">
                <SettingIcon />
              </IconButton>
              <Menu
                id="widget-menu"
                open={isMoreMenuOpen}
                anchorEl={moreButtonRef}
                onClose={() => setMoreMenuOpen(false)}
                disableAutoFocusItem>
                <MenuItem>
                  <Typography>Edit</Typography>
                </MenuItem>
                <MenuItem>
                  <Typography>Copy</Typography>
                </MenuItem>
                <MenuItem>
                  <Typography>Delete</Typography>
                </MenuItem>
                <MenuItem>
                  <Typography>Print</Typography>
                </MenuItem>
              </Menu>
            </>
          )}
        </Box>
      )}
      <Box className={classes.body}>{children}</Box>
    </Paper>
  );
};
export default Widget;

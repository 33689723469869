import React, { FC, useContext } from 'react';
import { Box, TextField, Typography } from '@material-ui/core';
import TextInput from '../../../../Components/TextInput';
import styles from '../style';
import SearchInMap from '../../../../Components/MapComponent/SearchInMap';
import { ExpediteurContext } from '../../../../Providers/Expediteur';
import { useQuery } from '@apollo/react-hooks';
import { DO_ADDRESS_BOOKS_PICKUP } from '../../../../Graphql/Delivery/query';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ExpediteurLocationContext } from '../../../../Providers/ExpediteurLocation';
import {
  ADDRESS_BOOKS_PICKUP,
  ADDRESS_BOOKS_PICKUPVariables,
  ADDRESS_BOOKS_PICKUP_addressBookUserPickup,
} from '../../../../Graphql/Delivery/types/ADDRESS_BOOKS_PICKUP';
import { UserContext } from '../../../../Providers/User';

const DeliveryInput: FC = () => {
  const {
    setNameExpediteur,
    setPhonexpediteur,
    setAdressExpediteur,
    setIdExpediteur,
    setAdresseBookIdExpediteur,
    name,
    phone,
    address,
    clearExpediteur,
  } = useContext(ExpediteurContext);
  const {
    setLatitudeExpediteurLocation,
    setLongitudeExpediteurLocation,
  } = useContext(ExpediteurLocationContext);

  const title = 'Choisir un point de départ';
  const { customerId } = useContext(UserContext);
  const classes = styles();

  const { data, refetch } = useQuery<
    ADDRESS_BOOKS_PICKUP,
    ADDRESS_BOOKS_PICKUPVariables
  >(DO_ADDRESS_BOOKS_PICKUP, {
    skip: customerId ? false : true,
    variables: { id: customerId },
  });

  const onChangeExpediteur = (
    value: string | ADDRESS_BOOKS_PICKUP_addressBookUserPickup | null,
  ) => {
    if (value === null) {
      setIdExpediteur('');
      clearExpediteur();
      setLatitudeExpediteurLocation(0);
      setLongitudeExpediteurLocation(0);
      clearExpediteur();
    } else {
      const { pickup, id, name, address, phone } = value as any;
      const lng = Number(JSON.parse(pickup.location).coordinates[0]);
      const lat = Number(JSON.parse(pickup.location).coordinates[1]);
      if (pickup.id) {
        setIdExpediteur(pickup.id);
      }
      setAdresseBookIdExpediteur(id || '');
      setNameExpediteur(name || '');
      setAdressExpediteur(address || '');
      setPhonexpediteur(phone || '');
      setLatitudeExpediteurLocation(lat);
      setLongitudeExpediteurLocation(lng);
    }
  };

  const onChangeTextInput = (text: string) => {
    setIdExpediteur('');
    clearExpediteur();
    setLatitudeExpediteurLocation(0);
    setLongitudeExpediteurLocation(0);
    setNameExpediteur(text);
  };

  const defaultProps = {
    options:
      (data && data.addressBookUserPickup && data.addressBookUserPickup) || [],
    getOptionLabel: (option: ADDRESS_BOOKS_PICKUP_addressBookUserPickup) =>
      option.name,
  };

  return (
    <Box position="relative" mb={1}>
      <Typography variant="h2">{title}</Typography>
      <Box className={classes.flex}>
        <Autocomplete
          id="name"
          {...defaultProps}
          freeSolo
          onChange={(event, value) => {
            onChangeExpediteur(value);
          }}
          onFocus={() => {
            refetch();
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="filled"
              placeholder="Prénom et nom"
              style={{ marginBottom: 0 }}
              value={name}
              onChange={(e) => onChangeTextInput(e.target.value)}
              fullWidth
            />
          )}
        />
        <TextInput
          type="phone"
          variant="filled"
          name="Téléphone"
          placeholder="Téléphone"
          value={phone}
          onChange={(e) => setPhonexpediteur(e.target.value)}
        />
      </Box>

      <SearchInMap />

      <Box className={classes.root}>
        <TextInput
          type="text"
          variant="filled"
          name="Adresse"
          placeholder="Adresse ou point de repère"
          value={address}
          onChange={(e) => setAdressExpediteur(e.target.value)}
        />
      </Box>
    </Box>
  );
};

export default DeliveryInput;

import React, { FC } from 'react';
import { Typography, Box, Button } from '@material-ui/core';
import useStyles from './style';
import logo from '../../Images/logo.svg';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import User from '../../Components/Icons/User';
import Bag from '../../Components/Icons/Bag';
import CustomLink from '../../Components/CustomLink';
import { Link } from 'react-router-dom';

const ChoiceAccountToCreate: FC<RouteComponentProps> = ({ history }) => {
  const classes = useStyles();

  const onClickParticularAccount = () => {
    history.push('/particularaccount');
  };

  const onClickProfessionnalAccount = () => {
    history.push('/professionnalaccount');
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.left}>
        <Box className={classes.logo}>
          <img src={logo} alt="loo" />
        </Box>

        <Box className={classes.login}>
          <Typography variant="h1">Choisissez votre type de compte</Typography>

          <Button
            startIcon={<User />}
            variant="contained"
            color="primary"
            onClick={onClickParticularAccount}>
            Particulier
          </Button>
          <Typography>ou</Typography>

          <Button
            startIcon={<Bag />}
            variant="contained"
            color="primary"
            onClick={onClickProfessionnalAccount}>
            Professionnel
          </Button>

          <Typography variant="subtitle2">
            Si vous avez déjà un compte,
          </Typography>
          <CustomLink
            href="/login"
            variant="h1"
            color="inherit"
            underline="hover">
            Identifiez-vous
          </CustomLink>
        </Box>
        <Box className={classes.terms}>
          <Typography>
            En vous connectant sur notre plateforme, <br />
            vous êtes d&apos;accord avec&nbsp;
            <Link style={{ color: 'inherit' }} to="/terms">
              les termes et les conditions
            </Link>
          </Typography>
        </Box>
      </Box>
      <Box className={classes.bg} />
    </Box>
  );
};

export default withRouter(ChoiceAccountToCreate);

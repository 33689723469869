import React, { FC, useContext, useState } from 'react';
import useStyles from './style';
import {
  Backdrop,
  Box,
  Button,
  TextField,
  Typography,
} from '@material-ui/core';
import { RouteComponentProps } from 'react-router-dom';
import { UserContext } from '../../Providers/User';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { DO_UPDATE_PROFIL } from '../../Graphql/Livreur/mutation';
import {
  UPDATE_PROFIL,
  UPDATE_PROFILVariables,
} from '../../Graphql/Livreur/types/UPDATE_PROFIL';
import SnackVariableInterface from '../../Interfaces/SnackVariableInterface';
import { displaySnackBar } from '../../Utils/snackBarUtils';

const MonCompte: FC<RouteComponentProps> = () => {
  const classes = useStyles();

  const client = useApolloClient();

  const {
    idMe,
    name,
    firstName,
    phone,
    email,
    setName,
    setFirstName,
    setPhone,
    setEmail,
  } = useContext(UserContext);

  const [nameLocal, setNameLocal] = useState(name);
  const [firstNameLocal, setFirstNameLocal] = useState(firstName);
  const [phoneLocal, setPhoneLocal] = useState(phone);
  const [emailLocal, setEmailLocal] = useState(email);
  const [passwordLocal, setpasswordLocal] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmePassword, setConfirmePassword] = useState('');

  const [doUpdate, { loading }] = useMutation<
    UPDATE_PROFIL,
    UPDATE_PROFILVariables
  >(DO_UPDATE_PROFIL, {
    onCompleted: (data) => {
      const snackBarData: SnackVariableInterface = {
        type: 'INFO',
        message: 'Les modifications sont effectuées avec succès.',
        isOpen: true,
      };
      displaySnackBar(client, snackBarData);
      if (data && data.updateProfilCourier && data.updateProfilCourier.user) {
        setName(data.updateProfilCourier.user.name);
        setFirstName(data.updateProfilCourier.user.firstName || '');
        setPhone(data.updateProfilCourier.user.phone || '');
        setEmail(data.updateProfilCourier.user.email || '');
      }
    },
    onError: (err) => {
      if (err.networkError?.message === 'Failed to fetch') {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Problème de connexion...',
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      }
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: err.message.split(':')[1],
        isOpen: true,
      };
      return displaySnackBar(client, snackBarData);
    },
  });

  const onPressEnregistrer = () => {
    if (newPassword.trim() !== confirmePassword.trim()) {
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: 'Les deux mot de passe ne sont pas conformes',
        isOpen: true,
      };
      displaySnackBar(client, snackBarData);
    } else {
      doUpdate({
        variables: {
          idUser: idMe,
          name: nameLocal,
          firstName: firstNameLocal,
          phone: phoneLocal,
          email: emailLocal,
          password: passwordLocal,
          newPassword: newPassword,
          confirmPassword: confirmePassword,
        },
      });
    }
  };

  return (
    <Box className={classes.allContent}>
      <Backdrop open={loading} />
      <Box className={classes.firstContent}>
        <Box className={classes.FirstcontentGreating}>
          <Box className={classes.contentGreating}>
            <Typography
              variant="h6"
              style={{
                fontSize: '14px',
                fontWeight: 600,
              }}>
              Bonjour
            </Typography>
            <Typography>
              Ci dessous les informations liées à votre compte
            </Typography>
          </Box>
          <Box className={classes.contentGreating1}>
            <Typography>Nom</Typography>
            <TextField
              value={nameLocal}
              onChange={(e) => setNameLocal(e.target.value)}
              className={classes.contentInput}
              id="outlined-textarea"
              placeholder=" Ex : Rakoto"
              variant="filled"
            />
          </Box>
          <Box className={classes.contentGreating1}>
            <Typography>Prénom</Typography>
            <TextField
              onChange={(e) => setFirstNameLocal(e.target.value)}
              value={firstNameLocal}
              className={classes.contentInput}
              id="outlined-textarea"
              placeholder=" Ex : Rakoto"
              variant="filled"
            />
          </Box>
          <Box className={classes.contentGreating1}>
            <Typography>Téléphone</Typography>
            <TextField
              onChange={(e) => setPhoneLocal(e.target.value)}
              value={phoneLocal}
              className={classes.contentInput}
              id="outlined-textarea"
              placeholder=" Ex : 032 00 000 00"
              variant="filled"
            />
          </Box>
          <Box className={classes.contentGreating1}>
            <Typography>Email</Typography>
            <TextField
              onChange={(e) => setEmailLocal(e.target.value)}
              value={emailLocal}
              className={classes.contentInput}
              id="outlined-textarea"
              placeholder=" Ex : rakoto@rakoto.com"
              variant="filled"
            />
          </Box>
        </Box>

        <Box className={classes.contentBottom}>
          <Typography
            variant="h6"
            style={{ textAlign: 'center', fontSize: '14px', fontWeight: 600 }}>
            Modifier le mot de passe
          </Typography>
          <Box className={classes.contentGreating1}>
            <TextField
              onChange={(e) => setpasswordLocal(e.target.value)}
              className={classes.contentInput1}
              id="outlined-textarea"
              placeholder=" Mot de passe actuel"
              variant="filled"
            />
          </Box>
          <Box className={classes.contentGreating1}>
            <TextField
              onChange={(e) => setNewPassword(e.target.value)}
              className={classes.contentInput1}
              id="outlined-textarea"
              placeholder=" Nouveau mot de passe"
              variant="filled"
            />
          </Box>{' '}
          <Box className={classes.contentGreating1}>
            <TextField
              onChange={(e) => setConfirmePassword(e.target.value)}
              className={classes.contentInput1}
              id="outlined-textarea"
              placeholder="Confirmer votre nouveau mot de passe"
              variant="filled"
            />
          </Box>
          <Box className={classes.contentButton}>
            <Button onClick={onPressEnregistrer} variant="contained">
              {' '}
              Enregistrer
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default MonCompte;

import gql from 'graphql-tag';

export const DO_ADDRESS_BOOKS = gql`
  query ADDRESS_BOOKS {
    addressBooks {
      id
      name
      phone
      address
      location
    }
  }
`;

export const DO_ADDRESS_BOOKS_PICKUP = gql`
  query ADDRESS_BOOKS_PICKUP($id: String!) {
    addressBookUserPickup(id: $id) {
      id
      name
      address
      phone
      pickup {
        id
        name
        address
        location
        phone
      }
    }
  }
`;

export const DO_ADDRESS_BOOKS_DROP_OFF = gql`
  query ADDRESS_BOOKS_DROP_OFF($id: String!) {
    addressBookUserDropoff(id: $id) {
      id
      name
      address
      phone
      dropoff {
        id
        name
        address
        location
        phone
      }
    }
  }
`;

export const DO_TYPE_VOITURE = gql`
  query TYPE_VOITURE {
    typeVehiculeVoiture {
      id
      costByDistance
      forfaitExpress
      forfaitProgram
    }
  }
`;

export const DO_TYPE_MOTO = gql`
  query TYPE_MOTO {
    typeVehiculeMoto {
      id
      costByDistance
      forfaitExpress
      forfaitProgram
    }
  }
`;

export const DO_TYPE_VELO = gql`
  query TYPE_VELO {
    typeVehiculeVelo {
      id
      costByDistance
      forfaitExpress
      forfaitProgram
    }
  }
`;

export const DO_TYPE_UTILITAIRE = gql`
  query TYPE_UTILITAIRE {
    typeVehiculeUtilitaire {
      id
      costByDistance
      forfaitExpress
      forfaitProgram
    }
  }
`;

export const DO_MY_DELIVERY_WAITING = gql`
  query MY_DELIVERY_WAITING($id: String!) {
    myDeliveriesWaiting(id: $id) {
      id
      reference
      status
      manifest
      manifestItem
      pickup {
        id
        name
        address
        phone
        location
      }
      dropoff {
        id
        name
        phone
        address
        location
      }
      vehiculeType {
        id
        name
      }
      courier {
        id
        name
        phone
      }
      shortDelivery {
        id
        manifest
        manifestItem
        distance
        dropoff {
          id
          name
          phone
          address
          location
        }
        status
      }
      vehicule {
        immatriculation
      }
      deliveryDate
    }
  }
`;

export const DO_MY_DELIVERY_ON_THE_WAY = gql`
  query MY_DELIVERY_ON_THE_WAY($id: String!) {
    myDeliveriesOnTheWay(id: $id) {
      id
      manifest
      reference
      status
      manifestItem
      pickup {
        id
        name
        address
        phone
        location
      }
      dropoff {
        id
        name
        phone
        address
        location
      }
      vehiculeType {
        id
        name
      }
      courier {
        id
        name
        phone
      }
      shortDelivery {
        id
        manifest
        manifestItem
        distance
        dropoff {
          id
          name
          phone
          address
          location
        }
        status
      }
      vehicule {
        immatriculation
      }
      deliveryDate
    }
  }
`;

export const DO_PROMOS = gql`
  query PROMOS {
    promos {
      id
      name
      value
      princingId
      dateFirst
      dateLast
      status
    }
  }
`;

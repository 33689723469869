import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function Account(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 21.167 21.167">
      <g transform="translate(0.75 0.75)">
        <path
          d="M9.833,11.063A5.531,5.531,0,1,0,4.3,5.531,5.533,5.533,0,0,0,9.833,11.063Zm4.917,1.229H12.634a6.687,6.687,0,0,1-5.6,0H4.917A4.916,4.916,0,0,0,0,17.209v.615a1.844,1.844,0,0,0,1.844,1.844H17.823a1.844,1.844,0,0,0,1.844-1.844v-.615A4.916,4.916,0,0,0,14.75,12.292Z"
          fill="none"
          strokeWidth="1.5"
        />
      </g>
    </SvgIcon>
  );
}

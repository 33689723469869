import React, { FC } from 'react';
import { VOL_aeroportFilter } from '../../../Graphql/Vol/types/VOL';
import Box from '@material-ui/core/Box';

interface Props {
  aeroport: VOL_aeroportFilter;
}

const AeroportSuggestion: FC<Props> = (props) => {
  const {
    aeroport: { id, name, continent, municipality },
  } = props;

  // Case this is a Municipalite title
  if (!id) return <Box style={{ fontWeight: 'bold' }}>{municipality}</Box>;

  return <Box style={{ paddingLeft: 15 }}>{`${name} - ${continent}`}</Box>;
};

export default AeroportSuggestion;

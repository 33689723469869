import { makeStyles } from '@material-ui/core/styles';
import AutoComplete from '../../Components/AutoComplete';

const useStyles = makeStyles({
  allContent: {
    display: 'flex',
    justifyContent: 'center',
    width: '80%',
    margin:'auto',
    color:'#6B6B6B',
    backgroundColor: '#fff',
    '& p':{
      fontSize:'12px',
    },
    '@media (max-width:1365px)': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      width: '100%',
      alignItems: 'center',
    },
    '@media (max-width:1024px)': {},
  },
  firstContent: {
    display: 'flex',
    width: '100%',
    minHeight:'720px',
    color: '#000',
    justifyContent:'space-between',
    flexDirection: 'row',
    webkitBoxShadow: '0px 3px 6px 0px rgba(0,0,0,0.27)',
    mozBoxShadow: '0px 3px 6px 0px rgba(0,0,0,0.27)',
    boxShadow: '0px 3px 6px 0px rgba(0,0,0,0.27)',
    padding: '20px',
     '& button': {
      width: '35%',
      padding: '0 !important',
    },
    '@media (max-width:1365px)': {
      webkitBoxShadow: 'none',
      flexDirection: 'row',
      mozBoxShadow: 'none',
      boxShadow: 'none',
      width: '80%',
      borderBottom: '1px solid #ECECEC',
    },
    '@media (max-width:1024px)': {
      width: '85%',
      flexDirection:'column',
      alignItems:'center',

    },
    '@media (max-width:650px)': {
      width: '100%', 
      flexDirection:'column',
      alignItems:'center',
    },
    '& .MuiButton-contained ': {
      backgroundColor: '#55A7EE',
      color: '#fff',
      fontWeight: 'normal',
      '& span': {
        color: '#fff',
      },
    },
  },
  contentGreating: {
    borderBottom:'1px solid grey',
    fontSize:'14px',
    paddingBottom:'20px',
    // paddingTop:'20px',
    // paddingLeft:'10px',
    marginBottom:'10px',
    '& :first-child':{
      marginBottom:'20px',
    },
  },
  contentGreating1:{
    display:'flex',
    alignItems:'Baseline',
    justifyContent:'space-between',
    '& input': {
      padding:0,
      border: '1px solid gray',
      height: '34px',
      paddingLeft:'10px',
      fontSize: '13px',
      borderRadius: '5px',
      backgroundColor: 'unset',
      '&::placeholder': {        
      color:'black',
      fontWeight:900,
      },
    },
  },
  contentInput: {
    width:'60%',
   
  },  contentInput1: {
    width:'100%',
  },
  FirstcontentGreating: {
    width:'49%',
    borderBottom:'1px solid gray',
    marginBottom:'30px',
    '@media (min-width:1024px)': {
      borderBottom:'unset',
      // width:'100%',
    },
    '@media (max-width:1024px)': {
      borderBottom:'unset',
      width:'100%',
    },
  },
  contentBottom:{
    display:'flex',
    width:'49%',
    flexDirection:'column',
    // justifyContent:'center'
    '& input':{
      '&::placeholder':{
        color:'#6B6B6B',
        fontWeight:400,
      },
    },
    '@media (max-width:1024px)': {
      borderBottom:'unset',
      width:'100%',
    },
    '& h6':{
      color:'#6B6B6B',
      paddingBottom:'29px',
      paddingTop: '40px',
    },
  },
  contentButton:{
    display:'flex',
    justifyContent:'flex-end',
  },
});
export default useStyles;
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { useContext, useState } from 'react';
import {
  DO_MY_REQUEST_BOKING_PROCESSING,
  DO_MY_REQUEST_BOOKING_WAITING,
} from '../../Graphql/Hotel/query';
import {
  MY_REQUEST_BOKING_PROCESSING,
  MY_REQUEST_BOKING_PROCESSINGVariables,
} from '../../Graphql/Hotel/types/MY_REQUEST_BOKING_PROCESSING';
import {
  MY_REQUEST_BOOKING_WAITING,
  MY_REQUEST_BOOKING_WAITINGVariables,
} from '../../Graphql/Hotel/types/MY_REQUEST_BOOKING_WAITING';
import SnackVariableInterface from '../../Interfaces/SnackVariableInterface';
import { UserContext } from '../../Providers/User';
import { displaySnackBar } from '../../Utils/snackBarUtils';

export const useGetNumberRequest = () => {
  const client = useApolloClient();
  const { idMe } = useContext(UserContext);

  const [numberRequestWaiting, setNumberRequestWaiting] = useState<number>(0);
  const [numberRequestProcessed, setNumberRequestProcessed] = useState<number>(
    0,
  );

  const { loading: loadingWaiting } = useQuery<
    MY_REQUEST_BOOKING_WAITING,
    MY_REQUEST_BOOKING_WAITINGVariables
  >(DO_MY_REQUEST_BOOKING_WAITING, {
    variables: { user_id: idMe },
    skip: idMe ? false : true,
    onError: (err) => {
      if (err.networkError?.message === 'Failed to fetch') {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Problème de connexion...',
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      }
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: err.message.split(':')[1],
        isOpen: true,
      };
      return displaySnackBar(client, snackBarData);
    },
    onCompleted: (data) => {
      if (data && data.myRequestBookingWaiting) {
        setNumberRequestWaiting(data.myRequestBookingWaiting.length);
      }
    },
    fetchPolicy: 'network-only',
  });

  const { loading: loadingProcessed } = useQuery<
    MY_REQUEST_BOKING_PROCESSING,
    MY_REQUEST_BOKING_PROCESSINGVariables
  >(DO_MY_REQUEST_BOKING_PROCESSING, {
    variables: { user_id: idMe },
    skip: idMe ? false : true,
    onError: (err) => {
      if (err.networkError?.message === 'Failed to fetch') {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Problème de connexion...',
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      }
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: err.message.split(':')[1],
        isOpen: true,
      };
      return displaySnackBar(client, snackBarData);
    },
    onCompleted: (data) => {
      if (data && data.myRequestBookingProcessing) {
        setNumberRequestProcessed(data.myRequestBookingProcessing.length);
      }
    },
    fetchPolicy: 'network-only',
  });

  return {
    numberRequestWaiting,
    numberRequestProcessed,
    loadingWaiting,
    loadingProcessed,
  };
};

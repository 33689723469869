import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  buttonGroup: {
    flex: '0 0 100%',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    alignContent: 'center',
    marginTop: theme.spacing(2),
    '&>button': {
      marginBottom: theme.spacing(2),
    },
    '&>button:not(:last-of-type)': {
      marginRight: theme.spacing(2),
    },
  },
  periode: {},
  vehicule: {
    '&>button:first-of-type': {
      background: '#15AE96',
      color: '#fff',
    },
    '&>button:nth-of-type(2)': {
      color: '#E08E0B',
      border: '1px solid #E08E0B',
      backgroundColor: '#FFF',
    },
    '&>button:last-of-type': {
      color: '#4267B2',
      border: '1px solid #4267B2',
      backgroundColor: '#FFF',
    },
  },
}));

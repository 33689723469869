import gql from 'graphql-tag';

export const DO_DELETE_ADRESS_BOOK = gql`
  mutation DELETE_ADRESS_BOOK($adressBook_id: [String!]!) {
    deleteAddressBook(adressBook_id: $adressBook_id) {
      addressBooks {
        id
        name
        phone
        address
      }
    }
  }
`;

export const DO_UPDATE_ADRESS_BOOK = gql`
  mutation UPDATE_ADRESS_BOOK(
    $id: String!
    $customer_id: String!
    $name: String!
    $address: String!
    $phone: String!
  ) {
    updateAddressBook(
      id: $id
      customer_id: $customer_id
      name: $name
      address: $address
      phone: $phone
    ) {
      id
      name
      phone
      address
    }
  }
`;

import { useContext, useEffect, useMemo, useState } from 'react';
import { DestinataireSortedByDistanceContext } from '../../Providers/DestinataireSortedByDistance';

export const useGetWayPoint = () => {
  const { usersSortedByDistance } = useContext(
    DestinataireSortedByDistanceContext,
  );

  const [directions, setDirections] = useState<google.maps.DirectionsResult>();

  const directionsService = useMemo(
    () => new google.maps.DirectionsService(),
    [],
  );

  useEffect(() => {
    const origin = {
      lat: +usersSortedByDistance[0].latitude,
      lng: +usersSortedByDistance[0].longitude,
    };

    const destination = {
      lat: +usersSortedByDistance[usersSortedByDistance.length - 1].latitude,
      lng: +usersSortedByDistance[usersSortedByDistance.length - 1].longitude,
    };

    const wayPoints = () => {
      let locations: google.maps.DirectionsWaypoint[] = [];
      for (const location of usersSortedByDistance) {
        locations.push({
          location: new google.maps.LatLng(
            +location.latitude,
            +location.longitude,
          ),
        });
      }
      return locations;
    };

    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: google.maps.TravelMode.DRIVING,
        waypoints: wayPoints(),
        optimizeWaypoints: false,
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          setDirections(result);
        }
      },
    );
  }, [directionsService, usersSortedByDistance]);

  return { directions };
};

import React, { useState } from 'react';
import { createContext, FC } from 'react';

export interface User {
  id: string;
  name: string;
  phone: string;
  address: string;
  latitude: string;
  longitude: string;
  idPickOrDrop: string;
  distanceParcouru: number;
  dureeApproximatif?: string | null;
}

interface DestinataireSortedByDistanceInputProps {
  usersSortedByDistance: User[];
  setDestinatairesSortedByDistance: (destinataire: User[]) => void;
}

export const DestinataireSortedByDistanceContext = createContext<DestinataireSortedByDistanceInputProps>(
  {
    usersSortedByDistance: [],
    setDestinatairesSortedByDistance: () => {},
  },
);

export const DestinataireSortedBydistanceProvider: FC = ({ children }) => {
  const [destinataires, setDestinatairesSortedByDistance] = useState<User[]>(
    [],
  );

  return (
    <DestinataireSortedByDistanceContext.Provider
      value={{
        usersSortedByDistance: destinataires,
        setDestinatairesSortedByDistance,
      }}>
      {children}
    </DestinataireSortedByDistanceContext.Provider>
  );
};

import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function Mobix(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 140.845 41.782">
      <path
        id="Tracé_1"
        data-name="Tracé 1"
        d="M801,572.321a10.338,10.338,0,0,0-.929-5.124,3.449,3.449,0,0,0-3.192-1.508,14.265,14.265,0,0,0-1.457.076q-.755.075-1.458.176v20.4h-7.487v-25.53c.635-.169,1.382-.344,2.236-.527s1.759-.353,2.714-.5,1.935-.269,2.94-.352,1.994-.126,2.967-.126a12.48,12.48,0,0,1,4.648.729,10.482,10.482,0,0,1,2.939,1.734,14,14,0,0,1,7.54-2.463,13.228,13.228,0,0,1,5.2.879,7.837,7.837,0,0,1,3.243,2.488,9.427,9.427,0,0,1,1.658,3.82,24.634,24.634,0,0,1,.453,4.925v14.926h-7.49V572.321q0-3.618-.929-5.124a3.445,3.445,0,0,0-3.191-1.508,6.721,6.721,0,0,0-1.683.3,6.531,6.531,0,0,0-1.784.753,11.42,11.42,0,0,1,.452,2.436q.1,1.283.1,2.739v14.424H801Z"
        transform="translate(-786.473 -547.344)"
      />
      <path
        id="Tracé_2"
        data-name="Tracé 2"
        d="M843.126,579.644a21.794,21.794,0,0,1-4.185,5.568,16.61,16.61,0,0,1-5.3,3.44,15.5,15.5,0,0,1-6,1.1,16.533,16.533,0,0,1-6.311-1.4,14.813,14.813,0,0,1-5.125-3.481,12.062,12.062,0,0,1-2.8-4.852,13.749,13.749,0,0,1-.369-5.856,19.055,19.055,0,0,1,2.189-6.419,20.058,20.058,0,0,1,4.251-5.505,17.3,17.3,0,0,1,5.377-3.343,16.068,16.068,0,0,1,12.159.26,14.669,14.669,0,0,1,5.071,3.458,12.254,12.254,0,0,1,2.811,4.826,13.549,13.549,0,0,1,.4,5.8A18.8,18.8,0,0,1,843.126,579.644Zm-7.34-3.108a8.55,8.55,0,0,0,.54-6.083,7.752,7.752,0,0,0-9.772-4.189,8.335,8.335,0,0,0-3.984,4.605,8.646,8.646,0,0,0-.529,6.145,6.751,6.751,0,0,0,4.063,4.1,6.544,6.544,0,0,0,5.707.093A8.644,8.644,0,0,0,835.786,576.536Z"
        transform="translate(-775.121 -547.976)"
        fill="#ffec51"
      />
      <path
        id="Tracé_3"
        data-name="Tracé 3"
        d="M861.513,576.72a16.733,16.733,0,0,1-.929,5.73,12.11,12.11,0,0,1-2.689,4.371,11.819,11.819,0,0,1-4.323,2.791,16.224,16.224,0,0,1-5.829.979q-1.358,0-2.839-.126t-2.915-.326q-1.432-.2-2.738-.478l-.027-.006a2.908,2.908,0,0,1-2.285-2.854V552.146l7.487-1.207v13.167a14.048,14.048,0,0,1,2.614-.855,13.386,13.386,0,0,1,2.915-.3,11.935,11.935,0,0,1,4.975.981,9.748,9.748,0,0,1,3.618,2.789,12.531,12.531,0,0,1,2.212,4.348A19.836,19.836,0,0,1,861.513,576.72Zm-7.64-.2q0-7.238-5.326-7.236a8.812,8.812,0,0,0-2.287.3,6.479,6.479,0,0,0-1.834.753v13.618c.369.068.838.126,1.407.177s1.189.074,1.86.074a5.377,5.377,0,0,0,4.622-2.109A9.174,9.174,0,0,0,853.872,576.519Z"
        transform="translate(-764.79 -550.939)"
      />
      <path
        id="Tracé_4"
        data-name="Tracé 4"
        d="M866.587,555.713a4.107,4.107,0,0,1-1.332,3.241,4.768,4.768,0,0,1-6.282,0,4.612,4.612,0,0,1,0-6.482,4.763,4.763,0,0,1,6.282,0A4.107,4.107,0,0,1,866.587,555.713Zm-.7,34.073H858.4V563.352h7.487Z"
        transform="translate(-755.894 -550.788)"
      />
      <path
        id="Tracé_5"
        data-name="Tracé 5"
        d="M879.708,567.137l5.254-7.41h7.74l-9.175,12.854a78.182,78.182,0,0,1,5.6,7.2,66.045,66.045,0,0,1,3.924,6.379h-8.03q-.41-.755-1.012-1.887t-1.354-2.363a12.258,12.258,0,0,0-1.574-2.087q-.819-.856-1.616-1.554-.759.648-1.544,1.437a10.242,10.242,0,0,0-1.491,1.967q-.708,1.179-1.387,2.333t-1.211,2.154h-7.617q.631-1.237,1.668-2.958t2.272-3.554q1.237-1.829,2.675-3.557t2.822-3.24q-2.464-3.5-4.738-6.735t-4.5-6.391h8.092Z"
        transform="translate(-752.209 -547.163)"
      />
    </SvgIcon>
  );
}

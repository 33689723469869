import gql from 'graphql-tag';

export const GET_SNACKBAR_STATE = gql`
    query getSnackBarState {
        snackBar @client {
            type
            message
            isOpen
        }
    }
`;
import { Box, Button, IconButton, Typography } from '@material-ui/core';
import classNames from 'classnames';
import React, { FC } from 'react';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import styles from './style';

interface UploadFilesProps {
  onClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  onChangeFiles?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClickFile?: (name: string) => void;
  files: File[] | null;
}

const UploadFiles: FC<UploadFilesProps> = ({
  onClick,
  onChangeFiles,
  onClickFile,
  files,
}) => {
  const classes = styles();
  const filesTrue = Array.from(files || []);

  return (
    <Box>
      <input
        accept="*"
        id="icon-button-file"
        type="file"
        style={{ display: 'none' }}
        multiple={true}
        onChange={onChangeFiles}
      />
      <label htmlFor="icon-button-file">
        <Button
          component="span"
          onClick={onClick}
          className={classes.contentButtonAjout}
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '30px',
            marginBottom: '20px',
          }}>
          <Box
            className={classNames(
              classes.contentIncrement,
              classes.contentIncrementContent,
            )}>
            <IconButton color="primary" component="span">
              <AddIcon />
            </IconButton>
          </Box>
          <Box className={classes.modifBouton}>
            <Typography style={{ color: '#55A7EE', fontSize: '16px' }}>
              &nbsp;&nbsp;Ajoutez vos documents (CIN,passeport,...)
            </Typography>
          </Box>
        </Button>
      </label>
      {filesTrue &&
        filesTrue.map((file, index) => {
          return (
            //@ts-ignore
            <Box
              // className={classes.fileName}
              key={index}
              onClick={() => onClickFile && onClickFile(file.name)}>
              <Typography className={classes.fileName}>
                {file.name}{' '}
                <IconButton
                  style={{ display: 'flex', marginLeft: '10px', color: 'red' }}>
                  <ClearIcon />
                </IconButton>
              </Typography>
              <Box
                style={{ display: 'flex', alignItems: 'center' }}
                //onClick={() => window.open(url ? url : '')}
              >
                {' '}
              </Box>
            </Box>
          );
        })}
    </Box>
  );
};

export default UploadFiles;

import React, { FC, useContext, useState } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Box,
  TextFieldProps,
} from '@material-ui/core';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  Suggestion,
} from 'react-places-autocomplete';
import TextInput from '../../TextInput';
import { ExpediteurLocationContext } from '../../../Providers/ExpediteurLocation';
import { ExpediteurContext } from '../../../Providers/Expediteur';
import styles from '../style';
import { DestinationContext } from '../../../Providers/Destination';
import { IndexDeliveryContext } from '../../../Providers/IndexDelivery';

const SearchInMap: FC<TextFieldProps> = (props) => {
  const classes = styles();
  const [address, setAddress] = useState('');

  const {
    setLatitudeExpediteurLocation,
    setLongitudeExpediteurLocation,
    latitude,
    longitude,
  } = useContext(ExpediteurLocationContext);

  const {
    setLatitudeDestination,
    setLongitudeDestination,
    latitude: latitudeDest,
    longitude: longitudeDest,
  } = useContext(DestinationContext);

  const { indexDelivery } = useContext(IndexDeliveryContext);

  const { setIdExpediteur } = useContext(ExpediteurContext);
  const { setIdDestination } = useContext(DestinationContext);

  const searchOptions = {
    componentRestrictions: { country: ['mg'] },
  };

  const handleChange = (addressIn: string) => {
    setAddress(addressIn);
  };

  const handleSelect = async (addressIn: string, placeID: string) => {
    try {
      setAddress(addressIn);
      const result = await geocodeByAddress(addressIn);
      const { lat, lng } = await getLatLng(result[0]);

      if (indexDelivery === 0) {
        if (lat !== latitude && lng !== longitude) {
          setIdExpediteur('');
        }
        setLatitudeExpediteurLocation(lat);
        setLongitudeExpediteurLocation(lng);
      }

      if (indexDelivery === 1) {
        if (lat !== latitudeDest && lng !== longitudeDest) {
          setIdDestination('');
        }
        setLatitudeDestination(lat);
        setLongitudeDestination(lng);
      }
    } catch (err) {
      console.log('error', err);
    }
  };

  return (
    <Box className={classes.root}>
      <PlacesAutocomplete
        debounce={1500}
        value={address}
        searchOptions={searchOptions}
        onChange={(value) => handleChange(value)}
        onSelect={(address, placeId) => handleSelect(address, placeId)}>
        {({ getInputProps, suggestions, getSuggestionItemProps }) => {
          return (
            <>
              <TextInput
                {...(getInputProps({
                  placeholder: 'Rechercher un lieu',
                }) as any)}
                variant="filled"
              />
              {suggestions.length > 0 && (
                <List className={classes.list}>
                  {suggestions.map((suggestion: Suggestion, index: number) => {
                    return (
                      <ListItem
                        key={`list${index}`}
                        {...getSuggestionItemProps(suggestion)}
                        button
                        dense
                        divider>
                        <ListItemText
                          primary={suggestion.formattedSuggestion.mainText}
                          secondary={
                            suggestion.formattedSuggestion.secondaryText
                          }
                        />
                      </ListItem>
                    );
                  })}
                </List>
              )}
            </>
          );
        }}
      </PlacesAutocomplete>
    </Box>
  );
};

export default SearchInMap;

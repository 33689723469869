import React, { Fragment, useContext, useEffect, useState } from 'react';
import {
  Box,
  Typography,
  InputAdornment,
  IconButton,
  ButtonGroup,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment, { now } from 'moment';
import 'moment/locale/fr';

import styles from './style';
import Camera from '@material-ui/icons/CameraAlt';
import Delete from '@material-ui/icons/DeleteForeverOutlined';
import Bike from '@material-ui/icons/DirectionsBike';
import Car from '@material-ui/icons/DirectionsCar';
import Shipping from '@material-ui/icons/LocalShipping';
import Motorcycle from '@material-ui/icons/Motorcycle';
import Event from '@material-ui/icons/Event';
import Watch from '@material-ui/icons/WatchLaterOutlined';

import DeliveryNow from '../../../Components/Icons/DeliveryNow';
import DeliveryLater from '../../../Components/Icons/DeliveryLater';
import TextInput from '../../../Components/TextInput';
import Delivery from '../../../Components/Icons/DeliveryIcon';
import Weight from '../../../Components/Icons/Weight';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import CustomButton from '../../../Components/CustomButton';
import { DeliveryInfoContext } from '../../../Providers/DeliveryInfo';
import { TYPE_VOITURE } from '../../../Graphql/Delivery/types/TYPE_VOITURE';
import {
  DO_TYPE_MOTO,
  DO_TYPE_UTILITAIRE,
  DO_TYPE_VELO,
  DO_TYPE_VOITURE,
} from '../../../Graphql/Delivery/query';
import { TYPE_MOTO } from '../../../Graphql/Delivery/types/TYPE_MOTO';
import { TYPE_VELO } from '../../../Graphql/Delivery/types/TYPE_VELO';
import { TYPE_UTILITAIRE } from '../../../Graphql/Delivery/types/TYPE_UTILITAIRE';
import { useLazyQuery } from '@apollo/react-hooks';
import { TransportContext } from '../../../Providers/Transport';
import Avatar from '@material-ui/core/Avatar';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';

enum DeliveryMoment {
  NOW = 'now',
  LATER = 'later',
}

enum Conveyance {
  BIKE = 'bike',
  CAR = 'car',
  UTILITY = 'utility',
  MOTORBIKE = 'motorbike',
}

const DeliveryInfo = () => {
  const classes = styles();

  const [idTransport, setIdTransport] = useState<string>('');
  const {
    setConveyance,
    setDateDelivery,
    setDescription,
    setSize,
    setWeight,
    setPersonne,
    setDateType,
    setImageName,
    setImageUrl,
    setImageBlob,
    imageUrl,
    conveyance,
    description,
    size,
    weight,
    personne,
    dateDelivery,
  } = useContext(DeliveryInfoContext);

  const { setTransport } = useContext(TransportContext);

  const [deliverySelected, setDeliverySelected] = useState<DeliveryMoment>(
    DeliveryMoment.NOW,
  );

  useEffect(() => {
    setDateType(deliverySelected);
  }, []);

  const [tranportId, setTransportId] = useState<string>('');

  const [isclickedVelo, setIsclickedVelo] = useState(true);
  const [isClickedMoto, setIsClickedMoto] = useState(true);
  const [isClickVoiture, setIsClickVoiture] = useState(true);
  const [isClickUtilitaire, setIsClickUtilitaire] = useState(true);

  const handleDelivery = (e: React.MouseEvent<HTMLButtonElement>) => {
    if ((e.currentTarget.id as DeliveryMoment) === DeliveryMoment.NOW) {
      setDateDelivery(moment(new Date()).utc().format());
    }
    setDeliverySelected(e.currentTarget.id as DeliveryMoment);
    setDateType(e.currentTarget.id);
  };

  useEffect(() => {
    setIsClickedMoto(true);
    setIsclickedVelo(true);
    setIsClickVoiture(true);
    setIsClickUtilitaire(true);
    setConveyance('' as any);

    if (+weight > 12) {
      setIsclickedVelo(false);
    }
    if (+weight > 35) {
      setIsclickedVelo(false);
      setIsClickedMoto(false);
    }
    if (+weight > 50) {
      setIsclickedVelo(false);
      setIsClickedMoto(false);
      setIsClickVoiture(false);
    }

    if (/^[0-9]{1,2}$/g.test(personne) === true) {
      if (+personne > 0) {
        setIsclickedVelo(false);
      }

      if (+personne >= 2) {
        setIsclickedVelo(false);
        setIsClickedMoto(false);
      }
      if (+personne > 2) {
        setIsClickUtilitaire(false);
      }
    }

    if (/^(([0-9]{1,})*x*([0-9]{1,})){1,2}$/g.test(size) === true) {
      if (/^[0-9]{1,}$/g.test(size) === true) {
        if (+size > 60 || +weight > 12) {
          setIsclickedVelo(false);
        }
        if (+size > 60 || +weight > 35) {
          setIsClickedMoto(false);
        }
        if (+size > 50 || +weight > 50) {
          setIsClickVoiture(false);
        }
      }
      if (/^[0-9]{1,}$/g.test(size) === false) {
        const data = size.split('x');
        if (data && +data[0] > 60) {
          setIsClickedMoto(false);
          setIsclickedVelo(false);
        }

        if (data && +data[1] > 80) {
          setIsClickedMoto(false);
          setIsclickedVelo(false);
          setIsClickVoiture(false);
        }

        if (data && +data[1] > 40) {
          setIsClickedMoto(false);
          setIsclickedVelo(false);
        }

        if (data && +data[2] > 60) {
          setIsClickedMoto(false);
          setIsclickedVelo(false);
        }

        if (data && +data[2] > 50) {
          setIsClickVoiture(false);
        }
      }
    }
  }, [size, weight, personne]);

  const handleConveyance = (e: React.MouseEvent<HTMLButtonElement>) => {
    let id = e.currentTarget.id as Conveyance;
    setConveyance(id);
    if (id === 'motorbike') {
      setTransportId('1');
      doGetMoto();
    }
    if (id === 'car') {
      setTransportId('2');
      doGetVoiture();
    }
    if (id === 'utility') {
      setTransportId('3');
      doGetUtilitaire();
    }
    if (id === 'bike') {
      setTransportId('4');
      doGetVelo();
    }
  };

  useEffect(() => {
    setDateDelivery(moment(now()).utc().format());
  }, []);

  const handleDateDelivery = (date: MaterialUiPickersDate) => {
    setDateDelivery(moment(date).utc().format());
  };

  const [doGetVoiture] = useLazyQuery<TYPE_VOITURE>(DO_TYPE_VOITURE, {
    onCompleted: (data) => {
      if (data && data.typeVehiculeVoiture && data.typeVehiculeVoiture.id) {
        setIdTransport(data.typeVehiculeVoiture.id);
        setTransport({
          id: data.typeVehiculeVoiture.id,
          coastBydistance: data.typeVehiculeVoiture.costByDistance,
          forfaitExpress: data.typeVehiculeVoiture.forfaitExpress,
          forfaitProgram: data.typeVehiculeVoiture.forfaitProgram,
        });
      }
    },
    fetchPolicy: 'no-cache',
  });

  const [doGetMoto] = useLazyQuery<TYPE_MOTO>(DO_TYPE_MOTO, {
    onCompleted: (data) => {
      if (data && data.typeVehiculeMoto && data.typeVehiculeMoto.id) {
        setIdTransport(data.typeVehiculeMoto.id);
        setTransport({
          id: data.typeVehiculeMoto.id,
          coastBydistance: data.typeVehiculeMoto.costByDistance,
          forfaitExpress: data.typeVehiculeMoto.forfaitExpress,
          forfaitProgram: data.typeVehiculeMoto.forfaitProgram,
        });
      }
    },
    fetchPolicy: 'no-cache',
  });

  const [doGetVelo] = useLazyQuery<TYPE_VELO>(DO_TYPE_VELO, {
    onCompleted: (data) => {
      if (data && data.typeVehiculeVelo && data.typeVehiculeVelo.id) {
        setIdTransport(data.typeVehiculeVelo.id);
        setTransport({
          id: data.typeVehiculeVelo.id,
          coastBydistance: data.typeVehiculeVelo.costByDistance,
          forfaitExpress: data.typeVehiculeVelo.forfaitExpress,
          forfaitProgram: data.typeVehiculeVelo.forfaitProgram,
        });
      }
    },
    fetchPolicy: 'no-cache',
  });

  const [doGetUtilitaire] = useLazyQuery<TYPE_UTILITAIRE>(DO_TYPE_UTILITAIRE, {
    onCompleted: (data) => {
      if (
        data &&
        data.typeVehiculeUtilitaire &&
        data.typeVehiculeUtilitaire.id
      ) {
        setIdTransport(data.typeVehiculeUtilitaire.id);
        setTransport({
          id: data.typeVehiculeUtilitaire.id,
          coastBydistance: data.typeVehiculeUtilitaire.costByDistance,
          forfaitExpress: data.typeVehiculeUtilitaire.forfaitExpress,
          forfaitProgram: data.typeVehiculeUtilitaire.forfaitProgram,
        });
      }
    },
    fetchPolicy: 'no-cache',
  });

  return (
    <Box className={classes.deliveryInfo}>
      <Box mt={2}>
        <Typography variant="h2" color="textSecondary">
          Informations sur le colis
        </Typography>
        <Box className={classes.flexInputInfo}>
          <TextInput
            variant="filled"
            placeholder="Taille en cm&sup3; (Longueur x largeur x hauteur)"
            name="taille"
            value={size}
            onChange={(e) => setSize(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Delivery stroke="#6B6B6B" fontSize="small" />
                </InputAdornment>
              ),
            }}
          />
          <TextInput
            variant="filled"
            placeholder="Poids (Kg)"
            name="poids"
            type="number"
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Weight fontSize="small" />
                </InputAdornment>
              ),
            }}
          />{' '}
          <TextInput
            variant="filled"
            placeholder="Personnes"
            name="poids"
            type="number"
            value={personne}
            onChange={(e) => setPersonne(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <AccountCircleOutlinedIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Box className={classes.flex} alignItems="flex-start !important">
          <TextInput
            variant="outlined"
            multiline
            rows={5}
            placeholder="Description"
            name="description"
            fullWidth
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <Box className={classes.actionBtn}>
            {imageUrl.length !== 0 ? (
              <>
                <Avatar alt="image" src={imageUrl} className={classes.large} />
                <IconButton
                  color="primary"
                  style={{ backgroundColor: 'red' }}
                  size="small"
                  onClick={() => {
                    setImageName('');
                    setImageUrl('');
                  }}>
                  <Delete />
                </IconButton>
              </>
            ) : (
              <>
                <Box>
                  <input
                    accept="image/*"
                    className={classes.input}
                    id="icon-button-file"
                    type="file"
                    onChange={(e) => {
                      const previewUrl = URL.createObjectURL(
                        e && e.target && e.target.files && e.target.files[0],
                      );
                      setImageBlob(
                        e && e.target && e.target.files && e.target.files[0],
                      );
                      setImageName(
                        (e &&
                          e.target &&
                          e.target.files &&
                          e.target.files[0].name) ||
                          '',
                      );
                      setImageUrl(previewUrl);
                    }}
                  />
                  <label htmlFor="icon-button-file">
                    <IconButton size="medium" component="span">
                      <Camera />
                    </IconButton>
                  </label>
                </Box>
                <IconButton color="default" size="small">
                  <Delete />
                </IconButton>
              </>
            )}
          </Box>
        </Box>
      </Box>
      <Box>
        <Typography variant="h2" color="textSecondary">
          Quand ?
        </Typography>

        <Box className={classes.flex}>
          <CustomButton
            state={deliverySelected}
            startIcon={<DeliveryNow />}
            id={DeliveryMoment.NOW}
            onClick={handleDelivery}>
            Maintenant
          </CustomButton>
          <CustomButton
            state={deliverySelected}
            startIcon={<DeliveryLater />}
            id={DeliveryMoment.LATER}
            onClick={handleDelivery}>
            Plus tard
          </CustomButton>
        </Box>

        {deliverySelected === DeliveryMoment.LATER && (
          <Box className={classes.flex} mt={2}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                inputVariant="filled"
                value={dateDelivery}
                onChange={handleDateDelivery}
                variant="inline"
                format="DD-MM-yyyy"
                KeyboardButtonProps={{ size: 'small' }}
                keyboardIcon={<Event fontSize="small" />}
                disableToolbar
                disablePast
                autoOk
              />
              <KeyboardTimePicker
                inputVariant="filled"
                value={dateDelivery}
                onChange={handleDateDelivery}
                variant="inline"
                format="HH:mm"
                KeyboardButtonProps={{ size: 'small' }}
                keyboardIcon={<Watch fontSize="small" />}
                views={['hours', 'minutes']}
                ampm={false}
                disableToolbar
                autoOk
              />
            </MuiPickersUtilsProvider>
          </Box>
        )}
      </Box>

      <Box mt={2}>
        <Typography variant="h2" color="textSecondary">
          Choisissez un moyen de transport
        </Typography>

        <ButtonGroup>
          {isclickedVelo && (
            <CustomButton
              state={conveyance}
              id={Conveyance.BIKE}
              onClick={handleConveyance}
              startIcon={<Bike />}>
              Vélo
            </CustomButton>
          )}

          {isClickedMoto && (
            <CustomButton
              state={conveyance}
              id={Conveyance.MOTORBIKE}
              onClick={handleConveyance}
              startIcon={<Motorcycle />}>
              Moto
            </CustomButton>
          )}
          {isClickVoiture && (
            <CustomButton
              state={conveyance}
              id={Conveyance.CAR}
              onClick={handleConveyance}
              startIcon={<Car />}>
              Voiture
            </CustomButton>
          )}

          {isClickUtilitaire && (
            <CustomButton
              state={conveyance}
              id={Conveyance.UTILITY}
              onClick={handleConveyance}
              startIcon={<Shipping />}>
              Utilitaire
            </CustomButton>
          )}
        </ButtonGroup>
      </Box>
    </Box>
  );
};

export default DeliveryInfo;

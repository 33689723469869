import gql from 'graphql-tag';

export const DO_LOCATION_COURIER = gql`
  query LOCATION_COURIER($id: String!) {
    locationCourier(id: $id) {
      courierPosition {
        id
        location
      }
    }
  }
`;

export const DO_USER_BY_COURIER = gql`
  query USER_BY_COURIER($id: String!) {
    userByCourier(id: $id) {
      id
      name
      firstName
    }
  }
`;

export const DO_MY_DELIVERY_HISTORICAL_COURRIER = gql`
  query MY_DELIVERY_HISTORICAL_COURRIER($idUser: String!) {
    myDeliveryHistoricalCourier(idUser: $idUser) {
      id
      manifest
      manifestItem
      reference
      courierId
      customerId
      pickup {
        id
        address
        phone
        location
        name
      }
      dropoff {
        id
        address
        phone
        location
      }
      courier {
        id
        name
        phone
      }
      vehicule {
        id
        immatriculation
      }
      deliveryDate
      status
      deliveryType
      vehiculeType {
        id
        name
        costByDistance
        forfaitExpress
        forfaitProgram
        status
        capacity
      }
      shortDelivery {
        id
        manifest
        manifestItem
        reference
        distance
        customerId
        pickup {
          id
          address
          phone
          location
        }
        dropoff {
          id
          address
          phone
          location
          name
        }
        status
        payment {
          id
          status
          paymentMethod
        }
      }
    }
  }
`;

export const DO_MY_DELIVERY_COURIER = gql`
  query MY_DELIVERY_COURIER($idUser: String!) {
    myDeliveriesCourier(idUser: $idUser) {
      id
      manifest
      manifestItem
      reference
      courierId
      customerId
      pickup {
        id
        address
        phone
        location
        name
      }
      dropoff {
        id
        address
        phone
        location
        name
      }
      courier {
        id
        name
        phone
      }
      vehicule {
        id
        immatriculation
      }
      deliveryDate
      status
      deliveryType
      vehiculeType {
        id
        name
        costByDistance
        forfaitExpress
        forfaitProgram
        status
        capacity
      }
      shortDelivery {
        id
        manifest
        manifestItem
        reference
        distance
        customerId
        pickup {
          id
          address
          phone
          location
        }
        dropoff {
          id
          address
          phone
          location
          name
        }
        status
        payment {
          id
          status
          paymentMethod
        }
      }
    }
  }
`;

import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export default function Time(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 42 42">
      <path
        id="Union_6"
        data-name="Union 6"
        d="M0,21A21,21,0,1,1,20.978,42,20.989,20.989,0,0,1,0,21Zm4.2,0A16.8,16.8,0,1,0,21,4.2,16.795,16.795,0,0,0,4.2,21Zm14.7,2.1V10.5h3.151V21.525L31.5,27.134l-1.577,2.582Z"
      />
    </SvgIcon>
  );
}

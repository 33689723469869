import gql from 'graphql-tag';

export const DO_CREATE_NOTE = gql`
  mutation CREATE_NOTE(
    $customerId: String!
    $courierId: String!
    $numberStar: Int!
    $content: String
    $deliveryId: String!
  ) {
    createNote(
      customerId: $customerId
      courierId: $courierId
      numberStar: $numberStar
      content: $content
      deliveryId: $deliveryId
    ) {
      message
    }
  }
`;

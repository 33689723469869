import React from 'react';
import { FC } from 'react';
import { LoadScriptProvider } from './LoadMapScript';
import { ExpediteurProvider } from './Expediteur';
import { CustomerProvider } from './Customer';
import { DestinationProvider } from './Destination';
import { DestinationsProvider } from './Destinations';
import { ExpediteurLocationProvider } from './ExpediteurLocation';
import { DeliveryInfoProvider } from './DeliveryInfo';
import { DestinataireSortedBydistanceProvider } from './DestinataireSortedByDistance';
import { TransportProvider } from './Transport';
import { UserProvider } from './User';
import { IndexDeliveryProvider } from './IndexDelivery';
import { PointProvider } from './Points';
import { HotelFilterProvider } from './HotelFilter';
import { HotelProvider } from './Hotel';
import { PayementVanillaProvider } from './PayementVanilla';
import { CourierProvider } from './courier';

export const Provider: FC = ({ children }) => {
  return (
    <LoadScriptProvider>
      <UserProvider>
        <CustomerProvider>
          <ExpediteurProvider>
            <ExpediteurLocationProvider>
              <DestinataireSortedBydistanceProvider>
                <DestinationsProvider>
                  <DestinationProvider>
                    <DeliveryInfoProvider>
                      <TransportProvider>
                        <IndexDeliveryProvider>
                          <PointProvider>
                            <HotelProvider>
                              <HotelFilterProvider>
                                <PayementVanillaProvider>
                                  <CourierProvider>{children}</CourierProvider>
                                </PayementVanillaProvider>
                              </HotelFilterProvider>
                            </HotelProvider>
                          </PointProvider>
                        </IndexDeliveryProvider>
                      </TransportProvider>
                    </DeliveryInfoProvider>
                  </DestinationProvider>
                </DestinationsProvider>
              </DestinataireSortedBydistanceProvider>
            </ExpediteurLocationProvider>
          </ExpediteurProvider>
        </CustomerProvider>
      </UserProvider>
    </LoadScriptProvider>
  );
};

import React, { FC, useContext, useState, useEffect } from 'react';
import useStyles from './style';
import {
  Backdrop,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import Star from '@material-ui/icons/Star';
import Close from '@material-ui/icons/Close';
import Happy from '@material-ui/icons/SentimentSatisfiedAlt';
import TextInput from '../TextInput';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { DO_CREATE_NOTE } from '../../Graphql/Note/mutation';
import {
  CREATE_NOTE,
  CREATE_NOTEVariables,
} from '../../Graphql/Note/types/CREATE_NOTE';
import SnackVariableInterface from '../../Interfaces/SnackVariableInterface';
import { displaySnackBar } from '../../Utils/snackBarUtils';
import { UserContext } from '../../Providers/User';

interface RatingProps {
  deliveryId: string;
  courrierId: string;
}

const RatingNote: FC<RatingProps> = ({ courrierId, deliveryId }) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(true);
  const [value, setValue] = useState<number>(0);
  const handleClose = () => setOpen(false);
  const handleRating = (e: React.ChangeEvent<{}>, value: number | null) => {
    setValue(value ? value : 0);
  };

  const { customerId } = useContext(UserContext);

  const client = useApolloClient();

  const [doAvis, { loading }] = useMutation<CREATE_NOTE, CREATE_NOTEVariables>(
    DO_CREATE_NOTE,
    {
      onError: (err) => {
        if (err.networkError?.message === 'Failed to fetch') {
          const snackBarData: SnackVariableInterface = {
            type: 'ERROR',
            message: 'Problème de connexion...',
            isOpen: true,
          };
          return displaySnackBar(client, snackBarData);
        }
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: err.message.split(':')[1],
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      },
      onCompleted: (data) => {
        setOpen(false);
      },
    },
  );

  const [message, setMessage] = useState('');

  const onPressEnvoyer = () => {
    doAvis({
      variables: {
        courierId: courrierId,
        deliveryId: deliveryId,
        numberStar: value,
        content: message,
        customerId,
      },
    });
  };

  return (
    <Box className={classes.resume}>
      <Backdrop open={loading} />
      <Dialog maxWidth="xs" open={open} fullWidth>
        <DialogTitle disableTypography>
          <Typography variant="h3" gutterBottom>
            Ajouter une note ou un avis
          </Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers style={{ borderBottomWidth: 0 }}>
          <Box textAlign="center">
            <>
              {/* <Typography variant="h1" color="primary" align="center">
                <Typography>
                  <Happy color="inherit" style={{ fontSize: '4rem' }} />
                </Typography>
                Merci pour votre avis
              </Typography>
              <Button variant="contained" color="primary" onClick={handleClose}>
                Continuer
              </Button> */}

              <Rating
                value={value}
                className={classes.rating}
                size="large"
                icon={<Star fontSize="large" color="secondary" />}
                emptyIcon={<Star fontSize="large" htmlColor="#CAD1DE" />}
                onChange={handleRating}
              />
              <TextInput
                variant="outlined"
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                placeholder="Laisser un commentaire"
                rows={6}
                fullWidth
                multiline
              />
            </>
          </Box>
        </DialogContent>
        <DialogActions
          style={{ justifyContent: 'space-evenly', paddingBottom: 24 }}>
          <>
            <Button variant="contained" onClick={handleClose}>
              Annuler
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={onPressEnvoyer}>
              Envoyer
            </Button>
          </>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
export default RatingNote;

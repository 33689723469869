import React, { FC, useContext, useEffect, useState } from 'react';
import { RouteComponentProps, useHistory, useParams } from 'react-router-dom';
import useStyles from './style';
import { Box, Typography, Button, Paper } from '@material-ui/core';
import 'react-image-gallery/styles/css/image-gallery.css';
// import ImageGallery from 'react-image-gallery';
import Slide from '../../Components/Slide';

import CardHotelList from '../../Components/CardHotelList';
import { HotelContext } from '../../Providers/Hotel';
import { displaySnackBar } from '../../Utils/snackBarUtils';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { DO_GET_HOTEL } from '../../Graphql/Hotel/query';
import {
  GET_HOTEL,
  GET_HOTELVariables,
} from '../../Graphql/Hotel/types/GET_HOTEL';
import SnackVariableInterface from '../../Interfaces/SnackVariableInterface';
import { HotelFilterContext } from '../../Providers/HotelFilter';
import moment from 'moment';

interface ImageTypes {
  original: string;
  thumbnail: string;
}

const ReservationHotel: FC<RouteComponentProps> = () => {
  const history = useHistory();
  const classes = useStyles();
  const client = useApolloClient();
  const { idHotel }: { idHotel: string } = useParams();
  const [imagesSource, setImagesSource] = useState<ImageTypes[] | null>(null);
  const { dateDepartContext, dateArriveContext } = useContext(
    HotelFilterContext,
  );
  const { hotel } = useContext(HotelContext);

  const { data: dataHotel, loading: loadingHotel } = useQuery<
    GET_HOTEL,
    GET_HOTELVariables
  >(DO_GET_HOTEL, {
    onError: (err) => {
      if (err.networkError?.message === 'Failed to fetch') {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Problème de connexion...',
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      }
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: err.message.split(':')[1],
        isOpen: true,
      };
      return displaySnackBar(client, snackBarData);
    },
    variables: { id: idHotel },
  });

  useEffect(() => {
    if (
      dataHotel &&
      dataHotel.hotel &&
      dataHotel.hotel.pictureHotels.length > 0
    ) {
      const images = dataHotel.hotel.pictureHotels.map((p) => {
        return {
          original: p.urlPicture,
          thumbnail: p.urlPicture,
        };
      });
      setImagesSource([...images]);
    }
  }, [dataHotel]);

  const gotoReservationUn = () => {
    history.push('/accueil/Faire-une-reservation');
  };

  if (loadingHotel) {
    return null;
  }
  const numberArroud = (value: number) => {
    if (value % 100 === 0) {
      return value;
    } else {
      return Math.ceil((value + 1) / 100) * 100;
    }
  };
  return (
    <Box className={classes.allContent}>
      <Box className={classes.firstContentReservation}>
        {imagesSource && imagesSource[0].original && (
          <Slide imagesSource={imagesSource} />
        )}
        <Box></Box>
        <Box className={classes.contentTitleHotel}>
          <Typography variant="h5">{dataHotel?.hotel.name}</Typography>
          <Typography variant="subtitle2">{dataHotel?.hotel.city}</Typography>
        </Box>
        <Box>
          <Typography variant="body1" className={classes.descriptionHotel}>
            {dataHotel?.hotel.description}
          </Typography>
        </Box>
      </Box>

      <Box className={classes.secondContentReservation}>
        <Typography variant="subtitle1">
          Pour une demande spéciale dans l&apos;établissement ?
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            history.push(`/accueil/Demande-reservation/${dataHotel?.hotel.id}`)
          }
          className={classes.frstScdBtnReservation}>
          Demander une réservation
        </Button>
        <Typography variant="h6" className={classes.titleRoom}>
          Liste de Chambre
        </Typography>
        <Box className={classes.promoContnt}>
          {dataHotel &&
            dataHotel.hotel &&
            dataHotel.hotel.rooms.length > 0 &&
            dataHotel.hotel.rooms.map((room) => {
              let price;
              if (
                dataHotel.hotel.promotionHotels.length !== 0 &&
                !(
                  moment(dateDepartContext).isBefore(
                    dataHotel.hotel.promotionHotels[0].dateFirst,
                    'days',
                  ) ||
                  moment(dataHotel.hotel.promotionHotels[0].dateLast).isBefore(
                    moment(dateArriveContext, 'DD-MM-YYYY')
                      .subtract(1, 'days')
                      .toDate(),
                    'days',
                  )
                )
              ) {
                price =
                  hotel &&
                  hotel.promotionHotels &&
                  hotel.promotionHotels.length !== 0
                    ? numberArroud(
                        room.rateDay -
                          (room.rateDay *
                            hotel.promotionHotels[0].ratePercentage) /
                            100,
                      )
                    : 0;
              } else {
                price = undefined;
              }
              return (
                <Paper className={classes.positionnementBouton} key={room.id}>
                  <CardHotelList
                    titre={room.typeRoom.name}
                    contentChambre={room.typeRoom.typeBed}
                    price={numberArroud(room.rateDay)}
                    person={room.typeRoom.numberPerson}
                    pricePromo={price && numberArroud(price)}
                  />
                  <Button
                    variant="contained"
                    onClick={() =>
                      history.push(
                        `/accueil/faire-une-reservation/${dataHotel.hotel.id}/${room.id}`,
                      )
                    }
                    color="primary"
                    className={classes.bookingButton}>
                    Réserver
                  </Button>
                </Paper>
              );
            })}
        </Box>
      </Box>
    </Box>
  );
};

export default ReservationHotel;

import { storage } from 'firebase';
import { useContext, useState } from 'react';
import { Files } from '../../types/globalTypes';
import { UserContext } from '../Providers/User';

export const useUploadFile = () => {
  const [loading, setLoading] = useState(false);
  const { idMe } = useContext(UserContext);

  const getFiles = async (files: File[] | null) => {
    const filesUpload: Files[] = [];
    if (files) {
      for (const file of files) {
        filesUpload.push({
          fileName: file.name,
          type: file.type.split('/')[1],
          pathUrl: `documents/${idMe}${file.name}`,
        });
      }
      return filesUpload;
    }
    return filesUpload;
  };

  const uploadFile = async (files: File[] | null) => {
    if (files !== null) {
      for (const file of files) {
        setLoading(true);
        const metadata = {
          contentType: file.type,
        };
        await storage()
          .ref(`documents/${idMe}${file.name}`)
          .put(file, metadata);

        setLoading(false);
      }
    }
  };

  return { uploadFile, loading, getFiles };
};

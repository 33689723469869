import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { DO_CREATE_NOTIFICATION_ITERM_ADMIN } from '../../Graphql/Notification/mutation';
import {
  CREATE_NOTIFICATION_ITERM_ADMIN,
  CREATE_NOTIFICATION_ITERM_ADMINVariables,
} from '../../Graphql/Notification/types/CREATE_NOTIFICATION_ITERM_ADMIN';
import { DO_CREATE_REQUEST_BOOKING_VOL } from '../../Graphql/Vol/mutation';
import {
  CREATE_REQUEST_BOOKING_VOL,
  CREATE_REQUEST_BOOKING_VOLVariables,
} from '../../Graphql/Vol/types/CREATE_REQUEST_BOOKING_VOL';
import SnackVariableInterface from '../../Interfaces/SnackVariableInterface';
import { UserContext } from '../../Providers/User';
import { displaySnackBar } from '../../Utils/snackBarUtils';

export const useCreateBookingVol = () => {
  const { customerId, idMe } = useContext(UserContext);
  const client = useApolloClient();
  const history = useHistory();

  const [doCreateBooking, { loading }] = useMutation<
    CREATE_REQUEST_BOOKING_VOL,
    CREATE_REQUEST_BOOKING_VOLVariables
  >(DO_CREATE_REQUEST_BOOKING_VOL, {
    onError: (err) => {
      if (err.networkError?.message === 'Failed to fetch') {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Problème de connexion...',
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      }
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: err.message.split(':')[1],
        isOpen: true,
      };
      return displaySnackBar(client, snackBarData);
    },
    onCompleted: async (data) => {
      const snackBarData: SnackVariableInterface = {
        type: 'SUCCESS',
        message: 'Votre demande de réservation a été émise avec succès',
        isOpen: true,
      };
      displaySnackBar(client, snackBarData);
      setTimeout(() => {
        history.push('/accueil/Reservation-Vol/Vol-En-Attente');
      }, 1000);
    },
  });

  return { doCreateBooking, loading };
};

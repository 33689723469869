import React, { useState, FC, useContext } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import useStyles from './style';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import Widget from '../../Components/Widget/Widget';
import DeliveryInfo from './DeliveryInfo';
import DeliveryRecap from './DeliveryRecap';
import Deliveries from './Deliveries';
import MapExpediteur from '../../Components/MapExpediteur/MapExpediteur';
import Destination from './Destination';
import MapDestination from '../../Components/MapDestination';
import MapDeliveryInfo from '../../Components/MapDeliveryInfo';
import { ExpediteurContext } from '../../Providers/Expediteur';
import { ExpediteurLocationContext } from '../../Providers/ExpediteurLocation';
import { UserContext } from '../../Providers/User';
import { useApolloClient } from '@apollo/react-hooks';
import SnackVariableInterface from '../../Interfaces/SnackVariableInterface';
import { displaySnackBar } from '../../Utils/snackBarUtils';
import Backdrop from '../../Components/Backdrop';
import { useComponentMutationsExpediteur } from './useCreateExpediteur';
import { IndexDeliveryContext } from '../../Providers/IndexDelivery';
import { DestinationsContext } from '../../Providers/Destinations';
import { DeliveryInfoContext } from '../../Providers/DeliveryInfo';
import { TransportContext } from '../../Providers/Transport';
import { useCreateDelivery } from './useCreateDelivery';
import { DestinataireSortedByDistanceContext } from '../../Providers/DestinataireSortedByDistance';
import moment from 'moment';
import { useUploadImage } from './uploadImage';
import { useGetDistanceSortedByUser } from './useGetDistance';
import { useInitUSers } from './sortDeliveryByDistance';

const Current = (props: any) => {
  const { index } = props;

  if (index === 0) {
    return <Deliveries />;
  } else if (index === 1) {
    return <Destination />;
  } else if (index === 2) {
    return <DeliveryInfo />;
  } else if (index === 3) {
    return <DeliveryRecap />;
  }

  return <></>;
};

const MakeDelivery: FC<RouteComponentProps> = ({ history }) => {
  const [showInformation, setShowInformation] = useState(true);
  var classes = useStyles();

  const [mapContainerStyle, setMapContainerStyle] = useState<any>({
    height: 0,
  });

  const { name, address, phone, id } = useContext(ExpediteurContext);
  const { latitude, longitude } = useContext(ExpediteurLocationContext);
  const { customerId } = useContext(UserContext);
  const { indexDelivery, setIndexDelivery } = useContext(IndexDeliveryContext);
  const { destinations } = useContext(DestinationsContext);
  const {
    reference,
    description,
    personne,
    size,
    weight,
    dateDelivery,
    dateType,
    imageName,
  } = useContext(DeliveryInfoContext);
  const { id: idTransport, setTransport } = useContext(TransportContext);
  const { usersSortedByDistance } = useContext(
    DestinataireSortedByDistanceContext,
  );

  const { uploadImage, loadingUploadImage } = useUploadImage();

  const client = useApolloClient();

  const {
    doCreateAdresseBook,
    loadingCreateAdresseBook,
    loadingPickup,
  } = useComponentMutationsExpediteur();

  const { loadingDistance, doGetDistance } = useGetDistanceSortedByUser();

  const {
    doCreateDelivery,
    calculDistanceTotal,
    loadingCreateDelivery,
    loadingPaiement,
    loadingShortDelivery,
  } = useCreateDelivery();

  const { onGetInitUSer } = useInitUSers();

  React.useEffect(() => {
    window.addEventListener('load', () =>
      setMapContainerStyle({ height: window.innerHeight - 95 }),
    );
    window.addEventListener('resize', () =>
      setMapContainerStyle({ height: window.innerHeight - 95 }),
    );
  }, [mapContainerStyle.height]);

  const handleBack = () => {
    setTransport({ id: '' });
    if (indexDelivery > 0) setIndexDelivery(indexDelivery - 1);
    if (indexDelivery === 0) history.push('/accueil');
  };

  const handleContinue = async () => {
    if (indexDelivery === 0) {
      if (id.trim().length === 0) {
        if (latitude === 0 || longitude === 0) {
          const snackBarData: SnackVariableInterface = {
            type: 'ERROR',
            message: 'Veuillez séléctionner une position sur la carte.',
            isOpen: true,
          };
          displaySnackBar(client, snackBarData);
        } else {
          doCreateAdresseBook({
            variables: {
              name: name,
              phone: phone,
              address: address,
              latitude: latitude === 0 ? '' : '' + latitude,
              longitude: longitude === 0 ? '' : '' + longitude,
              customer: customerId,
            },
          });
        }
      } else {
        setIndexDelivery(indexDelivery + 1);
      }
    }
    if (indexDelivery === 1) {
      if (destinations.length === 0) {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Veuillez ajouter au moins un destinataire.',
          isOpen: true,
        };
        displaySnackBar(client, snackBarData);
      } else {
        const dataInitiale = await onGetInitUSer();
        doGetDistance({ variables: { listUser: dataInitiale } });
      }
    }
    if (indexDelivery === 2) {
      if (idTransport.length === 0) {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Veuillez séléctionner un moyen de transport',
          isOpen: true,
        };
        displaySnackBar(client, snackBarData);
      } else if (
        /^(([0-9]{1,})*x*([0-9]{1,})){1,2}$/g.test(size) === false &&
        size.length !== 0
      ) {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Le taille est invalide.',
          isOpen: true,
        };
        displaySnackBar(client, snackBarData);
      } else if (/^[0-9]{1,}$/g.test(weight) === false && weight.length !== 0) {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Le poids est invalide.',
          isOpen: true,
        };
        displaySnackBar(client, snackBarData);
      } else if (
        /^[0-9]{1,2}$/g.test(personne) === false &&
        personne.length !== 0
      ) {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Le nombre de personne est invalide.',
          isOpen: true,
        };
        displaySnackBar(client, snackBarData);
      } else if (
        /^[0-9]{1,2}$/g.test(personne) === false &&
        personne.length !== 0
      ) {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Le nombre de personne est invalide.',
          isOpen: true,
        };
        displaySnackBar(client, snackBarData);
      } else if (
        dateType === 'later' &&
        moment(dateDelivery).format('YYYY-MM-DD') ===
          moment(new Date()).format('YYYY-MM-DD') &&
        moment(dateDelivery).format('HH:mm') <
          moment(new Date()).format('HH:mm')
      ) {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: "Veuillez entrer une heure supérieure à l'heure actuelle.",
          isOpen: true,
        };
        displaySnackBar(client, snackBarData);
      } else {
        setIndexDelivery(indexDelivery + 1);
      }
    }
    if (indexDelivery === 3) {
      if (imageName.length === 0) {
        doCreateDelivery({
          variables: {
            customer: customerId,
            manifest: {
              reference: reference,
              description: description,
              numberPerson: parseFloat(personne.toString()),
            },
            manifestItem: {
              name: '',
              taille: size,
              poids: parseFloat(weight.toString()),
            },
            distance: calculDistanceTotal(),
            pickup: usersSortedByDistance[0].idPickOrDrop,
            dropoff:
              usersSortedByDistance[usersSortedByDistance.length - 1]
                .idPickOrDrop,
            deliveryDate: moment(dateDelivery).unix(),
            vehiculeType: idTransport,
            deliveryType: dateType,
          },
        });
      } else {
        uploadImage();
        doCreateDelivery({
          variables: {
            customer: customerId,
            manifest: {
              reference: reference,
              description: description,
              personne,
            },
            manifestItem: {
              name: '',
              taille: size,
              poids: parseFloat(weight.toString()),
            },
            distance: calculDistanceTotal(),
            pickup: usersSortedByDistance[0].idPickOrDrop,
            dropoff:
              usersSortedByDistance[usersSortedByDistance.length - 1]
                .idPickOrDrop,
            deliveryDate: moment(dateDelivery).unix(),
            vehiculeType: idTransport,
            deliveryType: dateType,
            pathImageDelivery: `livraison/${imageName}`,
          },
        });
      }
    }
  };

  return (
    <Box className={classes.delivery}>
      <div>
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={showInformation}>
          <DialogTitle id="customized-dialog-title">
            Information de livraison
          </DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom>
              Les livraisons se font uniquement du lundi au samedi,
            </Typography>
            <Typography gutterBottom>de 08h - 20h.</Typography>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              color="primary"
              onClick={() => {
                setShowInformation(false);
              }}>
              ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Backdrop open={loadingPickup} />
      <Backdrop open={loadingCreateAdresseBook} />
      <Backdrop open={loadingShortDelivery} />
      <Backdrop open={loadingCreateDelivery} />
      <Backdrop open={loadingPaiement} />
      <Backdrop open={loadingUploadImage || loadingDistance} />
      <Box className={classes.controls}>
        <Widget title="Effectuer une livraison" disableWidgetMenu>
          <Current index={indexDelivery} />
          <Box className={classes.flex}>
            <Button variant="contained" onClick={handleBack}>
              Retour
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleContinue}>
              Continuer
            </Button>
          </Box>
        </Widget>
      </Box>
      <Box className={classes.map}>
        {indexDelivery === 0 && <MapExpediteur />}
        {indexDelivery === 1 && <MapDestination />}
        {(indexDelivery === 2 || indexDelivery === 3) && <MapDeliveryInfo />}
      </Box>
    </Box>
  );
};

export default withRouter(MakeDelivery);

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  contentCard: {
    display: 'flex',
    justifyContent: 'space-between',
    // alignItems: 'start',
    borderRadius: '5px',
    position: 'relative',
    padding: 6,
    '& Button': {},
  },
  bookingFirstContent: {
    padding: '0px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '5px',
  },
  contentDescription: {
    // width: '80%',
    display: 'flex',
    alignItems:'flex-start',
    flexDirection: 'row',
    '& img': {
      marginRight:'30px',
      fill:'blue',
    },
  },
  contentPrice: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    padding: 0,
  },
  priceDeco: {
    // fontWeight: 900,
    fontSize: '14px',
    color: '#6B6B6B',
  },

  titreOffres: {
    fontSize: 14,
    fontWeight: 'bold',
    marginTop: '0px',
    marginBottom: '0px',
    display: 'block',
    color: '#6B6B6B',
  },
  adresseOfre: {
    fontSize: '12px',
    fontWeight: 400,
  },
  contentTélephone: {
margin:0,
  },
});

export default useStyles;

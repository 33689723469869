import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export default function Tower(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 56.954 56.954">
      <g id="XMLID_631_">
        <path
          id="XMLID_806_"
          d="M55.842,49.39H53.617V6.229A1.112,1.112,0,0,0,52.5,5.117H44.31v-4A1.112,1.112,0,0,0,43.2,0H13.757a1.112,1.112,0,0,0-1.112,1.112v4H4.45A1.112,1.112,0,0,0,3.337,6.229V49.39H1.112A1.112,1.112,0,0,0,0,50.5v5.339a1.112,1.112,0,0,0,1.112,1.112H23.472a1.112,1.112,0,1,0,0-2.225H2.225V51.615h52.5v3.115H33.483a1.112,1.112,0,0,0,0,2.225H55.842a1.112,1.112,0,0,0,1.112-1.112V50.5a1.112,1.112,0,0,0-1.112-1.112ZM14.869,2.225H42.085V5.117H14.869Zm12.5,47.165H21.58V42.716a6.907,6.907,0,0,1,5.784-6.806Zm2.225,0V35.91a6.907,6.907,0,0,1,5.784,6.806V49.39Zm8.009,0V42.716a9.122,9.122,0,1,0-18.243,0V49.39H5.562V7.342h45.83V49.39Z"
          transform="translate(0)"
        />
        <path
          id="XMLID_825_"
          d="M84.452,110H79.112A1.112,1.112,0,0,0,78,111.112v5.339a1.112,1.112,0,0,0,1.112,1.112h5.339a1.112,1.112,0,0,0,1.112-1.112v-5.339A1.112,1.112,0,0,0,84.452,110Zm-1.112,5.339H80.225v-3.115h3.115Z"
          transform="translate(-69.323 -97.764)"
        />
        <path
          id="XMLID_828_"
          d="M180.452,110h-5.339A1.112,1.112,0,0,0,174,111.112v5.339a1.112,1.112,0,0,0,1.112,1.112h5.339a1.112,1.112,0,0,0,1.112-1.112v-5.339A1.112,1.112,0,0,0,180.452,110Zm-1.112,5.339h-3.115v-3.115h3.115Z"
          transform="translate(-154.645 -97.764)"
        />
        <path
          id="XMLID_831_"
          d="M276.452,110h-5.339A1.112,1.112,0,0,0,270,111.112v5.339a1.112,1.112,0,0,0,1.112,1.112h5.339a1.112,1.112,0,0,0,1.112-1.112v-5.339A1.112,1.112,0,0,0,276.452,110Zm-1.112,5.339h-3.115v-3.115h3.115Z"
          transform="translate(-239.966 -97.764)"
        />
        <path
          id="XMLID_834_"
          d="M372.452,110h-5.339A1.112,1.112,0,0,0,366,111.112v5.339a1.112,1.112,0,0,0,1.112,1.112h5.339a1.112,1.112,0,0,0,1.112-1.112v-5.339A1.112,1.112,0,0,0,372.452,110Zm-1.112,5.339h-3.115v-3.115h3.115Z"
          transform="translate(-325.287 -97.764)"
        />
        <path
          id="XMLID_1471_"
          d="M84.452,206H79.112A1.112,1.112,0,0,0,78,207.112v5.339a1.112,1.112,0,0,0,1.112,1.112h5.339a1.112,1.112,0,0,0,1.112-1.112v-5.339A1.112,1.112,0,0,0,84.452,206Zm-1.112,5.339H80.225v-3.115h3.115Z"
          transform="translate(-69.323 -183.085)"
        />
        <path
          id="XMLID_1482_"
          d="M180.452,206h-5.339A1.112,1.112,0,0,0,174,207.112v5.339a1.112,1.112,0,0,0,1.112,1.112h5.339a1.112,1.112,0,0,0,1.112-1.112v-5.339A1.112,1.112,0,0,0,180.452,206Zm-1.112,5.339h-3.115v-3.115h3.115Z"
          transform="translate(-154.645 -183.085)"
        />
        <path
          id="XMLID_1487_"
          d="M276.452,206h-5.339A1.112,1.112,0,0,0,270,207.112v5.339a1.112,1.112,0,0,0,1.112,1.112h5.339a1.112,1.112,0,0,0,1.112-1.112v-5.339A1.112,1.112,0,0,0,276.452,206Zm-1.112,5.339h-3.115v-3.115h3.115Z"
          transform="translate(-239.966 -183.085)"
        />
        <path
          id="XMLID_1490_"
          d="M372.452,206h-5.339A1.112,1.112,0,0,0,366,207.112v5.339a1.112,1.112,0,0,0,1.112,1.112h5.339a1.112,1.112,0,0,0,1.112-1.112v-5.339A1.112,1.112,0,0,0,372.452,206Zm-1.112,5.339h-3.115v-3.115h3.115Z"
          transform="translate(-325.287 -183.085)"
        />
        <path
          id="XMLID_632_"
          d="M84.452,302H79.112A1.112,1.112,0,0,0,78,303.112v5.339a1.112,1.112,0,0,0,1.112,1.112h5.339a1.112,1.112,0,0,0,1.112-1.112v-5.339A1.112,1.112,0,0,0,84.452,302Zm-1.112,5.339H80.225v-3.115h3.115Z"
          transform="translate(-69.323 -268.406)"
        />
        <path
          id="XMLID_1513_"
          d="M372.452,302h-5.339A1.112,1.112,0,0,0,366,303.112v5.339a1.112,1.112,0,0,0,1.112,1.112h5.339a1.112,1.112,0,0,0,1.112-1.112v-5.339A1.112,1.112,0,0,0,372.452,302Zm-1.112,5.339h-3.115v-3.115h3.115Z"
          transform="translate(-325.287 -268.406)"
        />
        <path
          id="XMLID_1522_"
          d="M247.112,492a1.113,1.113,0,1,0,.786.326A1.121,1.121,0,0,0,247.112,492Z"
          transform="translate(-218.635 -437.271)"
        />
      </g>
    </SvgIcon>
  );
}

import React, { useState } from 'react';
import { createContext, FC } from 'react';
import { GET_HOTELS_hotels } from '../Graphql/Hotel/types/GET_HOTELS';

interface HotelInfoProps {
  hotel: GET_HOTELS_hotels | undefined;
  setHotel: (hotel: GET_HOTELS_hotels | undefined) => void;
  clearHotel: () => void;
}

export const HotelContext = createContext<HotelInfoProps>({
  hotel: undefined,
  setHotel: () => {},
  clearHotel: () => {},
});

export const HotelProvider: FC = ({ children }) => {
  const [hotel, setHotel] = useState<GET_HOTELS_hotels | undefined>();
  return (
    <HotelContext.Provider
      value={{
        hotel,
        setHotel,
        clearHotel: () => {
          setHotel(undefined);
        },
      }}>
      {children}
    </HotelContext.Provider>
  );
};

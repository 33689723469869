import React, { FC } from 'react';
import {
  Backdrop as MUIBackdrop,
  CircularProgress,
  Box,
} from '@material-ui/core';
import useStyles from './styles';

interface BackdropProps {
  open?: boolean;
  value?: string | undefined;
}

const Backdrop: FC<BackdropProps> = ({ open, value }) => {
  const classes = useStyles();
  return (
    <MUIBackdrop
      className={classes.backdrop}
      open={open !== undefined ? open : true}>
      <Box className={classes.container}>
        <Box>
          <Box className={classes.element}>
            <CircularProgress color="inherit" />
          </Box>
        </Box>
        {value ? (
          <Box>
            <Box className={classes.element}>
              <Box>{value}</Box>
            </Box>
          </Box>
        ) : null}
      </Box>
    </MUIBackdrop>
  );
};

Backdrop.defaultProps = {
  value: 'Chargement en cours...',
};

export default Backdrop;

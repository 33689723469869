import React, { useEffect, useState } from 'react';
import { createContext, FC } from 'react';


interface CustomerInterface {
    id:string
}

interface CustomerInputProps {
  id: string;
  setId: (id: string) => void;
  clearCustomer: () => void;
}

export const CustomerContext = createContext<CustomerInputProps>({
    id: '',       
    setId: (id: string) => {},
    clearCustomer: () => {}
});

export const CustomerProvider: FC = ({ children }) => {
   const [customer, setCustomer] = useState<CustomerInterface>({
     id: ''
   });

  const [id, setId] = useState('');

  return (
    <CustomerContext.Provider value={{
      id: id,
      setId,
      clearCustomer: () => {}
    }}>
      {children}
    </CustomerContext.Provider>
  );
};

import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  input: {
    display: 'none',
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  adornementText: {
    fontSize: '16px',
    paddingRight: 5,
  },
  deliveryInfo: {
    // height: '100%',
    '& .MuiIconButton-root': {
      marginRight: '0 !important',
    },
    '& .MuiButtonGroup-grouped': {
      width: 80,
      height: 80,
      fontSize: '0.75rem !important',
      '& svg': {
        fontSize: 'x-large',
      },
    },
    '& button:not(.MuiIconButton-root):not(:only-of-type)': {
      padding: `${theme.spacing(2)}px !important`,
      fontSize: '0.875rem',
      fontWeight: 400,
      borderRadius: 6,
    },
  },
  flexInputInfo: {
    display: 'flex',
    flexDirection: 'column',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    '&>div:not(.MuiFormControl-fullWidth)': {
      flex: '1 1 50%',
    },
    '&>div:first-of-type': {
      marginRight: theme.spacing(2),
    },
  },
  actionBtn: {
    flex: '0 1 auto !important',
    textAlign: 'center',
    '& div:first-child': {
      marginBottom: 20,
      border: `1px solid ${theme.palette.text.secondary}`,
      borderRadius: '50%',
    },
    '&>button:first-of-type': {
      width: 30,
      height: 30,
      marginBottom: theme.spacing(1),
    },
    '&>button:last-of-type': {
      color: '#FFF',
      backgroundColor: '#E6E6E6',
    },
  },
}));

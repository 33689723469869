import { Box, Typography } from '@material-ui/core';
import React, { FC, Fragment, useContext, useState } from 'react';
import CardHistory from '../../../Components/CardHistory/CardHistory';
import filehistory from '../../../Images/filehistory.svg';
import useStyles from './style';
import {
  GET_BILL_VOL_USER,
  GET_BILL_VOL_USERVariables,
} from '../../../Graphql/Bill/types/GET_BILL_VOL_USER';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { DO_GET_BILL_VOL_USER } from '../../../Graphql/Bill/query';
import { UserContext } from '../../../Providers/User';
import SnackVariableInterface from '../../../Interfaces/SnackVariableInterface';
import { displaySnackBar } from '../../../Utils/snackBarUtils';
import moment from 'moment';
import Backdrop from '../../../Components/Backdrop/Backdrop';
import { classnames } from '../../../Components/MapComponent/helper';
import {
  GET_RESERVATION_CERIFICATE,
  GET_RESERVATION_CERIFICATEVariables,
} from '../../../Graphql/ReservationCertificate/types/GET_RESERVATION_CERIFICATE';
import { DO_GET_RESERVATION_CERIFICATE } from '../../../Graphql/ReservationCertificate/query';
import ReservationCertificate from '../ReservationCertificate';
interface vol {
  value?: string | null;
  setValue?: (params: any) => void;
  index?: number;
  setIndex: (index: any) => void;
  showDetails?: () => void;
  setLoader?: (loader: any) => void;
}

const HistoriqueVol: FC<vol> = ({
  setValue,
  setIndex,
  showDetails,
  setLoader,
}) => {
  const classes = useStyles();
  const { customerId } = useContext(UserContext);
  const client = useApolloClient();
  const [active, setActive] = useState(0);

  const { data, loading } = useQuery<
    GET_BILL_VOL_USER,
    GET_BILL_VOL_USERVariables
  >(DO_GET_BILL_VOL_USER, {
    variables: { customer_id: customerId },
    skip: customerId ? false : true,
    fetchPolicy: 'cache-and-network',
    onError: (err) => {
      if (err.networkError?.message === 'Failed to fetch') {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Problème de connexion...',
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      }
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: err.message.split(':')[1],
        isOpen: true,
      };
      return displaySnackBar(client, snackBarData);
    },
    onCompleted: () => {
      setValue && setValue(data?.billsFlightUser[0]);
      setLoader && setLoader(loading);
    },
  });

  const { data: dataCertificate, loading: certificateLoading } = useQuery<
    GET_RESERVATION_CERIFICATE,
    GET_RESERVATION_CERIFICATEVariables
  >(DO_GET_RESERVATION_CERIFICATE, {
    variables: { customer_id: customerId },
    skip: customerId ? false : true,
    fetchPolicy: 'cache-and-network',
    onError: (err) => {
      if (err.networkError?.message === 'Failed to fetch') {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Problème de connexion...',
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      }
    },
    onCompleted: () => {
      setLoader && setLoader(certificateLoading);
    },
  });

  const handleClick = (index: any | null) => {
    if (index) {
      setValue && setValue(data?.billsFlightUser[index - 1]);
    }
  };

  const Listehistoriques =
    data &&
    data.billsFlightUser.map((vol, index) => {
      let numero = index + 1;
      return (
        <Box
          key={`${index}`}
          className={classnames(
            classes.demandeContainer,
            active === index ? classes.clicked : '',
          )}
          onClick={() => {
            handleClick(numero);
            setActive(index);
          }}>
          <Backdrop open={loading} />
          <img src={filehistory}></img>
          <CardHistory
            titre={vol.reference}
            date={
              vol.datePaymentBill
                ? moment(vol.datePaymentBill).format('DD-MM-YYYY')
                : '-/-/----'
            }
            price={+vol.amountTotal !== 0 ? +vol.amountTotal : 0}
          />
        </Box>
      );
    });
  return (
    <div>
      <Backdrop open={loading} />
      <ul>{Listehistoriques}</ul>
      {dataCertificate &&
        dataCertificate.getReservationCertificate &&
        dataCertificate.getReservationCertificate.length !== 0 && (
          <Box className={classes.containerFirst}>
            <Box className={classes.titleDocument}>
              <Typography>Attestation de réservation</Typography>
            </Box>
            <Box className={classes.contentDoc}>
              {dataCertificate &&
                dataCertificate.getReservationCertificate.map(
                  ({ type, fileName, url }, index) => (
                    <ReservationCertificate
                      key={index}
                      fileName={fileName}
                      url={url}
                      client={client}
                      typeDown="down"
                      type={type}
                      color="#55A7E"
                      variant="inherit"
                    />
                  ),
                )}
            </Box>
          </Box>
        )}
    </div>
  );
};
export default HistoriqueVol;

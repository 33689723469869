import { Box, useMediaQuery } from '@material-ui/core';
import styles from './style';
import React, { createContext, useContext, useEffect, useState } from 'react';

import Menu from '../Layout/Menu';
import AppBar from '../Layout/AppBar';

interface WrapperComponentProps {
  children?: any;
}

const Context = createContext({
  toggle: () => {},
});

const WrapperComponent = (props: WrapperComponentProps) => {
  const { children } = props;

  let mobile = useMediaQuery('(max-width:1050px)');
  const [open, setOpen] = useState<boolean>(!mobile && true);
  useEffect(() => {
    window.addEventListener('load', () => {
      if (window.innerWidth <= 1050) setOpen(false);
    });
    window.addEventListener('resize', () => {
      if (window.innerWidth <= 1050) setOpen((prev) => (prev ? false : false));
    });
  }, [mobile]);
  const toggleMenu = () => setOpen(!open);
  const classes = styles();

  return (
    <Context.Provider value={{ toggle: toggleMenu }}>
      <Box className={classes.layout}>
        <Menu open={open} />
        <Box className={classes.dashboardContent}>
          <AppBar open={open} />
          <Box className={classes.content}>{children}</Box>
        </Box>
      </Box>
    </Context.Provider>
  );
};

const Wrapper = (WrappedComponent: any) => () => (
  <WrapperComponent>
    <WrappedComponent />
  </WrapperComponent>
);

export const useTheme = () => useContext(Context);
export default Wrapper;

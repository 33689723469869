import React, { FC } from 'react';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const TextInput: FC<TextFieldProps> = (props) => {
  const [isPassword, setIsPassword] = React.useState<boolean>(true);

  const handleClickShowPassword = () => {
    setIsPassword(!isPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  return (
    <TextField
      {...props}
      type={props.type === 'password' && isPassword ? 'password' : 'text'}
      InputProps={
        props.type === 'password'
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    tabIndex={-1}
                    edge="end">
                    {isPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }
          : props.InputProps
      }
    />
  );
};

export default TextInput;

import React, { FC, useContext, useEffect, useState } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { Box, Typography, Button, Paper } from '@material-ui/core';
import useStyles from './style';
import DateAndTime from './../../Components/DateAndTime/DateAndTime';
import moment, { now } from 'moment';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton/IconButton';
import Zoom from '@material-ui/core/Zoom';
import {
  CREATE_BOOKING_HOTEL,
  CREATE_BOOKING_HOTELVariables,
} from '../../Graphql/Hotel/types/CREATE_BOOKING_HOTEL';
import { DO_CREATE_BOOKING_HOTEL } from '../../Graphql/Hotel/mutation';

import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks';
import SnackVariableInterface from '../../Interfaces/SnackVariableInterface';
import { displaySnackBar } from '../../Utils/snackBarUtils';
import { UserContext } from '../../Providers/User';
import { useHistory } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import Backdrop from './../../Components/Backdrop';

import {
  GET_NUMBER_ROOM_AVAILABLE,
  GET_NUMBER_ROOM_AVAILABLEVariables,
} from '../../Graphql/Hotel/types/GET_NUMBER_ROOM_AVAILABLE';
import {
  DO_GET_HOTEL,
  DO_GET_NUMBER_ROOM_AVAILABLE,
} from '../../Graphql/Hotel/query';
import {
  GET_HOTEL,
  GET_HOTELVariables,
} from '../../Graphql/Hotel/types/GET_HOTEL';
import {
  PAIEMENT,
  PAIEMENTVariables,
} from '../../Graphql/Paiement/types/PAIEMENT';
import { DO_PAIEMENT } from '../../Graphql/Paiement/mutation';
import { PayementVanillaContext } from '../../Providers/PayementVanilla';
import UploadFiles from '../../Components/UploadFiles';
import { DO_CREATE_DOCUMENT_USER } from '../../Graphql/UploadFile/mutation';
import {
  CREATE_DOCUMENT_USER,
  CREATE_DOCUMENT_USERVariables,
} from '../../Graphql/UploadFile/types/CREATE_DOCUMENT_USER';

import { useUploadFile } from '../../Utils/uploadFIle';
import { HotelFilterContext } from '../../Providers/HotelFilter';

const separatorOfMillier = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

const MakeReservation: FC<RouteComponentProps> = () => {
  const classes = useStyles();
  const [helper, setHelper] = useState<string>('Veuiller choisir une date');
  const client = useApolloClient();
  const [loadingConfirmPaiement, setLoadingConfirmPaiement] = useState<boolean>(
    false,
  );
  const { customerId, idMe } = useContext(UserContext);
  const textComment =
    "Vous devrez présenter une pièce d'identité avec photo et une carte de crédit lors de l'enregistrement. Veuillez noter que toutes les demandes spéciales seront satisfaites sous réserve de disponibilité et pourront entraîner des frais supplémentaires.";

  const { setMontant, setIdPanier, setReference } = useContext(
    PayementVanillaContext,
  );
  const { dateArriveContext, dateDepartContext } = useContext(
    HotelFilterContext,
  );
  const { idHotel, idRoom }: { idHotel: string; idRoom: string } = useParams();
  const history = useHistory();

  const [show, setShow] = useState<boolean>(false);
  const [dateDepart, setDepartDate] = useState<Date | null>(dateDepartContext);
  const [dateArrive, setArriveDate] = useState<Date | null>(dateArriveContext);
  const [selectChambre, setSelectChambre] = useState(0);
  const [nbrJourPromo, setNbrJourPromo] = useState(0);
  const [nbrJourSansPromo, setNbrJourSansPromo] = useState(0);
  const [dateFirstPromo, setDateFirstPromo] = useState<Date>();
  const [dateLastPromo, setDateLastPromo] = useState<Date>();
  const [cas, setCas] = useState<number>(0);
  const [pricePromo, setPricePromo] = useState<number>(0);
  const [priceSansPromo, setPriceSansPromo] = useState<number>(0);
  const [room, setRoom] = useState<any>();

  let nbrJour =
    Math.abs(
      moment(dateArrive, 'YYYY-MM-DD')
        .startOf('day')
        .diff(moment(dateDepart, 'YYYY-MM-DD').startOf('day'), 'days'),
    ) === 0
      ? 1
      : Math.abs(
          moment(dateArrive, 'YYYY-MM-DD')
            .startOf('day')
            .diff(moment(dateDepart, 'YYYY-MM-DD').startOf('day'), 'days'),
        );
  const [
    bookingCreated,
    setBookingCreated,
  ] = useState<CREATE_BOOKING_HOTEL | null>(null);
  const [rateHotel, setRateHotel] = useState<number>(0);
  const [rateDay, setRateDay] = useState<number>(0);

  const { uploadFile, getFiles, loading: loadingFile } = useUploadFile();
  const [files, setFiles] = useState<File[] | null>(null);

  const numberArroud = (value: number) => {
    if (value % 100 === 0) {
      return value;
    } else {
      return Math.ceil((value + 1) / 100) * 100;
    }
  };

  const { data: dataHotel, loading: loadingHotel } = useQuery<
    GET_HOTEL,
    GET_HOTELVariables
  >(DO_GET_HOTEL, {
    onError: (err) => {
      if (err.networkError?.message === 'Failed to fetch') {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Problème de connexion...',
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      }
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: err.message.split(':')[1],
        isOpen: true,
      };
      return displaySnackBar(client, snackBarData);
    },
    onCompleted: () => {
      if (dataHotel) {
        let roomTemp = dataHotel.hotel.rooms.filter((r) => r.id === idRoom)[0];
        setRoom(roomTemp);
        let { rateDay } = roomTemp;
        setPriceSansPromo(numberArroud(rateDay));
        if (dataHotel.hotel.promotionHotels.length > 0) {
          setPricePromo(
            rateDay -
              (numberArroud(rateDay) *
                dataHotel.hotel.promotionHotels[0].ratePercentage) /
                100,
          );

          // compteur jour promo
          setNbrJourPromo(
            Math.abs(
              moment(dataHotel.hotel.promotionHotels[0].dateFirst, 'YYYY-MM-DD')
                .startOf('day')
                .diff(
                  moment(
                    dataHotel.hotel.promotionHotels[0].dateLast,
                    'YYYY-MM-DD',
                  ).startOf('day'),
                  'days',
                ),
            ) === 0
              ? 1
              : Math.abs(
                  moment(
                    dataHotel.hotel.promotionHotels[0].dateFirst,
                    'YYYY-MM-DD',
                  )
                    .startOf('day')
                    .diff(
                      moment(
                        dataHotel.hotel.promotionHotels[0].dateLast,
                        'YYYY-MM-DD',
                      ).startOf('day'),
                      'days',
                    ),
                ) + 1,
          );

          //dateJourDebut promo
          setDateFirstPromo(
            new Date(dataHotel.hotel.promotionHotels[0].dateFirst),
          );

          //dateJourFin promo
          setDateLastPromo(
            new Date(dataHotel.hotel.promotionHotels[0].dateLast),
          );
        }
      }
    },
    variables: { id: idHotel },
  });

  useEffect(() => {
    if (
      customerId === null ||
      customerId === undefined ||
      idMe === null ||
      idMe === undefined
    ) {
      history.push('/accueil/Booking-Hotel');
    }
  }, [customerId, idMe]);

  const { data: dataNumberRoom } = useQuery<
    GET_NUMBER_ROOM_AVAILABLE,
    GET_NUMBER_ROOM_AVAILABLEVariables
  >(DO_GET_NUMBER_ROOM_AVAILABLE, {
    onError: (err) => {
      if (err.networkError?.message === 'Failed to fetch') {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Problème de connexion...',
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      }
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: err.message.split(':')[1],
        isOpen: true,
      };
      return displaySnackBar(client, snackBarData);
    },
    variables: { idRoom },
  });

  const [doCreateDocument] = useMutation<
    CREATE_DOCUMENT_USER,
    CREATE_DOCUMENT_USERVariables
  >(DO_CREATE_DOCUMENT_USER, {
    onError: (err) => {
      if (err.networkError?.message === 'Failed to fetch') {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Problème de connexion...',
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      }
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: err.message.split(':')[1],
        isOpen: true,
      };
      return displaySnackBar(client, snackBarData);
    },
    onCompleted: async (data) => {
      await uploadFile(files);
      setFiles([]);
    },
  });

  const [doCreateBoking, { loading }] = useMutation<
    CREATE_BOOKING_HOTEL,
    CREATE_BOOKING_HOTELVariables
  >(DO_CREATE_BOOKING_HOTEL, {
    onError: (err) => {
      if (err.networkError?.message === 'Failed to fetch') {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Problème de connexion...',
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      }
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: err.message.split(':')[1],
        isOpen: true,
      };
      return displaySnackBar(client, snackBarData);
    },
    onCompleted: async (data) => {
      if (data.createBooking && dataHotel) {
        setBookingCreated(data);
        setRateHotel(
          numberArroud(
            nbrJourPromo * pricePromo * selectChambre +
              nbrJourSansPromo * priceSansPromo * selectChambre,
          ),
        );

        doPaiment({
          variables: {
            typeOrder: 'BOOKING',
            booking: data.createBooking.booking.id,
            reference: 'HOB' + data.createBooking.booking.reference,
            paymentMethod: 'PAIEMENT_MOBILE_MONEY',
            datePayment: moment(now()).utc().unix(),
            amountPayment:
              nbrJourPromo * pricePromo * selectChambre +
              nbrJourSansPromo * priceSansPromo * selectChambre,
          },
        });
      }
    },
  });

  const calculeNbrJour = (dateBegin: Date, dateEnd: Date) => {
    //ce calcul se fait par nuit

    return Math.abs(
      moment(dateBegin, 'YYYY-MM-DD')
        .startOf('day')
        .diff(moment(dateEnd, 'YYYY-MM-DD').startOf('day'), 'days'),
    ) === 0
      ? 1
      : Math.abs(
          moment(dateBegin, 'YYYY-MM-DD')
            .startOf('day')
            .diff(moment(dateEnd, 'YYYY-MM-DD').startOf('day'), 'days'),
        );
  };
  //sert à capturer les changements sur nombre de jour saisies par l'utilisateur

  useEffect(() => {
    if (selectChambre > 0) {
      setShow(false);
      window.setTimeout(() => {
        setShow(true);
      }, 1000);
    }

    if (dataHotel && dateDepart && dateArrive) {
      if (dateFirstPromo && dateLastPromo) {
        //cas 1
        if (
          moment(dateArrive).isBefore(dateFirstPromo, 'days') &&
          moment(
            moment(dateLastPromo, 'DD-MM-YYYY').add(1, 'days').toDate(),
          ).isBefore(dateDepart, 'days')
        ) {
          setCas(1);
          setNbrJourSansPromo(nbrJour - nbrJourPromo);
        }

        //cas 2
        if (
          moment(
            moment(dateFirstPromo, 'DD-MM-YYYY').subtract(1, 'days').toDate(),
          ).isBefore(dateArrive, 'days') &&
          moment(dateLastPromo).isBefore(
            moment(dateDepart, 'DD-MM-YYYY').subtract(1, 'days').toDate(),
            'days',
          ) &&
          moment(
            moment(dateArrive, 'DD-MM-YYYY').subtract(1, 'days').toDate(),
          ).isBefore(
            moment(dateLastPromo, 'DD-MM-YYYY').add(1, 'days').toDate(),
            'days',
          )
        ) {
          setCas(2);

          setNbrJourPromo(calculeNbrJour(dateArrive, dateLastPromo));

          setNbrJourSansPromo(calculeNbrJour(dateLastPromo, dateDepart));
        }

        //cas 3
        if (
          moment(dateArrive).isBefore(dateFirstPromo, 'days') &&
          moment(
            moment(dateFirstPromo, 'DD-MM-YYYY').subtract(1, 'days').toDate(),
          ).isBefore(dateDepart, 'days') &&
          moment(
            moment(dateDepart, 'DD-MM-YYYY').subtract(1, 'days').toDate(),
          ).isBefore(
            moment(dateLastPromo, 'DD-MM-YYYY').add(1, 'days').toDate(),
            'days',
          )
        ) {
          setCas(3);

          setNbrJourPromo(calculeNbrJour(dateFirstPromo, dateDepart));

          setNbrJourSansPromo(calculeNbrJour(dateArrive, dateFirstPromo) - 1);
        }

        //cas 4
        if (
          moment(
            moment(dateFirstPromo, 'DD-MM-YYYY').subtract(1, 'days').toDate(),
          ).isBefore(dateArrive, 'days') &&
          moment(
            moment(dateDepart, 'DD-MM-YYYY').subtract(1, 'days').toDate(),
          ).isBefore(
            moment(dateLastPromo, 'DD-MM-YYYY').add(1, 'days').toDate(),
            'days',
          )
        ) {
          setCas(4);

          setNbrJourSansPromo(0);
          setNbrJourPromo(calculeNbrJour(dateArrive, dateDepart));
        }

        //cas 5
        if (
          moment(dateDepart).isBefore(dateFirstPromo, 'days') ||
          moment(dateLastPromo).isBefore(
            moment(dateArrive, 'DD-MM-YYYY').subtract(1, 'days').toDate(),
            'days',
          )
        ) {
          setCas(5);

          setNbrJourPromo(0);
          setNbrJourSansPromo(calculeNbrJour(dateArrive, dateDepart));
        }
      } else {
        setNbrJourPromo(0);
        setNbrJourSansPromo(calculeNbrJour(dateArrive, dateDepart));
      }
    }
  }, [nbrJour, dateArrive, dateDepart, dateLastPromo]);

  const [doPaiment, { loading: loadingPaiement }] = useMutation<
    PAIEMENT,
    PAIEMENTVariables
  >(DO_PAIEMENT, {
    onCompleted: (data) => {
      setMontant(rateHotel);
      setIdPanier('HOB' + bookingCreated?.createBooking.booking.reference);
      setReference('HOB' + bookingCreated?.createBooking.booking.reference);
      history.push('/paiement');
    },
    onError: (err) => {
      if (err.networkError?.message === 'Failed to fetch') {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Problème de connexion...',
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      }
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: err.message.split(':')[1],
        isOpen: true,
      };
      return displaySnackBar(client, snackBarData);
    },
  });

  const ajoutChambre = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    event.preventDefault();
    setSelectChambre((prevValue: number) => {
      return prevValue + 1;
    });
    if (dateDepart && dateArrive && selectChambre === 0) {
      setShow(false);
      window.setTimeout(() => {
        setShow(true);
      }, 1000);
    }
  };
  const retirChambre = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    event.preventDefault();
    setSelectChambre((prevValue: number) => {
      return prevValue - 1;
    });
    if (selectChambre === 1) {
      setShow(false);
    }
  };

  const handleConfirmPaiement = () => {
    setLoadingConfirmPaiement(true);
    console.log(` setLoadingConfirmPaiement ${loadingConfirmPaiement}`);
    if (dataHotel) {
      //setLoadingConfirmPaiement(false);
      console.log(` setLoadingConfirmPaiement ${loadingConfirmPaiement}`);
      doCreateBoking({
        variables: {
          customerId,
          hotelId: dataHotel.hotel.id,
          roomId: idRoom,
          dateBooking: moment(now()).unix(),
          dateFirst: moment(dateDepart).unix(),
          dateLast: moment(dateArrive).unix(),
          numberofBooking: selectChambre,
          dateBookingFirstPromo: moment(
            dataHotel.hotel.promotionHotels.length === 0 ||
              moment(dateDepart).isBefore(dateFirstPromo, 'days') ||
              moment(dateLastPromo).isBefore(dateArrive, 'days')
              ? undefined
              : cas === 2
              ? dateArrive
              : cas === 3
              ? dateFirstPromo
              : cas === 4
              ? dateArrive
              : dateFirstPromo,
          ).unix(),
          dateBookingLastPromo: moment(
            dataHotel.hotel.promotionHotels.length === 0 ||
              moment(dateDepart).isBefore(dateFirstPromo, 'days') ||
              moment(dateLastPromo).isBefore(dateArrive, 'days')
              ? undefined
              : cas === 2
              ? dateLastPromo
              : cas === 3
              ? dateDepart
              : cas === 4
              ? dateDepart
              : dateLastPromo,
          ).unix(),
          dateBookingFirstWithoutPromo: moment(
            moment(
              moment(dateFirstPromo, 'DD-MM-YYYY').subtract(1, 'days').toDate(),
            ).isBefore(dateArrive, 'days') &&
              moment(dateDepart).isBefore(
                moment(dateLastPromo, 'DD-MM-YYYY').add(1, 'days').toDate(),
                'days',
              )
              ? undefined
              : cas === 1
              ? dateArrive
              : cas === 2
              ? dateLastPromo
              : cas === 3
              ? dateArrive
              : dateArrive,
          ).unix(),
          dateBookingLastWithoutPromo: moment(
            moment(
              moment(dateFirstPromo, 'DD-MM-YYYY').subtract(1, 'days').toDate(),
            ).isBefore(dateArrive, 'days') &&
              moment(dateDepart).isBefore(
                moment(dateLastPromo, 'DD-MM-YYYY').add(1, 'days').toDate(),
                'days',
              )
              ? undefined
              : cas === 1
              ? dateDepart
              : cas === 2
              ? dateDepart
              : cas === 3
              ? dateFirstPromo
              : dateDepart,
          ).unix(),
          amountWithSolde: nbrJourPromo * pricePromo * selectChambre,
          amountWithoutSolde: nbrJourSansPromo * priceSansPromo * selectChambre,
          numberDayBookingPromo: nbrJourPromo,
          numberDayBookingWithoutPromo: nbrJourSansPromo,
        },
      });
    }

    //navigation.navigate('PaiementVanilla');
  };

  const onChangeFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filesConcat = Array.from(event.target.files || []);
    if (files === null) {
      setFiles(filesConcat);
    } else {
      setFiles((prevState: any) => {
        if (prevState !== null) {
          return [...prevState, ...filesConcat];
        }
        return [];
      });
    }
  };

  const onDeleteFile = (name: string) => {
    const filesFilter =
      files?.filter((file: { name: string }) => file.name !== name) || [];
    setFiles(filesFilter);
  };

  if (loadingHotel) {
    return null;
  }

  return (
    <Box className={classes.allContent}>
      <Backdrop open={loadingConfirmPaiement} />
      <Box className={classes.firstContent}>
        <Paper>
          <Typography
            variant="h1"
            classes={{ subtitle2: classes.firstContentTitle }}>
            Réservation
          </Typography>
          <Typography variant="h6">{dataHotel?.hotel.name}</Typography>
          <p>{dataHotel?.hotel.city}</p>
          <Typography variant="h6">
            {
              dataHotel?.hotel.rooms.filter((r) => r.id === idRoom)[0].typeRoom
                .name
            }
          </Typography>
          <p>
            À quelle période souhaitez-vous séjourner dans l&apos;établissement
          </p>
        </Paper>
        <Paper className={classes.contentDatePicker}>
          <DateAndTime
            titre="Date d'arrivée"
            setValue={setArriveDate}
            //onChange={applyFilter}
            setHelper={setHelper}
            helper={
              dateArrive === null && dateDepart !== null
                ? "Veuiller ajouter une date d'arrivée"
                : dateArriveContext
                ? ''
                : helper
            }
            error={
              dateArrive === null && dateDepart !== null
                ? true
                : helper === ''
                ? false
                : true
            }
            valueDate={dateArrive}
            dateDepart={dateDepart}
            setDateDepart={setDepartDate}
            type={'Hotel'}
          />
          <DateAndTime
            titre="Date de départ"
            setValue={setDepartDate}
            //onChange={applyFilter}
            error={
              dateArrive && dateDepart && dateArrive > dateDepart
                ? true
                : dateArrive !== null && dateDepart === null
                ? true
                : false
            }
            helper={
              dateArrive && dateDepart && dateArrive > dateDepart
                ? 'Date de départ inferieur à date Arrivé'
                : dateArrive !== null && dateDepart === null
                ? 'Veuiller choisir une date de départ'
                : ''
            }
            valueDate={dateDepart}
            setArriveDate={setArriveDate}
            arriveDate={dateArrive}
            type={'Hotel'}
          />
          <Box className={classes.contentInputAlign}>
            <label className={classes.colorLabel}>
              Selectionner des chambres
            </label>
            <Box className={classes.contentIncrement}>
              <IconButton
                color="primary"
                disabled={selectChambre === 0}
                // classes={{ root }}
                onClick={retirChambre}
                href="#">
                <RemoveIcon />
              </IconButton>
              <span>{selectChambre}</span>

              <Tooltip
                TransitionComponent={Zoom}
                open={
                  dateArrive !== null &&
                  dateDepart !== null &&
                  selectChambre === 0
                }
                title="Veuillez ajouter une chambre">
                <IconButton color="primary" onClick={ajoutChambre} href="#">
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          <UploadFiles
            files={files}
            onChangeFiles={onChangeFiles}
            onClickFile={onDeleteFile}
          />
          <Box className={classes.ButtonReservationContent}>
            <Button
              variant="contained"
              color="primary"
              className={classes.firstButton}
              onClick={() => history.goBack()}>
              Retour
            </Button>
          </Box>
        </Paper>
      </Box>
      {selectChambre !== 0 && dataHotel && show ? (
        <Box className={classes.secondContent}>
          <Typography variant="h6"> Vous avez choisi</Typography>
          <Typography className={classes.resultContent}>
            Du : {moment(dateArrive).format(' DD MMMM YYYY')}
          </Typography>
          <Typography className={classes.resultContent}>
            Au : {moment(dateDepart).format(' DD MMMM YYYY')}
          </Typography>
          {dataHotel.hotel.promotionHotels.length > 0 && nbrJourPromo !== 0 ? (
            <>
              <Box className={classes.contentRecat}>
                <Box className={classes.firstListChambre}>
                  <Typography variant="h6">{'La promotion sera '}</Typography>
                </Box>
                <Box style={{ display: 'flex', flexDirection: 'row' }}>
                  <Typography style={{ fontWeight: 'bold' }} variant="h6">
                    {'Du:'}
                  </Typography>
                  <Typography variant="h6">
                    &nbsp;&nbsp;
                    {moment(dateFirstPromo).format('dddd DD MMMM YYYY')}
                  </Typography>
                </Box>
                <Box style={{ display: 'flex', flexDirection: 'row' }}>
                  <Typography style={{ fontWeight: 'bold' }} variant="h6">
                    {'Au:'}
                  </Typography>
                  <Typography variant="h6">
                    &nbsp;&nbsp;
                    {moment(dateLastPromo).format('dddd DD MMMM YYYY')}
                  </Typography>
                </Box>
              </Box>
              <Box className={classes.contentRecat}>
                <Box className={classes.firstListChambre}>
                  <Typography variant="h6">
                    {room && room.typeRoom.name + '(avec promotion)'}
                  </Typography>
                  <Typography variant="h6">
                    {separatorOfMillier(
                      nbrJourPromo * pricePromo * selectChambre,
                    )}
                    &nbsp;Ar
                  </Typography>
                </Box>
                <Box style={{ display: 'flex', flexDirection: 'row' }}>
                  <Typography variant="h6">
                    &nbsp;&nbsp;
                    {room.typeRoom.typeBed}
                  </Typography>
                </Box>
                <Box style={{ display: 'flex', flexDirection: 'row' }}>
                  <Typography variant="h6">
                    &nbsp;&nbsp;
                    {dataHotel.hotel &&
                      room.typeRoom.numberPerson + ' personne(s)'}
                  </Typography>
                </Box>
              </Box>
            </>
          ) : null}
          {nbrJourSansPromo !== 0 && (
            <>
              <Box className={classes.contentRecat}>
                <Box className={classes.firstListChambre}>
                  <Typography variant="h6">
                    {room && room.typeRoom.name + '(sans promotion)'}
                  </Typography>
                  <Typography variant="h6">
                    {separatorOfMillier(
                      nbrJourSansPromo * priceSansPromo * selectChambre,
                    )}
                    &nbsp;Ar
                  </Typography>
                </Box>
                <Box style={{ display: 'flex', flexDirection: 'row' }}>
                  <Typography variant="h6">
                    &nbsp;&nbsp;
                    {room && room.typeRoom.typeBed}
                  </Typography>
                </Box>
                <Box style={{ display: 'flex', flexDirection: 'row' }}>
                  <Typography variant="h6">
                    &nbsp;&nbsp;
                    {room && room.typeRoom.numberPerson + ' personne(s)'}
                  </Typography>
                </Box>
              </Box>
            </>
          )}
          <Typography variant="h6" className={classes.textTransormCapitalize}>
            à savoir
          </Typography>
          <Typography variant="h6" className={classes.textTransorm}>
            {(room && room.comment) || textComment}
          </Typography>
          <Paper className={classes.contentTotal}>
            <Typography variant="h6">Total à payer </Typography>

            <Typography variant="h6">
              {separatorOfMillier(
                nbrJourPromo * pricePromo * selectChambre +
                  nbrJourSansPromo * priceSansPromo * selectChambre,
              )}{' '}
              Ar{' '}
            </Typography>
          </Paper>

          <Box className={classes.contntBtnRsrv}>
            <Button
              variant="contained"
              color="primary"
              className={classes.firstButton}
              onClick={() => history.goBack()}>
              Rejeter
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleConfirmPaiement}>
              OK
            </Button>
          </Box>
        </Box>
      ) : (
        dateArrive &&
        dateDepart &&
        selectChambre > 0 &&
        show === false && (
          <div className={classes.loaders}>
            <div className={classes.ball3}></div>
          </div>
        )
      )}
    </Box>
  );
};

export default MakeReservation;

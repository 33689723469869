import gql from 'graphql-tag';

export const DO_CREATE_BOOKING_HOTEL = gql`
  mutation CREATE_BOOKING_HOTEL(
    $customerId: String!
    $dateBooking: Date
    $dateFirst: Date
    $dateLast: Date
    $hotelId: String
    $roomId: String!
    $numberofBooking: Int!
    $dateBookingFirstPromo: Date
    $dateBookingLastPromo: Date
    $dateBookingFirstWithoutPromo: Date
    $dateBookingLastWithoutPromo: Date
    $numberDayBookingPromo: Int
    $numberDayBookingWithoutPromo: Int
    $amountWithSolde: Float
    $amountWithoutSolde: Float
  ) {
    createBooking(
      customerId: $customerId
      dateBooking: $dateBooking
      dateFirst: $dateFirst
      dateLast: $dateLast
      hotelId: $hotelId
      roomId: $roomId
      numberofBooking: $numberofBooking
      dateBookingFirstPromo: $dateBookingFirstPromo
      dateBookingLastPromo: $dateBookingLastPromo
      dateBookingFirstWithoutPromo: $dateBookingFirstWithoutPromo
      dateBokingLastWithoutPromo: $dateBookingLastWithoutPromo
      numberDayBookingPromo: $numberDayBookingPromo
      numberDayBookingWithoutPromo: $numberDayBookingWithoutPromo
      amountWithSolde: $amountWithSolde
      amountWithoutSolde: $amountWithoutSolde
    ) {
      message
      booking {
        id
        reference
        roomId
        hotelId
        numberofBooking
        numberDayofBooking
      }
    }
  }
`;

export const DO_CREATE_REQUEST_BOOKING = gql`
  mutation CREATE_REQUEST_BOOKING(
    $customerId: String!
    $dateRequestBooking: Date
    $dateFirst: Date
    $dateLast: Date
    $hotelId: String
    $manifest: JSON!
    $comment: String
  ) {
    createRequestBooking(
      customerId: $customerId
      dateFirst: $dateFirst
      dateLast: $dateLast
      dateRequestBooking: $dateRequestBooking
      hotelId: $hotelId
      manifest: $manifest
      comment: $comment
    ) {
      requestBooking {
        id
      }
      message
    }
  }
`;

export const DO_REJECTING_REQUEST_BOOKING = gql`
  mutation REJECTING_REQUEST_BOOKING($requestBooking_id: String!) {
    rejectingRequestBooking(requestBooking_id: $requestBooking_id) {
      message
    }
  }
`;

import React, { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import useStyles from './style';
import Box from '@material-ui/core/Box';

interface propsCradHistory {
  titre?: string;
  telephone?: string;
  Adresse?: string;
  date?: string;
  price?: number;
  Nom?: string;
}
const CardHistory: FC<propsCradHistory> = ({
  titre,
  date,
  price,
  telephone,
  Adresse,
  Nom,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.contentCard}>
      <Box className={classes.contentDescription}>
        <Box>
          {titre ? (
            <p className={classes.titreOffres}> Numéro de facture : {titre}</p>
          ) : null}
          {Nom ? <p className={classes.titreOffres}>{Nom}</p> : null}

          {telephone ? (
            <p className={classes.contentTélephone}>{telephone}</p>
          ) : null}
          {Adresse ? (
            <p className={classes.contentTélephone}>{Adresse}</p>
          ) : null}
          {date ? (
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              className={classes.priceDeco}>
              Le : {date}
            </Typography>
          ) : null}

          {price ? (
            <Box className={classes.contentPrice}>
              <Typography className={classes.priceDeco}>
                Total : {price} Ar
              </Typography>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};
export default CardHistory;

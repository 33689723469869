import gql from 'graphql-tag';

export const HOTEL_INFO_FRAGEMENT = gql`
  fragment HotelInfo on HotelPayloadSearch {
    id
    name
    city
    pictureHotels {
      urlPicture
      typePicture
    }
    promotionHotels {
      id
      ratePercentage
      dateFirst
      dateLast
    }
    rateDay
    ratePromotion
    numberStar
    description
    rooms {
      id
      typeRoom {
        id
        name
        codeType
        status
      }
      typeBed
      numberOfPerson
      rateDay
    }
  }
`;

export const REQUEST_BOOKING_INFO_FRAGMENT = gql`
  fragment RequestBookingInfo on RequestBooking {
    id
    reference
    dateRequestBooking
    dateFirst
    dateLast
    manifest
    comment
    status
    hotel {
      id
      name
      city
      rateDay
      rooms {
        id
        typeRoom {
          id
          name
          codeType
          status
        }
        typeBed
        numberOfPerson
        rateDay
      }
    }
    ResponseBooking {
      id
      rateHotel
      comment
    }
  }
`;

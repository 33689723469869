import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function Bag(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 20.723 18.334">
      <g
        id="Groupe_742"
        data-name="Groupe 742"
        transform="translate(-33.98 -85.91)">
        <path
          id="Tracé_2034"
          data-name="Tracé 2034"
          d="M48.928,88.97c1.3,0,2.553,0,3.8,0,1.234,0,1.835.68,1.951,1.913a4.24,4.24,0,0,1-1.414,3.632,12.739,12.739,0,0,1-18.565-.82c-.962-1.144-.775-2.411-.5-3.658a1.489,1.489,0,0,1,1.6-1.064c1.126-.008,2.253,0,3.379,0h.583c0-.483-.008-.914,0-1.345a1.689,1.689,0,0,1,1.691-1.7q2.888-.022,5.777,0a1.693,1.693,0,0,1,1.69,1.707C48.936,88.046,48.928,88.464,48.928,88.97Zm-8,0h.6c1.58,0,3.161,0,4.741,0,.418,0,.751.114.753.578s-.349.583-.755.582c-.563,0-1.126,0-1.689,0-2.87,0-5.74-.008-8.61.016-.243,0-.685.211-.692.343-.047.839-.319,1.7.333,2.484a11.577,11.577,0,0,0,16.788.78,3.092,3.092,0,0,0,1.137-2.767c-.092-.7-.164-.855-.885-.857-1.326,0-2.652,0-3.978,0-.74,0-.879-.156-.886-.909,0-.508,0-1.017-.012-1.526a.554.554,0,0,0-.61-.612q-2.806-.006-5.613,0a.556.556,0,0,0-.615.609C40.917,88.083,40.923,88.482,40.923,88.969Z"
          transform="translate(0 0)"
        />
        <path
          id="Tracé_2035"
          data-name="Tracé 2035"
          d="M44.57,155.9q-4.2,0-8.4,0a1.7,1.7,0,0,1-1.854-1.767c-.023-2.344-.007-4.689-.008-7.033,0-.412.13-.752.6-.737s.561.363.56.772q-.011,3.353-.005,6.706c0,.77.128.9.874.9l16.411.006c.951,0,1.038-.086,1.038-1.016,0-2.2,0-4.4,0-6.6,0-.38.063-.721.5-.767.468-.049.647.256.649.679.006.963,0,1.926,0,2.89,0,1.29.009,2.581,0,3.871-.006,1.463-.638,2.095-2.087,2.1q-4.144,0-8.288,0Z"
          transform="translate(-0.279 -51.656)"
        />
      </g>
    </SvgIcon>
  );
}

import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  contentButtonAjout: {
    display: 'flex',
    justifyContent: 'start',
    width: '100%',
    height: 'auto',
    padding: '0 !important',
    '& .MuiIconButton-label': {
      height: '27px',
    },
    '& .MuiButtonBase-root': {
      width: '27px !important',
    },
  },
  contentIncrementContent: {
    width: 'auto !important',
  },
  contentIncrement: {
    display: 'flex',
    width: '40%',
    justifyContent: 'space-between;',
    alignItems: 'center',
    alignContent: 'center',
    '& >span': {
      fontSize: 12,
    },
    '& .MuiIconButton-colorPrimary ': {
      color: '#f4f4f5',
      width: '22px',
      height: '22px',
      background: '#55A7EE',
      fontSize: 12,
      borderRadius: '2px',

      '& .MuiIconButton-colorPrimary:hover': {
        // backgroundColor: 'gray',
      },
    },
    '& .MuiIconButton-root.Mui-disabled': {
      borderRadius: 2,
      backgroundColor: '#CAD1DE',
    },
  },
  modifBouton: {
    display: 'flex !important',
    fontSize: '16px',
    width: '100%',
    justifyContent: 'space-between',
  },
  fileName: {
    // maxWidth: 347,
    width: 'auto',
    overflowX: 'hidden',
    display: 'flex',
    scroll: 'none',
    alignItems: 'center',
    scrollbarWidth: 'none',
    '& .scrollbar-hidden:-webkit-scrollbar ': {
      display: 'none',
    },
    '&::-webkit-scrollbar': {
      height: 10,
    },
    '&:hover': {
      overflowX: 'scroll',
    },
  },
}));

import React, { FC, useContext, useState } from 'react';
import useStyles from './style';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks';
import {
  MY_REQUEST_BOKING_PROCESSING,
  MY_REQUEST_BOKING_PROCESSINGVariables,
  MY_REQUEST_BOKING_PROCESSING_myRequestBookingProcessing,
} from '../../Graphql/Hotel/types/MY_REQUEST_BOKING_PROCESSING';
import { DO_MY_REQUEST_BOKING_PROCESSING } from '../../Graphql/Hotel/query';
import SnackVariableInterface from '../../Interfaces/SnackVariableInterface';
import { displaySnackBar } from '../../Utils/snackBarUtils';
import { UserContext } from '../../Providers/User';
import moment, { now } from 'moment';
import {
  PAIEMENT,
  PAIEMENTVariables,
} from '../../Graphql/Paiement/types/PAIEMENT';
import { DO_PAIEMENT } from '../../Graphql/Paiement/mutation';
import { PayementVanillaContext } from '../../Providers/PayementVanilla';
import { DO_REJECTING_REQUEST_BOOKING } from '../../Graphql/Hotel/mutation';
import {
  REJECTING_REQUEST_BOOKING,
  REJECTING_REQUEST_BOOKINGVariables,
} from '../../Graphql/Hotel/types/REJECTING_REQUEST_BOOKING';
import Backdrop from '../../Components/Backdrop';

const separatorOfMillier = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

const ReservationRecap: FC<RouteComponentProps> = () => {
  const classes = useStyles();
  const history = useHistory();

  const client = useApolloClient();
  const { idMe } = useContext(UserContext);
  const { setMontant, setIdPanier, setReference } = useContext(
    PayementVanillaContext,
  );

  const [
    selectedBooking,
    setSelectedBooking,
  ] = useState<MY_REQUEST_BOKING_PROCESSING_myRequestBookingProcessing | null>(
    null,
  );
  // const [manifest, setManifet] = useState();
  // const [priceWithTVA, setPriceWithTVA] = useState<number>(0);

  const { data: dataProcess, loading: loadingProcess, refetch } = useQuery<
    MY_REQUEST_BOKING_PROCESSING,
    MY_REQUEST_BOKING_PROCESSINGVariables
  >(DO_MY_REQUEST_BOKING_PROCESSING, {
    variables: { user_id: idMe },
    skip: idMe ? false : true,
    fetchPolicy: 'network-only',
    onError: (err) => {
      if (err.networkError?.message === 'Failed to fetch') {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Problème de connexion...',
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      }
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: err.message.split(':')[1],
        isOpen: true,
      };
      return displaySnackBar(client, snackBarData);
    },
    onCompleted: (data) => {
      if (data && data.myRequestBookingProcessing) {
        const booking = data.myRequestBookingProcessing[0];
        setSelectedBooking(booking);
        // const manifest =
        //   booking && booking.manifest && JSON.parse(booking.manifest);
        // setManifet(manifest);
        // console.log('manifest', manifest)
        // if (
        //   booking &&
        //   booking.ResponseBooking &&
        //   booking.ResponseBooking[0] &&
        //   booking.ResponseBooking[0].rateHotel
        // ) {
        //   const tva = (20 * booking.ResponseBooking[0].rateHotel) / 100;
        //   const price = booking.ResponseBooking[0].rateHotel + tva;
        //   setPriceWithTVA(price);
        // } else {
        //   setPriceWithTVA(-1);
        // }
      }
    },
  });

  const [doRejectRequestBooking, { loading: loadingReject }] = useMutation<
    REJECTING_REQUEST_BOOKING,
    REJECTING_REQUEST_BOOKINGVariables
  >(DO_REJECTING_REQUEST_BOOKING, {
    onCompleted: (data) => {
      const snackBarData: SnackVariableInterface = {
        type: 'SUCCESS',
        message: 'La demande a été rejetée',
        isOpen: true,
      };
      displaySnackBar(client, snackBarData);
      setSelectedBooking(null);
      refetch();
    },
    onError: (err) => {
      if (err.networkError?.message === 'Failed to fetch') {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Problème de connexion...',
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      }
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: err.message.split(':')[1],
        isOpen: true,
      };
      return displaySnackBar(client, snackBarData);
    },
  });

  const [doPaiment, { loading: loadingPaiement }] = useMutation<
    PAIEMENT,
    PAIEMENTVariables
  >(DO_PAIEMENT, {
    onCompleted: (data) => {
      setMontant(selectedBooking?.ResponseBooking[0].rateHotel || 0);
      setIdPanier('HOR' + selectedBooking?.reference);
      setReference('HOR' + selectedBooking?.reference);
      refetch();
      history.push('/paiement');
    },
    onError: (err) => {
      if (err.networkError?.message === 'Failed to fetch') {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Problème de connexion...',
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      }
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: err.message.split(':')[1],
        isOpen: true,
      };
      return displaySnackBar(client, snackBarData);
    },
  });

  const onPressConfirmer = () => {
    doPaiment({
      variables: {
        typeOrder: 'REQUEST_BOOKING',
        requestBooking: selectedBooking?.id,
        reference: 'HOR' + selectedBooking?.reference,
        paymentMethod: 'PAIEMENT_MOBILE_MONEY',
        datePayment: moment(now()).utc().unix(),
      },
    });
  };

  const handleRejectRequestBooking = () => {
    if (selectedBooking) {
      doRejectRequestBooking({
        variables: {
          requestBooking_id: selectedBooking.id,
        },
      });
    }
  };

  const selectBooking = (id: string) => {
    const booking = dataProcess?.myRequestBookingProcessing.filter(
      (b) => b.id === id,
    );
    if (booking && booking[0]) {
      setSelectedBooking(booking[0]);
      // const manifest = JSON.parse(booking[0].manifest);
      // setManifet(manifest);
      // if (
      //   booking[0].ResponseBooking &&
      //   booking[0].ResponseBooking[0] &&
      //   booking[0].ResponseBooking[0].rateHotel
      // ) {
      //   const tva = (20 * booking[0].ResponseBooking[0].rateHotel) / 100;
      //   const price = booking[0].ResponseBooking[0].rateHotel + tva;
      //   setPriceWithTVA(price);
      // } else {
      //   setPriceWithTVA(-1);
      // }
    }
  };

  if (loadingProcess || loadingPaiement || loadingReject) {
    return <Backdrop />;
  }

  return (
    <Box className={classes.allContent}>
      <Box className={classes.firstContent}>
        <Typography variant="h6">Réservation Hôtel</Typography>
        <Box className={classes.contentButton}>
          <Button
            variant="outlined"
            color="default"
            className={classes.button}
            startIcon={<CheckCircleIcon />}>
            Traité
          </Button>
          <Button
            variant="contained"
            color="default"
            onClick={() => history.push('/accueil/Reservation-hotel')}
            className={classes.button}
            startIcon={<AccessTimeIcon />}>
            En Attente
          </Button>
        </Box>
        {!loadingProcess &&
          dataProcess &&
          dataProcess.myRequestBookingProcessing.length === 0 && (
            <Typography variant="h3">Aucune demande traitée.</Typography>
          )}
        <Box>
          {dataProcess &&
            dataProcess.myRequestBookingProcessing.length > 0 &&
            dataProcess.myRequestBookingProcessing.map((booking) => (
              <Box
                className={classes.demandeContainer}
                key={booking.id}
                onClick={() => selectBooking(booking.id)}
                style={{ cursor: 'pointer' }}>
                <Box className={classes.contentDemande}>
                  <Typography variant="subtitle1">
                    Demande de réservation
                  </Typography>
                  <Typography variant="subtitle1">
                    {moment(booking.dateRequestBooking).format('DD MMM, YYYY')}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="subtitle1"
                    className={classes.demandeAlignement}>
                    Référence :&nbsp;
                    <Typography variant="subtitle1">
                      {booking.reference}
                    </Typography>
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    className={classes.demandeAlignement}>
                    Hôtel :&nbsp;{' '}
                    <Typography
                      variant="subtitle1"
                      className={classes.titleBolder}>
                      {booking.hotel.name}
                    </Typography>
                  </Typography>
                </Box>
              </Box>
            ))}
        </Box>
      </Box>
      {selectedBooking && (
        <Box className={classes.scndContent}>
          <Box>
            <Box className={classes.secondContent}>
              <Typography variant="h6"> Vous avez choisi</Typography>
              <Typography className={classes.resultContent}>
                Du : {moment(selectedBooking.dateLast).format('DD MMMM YYYY')}
              </Typography>
              <Typography className={classes.resultContent}>
                Au : {moment(selectedBooking.dateFirst).format('DD MMMM YYYY')}
              </Typography>
              <Box className={classes.contentRecat}>
                <Typography
                  variant="subtitle1"
                  className={classes.demandeAlignement}
                  style={{ marginBottom: '0px' }}>
                  Hôtel :&nbsp;{' '}
                  <Typography
                    variant="subtitle1"
                    className={classes.titleBolder}>
                    {selectedBooking.hotel.name}
                  </Typography>
                </Typography>
                <Box className={classes.addressContent} style={{ padding: 0 }}>
                  <LocationOnIcon className={classes.iconAdreser} />
                  <Box>{selectedBooking.hotel.city}</Box>
                </Box>
              </Box>
              {/* <Box className={classes.contentRecat}>
                  <Box className={classes.firstListChambre}>
                    <Typography variant="h6">Chambre Simple</Typography>
                    <Typography variant="h6">{selectedBooking.ResponseBooking[0].rateHotel} Ar</Typography>
                  </Box>
                  <Typography>
                    <ul className={classes.listChambre}>
                      <li>- 1 lit Simple</li>
                      <li>- 1 Personne</li>
                    </ul>
                  </Typography>
                  <Box className={classes.firstListChambre}>
                    <Typography variant="h6">20% TVA</Typography>
                    <Typography variant="h6">{priceWithTVA} Ar</Typography>
                  </Box>
                </Box> */}
              <Typography
                variant="h6"
                className={classes.textTransormCapitalize}>
                à savoir
              </Typography>
              <Typography className={classes.contentAsavoir}>
                {selectedBooking.ResponseBooking &&
                selectedBooking.ResponseBooking[0] &&
                selectedBooking.ResponseBooking[0].comment
                  ? selectedBooking.ResponseBooking[0].comment
                  : ''}
              </Typography>
              {selectedBooking &&
                selectedBooking.ResponseBooking &&
                selectedBooking.ResponseBooking[0] &&
                selectedBooking.ResponseBooking[0].rateHotel && (
                  <Paper className={classes.contentTotal}>
                    <Typography variant="h6">Total à payer </Typography>
                    <Typography variant="h6">
                      {separatorOfMillier(
                        selectedBooking.ResponseBooking[0].rateHotel,
                      )}{' '}
                      Ar
                    </Typography>
                  </Paper>
                )}
              {/* <Box className={classes.contentPayment}>
                <Typography variant="h3" color="textSecondary">
                  Mode de paiement
                </Typography>
                <FormControl component="fieldset" fullWidth>
                  <RadioGroup
                    defaultValue="female"
                    aria-label="gender"
                    name="customized-radios">
                    <Box className={classes.radioContainer}>
                      <FormControlLabel
                        value="Paiement à la livraison"
                        control={<Radio color="primary" />}
                        label="Carte Bancaire"
                      />
                    </Box>
                    <Box className={classes.radioContainer}>
                      <FormControlLabel
                        value="Paiement mobile money"
                        control={<Radio color="primary" />}
                        label="Mobile money"
                      />
                    </Box>
                  </RadioGroup>
                </FormControl>
              </Box> */}
              <Box className={classes.contntBtnRsrv}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.firstButton}
                  onClick={handleRejectRequestBooking}>
                  Rejeter
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onPressConfirmer}>
                  OK
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ReservationRecap;

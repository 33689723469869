import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({

    contentSlide: {     
        // display:'flex',
        '& .image-gallery-image': {
            height:'390px',
            width:'100%',
            objectFit:'fill',
        },
        '& .image-gallery-thumbnails-wrapper.right ': {           
            '& .image-gallery-thumbnails':{
                display:'flex',
                justifyContent:'center',
                flexDirection:'column',  
            },
        },
    },
});

export default useStyles;

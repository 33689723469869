import { VOL, VOL_aeroportFilter } from '../../../Graphql/Vol/types/VOL';
import _ from 'lodash';

const groupAeroportsByMunicipality = (
  data: VOL | undefined,
): VOL_aeroportFilter[] => {
  if (data) {
    const aeroportsArray = data.aeroportFilter;
    const groupedAeroportsObject = _.mapValues(
      _.groupBy(aeroportsArray, 'municipality'),
      (clist) => clist.map((aeroport) => _.omit(aeroport, 'municipality')),
    );

    let groupedAeroportsArray: VOL_aeroportFilter[] = [];
    for (const aeroportObject of Object.entries(groupedAeroportsObject)) {
      const array: VOL_aeroportFilter[] = [
        {
          id: '',
          name: aeroportObject[0],
          continent: '',
          iata: '',
          municipality: aeroportObject[0],
          __typename: 'Aeroport',
        },
        ...aeroportObject[1].map((item) => {
          return { ...item, municipality: aeroportObject[0] };
        }),
      ];
      groupedAeroportsArray = groupedAeroportsArray.concat(array);
    }

    return groupedAeroportsArray;
  } else {
    return [];
  }
};

export default groupAeroportsByMunicipality;

import { storage } from 'firebase';
import { useContext, useState } from 'react';
import { DeliveryInfoContext } from '../../Providers/DeliveryInfo';

export const useUploadImage = () => {
  const { imageName, setImageName, setImageUrl, imageBlob } = useContext(
    DeliveryInfoContext,
  );
  const [loadingUploadImage, setLoadingUploadImage] = useState(false);
  const uploadImage = async () => {
    if (imageBlob) {
      setLoadingUploadImage(true);
      await storage()
        .ref(`livraison/${imageName}`)
        .put(imageBlob as any);

      const downloadUrl = await storage()
        .ref(`livraison/${imageName}`)
        .getDownloadURL();
      setImageUrl('');
      setImageName('');
      setLoadingUploadImage(false);
      return downloadUrl;
    }
    return '';
  };
  return { uploadImage, loadingUploadImage };
};

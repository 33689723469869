import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  allContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
    overflow: 'auto',
    height: '100%',
    margin: 'auto',
    padding: '30px 120px 30px 120px',
    backgroundColor: '#fff',
    fontSize: 14,
    '& h5': {
      fontSize: '20px ',
      color: '#000',
    },
    '& h6': {
      fontWeight: 'bold',
      fontSize: 16,
      color: '#6B6B6B',
    },
    '@media(min-width:1366px and max-width:1920px)': {
      width: '100%',
    },
  },
  contentDemandeReservation: {
    width: '100%',
    '@media (max-width:1365px)': {
      width: '70%',
      margin: 'auto',
    },
    '@media (max-width:1200px)': {
      width: '90%',
      margin: 'auto',
    },
  },
  contentDatePicker: {
    display: 'flex',
    boxShadow: 'unset !important',
    height: '100%',
    justifyContent: 'space-between',
    marginTop: 30,
    '@media (max-width:1365px)': {
      display: 'flex',
      flexDirection: 'column',
      width: '70%',
      margin: 'auto',
    },
    '@media (max-width:1200px)': {
      width: '90%',
    },
    '& >*': {
      width: '45%',
      '& .MuiFormControl-root ': {
        width: '100%',
        border: '1px solid #A7A7A7',
        borderRadius: '5px',
      },
      '& p': {
        margin: 0,
        textTransform: 'uppercase',
      },
    },
  },
  contentInput: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '12px',
    '& label,input': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  contentInputAlign: {
    display: 'flex',
    alignItems: 'center',
    height: '55px',
    marginTop: 20,
    borderBottom: '1px solid #E6E6E6',
    justifyContent: 'space-between',
    '&:first-child': {
      textTransform: 'uppercase',
    },
  },
  colorLabel: {
    color: '#55A7EE',
    fontWeight: 'bold',
    fontSize: '12px',
  },
  contentIncrement: {
    display: 'flex',
    width: '40%',
    justifyContent: 'space-between;',
    alignItems: 'center',
    alignContent: 'center',
    '& >span': {
      fontSize: 12,
    },
    '& .MuiIconButton-colorPrimary ': {
      color: '#f4f4f5',
      width: '22px',
      height: '22px',
      background: '#55A7EE',
      fontSize: 12,
      borderRadius: '2px',

      '& .MuiIconButton-colorPrimary:hover': {
        backgroundColor: 'gray',
      },
    },
    '& .MuiIconButton-root.Mui-disabled': {
      borderRadius: 2,
      backgroundColor: '#CAD1DE',
    },
  },
  textField: {
    height: '160px',
    width: '45%',
    boxShadow: 'none',
    borderRadius: '5px',
    border: '1px solid #A7A7A7',
    '& .MuiFilledInput-input': {
      display: 'flex',
      alignSelf: 'flex-start',
    },
    '& .MuiInputBase-root': {
      width: '100%',
    },
  },
  scndContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    '@media (max-width:1365px)': {
      width: '100%',
      marginTop: '50px',
    },
    '& .MuiTextField-root': {
      height: '100%',
    },
    '& .MuiFilledInput-underline:after': {
      display: 'none',
    },
    '& :first-child': {},
    '& .MuiInputBase-root': {
      height: 45,
      textAlign: 'center',
      paddingLeft: 8,
    },
  },
  contentOption: {
    display: 'flex',
    width: '48%',
    flexDirection: 'column',
    '@media (max-width:1365px)': {
      marginTop: '50px',
      width: '100%',
    },
    '& .MuiInputBase-root': {
      height: 45,
      textAlign: 'center',
      paddingLeft: 8,
    },
  },
  rootTextFields: {
    display: 'flex',
    width: '50%',
    '& >*': {
      width: '100%',
      border: '1px solid #A7A7A7',
      height: '160px',
      borderRadius: '10px',
    },
  },
  ButtonReservationContent: {
    display: 'flex',
    // marginTop: '',
    justifyContent: 'space-between',
    '& :nth-child(2)': { width: '45%' },
  },
  firstButton: {
    color: '#000',
    backgroundColor: '#CAD1DE',
    marginRight: '15px',
    maxWidth: '109px',
    '&:hover': {
      backgroundColor: '#B4B9C4',
    },
  },
  comment: {
    display: 'flex',
    outline: 'none',
    height: '100%',
    '& .MuiFilledInput-root': {
      border: 'none',
      backgroundColor: 'unset',
    },
  },
});
export default useStyles;

import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function Bill(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 22.063 22.063">
      <g transform="translate(0.75 0.75)">
        <path
          d="M14.321,14.344h6.242V2.227a1.1,1.1,0,0,0-1.1-1.1H1.1A1.1,1.1,0,0,0,0,2.227v18.36a1.1,1.1,0,0,0,1.1,1.1H13.219V15.446A1.1,1.1,0,0,1,14.321,14.344Zm5.921,2.524-4.5,4.5a1.1,1.1,0,0,1-.78.321h-.275V15.813h5.875v.28A1.1,1.1,0,0,1,20.242,16.868Z"
          transform="translate(0 -1.125)"
          fill="none"
          strokeWidth="1.5"
        />
      </g>
    </SvgIcon>
  );
}

import gql from 'graphql-tag';

export const DO_UPDATE_POSITION_COURIER = gql`
  mutation UPDATE_POSITION_COURIER(
    $id: String!
    $longitude: String!
    $latitude: String!
  ) {
    updatePositionCourier(id: $id, longitude: $longitude, latitude: $latitude) {
      id
      location
    }
  }
`;

export const DO_UPDATE_PROFIL = gql`
  mutation UPDATE_PROFIL(
    $idUser: String!
    $name: String!
    $phone: String!
    $email: String!
    $firstName: String
    $password: String
    $newPassword: String
    $confirmPassword: String
  ) {
    updateProfilCourier(
      idUser: $idUser
      name: $name
      firstName: $firstName
      phone: $phone
      email: $email
      password: $password
      newPassword: $newPassword
      confirmPassword: $confirmPassword
    ) {
      accessToken
      user {
        id
        name
        firstName
        email
        phone
      }
    }
  }
`;

export const DO_PUBLISH_NOTIFICATION = gql`
  mutation PUBLISH_NOTIFICATION(
    $userId: String!
    $shortDeliveryId: String!
    $signatureReception: String
    $descriptionAnomaly: String
    $status: String!
  ) {
    publishNotification(
      userId: $userId
      shortDeliveryId: $shortDeliveryId
      signatureReception: $signatureReception
      descriptionAnomaly: $descriptionAnomaly
      status: $status
    ) {
      id
      status
      manifest
    }
  }
`;

export const DO_CHANGE_STATUS_DELIVERY = gql`
  mutation CHANGE_STATUS_DELIVERY(
    $deliveryId: String!
    $descriptionAnomaly: String
    $signatureReception: String
    $status: String!
  ) {
    publishNotificationStatusCourier(
      deliveryId: $deliveryId
      descriptionAnomaly: $descriptionAnomaly
      status: $status
      signatureReception: $signatureReception
    ) {
      id
      status
    }
  }
`;

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles({
  allContentDateTime: {},
  titreDateTime: {
    color: '#55A7EE',
    fontSize: '16px',
    marginBottom: '10px',
    display: 'flex',
    fontWeight: 'revert',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    '& textField': {
      backgroundColor: 'green',
    },
  },
  textField: {},
});
export default useStyles;

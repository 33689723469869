import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    minHeight: '100vh',
  },
  left: {
    flex: '0 0 40%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: theme.spacing(4),
    textAlign: 'center',
    color: '#FFF',
    background: 'linear-gradient(180deg, #59ACF1 14%, #1551BB 100%)',
    '&>div': {
      width: '100%',
      padding: theme.spacing(0, 2),
      '@media(min-width:1601px)': {
        width: '65%',
      },
      '@media(min-width:501px) and (max-width:850px)': {
        width: '75%',
      },
    },
    '@media(min-width:1366px)': {
      '&>div': {
        width: '75%',
      },
    },
    '@media(max-width:991px)': {
      flex: '0 0 50%',
    },
    '@media(max-width:850px)': {
      flex: '0 0 100%',
    },
  },
  logo: {
    marginTop: '20%',
    '& img': {
      width: 200,
    },
  },
  login: {
    color: '#FFF',
    '&>div': {
      marginBottom: theme.spacing(2),
    },
    '&>button': {
      margin: '1rem auto 1.5rem',
    },
    '&>h6': {
      marginTop: theme.spacing(5),
    },
  },
  btnFacebook: {
    '& p': {
      color: '#4267B2',
      fontWeight: 700,
    },
  },
  terms: {
    '& p, & span': {
      fontSize: 12,
    },
    '& p': {
      color: 'inherit',
    },
  },
  bg: {
    flex: 'auto',
    textAlign: 'right',
    '& img': {
      minHeight: '100%',
    },
    '@media(max-width:850px)': {
      display: 'none',
    },
  },
}));

export default useStyles;

import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  menuContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    overflow: 'auto',
    flex: '0 0 250px',
    maxWidth: 250,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
  collapsed: {
    position: 'absolute',
    left: -250,
    '& a': {
      width: 48,
      height: 48,
      padding: theme.spacing(1.5),
      borderRadius: '50%',
      '& svg': {
        marginRight: 0,
      },
    },
    '& span': {
      display: 'none',
    },
  },
  logo: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: 60,
    fontSize: '2.5rem',
  },
  listMenu: {
    display: 'flex',
    flexDirection: 'column',
    flex: 'auto',
    '& div:last-of-type': {
      marginTop: 'auto',
      // marginBottom: theme.spacing(2.5),
      '& p': {
        fontSize: '12px',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(0.5),
      },
    },
    '& .MuiListItem-button:hover': {
      backgroundColor: 'unset',
    },
  },
  menuAccueil: {
    padding: '0px',
    margin: '0px',
    '&:first-child.active': {
      backgroundColor: 'yellow',
    },
  },
  sousMenu: {
    height: 45,
    marginLeft: 53,
  },
  listContent: {
    display: 'flex',
    flexDirection: 'column',
    margin: 0,
    padding: 0,
    '& :li': {
      display: 'none',
    },
    '& >*': {
      paddingLeft: 53,
      margin: 0,
    },
  },
}));

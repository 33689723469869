import gql from 'graphql-tag';

export const DO_SIGNUP_PROFESSIONNEL = gql`
  mutation SIGNUP_PROFESSIONNEL(
    $name: String!
    $nif: String!
    $stat: String!
    $phone: String
    $email: String
    $password: String
    $userType: String!
  ) {
    signupProfessional(
      name: $name
      nif: $nif
      stat: $stat
      phone: $phone
      email: $email
      password: $password
      userType: $userType
    ) {
      accessToken
      user {
        id
        email
      }
    }
  }
`;

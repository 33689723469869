import { useContext } from 'react';
import { DestinationsContext } from '../../Providers/Destinations';
import { ExpediteurContext } from '../../Providers/Expediteur';
import { ExpediteurLocationContext } from '../../Providers/ExpediteurLocation';

export interface User {
  id: string;
  name: string;
  phone: string;
  address: string;
  latitude: string;
  longitude: string;
  idPickOrDrop: string;
  distanceParcouru: number;
  dureeApproximatif: string;
}

export const useInitUSers = () => {
  const {
    name: nameExp,
    phone: phoneEx,
    address: addressEx,
    id: idPickupEx,
    addressBookId,
  } = useContext(ExpediteurContext);

  const { latitude: latitudeEx, longitude: longitudeEx } = useContext(
    ExpediteurLocationContext,
  );

  const { destinations } = useContext(DestinationsContext);

  const initUsersDestination = async () => {
    let usersTab: User[] = [];
    usersTab = [
      {
        id: addressBookId,
        name: nameExp,
        phone: phoneEx,
        address: addressEx,
        latitude: String(latitudeEx),
        longitude: String(longitudeEx),
        idPickOrDrop: idPickupEx,
        distanceParcouru: 0,
        dureeApproximatif: '',
      },
    ];

    for (const user of destinations) {
      usersTab.push({
        id: user.addressBookId,
        address: user.address,
        latitude: user.latitude,
        longitude: user.longitude,
        name: user.name,
        idPickOrDrop: user.id,
        phone: user.phone,
        distanceParcouru: 0,
        dureeApproximatif: '',
      });
    }

    return usersTab;
  };

  const onGetInitUSer = async () => {
    return await initUsersDestination();
  };

  return { onGetInitUSer };
};

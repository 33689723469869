import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  allContent: {
    width: '80%',
    margin: 'auto',
    display: 'flex',
    // flexDirection: 'row',
    overflow: 'auto',
    justifyContent: 'space-arround',
    backgroundColor: '#fff',
    // padding: '0px 0px 0 0px',
    webkitBoxShadow: '0px 3px 6px 0px rgba(0,0,0,0.27)',
    mozBoxShadow: '0px 3px 6px 0px rgba(0,0,0,0.27)',
    boxShadow: '0px 3px 6px 0px rgba(0,0,0,0.27)',
    '@media (max-width : 1365px )': {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      overflow: 'auto',
    },
  },
  firstContent: {
    padding: '20px',
    overflow: 'auto',
    flex: 1,
    height: 'auto',
    maxWidth: '50%',
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    border: 'none',
    webkitBoxShadow: '0px 3px 6px 0px rgba(0,0,0,0.27)',
    mozBoxShadow: '0px 3px 6px 0px rgba(0,0,0,0.27)',
    boxShadow: '0px 3px 6px 0px rgba(0,0,0,0.27)',
    '@media (max-width:1365px)': {
      justifyContent: 'center',
      margin: 'auto',
      width: '60%',
    },
    '& h6': {
      fontSize: 16,
    },
    '& p': {
      fontSize: 14,
    },
    '& .MuiPaper-elevation1': {
      boxShadow: 'unset',
    },
    '& .MuiInputBase-input': {
      display: 'flex',
      justifyContent: 'left',
      height: '40px',
      paddingRight: '20px',
      '& img': {
        display: 'none',
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        border: 'none',
      },
      '& :hover': {
        border: 'none',
        transition: 'none',
      },
      '& .MuiInput-underline:hover': {
        border: 'none',
      },

      '& .MuiInput-underline:after': {
        border: 'none !important',
      },

      '& :before': {
        transition: 'unset ! important',
        display: 'none',
        border: 'none',
      },
    },
    '& .MuiTextField-root': {
      border: '1px solid #000',
      borderRadius: 5,
      paddingLeft: '10px',
      display: 'flex',
      lineHeight: '20px',
      width: '100%',
    },
    '& .makeStyles-container-41:hover': {
      border: 'none',
    },
  },
  secondContent: {
    padding: '40px',
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    border: 'none',
    boxShadow: '0px 3px 6px 0px rgba(0,0,0,0.27)',
    mozBoxShadow: ' 0px 3px 6px 0px rgba(0,0,0,0.27)',
    webkitBoxShadow: '0px 3px 6px 0px rgba(0,0,0,0.27)',
    '@media (max-width:1365px)': {
      justifyContent: 'center',
      margin: 'auto',
    },
    '& h6': {
      fontSize: 16,
    },
  },
  firstButton: {
    color: '#000',
    backgroundColor: '#CAD1DE',
    marginRight: '15px',
    maxWidth: '109px',
    '&:hover': {
      backgroundColor: '#B4B9C4',
    },
  },
  contentDatePicker: {
    '& input': {
      display: 'none',
    },
    '& .MuiInput-underline:after': {
      display: 'none',
    },
    '& .MuiInput-underline:before': {
      display: 'none',
    },
  },
  ButtonReservationContent: {
    display: 'flex',
    marginTop: '125px',
    justifyContent: 'end',
    '& :nth-child(2)': { width: '45%' },
  },
  contentInputAlign: {
    display: 'flex',
    alignItems: 'center',
    height: '55px',
    marginTop: '40px',
    justifyContent: 'space-between',
    borderBottom: '1px solid #E6E6E6',
  },
  colorLabel: {
    color: '#55A7EE',
    fontWeight: 'bold',
    fontSize: '12px',
  },
  contentIncrement: {
    display: 'flex',
    width: '40%',
    justifyContent: 'space-between;',
    alignItems: 'center',
    alignContent: 'center',

    '& >span': {
      fontSize: 12,
    },
    '& .MuiIconButton-colorPrimary ': {
      color: '#f4f4f5',
      width: '22px',
      height: '22px',
      background: '#55A7EE',
      fontSize: 12,
      borderRadius: '2px',

      '& .MuiIconButton-colorPrimary:hover': {
        backgroundColor: 'gray',
      },
    },
    '& .MuiIconButton-root.Mui-disabled': {
      borderRadius: 2,
      backgroundColor: '#CAD1DE',
    },
  },

  resultContent: {
    fontSize: 14,
    display: 'flex',
    alignContent: 'center',
    // lineHeight: 10,
    alignItems: 'center',

    '& p': {
      fontSize: 12,
      paddingRight: 10,
      color: 'gray',
    },
  },
  firstListChambre: {
    display: 'flex',
    fontWeight: 'bolder',
    justifyContent: 'space-between',
    '& h6': {
      fontSize: 16,
      fontWeight: 'bolder',
    },
    '&ul>li': {
      listStyle: 'none',
    },
  },
  listChambre: {
    listStyleType: 'none',
    padding: 0,
    fontSize: 12,
  },
  separator: {
    width: '60%',
    height: '2px',
    backgroundColor: '#FFF',
    margin: '10px 0 10px 0',
  },
  contentRecat: {
    borderBottom: '1px solid gray',
    paddingTop: 10,
    paddingBottom: 10,
    borderTop: '1px solid gray',
    marginTop: '10px',
  },
  contentAsavoir: {
    fontSize: 14,
  },
  textTransormCapitalize: {
    textTransform: 'uppercase',
    paddingTop: '30px',
  },
  textTransorm: {
    paddingTop: '30px',
  },
  contentTotal: {
    border: 'none',
    boxShadow: 'unset',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    margin: '35px 0px 30px 0',
    padding: '10px 0 10px 0',
    backgroundColor: '#58C6DE',
    color: '#fff',
  },
  contentPayment: {
    '& .MuiRadio-colorPrimary': {
      color: '#fff !important',
    },
    '& .MuiFormGroup-root': {
      display: 'flex',
      flexWrap: 'nowrap',
      justifyContent: 'center',
      flexDirection: 'row',
      '& :first-of-type , :nth-child(2)': {
        padding: 0,
        margin: 0,
        color: '#000',
        textTransform: 'uppercase',
        fontWeight: 900,
        fontSize: 12,
        // marginLeft: 10,
        backgroundColor: 'unset',
        '& span': {
          paddingRight: 10,
        },
      },
    },
  },

  radioContainer: {
    width: '80%',
    marginBottom: theme.spacing(3),
    padding: theme.spacing(0, 2),
    color: theme.palette.background.paper,
    borderRadius: 6,
    '&:first-of-type': {
      backgroundColor: '#A7A7A7',
    },
    '&:last-of-type': {
      backgroundColor: '#58C6DE',
    },
    '& label': {
      width: '100%',
      color: '#fff',
    },
  },
  contntBtnRsrv: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '30px',
    '& :nth-child(2)': {
      width: '50%',
    },
  },

  loaders: {
    display: 'block',
    marginLeft: 'auto',
    marginTop: 'auto',
    marginRight: 'auto',
    marginBottom: 'auto',
  },

  ball3: {
    animation: `$bounce 0.55s linear infinite`,
    backgroundColor: '#007aff',
    //WebkitAnimationDelay: '1.3s',
    float: 'left',
    width: 40,
    height: 40,
    borderRadius: '50%',
    boxShadow: 'inset 0 -10px rgba(0,0,0,0.1)',
  },

  '@keyframes bounce ': {
    '0%': { transform: 'translate(0,-50%) scale(1,0.8)' },
    '30% ': { transform: 'translate(0,-150%) scale(0.8,0.8)' },
    ' 60%': { transform: 'translate(0,-200%) scale(0.8,1)' },
    ' 85% ': { transform: 'translate(0,-50%) scale(0.8,1)' },
    '90%': { transform: ' translate(0,-50%) scale(0.8,1)' },
    '100%': { transform: 'translate(0,-50%) scale(1,0.8)' },
  },

  firstContentTitle: {},
}));

import React, { FC, useState } from 'react';
import useStyles from './style';
import { Box, Button, Typography } from '@material-ui/core';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import {
  MY_REQUEST_BOOKING_WAITING,
  MY_REQUEST_BOOKING_WAITINGVariables,
  MY_REQUEST_BOOKING_WAITING_myRequestBookingWaiting,
} from '../../Graphql/Hotel/types/MY_REQUEST_BOOKING_WAITING';
import { DO_MY_REQUEST_BOOKING_WAITING } from '../../Graphql/Hotel/query';
import { displaySnackBar } from '../../Utils/snackBarUtils';
import { useContext } from 'react';
import { UserContext } from '../../Providers/User';
import SnackVariableInterface from '../../Interfaces/SnackVariableInterface';
import moment from 'moment';
import Backdrop from '../../Components/Backdrop';

const MakeReservation: FC<RouteComponentProps> = () => {
  const classes = useStyles();
  const history = useHistory();
  const client = useApolloClient();
  const { idMe } = useContext(UserContext);
  const [
    selectedBooking,
    setSelectedBooking,
  ] = useState<MY_REQUEST_BOOKING_WAITING_myRequestBookingWaiting | null>(null);
  const [manifest, setManifest] = useState<any>();
  // const goToRecapReservation = () => {
  //   history.push('/accueil/Recapitulation-reservation/');
  // };

  const { data: dataEnAttente, loading: loadingEnAttente } = useQuery<
    MY_REQUEST_BOOKING_WAITING,
    MY_REQUEST_BOOKING_WAITINGVariables
  >(DO_MY_REQUEST_BOOKING_WAITING, {
    variables: { user_id: idMe },
    skip: idMe ? false : true,
    onError: (err) => {
      if (err.networkError?.message === 'Failed to fetch') {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Problème de connexion...',
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      }
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: err.message.split(':')[1],
        isOpen: true,
      };
      return displaySnackBar(client, snackBarData);
    },
    onCompleted: (data) => {
      if (data && data.myRequestBookingWaiting) {
        const booking = data.myRequestBookingWaiting[0];
        setSelectedBooking(booking);
        const manifest =
          booking && booking.manifest && JSON.parse(booking.manifest);
        setManifest(manifest);
      }
    },
    fetchPolicy: 'network-only',
  });

  const selectBooking = (id: string) => {
    const booking = dataEnAttente?.myRequestBookingWaiting.filter(
      (b) => b.id === id,
    );
    if (booking && booking[0]) {
      setSelectedBooking(booking[0]);
      const manifest = JSON.parse(booking[0].manifest);
      setManifest(manifest);
    }
  };

  if (loadingEnAttente || selectedBooking === null) {
    return <Backdrop />;
  }

  return (
    <Box className={classes.allContent}>
      <Box className={classes.firstContent}>
        <Typography variant="h6">Réservation Hôtel</Typography>
        <Box className={classes.contentButton}>
          <Button
            variant="outlined"
            color="default"
            className={classes.button}
            startIcon={<CheckCircleIcon />}
            onClick={() =>
              history.push('/accueil/Recapitulation-reservation/')
            }>
            Traité
          </Button>
          <Button
            variant="contained"
            color="default"
            className={classes.button}
            startIcon={<AccessTimeIcon />}>
            En Attente
          </Button>
        </Box>
        {/* Demandes en attente */}
        <Box>
          {dataEnAttente && dataEnAttente.myRequestBookingWaiting.length > 0 ? (
            dataEnAttente.myRequestBookingWaiting.map((booking) => (
              <Box
                className={classes.demandeContainer}
                key={booking.id}
                onClick={() => selectBooking(booking.id)}
                style={{ cursor: 'pointer' }}>
                <Box className={classes.contentDemande}>
                  <Typography variant="subtitle1">
                    Demande de réservation
                  </Typography>
                  <Typography variant="subtitle1">
                    {moment(booking.dateRequestBooking).format('DD MMM, YYYY')}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="subtitle1"
                    className={classes.demandeAlignement}>
                    Référence &nbsp;: &nbsp;
                    <Typography variant="subtitle1">
                      {booking.reference}
                    </Typography>
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    className={classes.demandeAlignement}>
                    Hôtel :{' '}
                    <Typography
                      variant="subtitle1"
                      className={classes.titleBolder}>
                      {booking.hotel.name}
                    </Typography>
                  </Typography>
                </Box>
              </Box>
            ))
          ) : (
            <Typography variant="h3">
              Aucun hôtel en attente de traitement.
            </Typography>
          )}
        </Box>
      </Box>
      {selectedBooking && (
        <Box className={classes.scndContent}>
          <Typography variant="caption">Demande de réservation</Typography>
          <Box>
            <Box>
              <Box className={classes.contentDemandeScnd}>
                <Typography
                  variant="subtitle1"
                  className={classes.demandeAlignement}>
                  Référence &nbsp;:&nbsp;
                  <Typography variant="subtitle1">
                    {selectedBooking.reference}
                  </Typography>
                </Typography>
                <Typography variant="subtitle1">
                  {moment(selectedBooking.dateRequestBooking).format(
                    'DD MMM, YYYY',
                  )}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="subtitle1"
                  className={classes.demandeAlignement}>
                  Hôtel :&nbsp;
                  <Typography
                    variant="subtitle1"
                    className={classes.titleBolder}>
                    {selectedBooking.hotel.name}
                  </Typography>
                </Typography>
              </Box>
            </Box>
            <Box className={classes.addressContent}>
              <LocationOnIcon className={classes.iconAdreser} />
              <Box>{selectedBooking.hotel.city}</Box>
            </Box>
            <Box className={classes.recapDate}>
              <Typography
                variant="subtitle1"
                className={classes.demandeAlignement}>
                <Typography variant="subtitle1" className={classes.colorText}>
                  Du :&nbsp;
                </Typography>
                <Typography variant="subtitle1">
                  {moment(selectedBooking.dateLast).format('DD MMMM YYYY')}
                </Typography>
              </Typography>
              <Typography
                variant="subtitle1"
                className={classes.demandeAlignement}>
                <Typography variant="subtitle1" className={classes.colorText}>
                  Au :&nbsp;
                </Typography>
                <Typography variant="subtitle1">
                  {moment(selectedBooking.dateFirst).format('DD MMMM YYYY')}
                </Typography>
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1" className={classes.colorText}>
                Pour
              </Typography>
              <Box className={classes.recapName}>
                {manifest &&
                  manifest?.numberRoom &&
                  manifest?.numberRoom > 0 && (
                    <Typography variant="subtitle1">{`${manifest.numberRoom} Chambre(s)`}</Typography>
                  )}
                {manifest &&
                  manifest.numberAdult &&
                  manifest.numberAdult > 0 && (
                    <Typography variant="subtitle1">{`${manifest.numberAdult} Adulte(s)`}</Typography>
                  )}
                {manifest &&
                  manifest.numberChildren &&
                  manifest.numberChildren > 0 && (
                    <Typography variant="subtitle1">{`${manifest.numberChildren} Enfant(s)`}</Typography>
                  )}
              </Box>

              {selectedBooking && selectedBooking.comment && (
                <>
                  <Typography variant="subtitle1" className={classes.colorText}>
                    Commentaire
                  </Typography>

                  <Typography variant="subtitle1" className={classes.comment}>
                    {selectedBooking.comment}
                  </Typography>
                </>
              )}
            </Box>
            <Box className={classes.statusReservation}>
              <Typography>Statut</Typography>
              <Typography>
                {selectedBooking.status === 'WAITING'
                  ? 'En attente'
                  : selectedBooking.status}
              </Typography>
              <Box className={classes.separator}></Box>
              <Typography>
                Nous vous enverrons une notification confirmant votre
                réservation.
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default MakeReservation;

import React, { useState, useEffect } from 'react';
import { createContext, FC } from 'react';
import { useApolloClient, useLazyQuery } from '@apollo/react-hooks';
import { ME } from '../Graphql/User/query';
import { Me } from '../Graphql/User/types/Me';
import SnackVariableInterface from '../Interfaces/SnackVariableInterface';
import { displaySnackBar } from '../Utils/snackBarUtils';

interface USerProps {
  token: string;
  courierId: string;
  customerId: string;
  idMe: string;
  name: string;
  firstName: string;
  phone: string;
  password: string;
  email: string;
  setCustomerId: (id: string) => void;
  setName: (name: string) => void;
  setFirstName: (firstName: string) => void;
  setPhone: (phone: string) => void;
  setEmail: (email: string) => void;
  setPassword: (password: string) => void;
  setIdMe: (id: string) => void;
  setToken: (token: string) => void;
  cleanUser: () => void;
}

export const UserContext = createContext<USerProps>({
  courierId: '',
  customerId: '',
  idMe: '',
  name: '',
  firstName: '',
  phone: '',
  email: '',
  password: '',
  token: '',
  setToken: () => {},
  setCustomerId: () => {},
  setName: () => {},
  setFirstName: () => {},
  setPhone: () => {},
  setEmail: () => {},
  setPassword: () => {},
  setIdMe: () => {},
  cleanUser: () => {},
});

export const UserProvider: FC = ({ children }) => {
  const [customerId, setCustomerId] = useState<string>('');
  const [courierId, setCourierId] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [idMe, setIdMe] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const [token, setToken] = useState<string>(
    localStorage.getItem('token') || '',
  );

  const client = useApolloClient();
  const [doGetMe, { loading, error }] = useLazyQuery<Me>(ME, {
    onError: (err) => {
      if (err.networkError?.message === 'Failed to fetch') {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Problème de connexion...',
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      }
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: err.message.split(':')[1],
        isOpen: true,
      };
      return displaySnackBar(client, snackBarData);
    },
    onCompleted: (data) => {
      if (data && data.me) {
        setCustomerId(data.me.customer?.id || '');
        setName(data.me.name);
        setFirstName(data.me.firstName);
        setPhone(data.me.phone || '');
        setEmail(data.me.email || '');
        setIdMe(data.me.id);
      } else {
        localStorage.removeItem('token');
        window.location.reload();
      }
    },
  });

  useEffect(() => {
    if (token.trim().length !== 0) {
      doGetMe();
    }
  }, [token]);

  return (
    <UserContext.Provider
      value={{
        courierId,
        customerId,
        idMe,
        name,
        firstName,
        email,
        password,
        phone,
        token,
        setCustomerId,
        setIdMe,
        setName,
        setFirstName,
        setEmail,
        setPassword,
        setPhone,
        setToken,
        cleanUser: async () => {
          setCustomerId('');
          setIdMe('');
          setName('');
          setFirstName('');
          setEmail('');
          setPassword('');
          setPhone('');
          setToken('');
          localStorage.clear();
        },
      }}>
      {children}
    </UserContext.Provider>
  );
};

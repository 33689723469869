import React, { useState } from 'react';
import { createContext, FC } from 'react';

interface TransportInputProps {
  id: string;
  coastBydistance: number;
  forfaitExpress: number;
  forfaitProgram: number;
  setTransport: (transport: any) => void;
}

export const TransportContext = createContext<TransportInputProps>({
  id: '',
  coastBydistance: 0,
  forfaitExpress: 0,
  forfaitProgram: 0,
  setTransport: () => {},
});

export const TransportProvider: FC = ({ children }) => {
  const [transport, setTransport] = useState({
    id: '',
    coastBydistance: 0,
    forfaitExpress: 0,
    forfaitProgram: 0,
  });

  return (
    <TransportContext.Provider
      value={{
        id: transport.id,
        coastBydistance: transport.coastBydistance,
        forfaitExpress: transport.forfaitExpress,
        forfaitProgram: transport.forfaitProgram,
        setTransport,
      }}>
      {children}
    </TransportContext.Provider>
  );
};

import gql from 'graphql-tag';

export const DO_GET_BILL_HOTEL_USER = gql`
  query GET_BILL_HOTEL_USER($customer_id: String!) {
    billsHotelUser(customer_id: $customer_id) {
      id
      reference
      amountTotal
      datePaymentBill
      order {
        id
        payments {
          id
          requestBooking {
            id
            dateFirst
            dateLast
            manifest
            comment
            reference
            ResponseBooking {
              id
              rateHotel
              comment
            }
            hotel {
              id
              name
              city
            }
          }
          booking {
            id
            dateFirst
            dateLast
            numberofBooking
            reference
            hotel {
              id
              name
              city
            }
            room {
              id
              numberofRoom
              typeRoom {
                id
                name
                numberPerson
                typeBed
              }
              rateDay
            }
          }
        }
      }
    }
  }
`;

export const DO_GET_BILL_VOL_USER = gql`
  query GET_BILL_VOL_USER($customer_id: String!) {
    billsFlightUser(customer_id: $customer_id) {
      id
      reference
      amountTotal
      datePaymentBill
      order {
        id
        payments {
          requestBookingFlight {
            id
            reference
            cityDeparture
            cityArrival
            manifest
            status
            typeBooking
          }
          requestBookingFlight {
            responseBookingFlights {
              id
              dateFirstOneWay
              dateLastOneWay
              dateFirstReturnWay
              dateLastReturnWay
              nameOfCompagny
            }
          }
        }
      }
    }
  }
`;

export const DO_GET_BILL_DELIVERY_USER = gql`
  query GET_BILL_DELIVERY_USER($customer_id: String!) {
    billsDeliveryUser(customer_id: $customer_id) {
      id
      reference
      amountTotal
      datePaymentBill
      order {
        typeOrder
        id
        payments {
          id
          amount
          delivery {
            id
            reference
            manifest
            manifestItem
            deliveryType
            deliveryDate
            vehiculeType {
              id
              name
              costByDistance
              forfaitExpress
              forfaitProgram
            }
          }
          shortDelivery {
            id
            reference
            distance
            dropoff {
              id
              name
              phone
              address
            }
          }
        }
      }
    }
  }
`;

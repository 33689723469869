import React, { FC, useContext, useState } from 'react';
import useStyles from './style';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  NativeSelect,
  TextField,
  Typography,
} from '@material-ui/core';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CardHistory from '../../Components/CardHistory';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Autocomplete } from '@material-ui/lab';
import iconAdresse from '../../Images/iconAdresse.svg';
import iconeDel from '../../Images/iconeDel.png';
import Groupe12638 from '../../Images/Groupe12638.png';
import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks';
import {
  ADDRESS_BOOK_USER,
  ADDRESS_BOOK_USERVariables,
  ADDRESS_BOOK_USER_addressBooksUser,
} from '../../Graphql/Contact/types/ADDRESS_BOOK_USER';
import { DO_ADDRESS_BOOK_USER } from '../../Graphql/Contact/query';
import SnackVariableInterface from '../../Interfaces/SnackVariableInterface';
import { displaySnackBar } from '../../Utils/snackBarUtils';
import { UserContext } from '../../Providers/User';
import {
  DELETE_ADRESS_BOOK,
  DELETE_ADRESS_BOOKVariables,
} from '../../Graphql/Contact/types/DELETE_ADRESS_BOOK';
import {
  DO_DELETE_ADRESS_BOOK,
  DO_UPDATE_ADRESS_BOOK,
} from '../../Graphql/Contact/mutation';
import {
  UPDATE_ADRESS_BOOK,
  UPDATE_ADRESS_BOOKVariables,
} from '../../Graphql/Contact/types/UPDATE_ADRESS_BOOK';

const CarnetAdresse: FC<RouteComponentProps> = () => {
  const classes = useStyles();
  const client = useApolloClient();
  const { customerId } = useContext(UserContext);
  const [dataContact, setDataContact] = useState<
    ADDRESS_BOOK_USER_addressBooksUser[]
  >([]);
  const [searchContact, setSearchContact] = useState<string | null>(null);
  const [
    selectedContact,
    setSelectedContact,
  ] = useState<ADDRESS_BOOK_USER_addressBooksUser | null>(null);
  const [state, setState] = React.useState<{
    name: string;
    phone: string;
    address: string;
  }>({
    name: '',
    phone: '',
    address: '',
  });

  const { loading, refetch } = useQuery<
    ADDRESS_BOOK_USER,
    ADDRESS_BOOK_USERVariables
  >(DO_ADDRESS_BOOK_USER, {
    onError: (err) => {
      if (err.networkError?.message === 'Failed to fetch') {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Problème de connexion...',
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      }
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: err.message.split(':')[1],
        isOpen: true,
      };
      return displaySnackBar(client, snackBarData);
    },
    onCompleted: (data) => {
      if (data && data.addressBooksUser) {
        setDataContact(data.addressBooksUser);
      }
    },
    fetchPolicy: 'cache-and-network',
    variables: { customer_id: customerId },
  });

  const [doDeleteAdresseBooks, { loading: loadingDelete }] = useMutation<
    DELETE_ADRESS_BOOK,
    DELETE_ADRESS_BOOKVariables
  >(DO_DELETE_ADRESS_BOOK, {
    onError: (err) => {
      if (err.networkError?.message === 'Failed to fetch') {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Problème de connexion...',
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      }
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: err.message.split(':')[1],
        isOpen: true,
      };
      return displaySnackBar(client, snackBarData);
    },
    onCompleted: (data) => {
      const snackBarData: SnackVariableInterface = {
        type: 'SUCCESS',
        message: 'Suppression effectuée',
        isOpen: true,
      };
      displaySnackBar(client, snackBarData);
      setSelectedContact(null);
      setState({ name: '', address: '', phone: '' });
      refetch();
    },
  });

  const [doUpdateContact, { loading: loadingUpdate }] = useMutation<
    UPDATE_ADRESS_BOOK,
    UPDATE_ADRESS_BOOKVariables
  >(DO_UPDATE_ADRESS_BOOK, {
    onError: (err) => {
      if (err.networkError?.message === 'Failed to fetch') {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Problème de connexion...',
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      }
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: err.message.split(':')[1],
        isOpen: true,
      };
      return displaySnackBar(client, snackBarData);
    },
    onCompleted: (data) => {
      const snackBarData: SnackVariableInterface = {
        type: 'SUCCESS',
        message: 'Mis à jour effectuée',
        isOpen: true,
      };
      displaySnackBar(client, snackBarData);
      setSelectedContact(null);
      setState({ name: '', address: '', phone: '' });
      refetch();
    },
  });

  const handleChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
  ) => {
    const name = event.target.name as keyof typeof state;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const handleSelectAddressBook = (id: string) => {
    if (dataContact && dataContact.length > 0) {
      const contact = dataContact.filter((c) => c.id === id);
      if (contact && contact[0]) {
        setSelectedContact(contact[0]);
        setState({
          name: contact[0].name,
          address: contact[0].address,
          phone: contact[0].phone,
        });
      }
    }
  };

  const onPressDelete = () => {
    if (selectedContact) {
      doDeleteAdresseBooks({
        variables: { adressBook_id: [selectedContact.id] },
      });
    }
  };

  const onPressUpdate = () => {
    if (
      selectedContact &&
      (state.name !== selectedContact.name ||
        state.address !== selectedContact.address ||
        state.phone !== selectedContact.phone)
    ) {
      doUpdateContact({
        variables: {
          id: selectedContact.id,
          customer_id: customerId,
          name: state.name,
          phone: state.phone,
          address: state.address,
        },
      });
    }
  };

  const handleSearch = (e: React.ChangeEvent<{}>, value: any) => {
    if (value) {
      setSearchContact(value);
    } else {
      setSearchContact(null);
    }
    setSelectedContact(null);
  };

  const handleText = (value: string) => {
    if (value) {
      setSearchContact(value);
    } else {
      setSearchContact(null);
    }
    setSelectedContact(null);
  };

  const AdresseItems = dataContact.map((book) => {
    if (searchContact && book.name === searchContact) {
      return (
        <Box
          className={classes.demandeContainer}
          key={book.id}
          onClick={() => handleSelectAddressBook(book.id)}
          style={{ cursor: 'pointer' }}>
          <img src={iconAdresse}></img>
          {/* <CardHistory titre={item.titre} date={item.date} price={+item.price} /> */}
          <CardHistory
            Nom={book.name}
            telephone={book.phone}
            Adresse={book.address}
          />
        </Box>
      );
    } else if (!searchContact) {
      return (
        <Box
          className={classes.demandeContainer}
          key={book.id}
          onClick={() => handleSelectAddressBook(book.id)}
          style={{ cursor: 'pointer' }}>
          <img src={iconAdresse}></img>
          {/* <CardHistory titre={item.titre} date={item.date} price={+item.price} /> */}
          <CardHistory
            Nom={book.name}
            telephone={book.phone}
            Adresse={book.address}
          />
        </Box>
      );
    }
  });

  return (
    <Box className={classes.allContent}>
      <Box className={classes.firstContent}>
        <Autocomplete
          freeSolo
          id="free-solo-2-demo"
          disableClearable
          options={dataContact.map((option) => option.name)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Recherche"
              margin="normal"
              variant="filled"
              InputProps={{ ...params.InputProps, type: 'search' }}
              onChange={(e) => handleText(e.target.value)}
            />
          )}
          onChange={handleSearch}
        />
        <Box>
          <ul>{AdresseItems}</ul>
        </Box>
      </Box>
      {selectedContact && (
        <Box className={classes.scndContent}>
          <Box className={classes.contentButtonAdresse}>
            <Button
              aria-label="delete"
              onClick={onPressUpdate}
              disabled={loadingUpdate}>
              <img src={Groupe12638} />
            </Button>
            <Button
              aria-label="delete"
              onClick={onPressDelete}
              disabled={loadingUpdate}>
              <img src={iconeDel} />
            </Button>
          </Box>
          <Box>
            <form className={classes.contentFormAdresse}>
              <TextField
                id="outlined-textarea"
                multiline
                variant="filled"
                name="name"
                value={state.name}
                onChange={handleChange}
              />
              <TextField
                id="outlined-textarea"
                multiline
                variant="filled"
                name="phone"
                value={state.phone}
                onChange={handleChange}
              />{' '}
              <TextField
                id="outlined-textarea"
                multiline
                variant="filled"
                name="address"
                value={state.address}
                onChange={handleChange}
              />
            </form>
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default CarnetAdresse;

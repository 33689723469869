import gql from 'graphql-tag';

export const DO_UPDATE_NOTIFICATION = gql`
  mutation UPDATE_NOTIFICATION($id: String!, $status: String!) {
    updateNotification(id: $id, status: $status) {
      message
      notificationInterm {
        id
        userId
        notification {
          id
          status
        }
        shortDelivery {
          dropoff {
            id
            address
            name
            phone
          }
          id
          status
          reference
          delivery {
            id
            status
            reference
            courier {
              id
            }
            dropoff {
              name
              address
              phone
            }
          }
        }
      }
    }
  }
`;

export const DO_CREATE_NOTIFICATION_ITERM_ADMIN = gql`
  mutation CREATE_NOTIFICATION_ITERM_ADMIN(
    $userId: String!
    $deliveryId: String
    $bookingId: String
    $requestBookingId: String
    $requestBookingFlight: String
    $status: String!
  ) {
    createNotificationIntermAdmin(
      userId: $userId
      deliveryId: $deliveryId
      bookingId: $bookingId
      requestBookingId: $requestBookingId
      requestBookingFlight: $requestBookingFlight
      status: $status
    ) {
      id
      delivery {
        id
        status
        reference
        courier {
          id
        }
        dropoff {
          name
          address
          phone
        }
      }
      notification {
        id
        status
      }
      status
    }
  }
`;

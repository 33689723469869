import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { PaperProps } from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import React, { FC, Fragment, useContext } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import useStyles from './style';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import imageAvion from '../../../Images/avionBlue.svg';
import EllipseBlue from '../../../Images/EllipseBlue.svg';
import ligneBlue from '../../../Images/LigneBlue.svg';
import { useState } from 'react';
import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks';
import {
  MY_REQUEST_BOKING_PROCESSING_VOL,
  MY_REQUEST_BOKING_PROCESSING_VOLVariables,
} from '../../../Graphql/Vol/types/MY_REQUEST_BOKING_PROCESSING_VOL';
import { MY_REQUEST_BOOKING_WAITING_VOLVariables } from '../../../Graphql/Vol/types/MY_REQUEST_BOOKING_WAITING_VOL';
import { DO_MY_REQUEST_BOKING_PROCESSING_VOL } from '../../../Graphql/Vol/query';
import { UserContext } from '../../../Providers/User';
import SnackVariableInterface from '../../../Interfaces/SnackVariableInterface';
import { displaySnackBar } from '../../../Utils/snackBarUtils';
import moment, { now } from 'moment';
import { DO_PAIEMENT } from '../../../Graphql/Paiement/mutation';
import {
  PAIEMENT,
  PAIEMENTVariables,
} from '../../../Graphql/Paiement/types/PAIEMENT';
import { PayementVanillaContext } from '../../../Providers/PayementVanilla';
import { separatorOfMillier } from '../../../Utils/toolsMontant';
import Backdrop from '../../../Components/Backdrop';
import { classnames } from '../../../Components/MapComponent/helper';
import { useRejectFlight } from './RejectBooking';
import { DO_UPDATE_REQUEST_BOOKING_VOL } from '../../../Graphql/Vol/mutation';
import {
  UPDATE_REQUEST_BOOKING_VOL,
  UPDATE_REQUEST_BOOKING_VOLVariables,
} from '../../../Graphql/Vol/types/UPDATE_REQUEST_BOOKING_VOL';

function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const VolRecap: FC<RouteComponentProps> = () => {
  const history = useHistory();
  const GotoAttente = () => {
    history.push('/accueil/Reservation-Vol/Vol-En-Attente');
  };
  const { doRejectFlight, loadingRejectFlight } = useRejectFlight();
  const [indexDetails, setIndexDetails] = useState<number>(0);
  const [open, setOpen] = React.useState(false);
  const { idMe } = useContext(UserContext);
  const client = useApolloClient();
  const [active, setActive] = useState(0);
  const [withCertificateRequest, setWithCertificateRequest] = useState(false);
  const { data, loading: loadingVol } = useQuery<
    MY_REQUEST_BOKING_PROCESSING_VOL,
    MY_REQUEST_BOKING_PROCESSING_VOLVariables
  >(DO_MY_REQUEST_BOKING_PROCESSING_VOL, {
    variables: { user_id: idMe },
    skip: idMe ? false : true,
    fetchPolicy: 'cache-and-network',
    onError: (err) => {
      if (err.networkError?.message === 'Failed to fetch') {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Problème de connexion...',
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      }
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: err.message.split(':')[1],
        isOpen: true,
      };
      return displaySnackBar(client, snackBarData);
    },
    onCompleted: () => {},
  });

  const handleShowDetails = (index: number) => {
    setIndexDetails(index);
  };
  let dateTest = new Date();
  const classes = useStyles();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [doPaiment, { loading: loadingPaiement }] = useMutation<
    PAIEMENT,
    PAIEMENTVariables
  >(DO_PAIEMENT, {
    onCompleted: (data) => {
      history.push('/paiement');
    },
    onError: (err) => {
      if (err.networkError?.message === 'Failed to fetch') {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Problème de connexion...',
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      }
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: err.message.split(':')[1],
        isOpen: true,
      };
      return displaySnackBar(client, snackBarData);
    },
  });

  const [requestCertifcate, { loading: laodingUpdate }] = useMutation<
    UPDATE_REQUEST_BOOKING_VOL,
    UPDATE_REQUEST_BOOKING_VOLVariables
  >(DO_UPDATE_REQUEST_BOOKING_VOL, {
    onCompleted: (DATA) => {
      const snackBarData: SnackVariableInterface = {
        type: 'SUCCESS',
        message: "L'attestation sera envoyée avec la facture",
        isOpen: true,
      };
      displaySnackBar(client, snackBarData);
      setWithCertificateRequest(
        DATA.updateRequestBookingFlight.requestBookingFlight.withCertificat ||
          false,
      );
    },
  });

  const {
    setMontant,
    setIdPanier: setIdPanierVanilla,
    setReference: setReferenceVanilla,
  } = useContext(PayementVanillaContext);

  const onPressPayment = () => {
    setMontant(
      data?.myRequestBookingFlightProcessing[indexDetails]
        .responseBookingFlights[0].rateBookingFlight || 0,
    );
    setIdPanierVanilla(
      'VO' + data?.myRequestBookingFlightProcessing[indexDetails].reference,
    );
    setReferenceVanilla(
      'VO' + data?.myRequestBookingFlightProcessing[indexDetails].reference,
    );
    doPaiment({
      variables: {
        typeOrder: 'REQUEST_BOOKING_FLIGHT',
        requestBookingFlight:
          data?.myRequestBookingFlightProcessing[indexDetails].id,
        reference:
          'VO' + data?.myRequestBookingFlightProcessing[indexDetails].reference,
        paymentMethod: 'PAIEMENT_MOBILE_MONEY',
        datePayment: moment(now()).utc().unix(),
      },
    });
  };
  const handleRejectFlight = (id: any) => {
    doRejectFlight({
      variables: {
        requestBookingFlight_id: id,
      },
    });
  };

  const onPressRequestCertificate = () => {
    if (
      data?.myRequestBookingFlightProcessing[indexDetails].withCertificat ||
      withCertificateRequest
    ) {
      const snackBarData: SnackVariableInterface = {
        type: 'SUCCESS',
        message: "La demmande est d'attestation est déja envoyée",
        isOpen: true,
      };
      displaySnackBar(client, snackBarData);
    } else {
      // requestCertifcate({
      //   variables: {
      //     requestId:
      //       data?.myRequestBookingFlightProcessing[indexDetails].id || '',
      //     customerId:
      //       data?.myRequestBookingFlightProcessing[indexDetails].customerId ||
      //       '',
      //     withCertificat: true,
      //   },
      // });
      // setMontant(30000);
      setMontant(500);
      setIdPanierVanilla(
        'VO_RC' +
          data?.myRequestBookingFlightProcessing[indexDetails].reference,
      );
      setReferenceVanilla(
        'VO_RC' +
          data?.myRequestBookingFlightProcessing[indexDetails].reference,
      );
      doPaiment({
        variables: {
          typeOrder: 'REQUEST_BOOKING_FLIGHT_CERTIFICAT',
          requestBookingFlight:
            data?.myRequestBookingFlightProcessing[indexDetails].id,
          reference:
            'VO_RC' +
            data?.myRequestBookingFlightProcessing[indexDetails].reference,
          paymentMethod: 'PAIEMENT_MOBILE_MONEY',
          datePayment: moment(now()).utc().unix(),
        },
      });
    }
  };

  console.log('data :>> ', data);
  return (
    <Box className={classes.allContent}>
      <Backdrop open={loadingPaiement || loadingVol || laodingUpdate} />
      <Box className={classes.firstContent}>
        <Typography variant="h6">Réservation Vol</Typography>
        <Box className={classes.contentButton}>
          <Button
            variant="contained"
            color="default"
            className={classes.button}
            onClick={GotoAttente}
            startIcon={<AccessTimeIcon />}>
            En Attente
          </Button>
          <Button
            variant="outlined"
            color="default"
            className={classes.buttonActive}
            startIcon={<CheckCircleIcon />}>
            Traité
          </Button>
        </Box>

        {data?.myRequestBookingFlightProcessing.length === 0 ? (
          <Box style={{ fontWeight: 'bold' }}>Aucun vol traité.</Box>
        ) : (
          data?.myRequestBookingFlightProcessing.map((dataVol, index) => {
            index++;
            return (
              <Box
                key={`${index}`}
                onClick={() => {
                  handleShowDetails(index - 1);
                  setActive(index);
                }}
                className={classnames(active === index ? classes.clicked : '')}>
                <Backdrop open={loadingVol} />
                <Box className={classes.demandeContainer}>
                  <Box className={classes.contentDemande}>
                    <Typography variant="subtitle1">
                      Demande de réservation
                    </Typography>
                    <Typography variant="subtitle1">
                      {moment(dataVol.createdAt).format(' DD MMM YYYY')}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="subtitle1"
                      className={classes.demandeAlignement}>
                      Référence :&nbsp;
                      <Typography variant="subtitle1">
                        {' '}
                        {dataVol?.reference}
                      </Typography>
                    </Typography>
                    <Box className={classes.departure}>
                      <Typography
                        variant="subtitle1"
                        className={classes.demandeAlignement}>
                        <img src={imageAvion}></img> {dataVol.cityDeparture}
                      </Typography>
                      <Typography>
                        <img
                          src={ligneBlue}
                          className={classes.alignementAvions}></img>
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        className={classes.demandeAlignement}>
                        <img src={EllipseBlue}></img> {dataVol.cityArrival}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            );
          })
        )}
      </Box>

      {data?.myRequestBookingFlightProcessing.length === 0 ? (
        <Box style={{ fontWeight: 'bold' }}></Box>
      ) : (
        <Box className={classes.scndContent}>
          <Typography variant="caption">Récapitulatif</Typography>
          <Box>
            <Box>
              <Typography variant="subtitle1" className={classes.vueRecap}>
                Vue d&apos;ensemble de votre demande
              </Typography>
              <Box className={classes.contentDemandeScnd}>
                <Typography
                  variant="subtitle1"
                  className={classes.demandeAlignement}>
                  Référence : &nbsp;
                  <Typography variant="subtitle1">
                    {data?.myRequestBookingFlightProcessing.length === 0
                      ? null
                      : data?.myRequestBookingFlightProcessing[indexDetails]
                          .reference}
                  </Typography>
                </Typography>
                <Typography variant="subtitle1">
                  {data?.myRequestBookingFlightProcessing.length === 0
                    ? null
                    : moment(
                        data?.myRequestBookingFlightProcessing[indexDetails]
                          .dateRequestBooking,
                      ).format(' DD MMM YYYY')}
                </Typography>
              </Box>

              <Box className={classes.demandeContainer}>
                <Typography variant="h3">Départ : </Typography>

                <Box className={classes.departure}>
                  <Box className={classes.aligncontentDeparture}>
                    <Typography
                      variant="subtitle1"
                      className={classes.demandeAlignement}>
                      <img src={imageAvion}></img>{' '}
                      {data?.myRequestBookingFlightProcessing.length === 0
                        ? null
                        : data?.myRequestBookingFlightProcessing[indexDetails]
                            .cityDeparture}
                    </Typography>
                    <Typography variant="subtitle1">
                      {data?.myRequestBookingFlightProcessing.length === 0
                        ? '--/--/----'
                        : moment(
                            data?.myRequestBookingFlightProcessing[indexDetails]
                              .responseBookingFlights[0].dateFirstOneWay,
                          ).format('DD MMM YYYY HH:mm')}
                    </Typography>
                  </Box>
                  <Typography>
                    <img
                      src={ligneBlue}
                      className={classes.alignementAvions}></img>
                  </Typography>
                  <Box className={classes.aligncontentDeparture}>
                    <Typography
                      variant="subtitle1"
                      className={classes.demandeAlignement}>
                      <img src={EllipseBlue}></img>
                      {data?.myRequestBookingFlightProcessing.length === 0
                        ? null
                        : data?.myRequestBookingFlightProcessing[indexDetails]
                            .cityArrival}
                    </Typography>
                    <Typography variant="subtitle1">
                      {data &&
                      data?.myRequestBookingFlightProcessing.length === 0
                        ? '--/--/----'
                        : moment(
                            data?.myRequestBookingFlightProcessing[indexDetails]
                              .responseBookingFlights[0].dateLastOneWay,
                          ).format('DD MMM YYYY HH:mm')}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              {data?.myRequestBookingFlightProcessing.length === 0 ? null : data
                  ?.myRequestBookingFlightProcessing[indexDetails]
                  .typeBooking === 'ONE_WAY' ? null : (
                <Box className={classes.demandeContainer}>
                  <Typography variant="h3">Retour : </Typography>
                  <Box className={classes.departure}>
                    <Box className={classes.aligncontentDeparture}>
                      <Typography
                        variant="subtitle1"
                        className={classes.demandeAlignement}>
                        <img src={imageAvion}></img>{' '}
                        {data?.myRequestBookingFlightProcessing.length === 0
                          ? null
                          : data?.myRequestBookingFlightProcessing[indexDetails]
                              .cityArrival}
                      </Typography>
                      <Typography variant="subtitle1">
                        {data &&
                        data?.myRequestBookingFlightProcessing.length === 0
                          ? '--/--/----'
                          : moment(
                              data?.myRequestBookingFlightProcessing[
                                indexDetails
                              ].responseBookingFlights[0].dateFirstReturnWay,
                            ).format('DD MMM YYYY HH:mm')}
                      </Typography>
                    </Box>
                    <Typography>
                      <img
                        src={ligneBlue}
                        className={classes.alignementAvions}></img>
                    </Typography>
                    <Box className={classes.aligncontentDeparture}>
                      <Typography
                        variant="subtitle1"
                        className={classes.demandeAlignement}>
                        <img src={EllipseBlue}></img>{' '}
                        {data?.myRequestBookingFlightProcessing.length === 0
                          ? null
                          : data?.myRequestBookingFlightProcessing[indexDetails]
                              .cityDeparture}
                      </Typography>
                      <Typography variant="subtitle1">
                        {data &&
                        data?.myRequestBookingFlightProcessing.length === 0
                          ? '--/--/----'
                          : moment(
                              data?.myRequestBookingFlightProcessing[
                                indexDetails
                              ].responseBookingFlights[0].dateLastReturnWay,
                            ).format(' DD MMM YYYY HH:mm')}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>

            <Box className={classes.recapDate}>
              <Typography
                variant="subtitle1"
                className={classes.demandeAlignement}>
                <Typography variant="subtitle1" className={classes.colorText}>
                  Compagnie Aérienne :
                </Typography>
                <Typography variant="subtitle1">
                  {data && data?.myRequestBookingFlightProcessing.length === 0
                    ? '---'
                    : data?.myRequestBookingFlightProcessing[indexDetails]
                        .responseBookingFlights[0].nameOfCompagny}
                </Typography>
              </Typography>
            </Box>

            <Box className={classes.contentPassager}>
              <Typography
                variant="subtitle1"
                className={classes.colorTextTransform}>
                Passager(s)
              </Typography>
              <Box className={classes.recapName}>
                <Typography variant="subtitle1">
                  {data?.myRequestBookingFlightProcessing.length === 0
                    ? null
                    : data?.myRequestBookingFlightProcessing[indexDetails]
                        ?.manifest &&
                      JSON.parse(
                        data?.myRequestBookingFlightProcessing[indexDetails]
                          ?.manifest,
                      ).numberAdult}{' '}
                  Adulte(s){' '}
                </Typography>
                <Typography variant="subtitle1">
                  {data?.myRequestBookingFlightProcessing.length === 0
                    ? null
                    : data?.myRequestBookingFlightProcessing[indexDetails]
                        ?.manifest &&
                      JSON.parse(
                        data?.myRequestBookingFlightProcessing[indexDetails]
                          ?.manifest,
                      ).numberChildren}{' '}
                  Enfant(s) de 2 à 12 ans
                </Typography>
                <Typography variant="subtitle1">
                  {data?.myRequestBookingFlightProcessing.length === 0
                    ? null
                    : data?.myRequestBookingFlightProcessing[indexDetails]
                        ?.manifest &&
                      JSON.parse(
                        data?.myRequestBookingFlightProcessing[indexDetails]
                          ?.manifest,
                      ).numberBaby}{' '}
                  Bébé(s)
                </Typography>
              </Box>

              <Typography
                variant="subtitle1"
                className={classes.colorTextTransform}>
                à savoir
              </Typography>

              <Typography variant="subtitle1" className={classes.comment}>
                {data && data?.myRequestBookingFlightProcessing.length === 0
                  ? '---'
                  : data?.myRequestBookingFlightProcessing[indexDetails]
                      .responseBookingFlights[0].comment}
              </Typography>
            </Box>
            <Paper className={classes.contentTotal}>
              <Typography variant="subtitle1">Total à payer </Typography>
              <Typography variant="subtitle1">
                {' '}
                {data && data?.myRequestBookingFlightProcessing.length === 0
                  ? '---'
                  : separatorOfMillier(
                      data?.myRequestBookingFlightProcessing[indexDetails]
                        .responseBookingFlights[0].rateBookingFlight || 0,
                    )}{' '}
                AR{' '}
              </Typography>
            </Paper>
            {/* <Box className={classes.contentPayment}>
            <Typography variant="h3" color="textSecondary">
              Mode de paiement
            </Typography>
            <FormControl component="fieldset" fullWidth>
              <RadioGroup
                defaultValue="female"
                aria-label="gender"
                name="customized-radios">
                <Box className={classes.radioContainer}>
                  <FormControlLabel
                    value="Paiement mobile money"
                    checked={true}
                    control={<Radio color="primary" />}
                    label="Mobile money"
                  />
                </Box>
              </RadioGroup>
            </FormControl>
          </Box> */}
            <Box
              className={
                data?.myRequestBookingFlightProcessing[indexDetails]
                  .typeFlight === 'VOL_INTERNATIONAL'
                  ? classes.contntBtnRsrv2
                  : classes.contntBtnRsrv
              }>
              <Button
                variant="contained"
                color="primary"
                className={classes.firstButton}
                onClick={handleClickOpen}>
                Rejeter
              </Button>
              {data?.myRequestBookingFlightProcessing[indexDetails]
                .typeFlight === 'VOL_INTERNATIONAL' && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onPressRequestCertificate}>
                  Demande d&apos;attestation
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                onClick={onPressPayment}>
                Confirmer
              </Button>
            </Box>
            <Box>
              <Dialog
                open={open}
                onClose={handleClose}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title">
                <DialogTitle
                  style={{ cursor: 'move' }}
                  id="draggable-dialog-title">
                  Rejeter un vol
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Voulez vous vraiment annuler votre vol pour{' '}
                    {data?.myRequestBookingFlightProcessing.length === 0
                      ? 'votre destination'
                      : data?.myRequestBookingFlightProcessing[indexDetails]
                          .cityArrival}
                    ?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button autoFocus onClick={handleClose} color="primary">
                    Annuler
                  </Button>
                  <Button
                    onClick={() => {
                      handleRejectFlight(
                        data?.myRequestBookingFlightProcessing[indexDetails].id,
                      );
                      history.push('/accueil');
                    }}
                    color="primary">
                    Rejeter
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default VolRecap;

import React, { FC } from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import StarIcon from '@material-ui/icons/Star';
import useStyles from './style';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import classnames from 'classnames';

const separatorOfMillier = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

interface propsCardHotel {
  titre: string;
  contentChambre: string;
  price?: number;
  typeBed?: string;
  person?: number;
  pricePromo?: number;
}

const CardHotel: FC<propsCardHotel> = ({
  titre,
  contentChambre,
  price,
  person,
  pricePromo,
}) => {
  const classes = useStyles();
  const star = [];

  // for (let i = 0; i < (starHotel || 0); i++) {
  //   star.push(i);
  // }
  // const numberArroud = (value: number) => {
  //   if (value % 100 === 0) {
  //     return value;
  //   } else {
  //     return Math.ceil((value + 1) / 100) * 100;
  //   }
  // };
  return (
    <Box className={classes.contentCard}>
      <Box className={classes.bookingFirstContent}>
        <Box className={classes.contentDescription}>
          <p className={classes.titreOffres}>{titre}</p>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className={classes.adresseOfre}>
            {contentChambre}
          </Typography>
          {person && person > 0 && (
            <Box className={classes.contentPrice}>
              <Typography variant="body1" color="textSecondary">
                {person} personne(s)
              </Typography>
            </Box>
          )}
          <Box className={classes.contentPrice}>
            <Typography variant="body1" color="textSecondary">
              Tarif par nuit :&nbsp;
            </Typography>
            {price && (
              <Typography className={classes.priceDeco}>
                &nbsp;{separatorOfMillier(price)} Ar
              </Typography>
            )}
          </Box>
          {pricePromo && (
            <Box className={classes.contentPrice}>
              <Typography variant="body1" color="textSecondary">
                Tarif promo:&nbsp;
              </Typography>
              <Typography className={classes.priceDeco}>
                &nbsp;{separatorOfMillier(pricePromo)} Ar
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default CardHotel;

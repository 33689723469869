import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  contentCard: {
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '5px',
    position: 'relative',
    padding: 6,
    '& Button': {},
  },
  bookingFirstContent: {
    padding: '0px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '5px',
  },
  contentDescription: {
    display: 'flex',
    paddingLeft: '15px',
    flexDirection: 'column',
  },
  contentPrice: {
    flexDirection:'row',
    display:'flex',
    alignItems:'baseline',
    width: '100%',
    // marginTop: '20px',
  },
  priceDeco: {
    fontWeight: 900,
    fontSize: '12px',
    color: '#6B6B6B',
  },
  bookingContentImg: {
    display: 'flex',
    justifyContent: 'center',
    margin: '5px',
  },
  textBarrer: {
    textDecoration: 'line-through',
    color: '#6B6B6B',
    fontSize: '10px',
    fontWeight: 'lighter',
    padding: 0,
    margin: 0,
  },
  titreOffres: {
    fontSize: 14,
    fontWeight: 'bold',
    marginTop: '0px',
    marginBottom: '0px',
    display: 'block',
    color: '#6B6B6B',
  },
  adresseOfre: {
    fontSize: '12px',
    fontWeight: 400,
  },
  contentStarFirst: {
    display: 'flex',
    marginTop: '10px',
  },
  promoContent: {
    backgroundColor: '#FFBF51',
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: 'bold',
    width: '50px',
    height: '22px',
    paddingLeft: 8,
    borderTopLeftRadius: '5px',
    borderBottomLeftRadius: '5px',
    marginRight: '-6px',
    lineHeight: '6px',
    color: '#fff',
  },
  percentPromo: {
    fontSize: '10px',
    alignItems: 'top',
    marginTop: '-3px',
    display: 'flex',
  },
  contentStar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    '& Svg': {
      display: 'flex',
      width: 22,
      height: 22,
      color: '#E9685E',
    },
  },
  root: {},
  CardActionArea: {},
  imageBooking: {
    width: 101,
    height: 109,
    borderRadius: '5px',
  },
  bookingButton: {
    color: '#fff',
    right: 0,
    width: '77px',
    height: '25px',
    fontSize: '8px',
    bottom: 0,
    display: 'flex',
    position: 'absolute',
    backgroundColor: '#E9685E',
  },
});

export default useStyles;

import React, { FC } from 'react';
import { Button } from '@material-ui/core';

interface ButtonVolProps {
  title: string;
  id: string;
  selectedId: string;
  onClick?: (event: any) => void;
}

const ButtonVol: FC<ButtonVolProps> = ({ title, id, selectedId, onClick }) => {
  return (
    <Button
      onClick={onClick}
      variant="contained"
      style={{
        background: selectedId === id ? '#55A7EE' : 'white',
        color: selectedId === id ? 'white' : '#000',
        border: selectedId === id ? undefined : '1px solid #E6E6E6',
      }}>
      {title}
    </Button>
  );
};
export default ButtonVol;

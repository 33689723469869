import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	delivery: {
		display: 'flex',
		height: '100%',
		'& button:not(:only-child):not(:last-of-type)': {
			marginRight: theme.spacing(2)
		}
	},
	controls: {
		flex: '0 0 35%'
	},
	map: {
		flex: 'auto'
	},
	flex: {
		display: 'flex',
		paddingTop: '35px',
		justifyContent: 'space-evenly',
		marginTop: 'auto',
		'& button:last-of-type': {
			displa: 'none',
			minWidth: '45%'
		}
	}
}));
export default useStyles;

import gql from 'graphql-tag';

export const DO_NOTIFICATION_CUSTOMER = gql`
  query NOTIFICATION_CUSTOMER($idUser: String!) {
    notificationsCustomer(idUser: $idUser) {
      id
      status
      createdAt
      shortDelivery {
        dropoff {
          id
          address
          name
          phone
        }
        id
        status
        reference
        delivery {
          id
          status
          reference
          courier {
            id
          }
          dropoff {
            name
            address
            phone
          }
        }
      }
      requestBooking {
        id
        reference
        hotel {
          id
          name
        }
      }
      requestBookingFlight {
        id
        reference
        status
      }
      notification {
        id
        status
      }
    }
  }
`;

export const DO_GET_NUMBER_NOTIFICATION = gql`
  query GET_NUMBER_NOTIFICATION($idUser: String!) {
    num_notification(idUser: $idUser)
  }
`;

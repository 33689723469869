import moment from 'moment';
import { Moment } from 'moment';
import React, { useState } from 'react';
import { createContext, FC } from 'react';

enum Conveyance {
  BIKE = 'bike',
  CAR = 'car',
  UTILITY = 'utility',
  MOTORBIKE = 'motorbike',
}

interface DeliveryInfoInputProps {
  id: string;
  conveyance: string;
  dateDelivery: string;
  dateType: string;
  selectedIdButtonPaiement: string;
  description: string;
  size: string;
  weight: string;
  personne: string;
  reference: string;
  imageUrl: string;
  imageName: string;
  imageBlob: File | null;
  setId: (id: string) => void;
  setConveyance: (conveyance: Conveyance) => void;
  setImageUrl: (image: string) => void;
  setImageName: (image: string) => void;
  setImageBlob: (image: File | null) => void;
  setDateDelivery: (date: string) => void;
  setDateType: (type: string) => void;
  setSelectedIdButtonPaiement: (type: string) => void;
  setDescription: (description: string) => void;
  setSize: (size: string) => void;
  setWeight: (weigth: string) => void;
  setPersonne: (personne: string) => void;
  setReference: (value: string) => void;
  clearDeliveryInfo: () => void;
}

export const DeliveryInfoContext = createContext<DeliveryInfoInputProps>({
  id: '',
  conveyance: '',
  dateDelivery: '',
  description: '',
  dateType: 'now',
  size: '0',
  selectedIdButtonPaiement: '1',
  imageName: '',
  imageUrl: '',
  imageBlob: null,
  weight: '0',
  personne: '0',
  reference: '',
  setId: (id: string) => {},
  setDateType: (type: string) => {},
  setSelectedIdButtonPaiement: (type: string) => {},
  setImageName: (image: string) => {},
  setImageBlob: (image: File | null) => {},
  setImageUrl: (image: string) => {},
  setConveyance: (conveyance: Conveyance) => {},
  setDateDelivery: (date: string) => {},
  setDescription: (description: string) => {},
  setSize: (size: string) => {},
  setWeight: (weigth: string) => {},
  setPersonne: (personne: string) => {},
  setReference: (value: string) => {},
  clearDeliveryInfo: () => {},
});

export const DeliveryInfoProvider: FC = ({ children }) => {
  const getRandomInt = (length: number) => {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const [id, setId_] = useState('');
  const [conveyance, setConveyance_] = useState('');
  const [dateDelivery, setDateDelivery_] = useState(
    moment(new Date()).utc().format(),
  );

  const [dateType, setDateType] = useState('now');

  const [description, setDescription_] = useState('');
  const [size, setSize_] = useState('');
  const [weight, setWeight_] = useState('');
  const [personne, setPersonne] = useState('');
  const [reference, setReference_] = useState(getRandomInt(7));

  const [imageName, setImageName] = useState('');
  const [imageUrl, setImageUrl] = useState('');

  const [selectedIdButtonPaiement, setSelectedIdButtonPaiement] = useState<
    string
  >('1');

  const [imageBlob, setImageBlob] = useState<File | null>(null);

  return (
    <DeliveryInfoContext.Provider
      value={{
        id: id,
        imageUrl,
        imageName,
        conveyance: conveyance,
        dateDelivery: dateDelivery,
        description: description,
        size: size,
        weight: weight,
        selectedIdButtonPaiement,
        dateType,
        reference: reference,
        imageBlob,
        personne,
        setPersonne,
        setImageBlob,
        setImageName,
        setImageUrl,
        clearDeliveryInfo: () => {
          setId_('');
          setConveyance_('');
          setDateDelivery_(moment(new Date()).utc().format());
          setDateType('now');
          setDescription_('');
          setSize_('');
          setWeight_('');
          setPersonne('');
          setReference_(getRandomInt(7));
          setImageName('');
          setImageUrl('');
          setSelectedIdButtonPaiement('1');
          setImageBlob(null);
        },
        setId: (id: string) => setId_(id),
        setDateType,
        setSelectedIdButtonPaiement,
        setConveyance: (conveyance: Conveyance) => setConveyance_(conveyance),
        setDateDelivery: (date: string) =>
          setDateDelivery_(moment(date).utc().format()),
        setDescription: (conveyance: string) => setDescription_(conveyance),
        setSize: (size: string) => setSize_(size),
        setWeight: (weight: string) => setWeight_(weight),
        setReference: (value: string) => setReference_(value),
      }}>
      {children}
    </DeliveryInfoContext.Provider>
  );
};

import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  flex: {
    display: 'flex',
    '&>div': {
      flex: '1 1 50%',
    },
    '&>div:first-of-type': {
      marginRight: theme.spacing(2),
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"]': {
      paddingTop: '12px'
    }
  },
  addDestionation: {
    justifyContent: 'flex-start',
    textAlign: 'left',
    paddingLeft: `${theme.spacing(2)}px !important`,
    fontWeight: 400,
    color: theme.palette.primary.main,
  },
  root: {
    width: '100%',
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
  modifFont: { '& .MuiFormLabel-root': { fontSize: '15px', color: '#c0c2d0' } },

  modifInput: {
    fontSize:'14px',
    color:'gray',
  },














}));

import gql from 'graphql-tag';
import { VOL_INFO_FRAGEMENT } from './fragment';

export const DO_MY_REQUEST_BOOKING_WAITING_VOL = gql`
  query MY_REQUEST_BOOKING_WAITING_VOL($user_id: String!) {
    myRequestBookingFlightWaiting(user_id: $user_id) {
      ...VolInfo
    }
  }
  ${VOL_INFO_FRAGEMENT}
`;

export const DO_MY_REQUEST_BOKING_PROCESSING_VOL = gql`
  query MY_REQUEST_BOKING_PROCESSING_VOL($user_id: String!) {
    myRequestBookingFlightProcessing(user_id: $user_id) {
      ...VolInfo
    }
  }
  ${VOL_INFO_FRAGEMENT}
`;
export const DO_NUM_REQUEST_BOOKING_FLIGHTS = gql`
  query NUM_REQUEST_BOOKING_FLIGHTS($user_id: String!) {
    num_RequestBookingFlights(user_id: $user_id)
  }
`;

export const GET_VOL = gql`
  query VOL($name: String!) {
    aeroportFilter(name: $name) {
      id
      name
      continent
      iata
      municipality
    }
  }
`;

export const GET_VOL_MG = gql`
  query VOL_MG($name: String!) {
    aeroportFilterMg(name: $name) {
      id
      name
      continent
      iata
    }
  }
`;

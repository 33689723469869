import React from 'react';
import styles from './style';
import { Typography } from '@material-ui/core';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

interface IMenuItem {
  icon?: JSX.Element;
  title: string;
  active?: boolean;
  to: string;
}

export default function MenuItem(props: IMenuItem) {
  const classes = styles();
  const { icon, title, active, to } = props;

  return (
    <Link
      to={to}
      className={classnames(classes.link, active ? classes.active : '')}>
      {icon}
      <Typography component="span">{title}</Typography>
    </Link>
  );
}

import { useApolloClient } from '@apollo/react-hooks';
import { uniqBy } from 'lodash';
import React, { useState } from 'react';
import { createContext, FC } from 'react';
import SnackVariableInterface from '../Interfaces/SnackVariableInterface';
import { displaySnackBar } from '../Utils/snackBarUtils';

export interface DestinationsInterface {
  id: string;
  name: string;
  phone: string;
  address: string;
  addressBookId: string;
  latitude: string;
  longitude: string;
}

interface DestinationsInputProps {
  destinations: DestinationsInterface[];
  addDestination: (destination: DestinationsInterface) => void;
  removeDestination: (id: string) => void;
  clearDestinations: () => void;
}

export const DestinationsContext = createContext<DestinationsInputProps>({
  destinations: [],
  addDestination: (destination: DestinationsInterface) => {},
  removeDestination: (id: string) => {},
  clearDestinations: () => {},
});

export const DestinationsProvider: FC = ({ children }) => {
  const [destinations, setDestinations] = useState<DestinationsInterface[]>([]);
  const client = useApolloClient();

  return (
    <DestinationsContext.Provider
      value={{
        destinations,
        addDestination: (destination: DestinationsInterface) => {
          if (destinations.length === 5) {
            const snackBarData: SnackVariableInterface = {
              type: 'ERROR',
              message: 'Vous avez atteint le nombre maximum de destination.',
              isOpen: true,
            };
            displaySnackBar(client, snackBarData);
          } else {
            setDestinations(uniqBy([...destinations, destination], 'id'));
          }
        },
        removeDestination: (id: string) => {
          let newDest =
            destinations &&
            destinations.filter((destination) => destination.id != id);
          setDestinations(newDest);
        },
        clearDestinations: () => {
          setDestinations([]);
        },
      }}>
      {children}
    </DestinationsContext.Provider>
  );
};

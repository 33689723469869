import gql from 'graphql-tag';

export const DO_CREATE_DOCUMENT_USER = gql`
  mutation CREATE_DOCUMENT_USER(
    $userId: String!
    $deliveryId: String
    $bookingId: String
    $requestBookingId: String
    $requestBookingFlightId: String
    $files: [Files!]
  ) {
    createDocumentUser(
      userId: $userId
      deliveryId: $deliveryId
      bookingId: $bookingId
      requestBookingId: $requestBookingId
      requestBookingFlightId: $requestBookingFlightId
      files: $files
    ) {
      message
    }
  }
`;

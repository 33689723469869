import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  flex: {
    display: 'flex',
    '&>div': {
      flex: '1 1 50%',
      display:'flex',
      paddingBottom:5,
      '& :first-child div':{
        paddingBottom:3,
        paddingLeft:10,
        '&.MuiInput-underline:before': {          
          borderBottom: '1px solid rgb(230 230 230) !important',
        },
      },
    },
    '& :first-of-type div': {
      // marginRight: theme.spacing(2),
      marginRight:'10px',
    },
  },
  addDestionation: {
    justifyContent: 'flex-start',
    textAlign: 'left',
    paddingLeft: `${theme.spacing(2)}px !important`,
    fontWeight: 400,
    color: theme.palette.primary.main,
  },
}));

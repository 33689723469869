import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function Weight (props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 19.602 15.681">
      <path
        id="Icon_awesome-balance-scale"
        data-name="Icon awesome-balance-scale"
        d="M7.841,10.291h0c0-.5.041-.267-2.6-5.559a1.471,1.471,0,0,0-2.63,0C-.063,10.069,0,9.811,0,10.291H0c0,1.353,1.755,2.45,3.92,2.45S7.841,11.644,7.841,10.291ZM3.92,5.39,6.126,9.8H1.715Zm15.681,4.9c0-.5.041-.267-2.6-5.559a1.471,1.471,0,0,0-2.63,0c-2.668,5.337-2.6,5.079-2.6,5.559h0c0,1.353,1.755,2.45,3.92,2.45s3.92-1.1,3.92-2.45ZM13.476,9.8l2.205-4.41L17.887,9.8Zm2.7,3.92h-5.39V4.694A2.452,2.452,0,0,0,12.2,2.94h3.97a.49.49,0,0,0,.49-.49V1.47a.49.49,0,0,0-.49-.49H11.75a2.428,2.428,0,0,0-3.9,0H3.43a.49.49,0,0,0-.49.49v.98a.49.49,0,0,0,.49.49H7.4A2.452,2.452,0,0,0,8.821,4.694v9.027H3.43a.49.49,0,0,0-.49.49v.98a.49.49,0,0,0,.49.49H16.171a.49.49,0,0,0,.49-.49v-.98A.49.49,0,0,0,16.171,13.721Z"
      />
    </SvgIcon>
  );
}

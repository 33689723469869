import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export default function Hotel(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 26.359 30.124">
      <path
        id="Icon_awesome-building-2"
        data-name="Icon awesome-building"
        d="M25.653,28.242H24.476V1.412A1.412,1.412,0,0,0,23.064,0H3.295A1.412,1.412,0,0,0,1.883,1.412V28.242H.706A.706.706,0,0,0,0,28.948v1.177H26.359V28.948A.706.706,0,0,0,25.653,28.242ZM7.531,4.472a.706.706,0,0,1,.706-.706h2.353a.706.706,0,0,1,.706.706V6.825a.706.706,0,0,1-.706.706H8.237a.706.706,0,0,1-.706-.706Zm0,5.648a.706.706,0,0,1,.706-.706h2.353a.706.706,0,0,1,.706.706v2.353a.706.706,0,0,1-.706.706H8.237a.706.706,0,0,1-.706-.706Zm3.06,8.708H8.237a.706.706,0,0,1-.706-.706V15.768a.706.706,0,0,1,.706-.706h2.353a.706.706,0,0,1,.706.706v2.353A.706.706,0,0,1,10.591,18.828Zm4.472,9.414H11.3V23.3A.706.706,0,0,1,12,22.593h2.353a.706.706,0,0,1,.706.706Zm3.766-10.12a.706.706,0,0,1-.706.706H15.768a.706.706,0,0,1-.706-.706V15.768a.706.706,0,0,1,.706-.706h2.353a.706.706,0,0,1,.706.706Zm0-5.648a.706.706,0,0,1-.706.706H15.768a.706.706,0,0,1-.706-.706V10.12a.706.706,0,0,1,.706-.706h2.353a.706.706,0,0,1,.706.706Zm0-5.648a.706.706,0,0,1-.706.706H15.768a.706.706,0,0,1-.706-.706V4.472a.706.706,0,0,1,.706-.706h2.353a.706.706,0,0,1,.706.706Z"
        transform="translate(0)"
      />
    </SvgIcon>
  );
}

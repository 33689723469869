import gql from 'graphql-tag';

export const DO_SIGNUP_PARTICULAR = gql`
  mutation SIGNUP_PARTICULAR(
    $name: String
    $firstName: String
    $phone: String!
    $email: String!
    $password: String!
    $userType: String!
  ) {
    signupParticular(
      name: $name
      firstName: $firstName
      phone: $phone
      email: $email
      password: $password
      userType: $userType
    ) {
      accessToken
      user {
        id
        email
      }
    }
  }
`;

import gql from 'graphql-tag';

export const DO_GET_DISTANCE = gql`
  query GET_DISTANCE($listUser: [UserState!]) {
    getDistance(listUser: $listUser) {
      id
      phone
      name
      address
      latitude
      longitude
      idPickOrDrop
      distanceParcouru
      dureeApproximatif
    }
  }
`;

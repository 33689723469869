import { makeStyles } from '@material-ui/core/styles';
import transitions from '@material-ui/core/styles/transitions';

const useStyles = makeStyles({
  allContent: {
    display: 'flex',
    margin: 'auto',
    height: '100%',
    overflow: 'auto',
    '& .MuiMenu-paper': {
      display: 'none',
    },
  },

  bookingContaitner: {
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
    overflow: 'auto',
    margin: 'auto',
    textTransform: 'unset',
    backgroundColor: '#fff',
    paddingBottom: '50px',
    '& Button': {
      borderRadius: '5px',
      marginRight: '10px',
      border: 'none',
    },
    '&span': {},
  },
  bookingButton: {
    backgroundColor: '#E9685E',
    color: '#fff',
    fontSize: '10px',
    width: 77,
    height: 25,
    display: 'flex',
    lineHeight: '25px',
    padding: '0 !important',
    position: 'absolute',
    right: 0,
    bottom: '8px',
    '& :hover': {
      backgroundColor: '#D66158',
      borderRadius: '5px',
    },
  },
  root: {},
  positionnementBouton: {
    position: 'relative',
    marginBottom: '20px',
    width: '375px',
    webkitBoxShadow: '0px 3px 6px 2px rgba(0,0,0,0.26)',
    mozBoxShadow: '0px 3px 6px 2px rgba(0,0,0,0.26)',
    boxShadow: '0px 3px 6px 2px rgba(0,0,0,0.26)',
    '@media (max-width : 1270px)': {
      width: 500,
    },
  },
  contentOffre: {
    display: 'flex',
    margin: 'auto',
    flexFlow: 'row wrap',
    justifyContent: 'space-evenly',
  },
  input: {
    marginLeft: '2px',
    flex: 1,
    fontSize: '12px',
    paddingLeft: '10px',
    height: '27px',
    lineHeight: 40,
    color: '#000',
  },
  iconButton: {
    padding: 0,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  list: {
    display: 'none',
  },

  inputcontent: {
    width: '57%',
    alignSelf:'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '30px',
    marginBottom: '30px',

    '& form': {
      display: 'flex',
      width: '60%',
      justifyContent: 'center',
      borderRadius: '20px',
      alignItems: 'center',
      '& .MuiMenu-list': {
        display: 'flex',
        flexDirection: 'column',
      },
      '& .MuiIconButton-label': {
        fontSize: 14,
        lineHeight: '33px',
        padding: '0px 10px 0px 10px',
        borderRadius: '20px',
        color: '#fff',
        backgroundColor: '#55A7EE',
      },
    },
    '& .MuiButton-containedPrimary:hover': {
      color: '#000',
    },
    '& .MuiButton-contained': {
      // border: '1px solid gray',
      backgroundColor: 'unset',
      '& :hover': {
        color: 'red',
        backgroundColor: 'unset',
      },
    },
    '@media (max-width : 750px)': {
      width: '80%',
    },
  },
  '@keyframes shake': {
    
    '10% , 90%': {
      transform: 'translate3d(-11px, 0, 0)',
    },

    '20%, 80% ': {
      transform: 'translate3d(12px, 0, 0)',
    },

    ' 30%, 50%, 70%': {
      transform: 'translate3d(-14px, 0, 0)',
    },

    ' 40%, 60%': {
      transform: ' translate3d(14px, 0, 0)',
    },
  },
  inputOnShake: {
    width:"180%",
    marginRight: '20px',
 
    fontSize: '14px',
    color: '#000',
    borderRadius: '20px',
    webkitBoxShadow: '0px 3px 6px 0px rgba(0,0,0,0.16)',
    mozBoxShadow: '0px 3px 6px 0px rgba(0,0,0,0.16)',
    boxShadow: '0px 3px 6px 0px rgba(0,0,0,0.16)',
    paddingLeft: 20,
    animation: `$shake 1000ms ${transitions.easing.easeInOut}`,
  },
  inputNormal: {
    width:"180%",
    marginRight: '20px',
    fontSize: '14px',
    
    color: '#000',
    borderRadius: '20px',
    webkitBoxShadow: '0px 3px 6px 0px rgba(0,0,0,0.16)',
    mozBoxShadow: '0px 3px 6px 0px rgba(0,0,0,0.16)',
    boxShadow: '0px 3px 6px 0px rgba(0,0,0,0.16)',
    paddingLeft: 20,
   
  },
  percentPromo: {},
  menuBooking: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& li': {
      paddingTop: 10,
      borderBottom: '1px solid #000',
    },
    '& li:first-child': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  boutonContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '77%',
    margin: 'auto',
    marginBottom: '40px',
    '& span': {
      fontWeight: 'lighter',
      textTransform: 'uppercase',
    },
    '@media (max-width : 1366px)': {
      display: 'flex',
      marginTop: '20px',
      width: '90%',
      padding: '0',
    },
    '@media (max-width : 1220px)': {
      display: 'flex',
      marginTop: '20px',
      width: '100%',
      padding: '0',
    },
    '@media (max-width : 1200px)': {
      display: 'flex',
      flexDirection: 'column',
      width: '90%',
      '& Button': {
        marginBottom: '20px',
      },
    },
    '@media (max-width : 1000px)': {
      display: 'flex',
      flexDirection: 'column',
      width: '80%',
    },
    '@media (max-width : 750px)': {
      display: 'flex',
      flexDirection: 'column',
      width: '90%',
    },
  },

  firstBouton: {
    backgroundColor: 'unset',
    border: 'none',
    color: '#55A7EE',
    fontSize: '16px',
    fontWeight: 900,
  },

  secondBouton: {
    backgroundColor: 'none',
  },
  listEtoiles: {
    width: '100%',
    '& .MuiSelect-select.MuiSelect-select ,.MuiInputLabel-formControl': {
      paddingLeft: '20px !important',
    },

    input: {
      paddingLeft: '20px',
    },
  },
  typo :{
    fontSize: 12,
    marginLeft:300,
    marginBottom:12,
    marginTop:-30,
    color:"red",
  //  animation: `$shake 1000ms ${transitions.easing.easeInOut}`,
  },
});
export default useStyles;

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  allContent: {
    display: 'flex',
    // justifyContent: 'space-around',
    minHeight: '720px',
    width: '80%',
    margin: 'auto',
    backgroundColor: '#fff',
    '@media (max-width:1365px)': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      width: '100%',
      alignItems: 'center',
    },
    '@media (max-width:1024px)': {},
  },
  firstContent: {
    display: 'flex',
    width: '50%',
    color: '#000',
    flexDirection: 'column',
    webkitBoxShadow: '0px 3px 6px 0px rgba(0,0,0,0.27)',
    mozBoxShadow: '0px 3px 6px 0px rgba(0,0,0,0.27)',
    boxShadow: '0px 3px 6px 0px rgba(0,0,0,0.27)',
    padding: '20px',
    '& ul': {
      margin:0,
      padding:0,
    },
    '& button': {
      width: '35%',
      padding: '0 !important',
    },
    '@media (max-width:1365px)': {
      webkitBoxShadow: 'none',
      mozBoxShadow: 'none',
      boxShadow: 'none',
      width: '80%',
      borderBottom: '1px solid #ECECEC',
    },
    '@media (max-width:1024px)': {
      width: '85%',
    },
    '@media (max-width:650px)': {
      width: '100%',
    },
    '& .MuiButton-contained ': {
      backgroundColor: '#55A7EE',
      color: '#fff',
      fontWeight: 'normal',
      '& span': {
        color: '#fff',
      },
    },
  },
  demandeAlignement: {
    display: 'flex',
  },
  colorText: {
    color: '#707070',
  },
  comment: {
    color: 'black',
    width: '90%',
  },
  titleBolder: {
    fontWeight: 'bolder',
    textTransform: 'uppercase',
    color: '#707070',
    paddingLeft: 20,
  },
  contentDemandeScnd: {
    marginTop: '20px',
    marginBottom: '10px',
    padding: '10px 0 10px 0',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid gray',
  },
  demandeContainer: {
    padding: '20px 0 0px 0',
    borderBottom: '1px solid #C7D3E6',
    alignItems: 'flex-Start',
    display: 'flex',
    '& img': {
      marginRight:20,
    },  
  },
  contentButton: {
    paddingTop: 20,
    paddingBottom: 0,
    display: 'flex',
    borderBottom: '1px solid gray',
    '& nth-child(1)': {
      backgroundColor: 'red !important',
    },
  },
  iconAdreser: {},
  addressContent: {
    display: 'flex',
    padding: '20px 0 20px 0',
    justifyContent: 'start',
    alignItems: 'center',
    '& :nth-child(1)': {
      display: 'flex',
      marginTop: '-12px',
      width: '28px',
      height: '42px',
      color: '#6B6B6B',
      marginRight: '20px',
    },
    '& :nth-child(2)': {
      width: '70%',
      color: '#6B6B6B',
    },
  },
  statusReservation: {
    padding: '20px 10px 20px 10px',
    fontSize: 12,
    borederRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#E6E6E6',
    color: '#000',
    '& :first-child': {
      fontSize: 12,
    },
    '& :nth-child(2)': {
      backgroundColor: '#6B6B6B',
      padding: '0px 10px 0px 10px',
      lineHeight: '27px',
      borderRadius: 30,
      display: 'flex',
      justifyContent: 'center',
      fontSize: 12,
      width: '30%',
      color: '#fff',
    },
    '& :last-child': {
      fontSize: 12,
      width: '50%',
      textAlign: 'center',
    },
  },
  recapDate: {
    margin: '20px 10px 20px 0px ',
  },
  recapName: {
    margin: '0px 10px 10px 0px ',
  },
  separator: {
    width: '60%',
    display: 'flex',
    height: '1px',
    backgroundColor: '#fff',
    margin: '10px 0 10px 0 ',
  },
  contentDemande: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  scndContent: {
    width: '50%',
    padding: '50px 50px 20px 50px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& h6': {
      fontSize:14,
    },
    '& h5': {
      fontSize:18,
    },
    '& span': {
      // color: 'red',
      fontSize: 16,
      fontWeight: 600,
    },    '@media (max-width:1365px)': {
      webkitBoxShadow: 'none',
      mozBoxShadow: 'none',
      boxShadow: 'none',
      width: '80%',
      borderBottom: '1px solid #ECECEC',
    },
    '@media (max-width:1024px)': {
      width: '85%',
    },
    '@media (max-width:650px)': {
      width: '100%',
    },
  },
  contentDistance: {
    fontSize:'14px',
  },
  scndContentDate: {
      fontSize:12,
      borderBottom:'1px solid gray',
      paddingBottom:10,
      marginBottom:10,
      '& :nth-child(2)div':{
        display:'flex ',
      },
  },
  alignementScndContent: {
    display:'flex',
    padding:'0px 0px 5px 0',
    justifyContent:'space-between',

  },
  alignementScndContent1: {
    display:'flex',
    padding:'0px 0px 10px 0',
    marginBottom:'20px',
    justifyContent:'space-between',
    borderBottom:'1px solid #C7D3E6',
  },
  alignementScndContent2: {
    display:'flex',
    flexDirection:'column',
    padding:'0px 0px 10px 0',
    marginBottom:'20px',
    justifyContent:'space-between',
    borderBottom:'1px solid #C7D3E6',
  },
  alignContentLast :{
    fontSize: '14px',
    marginBottom: '20px',
    borderTop: '1px solid #c7d3e6',
    paddingTop: '20px',
    '& :nth-child(2)':{
      marginBottom:20,
    },
    '& Button': {
      minWidth:'40%',
      maxWidth:'41%',
      backgroundColor:'grey',
      color:'#fff',
      '&:hover':{
        backgroundColor:'#969393',
      },
    },
  },
  button: {
    borderRadius: '5px',
    marginRight: '20px',
  },
  formControl: {
    // margin:10,
    minWidth: '100%',
    fontSize:14,
    '& :after': {
      display:'none',
    },
  },
  selectEmpty: {
    '&.MuiInput-input > option ':{
      padding:20,
    }, 
  },
  alignementScndConten:{
display:'flex',
fontWeight:900,
  },
  buttonDownload: {
    backgroundColor:'#706d6b',
    borderRadius:2,
    color:'#fff',
    '&:hover':{  
    backgroundColor:'#848282',
  },
    },
});
export default useStyles;
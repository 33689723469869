import { makeStyles } from '@material-ui/core/styles';
import GroupeDeMasque from '../../Images/packge_mobix.jpg';


const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    minHeight: '100vh',
  },
  left: {
    flex: '0 0 40%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: theme.spacing(4),
    textAlign: 'center',
    color: '#FFF',
    background: 'linear-gradient(180deg, #59ACF1 14%, #1551BB 100%)',
    '&>div': {
      width: '100%',
      padding: theme.spacing(0, 2),
      '@media(min-width:1601px)': {
        width: '65%',
      },
      '@media(min-width:501px) and (max-width:850px)': {
        width: '75%',
      },
      '@media(min-width:1366px)': {
        width: '75%',
      },
    },
    '&>div:last-of-type': {
      marginBottom: '5rem',
    },
    '& label': {
      textAlign: 'left',
      '& span, & a': {
        lineHeight: 1,
      },
    },
    '& button:not(.MuiIconButton-edgeEnd)': {
      margin: '1rem auto 1.5rem',
    },
    '& h6': {
      marginTop: theme.spacing(3),
    },
    '@media(max-width:991px)': {
      flex: '0 0 50%',
    },
    '@media(max-width:850px)': {
      flex: '0 0 100%',
    },
  },
  btnPrev: {
    marginTop: '10%',
    '& button': {
      borderRadius: '50%',
      border: '1px solid #FFF',
    },
  },
  bg: {
    flex:1,
    background: `url(${GroupeDeMasque}) center right / cover no-repeat`,
    '@media(max-width:850px)': {
      background: 'none',
    },
  },
}));

export default useStyles;

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  demandeContainer: {
    padding: '20px 0 0px 0',
    borderBottom: '1px solid #C7D3E6',
    alignItems: 'flex-Start',
    display: 'flex',
    '& img': {
      marginRight:20,
    },  
  },
  clicked:{
    backgroundColor: '#ECF1F9',
  },
});
export default useStyles;
import React, { useState, FC } from 'react';
import { Typography, Box, Button } from '@material-ui/core';
import useStyles from './style';
import logo from '../../Images/logo.svg';
import TextInput from '../../Components/TextInput';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { displaySnackBar } from '../../Utils/snackBarUtils';
import SnackVariableInterface from '../../Interfaces/SnackVariableInterface';
import { LOGINVariables, LOGIN } from '../../Graphql/Login/types/LOGIN';
import { DO_LOGIN } from '../../Graphql/Login/mutation';
import CustomLink from '../../Components/CustomLink';
import Backdrop from '../../Components/Backdrop';
import { Link } from 'react-router-dom';

const Login: FC<RouteComponentProps> = () => {
  const classes = useStyles();

  const [login, setLogin] = useState<LOGINVariables>({
    email: '',
    password: '',
  });

  const client = useApolloClient();

  const [doLogin, { loading }] = useMutation<LOGIN, LOGINVariables>(DO_LOGIN, {
    onCompleted: (data) => {
      if (data && data.login) {
        if (
          data &&
          data.login &&
          data.login.user &&
          data.login.user.customerId
        ) {
          localStorage.setItem('token', data.login.accessToken);
          window.location.reload();
        } else {
          const snackBarData: SnackVariableInterface = {
            type: 'ERROR',
            message: 'Vous avez entré un compte pour un livreur',
            isOpen: true,
          };
          return displaySnackBar(client, snackBarData);
        }
      } else {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message:
            "Erreur lors de l'authentification, Verifier l'email et le mot de passe en entrée",
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      }
    },
    onError: (err) => {
      if (err.networkError?.message === 'Failed to fetch') {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Problème de connexion...',
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      }
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: err.message.split(':')[1],
        isOpen: true,
      };
      return displaySnackBar(client, snackBarData);
    },
  });

  const onChangeText = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.target;
    setLogin({ ...login, [name]: value });
  };

  const onClickConnect = () => {
    doLogin({ variables: { ...login } });
  };

  return (
    <Box className={classes.container}>
      <Backdrop open={loading} />
      <Box className={classes.left}>
        <Box className={classes.logo}>
          <img src={logo} alt="logo" />
        </Box>

        <Box className={classes.login}>
          <Typography variant="h1">Connexion</Typography>

          <TextInput
            variant="outlined"
            onChange={onChangeText}
            name="email"
            placeholder="Adresse email ou numéro de téléphone"
            autoComplete="off"
            fullWidth
          />
          <TextInput
            variant="outlined"
            type="password"
            onChange={onChangeText}
            name="password"
            placeholder="Mot de passe"
            fullWidth
          />

          <Button variant="contained" color="primary" onClick={onClickConnect}>
            Se connecter
          </Button>
          <CustomLink
            variant="subtitle2"
            color="inherit"
            href="/initpassword"
            underline="hover">
            Mot de passe oublié ?
          </CustomLink>
        </Box>

        <Box className={classes.terms}>
          <Typography>
            En vous connectant sur notre plateforme, <br />
            vous êtes d&apos;accord avec&nbsp;
            <Link style={{ color: 'inherit' }} to="/terms">
              les termes et les conditions
            </Link>
          </Typography>
        </Box>
      </Box>
      <Box className={classes.bg} />
    </Box>
  );
};

export default withRouter(Login);

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      background: 'rgba(0,0,0,0.5) !important',
      width: '100vw',
      height: '100vh',
      alignItems: 'center',
      justifyContent: 'center',
    },
    element: {
      display: 'flex',
      justifyContent: 'center',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 9999,
      color: '#fff',
      position: 'fixed',
      background: 'rgba(0,0,0,0.5) !important',
      // width: '100vw',
      // height: '100vh',
    },
  }),
);

export default useStyles;

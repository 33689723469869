import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  recap: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3),
    backgroundColor: '#ECF1F9',
  },
  flex: {
    display: 'flex',
    '&>*': {
      flex: '0 0 50%',
    },
    '&>*:first-child': {
      paddingRight: theme.spacing(1.5),
    },
  },
  borderBottom: {
    marginBottom: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    borderBottom: '1px solid #C7D3E6',
  },
  radioContainer: {
    width: '80%',
    marginBottom: theme.spacing(3),
    padding: theme.spacing(0, 2),
    color: theme.palette.background.paper,
    borderRadius: 6,
    '&:first-of-type': {
      backgroundColor: '#A7A7A7',
    },
    '&:last-of-type': {
      backgroundColor: '#58C6DE',
    },
    '& label': {
      width: '100%',
    },
  },
}));

import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    '&>div': {
      padding: theme.spacing(2),
    },
  },
  header: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    alignContent: 'center',
    borderBottom: '1px solid #E6E6E6',
    '&>h1': {
      marginBottom: 0,
      fontWeight: 'normal',
    },
    '&>button': {
      marginLeft: 'auto',
    },
  },
  body: ({ height }: any) => {
    return {
      flex: 'auto',
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
      maxHeight: height - 155,
    };
  },
}));

import gql from 'graphql-tag';

// subscription newPositionCourierSubscription {
//   #id User courier
// newPositionCourierSubscription(courier_id: "cketm8m8h0686htous4ffyczo") {
//   id
//   location
// }
// }

export const DO_NEW_POSITION_COURIER_SUBSCRIPTION = gql`
  subscription NEW_POSITION_COURIER_SUBSCRIPTION($courier_id: String) {
    newPositionCourierSubscription(courier_id: $courier_id) {
      id
      location
    }
  }
`;

export const DO_NEW_NOTIFICATION_DELIVERY_COURIER = gql`
  subscription NEW_NOTIFICATION_DELIVERY_COURIER($courier_id: String) {
    newNotificationDeliveryCourier(courier_id: $courier_id) {
      id
      manifest
      manifestItem
      reference
      courierId
      customerId
      pickup {
        id
        address
        phone
        location
        name
      }
      dropoff {
        id
        address
        phone
        location
        name
      }
      courier {
        id
        name
        phone
      }
      vehicule {
        id
        immatriculation
      }
      deliveryDate
      status
      deliveryType
      vehiculeType {
        id
        name
        costByDistance
        forfaitExpress
        forfaitProgram
        status
        capacity
      }
      shortDelivery {
        id
        manifest
        manifestItem
        reference
        distance
        customerId
        pickup {
          id
          address
          phone
          location
        }
        dropoff {
          id
          address
          phone
          location
          name
        }
        status
        payment {
          id
          status
          paymentMethod
        }
      }
    }
  }
`;

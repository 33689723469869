import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { useContext, useState } from 'react';
import {
  DO_MY_DELIVERY_WAITING,
  DO_MY_DELIVERY_ON_THE_WAY,
} from '../../Graphql/Delivery/query';
import {
  MY_DELIVERY_ON_THE_WAY,
  MY_DELIVERY_ON_THE_WAYVariables,
} from '../../Graphql/Delivery/types/MY_DELIVERY_ON_THE_WAY';
import {
  MY_DELIVERY_WAITING,
  MY_DELIVERY_WAITINGVariables,
} from '../../Graphql/Delivery/types/MY_DELIVERY_WAITING';
import SnackVariableInterface from '../../Interfaces/SnackVariableInterface';
import { UserContext } from '../../Providers/User';
import { displaySnackBar } from '../../Utils/snackBarUtils';

export const useGetNumberDelivery = () => {
  const client = useApolloClient();
  const { idMe } = useContext(UserContext);

  const [numberWaiting, setnumberWaiting] = useState(0);
  const [numberOnWay, setnumberOnWay] = useState(0);

  const { loading: loadingWaiting } = useQuery<
    MY_DELIVERY_WAITING,
    MY_DELIVERY_WAITINGVariables
  >(DO_MY_DELIVERY_WAITING, {
    variables: { id: idMe },
    skip: idMe ? false : true,
    onError: (err) => {
      if (err.networkError?.message === 'Failed to fetch') {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Problème de connexion...',
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      }
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: err.message.split(':')[1],
        isOpen: true,
      };
      return displaySnackBar(client, snackBarData);
    },
    onCompleted: (data) => {
      if (data && data.myDeliveriesWaiting && data.myDeliveriesWaiting) {
        setnumberWaiting(data.myDeliveriesWaiting.length);
      }
    },
    fetchPolicy: 'network-only',
  });

  const { loading: loadingOnTheWay } = useQuery<
    MY_DELIVERY_ON_THE_WAY,
    MY_DELIVERY_ON_THE_WAYVariables
  >(DO_MY_DELIVERY_ON_THE_WAY, {
    variables: { id: idMe },
    skip: idMe ? false : true,
    onError: (err) => {
      if (err.networkError?.message === 'Failed to fetch') {
        const snackBarData: SnackVariableInterface = {
          type: 'ERROR',
          message: 'Problème de connexion...',
          isOpen: true,
        };
        return displaySnackBar(client, snackBarData);
      }
      const snackBarData: SnackVariableInterface = {
        type: 'ERROR',
        message: err.message.split(':')[1],
        isOpen: true,
      };
      return displaySnackBar(client, snackBarData);
    },
    onCompleted: (data) => {
      if (data && data.myDeliveriesOnTheWay && data.myDeliveriesOnTheWay) {
        setnumberOnWay(data.myDeliveriesOnTheWay.length);
      }
    },
    fetchPolicy: 'network-only',
  });

  return {
    numberWaiting,
    numberOnWay,
    loadingWaiting,
    loadingOnTheWay,
  };
};
